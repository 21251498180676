import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "../../../dashboard-common/DetailsTags";
import { getArrayValue, getBooleanValue} from "../../../dashboard-common/DashboardHelper";
import DetailsGrid from "../../../dashboard-common/DetailsGrid";


const dashboardName = "Backup Plans";

const SubMenu = () => {
    const { projectId } = useParams();
  
    return (
      <>
        <h3>Backup</h3>
        <div className="otherTableTitles ">
          <h3 className="font-15">   
           <Link className="text-color-gray" to={ROUTES.DATA_PROTECTION_BACKUP_POLICY + "/" + projectId}>
             Policies
            </Link></h3>
          <h3 className="font-15">  
          <Link className="text-color-gray" to={ROUTES.DATA_PROTECTION_BACKUP_VAULTS + "/" + projectId}>  
             Vaults
         </Link>
          </h3>
          <h3 className="font-15 activetable">           
             Plans
          </h3>
          <h3 className="font-15">
            <Link className="text-color-gray" to={ROUTES.DATA_PROTECTION_BACKUP_JOBS + "/" + projectId}>
             Backup Jobs
            </Link>
          </h3>
          <h3 className="font-15">
            <Link className="text-color-gray" to={ROUTES.DATA_PROTECTION_BACKUP_COPY_JOBS + "/" + projectId}>
             Copy Jobs
            </Link>
          </h3>
        </div>
      </>
    );
  };


  const Details = ({ close, selectedRow }) => {
    const [type, setType] = useState("Details");
  
    return (
      <div className="justify-content-center align-items-end account-detail">
        <div className="w-100 s-flex flex-column account-detail-area">
          <button className="exitAccount" onClick={() => close()}>
            <img src="../images/close.svg" />
          </button>
          <div className="">
            <ul className="account-detail-link m-0 p-0 d-inline-flex">
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Details" && "my_active"
                }`}
                onClick={() => setType("Details")}
              >
                Details
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Backup Rules" && "my_active"
                }`}
                onClick={() => setType("Backup Rules")}
              >
               Backup Rules
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Resource Assignments" && "my_active"
                }`}
                onClick={() => setType("Resource Assignments")}
              >
                Resource Assignments
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Backup Plan Tags" && "my_active"
                }`}
                onClick={() => setType("Backup Plan Tags")}
              >
                Backup Plan Tags
              </li> 
            </ul>
          </div>
          <div className="d-flex w-100 account-detail-container-area">
            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Details" && "inaktif"
              }`}
            >
              <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.BackupPlan && selectedRow.BackupPlan.AccountId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Region</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.Region}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Backup Plan Name</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.BackupPlan && selectedRow.BackupPlan.BackupPlanName}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Backup Plan ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.BackupPlanId}
                  </p>
                </div>
              </div>
              <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Backup Plan ARN
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow && selectedRow.BackupPlanArn}
                  </p>
                </div>
  
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Version ID
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow && selectedRow.VersionId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Created Date</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow && selectedRow.CreationDate}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Last Execution Date
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {/*TODO*/}
                  </p>
                </div>
              </div>
            </div>

            <DetailsGrid
              type={type}
              typeName="Backup Rules"
              array={selectedRow.BackupPlan.Rules}
              metadata={[
                { header: "Rule Name", key: "RuleName" },
                { header: "Backup Vault", key: "TargetBackupVaultName" },
                { header: "Schedule Expression", key: "ScheduleExpression" },
                { header: "Start within", key: "StartWindowMinutes" },
                { header: "Complete within", key: "CompletionWindowMinutes" }, 
                // { header: "Move to Cold Storage After", key: "" }, 
                { header: "Delete After", key: "Lifecycle.DeleteAfterDays" },
                // { header: "Recovery Point Tags", key: "" },
                 { header: "Rule Id", key: "RuleId" },
                // { header: "Destination Region", key: "" },
                // { header: "Destination Vault ARN", key: "" },
                // { header: "Destination - Move to Cold Storage After", key: "" },
                // { header: "Destination - Delete After", key: "" }            
              ]}
            ></DetailsGrid>

          {/*ToDO:  Json data not available*/}
          {/*   <DetailsGrid
              type={type}
              typeName="Resource Assignments"
              array={selectedRow.BackupPlan}
              metadata={[
                { header: "Assignment Name", key: "" },
                { header: "Tag-Value", key: "" },
                { header: "IAM Role ARN", key: "" },         
              ]}
            ></DetailsGrid> */}

          {/*   <DetailsTags type={type} tags={selectedRow.Tags}></DetailsTags> */}

          </div>
        </div>
      </div>
    );
  };

  const Navigations = () => {
    const { projectId } = useParams();
    return (
      <>
{/*       <Link
      className="btn-post btn-animation d-inline-flex"
      to={ROUTES.DATA_PROTECTION_ACM + "/" + projectId}
    >
      <img src="../images/ribbon-design.svg" />
      <p className="m-0 p-0 ml-2 font-weight-bold">TGW Routing Domains</p>
    </Link> */}
    <Link
      className="btn-post btn-animation d-inline-flex"
      to={ROUTES.DATA_PROTECTION_ACM + "/" + projectId}
    >
      <p className="m-0 p-0 mr-2 font-weight-bold">ACM Certificates</p>
      <img src="../images/ribbon-designRight.svg" />
    </Link>
    </>
    );
  };
  
  
const BackupPlans = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Account ID",
        accessor: "AccountId",
        id:"Account ID"
      },
      {
        Header: "Region",
        accessor: "Region",
        id:"Region"
      },
      {
        Header: "Backup Plan Name",
        accessor: "BackupPlan.BackupPlanName",
        id: "Backup Plan Name"
      },
      {
        Header: "Backup Plan ID",
        accessor: "BackupPlanId",
        id: "Backup Plan ID"
      },
      {
        Header: "Creation Date",
        accessor: "CreationDate",
        id: "Creation Date"
      },
      {
        Header: "Last Execution Date",
        accessor: "LastExecutionDate",
        id: "Last Execution Date"
      },
      {
        Header: "Backup Plan ARN",
        accessor: "BackupPlanArn",
        id: "Backup Plan ARN"
      },
      {
        Header: "Version ID",
        accessor: "VersionId",
        id: "Version ID"
      }
    ],
    []
  );

  return Dashboard({
    domain: "dataprotection",
    title: "Data Protection",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.DATA_PROTECTION_BACKUP_ARCHIVAL,
    designRoute: ROUTES.DESIGN_DATA_PROTECTION,
    SubMenu: SubMenu,
  });
};

export default BackupPlans;