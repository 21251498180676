import React from "react";
import { Link } from "react-router-dom";
import LeftHandMenu from "./project/LeftHandMenu";
import TopMenu from "./TopMenu";
import DomainMenu from "./project/DomainMenu";

import * as ROUTES from "../constants/routes";

const NotFound = () => (
  <>
    <div className="userTitleTop">Account Framework</div>
    <LeftHandMenu></LeftHandMenu>
    <div className="container-fluid h-100 px-0">
      <div className="row h-100">
        <div className="fitToScreenRight h-100 d-flex flex-column">
          <TopMenu></TopMenu>
          <form className="tableArea accountsTableArea haveFilter coreTableArea container-fluid p-0 d-flex flex-column">
            <DomainMenu></DomainMenu>
            <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
              <div className="row d-flex align-items-center">
                <div className="col-12 pl-lg-0 px-2">
                  <h3>Coming Soon!</h3>
                  <Link to={ROUTES.ENTERPRISE_CORE_ACCOUNTS}>Go Home</Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </>
);

export default NotFound;
