import * as ROUTES from "../constants/routes";

export const getFirstFeatureRoute = (projectType) => {
  let route = ROUTES.HOME;

  switch (projectType.toLowerCase()) {
    case "aws":
      route = ROUTES.ENTERPRISE_CORE_ACCOUNTS;
      break;
    case "gcp":
      route = ROUTES.GCP_ENTERPRISE_CORE_ACCOUNTS;
      break;
    case "alicloud":
      route = ROUTES.ALICLOUD_ENTERPRISE_CORE_ACCOUNTS;
      break;
    case "azure":
      route = ROUTES.AZURE_ENTERPRISE_CORE_ACCOUNTS;
      break;
    default:
      break;
  }

  return route;
};

export const getPrelaunch = (projectType) => {
  let route = ROUTES.HOME;

  switch (projectType.toLowerCase()) {
    case "aws":
      route = ROUTES.PRELAUNCH;
      break;
    case "gcp":
      route = ROUTES.GCP_PRELAUNCH;
      break;
    case "alicloud":
      route = ROUTES.ALICLOUD_PRELAUNCH;
      break;
    case "azure":
      route = ROUTES.AZURE_PRELAUNCH;
      break;
    default:
      break;
  }

  return route;
};
