/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getClients = /* GraphQL */ `
  query GetClients {
    getClients {
      ClientID
      ClientName
      DomainName
      EnableDocAccess
      EnableBuildAccess
      ClientAdmins {
        UserID
        EmailID
        FirstName
        LastName
        Privilege
      }
      Logo
      CreatedOn
      CreatedBy
      UpdatedOn
      UpdatedBy
      Projects {
        ProjectID
        ProjectName
      }
    }
  }
`;
export const findUsersByDomainName = /* GraphQL */ `
  query FindUsersByDomainName($domainName: String) {
    findUsersByDomainName(domainName: $domainName) {
      UserID
      EmailID
      FirstName
      LastName
      Privilege
    }
  }
`;
export const getClientByID = /* GraphQL */ `
  query GetClientByID($ClientID: String!) {
    getClientByID(ClientID: $ClientID) {
      client {
        ClientID
        ClientName
        DomainName
        EnableDocAccess
        EnableBuildAccess
        Logo
        CreatedOn
        CreatedBy
        UpdatedOn
        UpdatedBy
      }
      domainUsers {
        UserID
        EmailID
        FirstName
        LastName
        Privilege
      }
    }
  }
`;
export const getProjects = /* GraphQL */ `
  query GetProjects {
    getProjects {
      ProjectID
      ProjectName
      ClientID
      ClientName
      ClientLogo
      ProjectType
      ProjectStatus
      EnableLaunchAccess
      AssignedUsers
      AssignedUserIDAndEmailIDs {
        UserID
        EmailID
      }
      SourceCodeBucket
      CreatedOn
      CreatedBy
      UpdatedOn
      UpdatedBy
    }
  }
`;
export const getProjectByID = /* GraphQL */ `
  query GetProjectByID($ProjectID: String!) {
    getProjectByID(ProjectID: $ProjectID) {
      project {
        ProjectID
        ProjectName
        ClientID
        ClientName
        ClientLogo
        ProjectType
        ProjectStatus
        EnableLaunchAccess
        AssignedUsers
        SourceCodeBucket
        CreatedOn
        CreatedBy
        UpdatedOn
        UpdatedBy
      }
      domainUsers {
        UserID
        EmailID
        FirstName
        LastName
        Privilege
      }
    }
  }
`;
export const getMyProjects = /* GraphQL */ `
  query GetMyProjects {
    getMyProjects {
      projects {
        ProjectID
        ProjectName
        ClientID
        ClientName
        ClientLogo
        ProjectType
        ProjectStatus
        EnableLaunchAccess
        AssignedUsers
        SourceCodeBucket
        CreatedOn
        CreatedBy
        UpdatedOn
        UpdatedBy
      }
      clientName
      clientLogo
    }
  }
`;
export const findUsersByClientID = /* GraphQL */ `
  query FindUsersByClientID($clientId: String) {
    findUsersByClientID(clientId: $clientId) {
      UserID
      EmailID
      FirstName
      LastName
      Privilege
    }
  }
`;
export const hasDocAccess = /* GraphQL */ `
  query HasDocAccess($ProjectID: String!) {
    hasDocAccess(ProjectID: $ProjectID)
  }
`;
export const getProjectAccess = /* GraphQL */ `
  query GetProjectAccess($ProjectID: String!) {
    getProjectAccess(ProjectID: $ProjectID) {
      hasBuildAccess
      hasDashboardAccess
      hasLaunchAccess
      hasDocAccess
      executionARN
    }
  }
`;
export const getProjectStatus = /* GraphQL */ `
  query GetProjectStatus($projectId: String!) {
    getProjectStatus(projectId: $projectId) {
      status
      updatedOn
      message
    }
  }
`;
export const getRemoveProjectInfoByID = /* GraphQL */ `
  query GetRemoveProjectInfoByID($projectId: String!) {
    getRemoveProjectInfoByID(projectId: $projectId) {
      ProjectID
      ProjectName
      ProjectStatus
      ProjectType
      CreatedOn
      CreatedUserFirstName
      CreatedUserLastName
    }
  }
`;
export const getProjectType = /* GraphQL */ `
  query GetProjectType($ProjectID: String!) {
    getProjectType(ProjectID: $ProjectID)
  }
`;
export const getUsers = /* GraphQL */ `
  query GetUsers {
    getUsers {
      UserID
      EmailID
      FirstName
      LastName
      Privilege
      AssignedProjects {
        ProjectID
        ProjectName
      }
      ClientName
      CreatedOn
      CreatedBy
      UpdatedOn
      UpdatedBy
    }
  }
`;
export const findProjectsByDomainName = /* GraphQL */ `
  query FindProjectsByDomainName($DomainName: String) {
    findProjectsByDomainName(DomainName: $DomainName) {
      ProjectID
      ProjectName
    }
  }
`;
export const getUserByEmailID = /* GraphQL */ `
  query GetUserByEmailID($EmailID: String!) {
    getUserByEmailID(EmailID: $EmailID) {
      UserID
      EmailID
      FirstName
      LastName
      Privilege
    }
  }
`;
export const getLogs = /* GraphQL */ `
  query GetLogs($nextToken: String) {
    getLogs(nextToken: $nextToken) {
      LastEvaluatedKey
      Items {
        Date
        Action
        AddInfo
        PerformedBy
      }
    }
  }
`;
export const getComplianceManagement = /* GraphQL */ `
  query GetComplianceManagement {
    getComplianceManagement {
      ServicesInScope {
        Name
        Value
      }
      StandardAssessmentFrameworks {
        Name
        Value
      }
      Standards {
        Name
        Value
      }
    }
  }
`;
export const getPatchManagement = /* GraphQL */ `
  query GetPatchManagement {
    getPatchManagement {
      APP {
        OS
      }
      OS {
        OS
      }
    }
  }
`;
export const getImages = /* GraphQL */ `
  query GetImages {
    getImages {
      Arn
      OsVersion
      Name
      DateCreated
    }
  }
`;
export const getWorkspaceBundles = /* GraphQL */ `
  query GetWorkspaceBundles {
    getWorkspaceBundles {
      BundleId
      Name
      LastUpdatedTime
    }
  }
`;
