import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import {
  validate1to10AlphaNumeric,
  validate1to10AlphaNumericHyphen,
  validateCidr,
  validateTgwasn,
} from "../../../../helpers/validator";
import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

//const projectId = "google_1";
const PREFIX = "/aws/network/intervpcconnectivity";
const SDLC_KEY = "/aws/accountframework/environmentstrategy/sdlcstageslist";
const REGION_KEY = "/aws/network/aws/awsregionslist";

const InterVPCConnectivity = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    deployintervpcconnectivity: {
      value: "yes",
    },
    productionnetworksegment: {
      value: "",
    },
    networkSegments: [],
    regions: [],
  });

  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched", "Deployed"]);

  const awsAmplify = useAwsAmplify();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        console.log(result);

        /* const sdlcResult = await awsAmplify.loadProjectData(
          projectId,
          SDLC_KEY
        );
        console.log(sdlcResult); */

        const regionResult = await awsAmplify.loadProjectData(
          projectId,
          REGION_KEY
        );
        console.log(regionResult);

        setIsLoading(false);

        setEmpty(result.data.empty);

        const fe = convertBEToFE(
          result.data,
          //sdlcResult.data,
          regionResult.data
        );
        console.log(fe);

        setFormData(fe);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);

        if (err.response.data.message === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (data, regionData) => {
    if (!data) {
      data = {};
    }

    const fe = {
      deployintervpcconnectivity: {
        value: data[`${PREFIX}/deployintervpcconnectivity`] || "yes",
      },
      productionnetworksegment: {
        value: data[`${PREFIX}/productionnetworksegment`] || "",
      },
      networkSegments: [createNetworkSegment()],
      regions:
        regionData && regionData[REGION_KEY]
          ? regionData[REGION_KEY].split(",").map((reg) => {
              return {
                region: reg,
                value: "",
                isValid: false,
                networkSegments: [createNetworkSegment(true)],
              };
            })
          : [],
    };

    if (data[`${PREFIX}/networksegmentslist`]) {
      fe.networkSegments = data[`${PREFIX}/networksegmentslist`]
        .split(",")
        .map((ns) => ({
          value: ns,
          isValid: true,
        }));
    }

    fe.regions.forEach((region) => {
      if (data[`${PREFIX}/${region.region}/tgwasn`]) {
        region.value = data[`${PREFIX}/${region.region}/tgwasn`];
        region.isValid = true;
      }

      region.networkSegments = fe.networkSegments.map((ns) => ({
        value: ns.value,
        isValid: ns.value ? true : false,
        cidrs: data[`${PREFIX}/${region.region}/${ns.value}/cidrblocks`]
          ? data[`${PREFIX}/${region.region}/${ns.value}/cidrblocks`]
              .split(",")
              .map((cidr) => ({
                value: cidr,
                isValid: true,
              }))
          : [
              {
                value: "",
                isValid: false,
              },
            ],
      }));
    });

    return fe;
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/deployintervpcconnectivity`]: formData
        .deployintervpcconnectivity.value,
    };

    if (formData.deployintervpcconnectivity.value === "yes") {
      if (formData.productionnetworksegment.value !== "") {
        be[`${PREFIX}/productionnetworksegment`] =
          formData.productionnetworksegment.value;
      }

      const networkSegmentsList = formData.networkSegments
        .filter((ns) => ns.isValid)
        .map((ns) => ns.value);

      if (networkSegmentsList.length > 0) {
        be[`${PREFIX}/networksegmentslist`] = networkSegmentsList.join(",");
      }

      const networkSegmentsObj = {};

      networkSegmentsList.forEach((ns) => {
        networkSegmentsObj[ns] = [];
      });

      console.log(networkSegmentsObj);

      let globalCidrs = [];

      formData.regions.forEach((region) => {
        if (region.isValid) {
          be[`${PREFIX}/${region.region}/tgwasn`] = region.value;
        }

        let regionalCidrs = [];

        region.networkSegments.forEach((ns) => {
          if (ns.isValid) {
            const nsCidrs = ns.cidrs
              .filter((cidr) => cidr.isValid)
              .map((cidr) => cidr.value);

            if (nsCidrs.length > 0) {
              be[
                `${PREFIX}/${region.region}/${ns.value}/cidrblocks`
              ] = nsCidrs.join(",");

              regionalCidrs = regionalCidrs.concat(nsCidrs);

              networkSegmentsObj[ns.value] = networkSegmentsObj[
                ns.value
              ].concat(nsCidrs);
            }
          }
        });

        if (regionalCidrs.length > 0) {     
          be[
            `${PREFIX}/${region.region}/regionalcidrlist`
          ] = regionalCidrs.join(",");

          globalCidrs = globalCidrs.concat(regionalCidrs);
        }
      });

      
      if(globalCidrs.length > 0)
      {
        be[`${PREFIX}/globalcidrlist`] = globalCidrs.join(",");
      }

      for (const ns in networkSegmentsObj) {
        if (networkSegmentsObj[ns].length > 0) {
          be[`${PREFIX}/${ns}/globalcidrlist`] = networkSegmentsObj[ns].join(
            ","
          );
        }
      }
    }

    console.log(be);

    return be;
  };

  const addNetworkSegment = () => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, { networkSegments: { $push: [createNetworkSegment()] } })
    );

    setFormData((state) => ({
      ...state,
      regions: state.regions.map((region) => ({
        ...region,
        networkSegments: update(region.networkSegments, {
          $push: [createNetworkSegment(true)],
        }),
      })),
    }));
  };

  const removeNetworkSegment = (idx) => {
    if (formData.networkSegments.length > 1) {
      setIsDirty(true);

      setFormData((state) =>
        update(state, { networkSegments: { $splice: [[idx, 1]] } })
      );

      setFormData((state) => ({
        ...state,
        regions: state.regions.map((region) => ({
          ...region,
          networkSegments: update(region.networkSegments, {
            $splice: [[idx, 1]],
          }),
        })),
      }));
    }
  };

  const createNetworkSegment = (withCidrs) => {
    return {
      value: "",
      isValid: false,
      cidrs: [
        {
          value: "",
          isValid: false,
        },
      ],
    };
  };

  const addCidr = (idxRegion, idxNs) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        regions: {
          [idxRegion]: {
            networkSegments: {
              [idxNs]: {
                cidrs: { $push: [{ value: "", isValid: false }] },
              },
            },
          },
        },
      })
    );
  };

  const removeCidr = (idxRegion, idxNs, idxCidr) => {
    if (formData.regions[idxRegion].networkSegments[idxNs].cidrs.length > 1) {
      setIsDirty(true);

      setFormData((state) =>
        update(state, {
          regions: {
            [idxRegion]: {
              networkSegments: {
                [idxNs]: { cidrs: { $splice: [[idxCidr, 1]] } },
              },
            },
          },
        })
      );
    }
  };

  const exists = (value) => {
    return formData.segmentedenvironments.indexOf(value) !== -1;
  };

  /* const modifyArray = (value) => {
    setIsDirty(true);

    if (exists(value)) {
      setFormData((state) =>
        update(state, {
          segmentedenvironments: {
            $splice: [[state.segmentedenvironments.indexOf(value), 1]],
          },
        })
      );
    } else {
      setFormData((state) =>
        update(state, { segmentedenvironments: { $push: [value] } })
      );
    }
  }; */

  const onFieldArrayChange = (idx, value, key1) => {
    setErrorMsg(null);
    setIsDirty(true);

    setFormData((state) =>
      update(state, { [key1]: { [idx]: { value: { $set: value } } } })
    );
  };

  const onFieldArrayBlur = (validateFunc, idx, value, key1) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      setFormData((state) =>
        update(state, {
          [key1]: { [idx]: { isValid: { $set: errorMsg === undefined } } },
        })
      );

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      setFormData((state) =>
        update(state, {
          [key1]: { [idx]: { isValid: { $set: false } } },
        })
      );
    }
  };

  const onNetworkSegmentFieldArrayChange = (idx, value, key1) => {
    setErrorMsg(null);
    setIsDirty(true);

    setFormData((state) =>
      update(state, { [key1]: { [idx]: { value: { $set: value } } } })
    );

    setFormData((state) => ({
      ...state,
      regions: state.regions.map((region) => ({
        ...region,
        networkSegments: update(region.networkSegments, {
          [idx]: { value: { $set: value } },
        }),
      })),
    }));
  };

  const onNetworkSegmentFieldArrayBlur = (validateFunc, idx, value, key1) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      setFormData((state) =>
        update(state, {
          [key1]: { [idx]: { isValid: { $set: errorMsg === undefined } } },
        })
      );

      setFormData((state) => ({
        ...state,
        regions: state.regions.map((region) => ({
          ...region,
          networkSegments: update(region.networkSegments, {
            [idx]: { isValid: { $set: errorMsg === undefined } },
          }),
        })),
      }));

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      setFormData((state) =>
        update(state, {
          [key1]: { [idx]: { isValid: { $set: false } } },
        })
      );

      setFormData((state) => ({
        ...state,
        regions: state.regions.map((region) => ({
          ...region,
          networkSegments: update(region.networkSegments, {
            [idx]: { isValid: { $set: false } },
          }),
        })),
      }));
    }
  };

  const onCidrFieldArrayChange = (idxRegion, idxNs, idxCidr, value) => {
    setErrorMsg(null);
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        regions: {
          [idxRegion]: {
            networkSegments: {
              [idxNs]: {
                cidrs: { [idxCidr]: { value: { $set: value } } },
              },
            },
          },
        },
      })
    );
  };

  const onCidrFieldArrayBlur = (idxRegion, idxNs, idxCidr, value) => {
    if (value !== "") {
      const errorMsg = validateCidr(value);

      setFormData((state) =>
        update(state, {
          regions: {
            [idxRegion]: {
              networkSegments: {
                [idxNs]: {
                  cidrs: {
                    [idxCidr]: { isValid: { $set: errorMsg === undefined } },
                  },
                },
              },
            },
          },
        })
      );

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      setFormData((state) =>
        update(state, {
          regions: {
            [idxRegion]: {
              networkSegments: {
                [idxNs]: {
                  cidrs: {
                    [idxCidr]: { isValid: { $set: false } },
                  },
                },
              },
            },
          },
        })
      );
    }
  };

  const getFieldArrayValidationClass = (idx, key1) => {
    return !formData[key1][idx].isValid && formData[key1][idx].value !== ""
      ? "invalid"
      : "";
  };

  const getCidrValidationClass = (idxRegion, idxNs, idxCidr) => {
    return !formData.regions[idxRegion].networkSegments[idxNs].cidrs[idxCidr]
      .isValid &&
      formData.regions[idxRegion].networkSegments[idxNs].cidrs[idxCidr]
        .value !== ""
      ? "invalid"
      : "";
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Network</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Network"
                isDirty={isDirty}
                dashboardRoute={ROUTES.VPC}
                designRoute={ROUTES.DESIGN_NETWORK}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-1 px-lg-4 ">
                <div className="row d-flex align-items-center ">
                  <div className="col-xl-6 col-lg-5 col-md-4 pl-lg-0 px-2">
                    <h3 className="font-weight-bold">Inter-VPC Connectivity</h3>
                  </div>
                  <div className="col-xl-6 col-lg-7 col-md-8 d-flex pr-0 align-items-center justify-content-end rightNavArea">
                    <div className="d-inline-flex yes-no-area align-items-center">
                      <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                        Deploy Inter-VPC Connectivity
                      </div>
                      <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                        <label
                          className={`createElementBtn btn btn-link btn-linkYes mw-70 ${
                            formData.deployintervpcconnectivity.value ===
                              "yes" && "active"
                          }`}
                        >
                          <input
                            type="radio"
                            name="options"
                            checked={
                              formData.deployintervpcconnectivity.value ===
                              "yes"
                            }
                            onChange={() => {
                              setIsDirty(true);
                              setFormData((state) =>
                                update(state, {
                                  deployintervpcconnectivity: {
                                    value: { $set: "yes" },
                                  },
                                })
                              );
                            }}
                          />{" "}
                          YES
                        </label>
                        <label
                          className={`dontCreateElementBtn btn btn-link btn-linkNo mw-70 ${
                            formData.deployintervpcconnectivity.value ===
                              "no" && "active"
                          }`}
                        >
                          <input
                            type="radio"
                            name="options"
                            checked={
                              formData.deployintervpcconnectivity.value === "no"
                            }
                            /* onChange={() => {
                              setIsDirty(true);
                              setFormData((state) =>
                                update(state, {
                                  deployintervpcconnectivity: {
                                    value: { $set: "no" },
                                  },
                                })
                              );
                            }} */
                          />{" "}
                          NO
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                 {empty && (
                   <NoInitialData />
                 )}           
                {formData.deployintervpcconnectivity.value === "yes" && (
                  <div className="d-flex flex-column   pb-3 pt-0">
                    <div className="show-hide-content">
                      {/* <div className="row  px-md-0 px-2 my-1 align-items-center ">
                        <div className=" col-xl-8 col-lg-12 col-12 d-inline-flex selectionArea my-1 px-2 justify-content-between flex-wrap py-2">
                          <span className="font-weight-bold font-12">
                            Inter-VPC-Network
                          </span>
                          <div className="hideTargetBtn">
                            <label className="container ">
                              Flat Network
                              <input
                                type="radio"
                                name="single"
                                checked={
                                  formData.intervpcnetwork.value ===
                                  "flatnetwork"
                                }
                                onChange={() => {
                                  setIsDirty(true);
                                  setFormData((state) =>
                                    update(state, {
                                      intervpcnetwork: {
                                        value: { $set: "flatnetwork" },
                                      },
                                    })
                                  );
                                }}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                          <div className="showTargetBtn">
                            <label className="container">
                              Segmented Network
                              <input
                                type="radio"
                                name="single"
                                checked={
                                  formData.intervpcnetwork.value ===
                                  "segmentednetwork"
                                }
                                onChange={() => {
                                  setIsDirty(true);
                                  setFormData((state) =>
                                    update(state, {
                                      intervpcnetwork: {
                                        value: { $set: "segmentednetwork" },
                                      },
                                    })
                                  );
                                }}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        {formData.intervpcnetwork.value ===
                          "segmentednetwork" && (
                          <div className="col-12 show-hide-target mt-1">
                            <div className="w-100 d-flex flex-wrap ">
                              <span className="mr-xl-5 mr-3 d-flex align-items-center font-12">
                                Segmented Environments
                              </span>

                              {formData.sdlcs.length === 0 ? (
                                <span className="warning">
                                  This depends on the SDLC stages information in
                                  'Environment Strategy' section of 'Account
                                  Framework' domain that is yet to be provided.
                                </span>
                              ) : (
                                formData.sdlcs.map((sdlc, idxSdlc) => (
                                  <div
                                    key={idxSdlc}
                                    className="blueSwitch mr-xl-5 mr-3 px-3 border-10 one bg-special my-lg-0 my-1 py-1 form-line d-inline-flex align-items-center"
                                  >
                                    <label className="switch  mb-0">
                                      <input
                                        type="checkbox"
                                        checked={exists(sdlc)}
                                        onChange={() => modifyArray(sdlc)}
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="font-12">{sdlc}</span>
                                  </div>
                                ))
                              )}
                            </div>
                          </div>
                        )}
                      </div>
 */}
                      <div className="row  align-items-center ">
                        <div className="col-12 font-12 my-1 px-md-3">
                          Transit Gateway ASN
                        </div>
                        <div className="col-xl-4 col-lg-6 col-12 my-1 py-1 selectionArea d-flex flex-column">
                          {formData.regions.length === 0 ? (
                            <span className="warning">
                              This depends on the AWS Regions informations in
                              'AWS Environment" section of 'Network' domain that
                              is yet to be provided.
                            </span>
                          ) : (
                            formData.regions.map((item, idxItem) => (
                              <div key={idxItem} className="d-inline-flex my-1">
                                <span className="d-flex align-items-center flex-1 font-12">
                                  {" "}
                                  {item.region} TGW ASN
                                </span>
                                <div className="flex-1">
                                  <input
                                    type="number"
                                    className={`form-control gray ${getFieldArrayValidationClass(
                                      idxItem,
                                      "regions"
                                    )}`}
                                    placeholder={64901 + idxItem}
                                    value={item.value}
                                    onChange={(e) => {
                                      const value = e.target.value;

                                      for (const [
                                        idxOtherItem,
                                        otherItem,
                                      ] of formData.regions.entries()) {
                                        if (idxItem !== idxOtherItem) {
                                          if (
                                            otherItem.isValid &&
                                            otherItem.value !== ""
                                          ) {
                                            if (otherItem.value === value) {
                                              setErrorMsg(
                                                "TGW ASN must be unique"
                                              );
                                              return false;
                                            }
                                          }
                                        }
                                      }

                                      onFieldArrayChange(
                                        idxItem,
                                        e.target.value,
                                        "regions"
                                      );
                                    }}
                                    onBlur={(e) =>
                                      onFieldArrayBlur(
                                        validateTgwasn,
                                        idxItem,
                                        e.target.value,
                                        "regions"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            ))
                          )}
                        </div>
                      </div>

                      <div class="mainContentArea mt-3 row">
                        <div class="d-inline-flex align-items-center row ">
                          <div class="col-xl-2 col-lg-2 col-md-3 my-1 p-0">
                            <span class="mb-0 font-12">Network Segments</span>
                          </div>
                          <div class=" col-xl-10 col-lg-10 col-md-9 flex-wrap d-inline-flex px-1">
                            {formData.networkSegments.map((item, idxItem) => (
                              <div class="mr-3 my-1 " key={idxItem}>
                                <div class="w-250 bg-gray border-10  p-relative d-inline-flex align-items-center">
                                  <button disabled={disabled}
                                    type="button"
                                    class="closableInput"
                                    onClick={() =>
                                      removeNetworkSegment(idxItem)
                                    }
                                  ></button>
                                  <input
                                    type="text"
                                    className={`form-control bg-transparent ${getFieldArrayValidationClass(
                                      idxItem,
                                      "networkSegments"
                                    )}`}
                                    placeholder="Prod"
                                    value={item.value}
                                    onChange={(e) => {
                                      const value = e.target.value;

                                      for (const [
                                        idxOtherItem,
                                        otherItem,
                                      ] of formData.networkSegments.entries()) {
                                        if (idxItem !== idxOtherItem) {
                                          if (
                                            otherItem.isValid &&
                                            otherItem.value !== ""
                                          ) {
                                            if (otherItem.value === value) {
                                              setErrorMsg(
                                                "Network Segment must be unique"
                                              );
                                              return false;
                                            }
                                          }
                                        }
                                      }

                                      onNetworkSegmentFieldArrayChange(
                                        idxItem,
                                        e.target.value,
                                        "networkSegments"
                                      );
                                    }}
                                    onBlur={(e) => {
                                      onNetworkSegmentFieldArrayBlur(
                                        validate1to10AlphaNumericHyphen,
                                        idxItem,
                                        e.target.value,
                                        "networkSegments"
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            ))}
                            <button disabled={disabled}
                              type="button"
                              onClick={() => addNetworkSegment()}
                            >
                              <img src="../images/purplePlusIcon.svg" />
                            </button>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-3 my-1 p-0">
                            <span class="mb-0 font-12">Production Network</span>
                          </div>
                          <div class=" col-xl-10 col-lg-10 col-md-9 flex-wrap d-inline-flex px-1">
                            <div class="mr-3 my-1 ">
                              <div class="w-250 bg-gray border-10  p-relative d-inline-flex align-items-center">
                                <select 
                                  class="form-control bg-transparent"
                                  value={
                                    formData.productionnetworksegment.value
                                  }
                                  onChange={(e) => {
                                    setErrorMsg(null);
                                    const value = e.target.value;

                                    setIsDirty(true);
                                    setFormData((state) =>
                                      update(state, {
                                        productionnetworksegment: {
                                          value: {
                                            $set: value,
                                          },
                                        },
                                      })
                                    );
                                  }}
                                >
                                  <option value="">Select</option>
                                  {formData.networkSegments
                                    .filter((item) => item.isValid)
                                    .map((item, idxItem) => (
                                      <option key={idxItem} value={item.value}>
                                        {item.value}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {formData.regions.map((region, idxRegion) => (
                        <React.Fragment key={idxRegion}>
                          <div class="col-12 px-3 mt-3 mb-1">
                            <span class="font-weight-bold font-12">
                              {region.region} CIDR Blocks
                            </span>
                          </div>
                          <div class="mainContentArea row">
                            {region.networkSegments.map(
                              (networkSegment, idxNs) => (
                                <div
                                  class="d-inline-flex align-items-center row "
                                  key={idxNs}
                                >
                                  <div class="col-xxl-2 col-lg-2 col-md-3 my-1 p-0">
                                    <span class="mb-0 font-12">
                                      {networkSegment.value} CIDR Blocks
                                    </span>
                                  </div>
                                  <div class=" col-xxl-10 col-lg-10 col-md-9 flex-wrap d-inline-flex px-1">
                                    {networkSegment.cidrs.map(
                                      (cidr, idxCidr) => (
                                        <React.Fragment key={idxCidr}>
                                          <div class="mr-3 my-1 ">
                                            <div class="w-250 bg-gray border-10  p-relative d-inline-flex align-items-center">
                                              <button disabled={disabled}
                                                type="button"
                                                class="closableInput"
                                                onClick={() =>
                                                  removeCidr(
                                                    idxRegion,
                                                    idxNs,
                                                    idxCidr
                                                  )
                                                }
                                              ></button>
                                              <input
                                              disabled={ disabled ||   
                                                !networkSegment.isValid
                                              }
                                                type="text"
                                                class="form-control bg-transparent"
                                                className={`form-control bg-transparent ${getCidrValidationClass(
                                                  idxRegion,
                                                  idxNs,
                                                  idxCidr
                                                )}`}
                                                placeholder="10.229.0.0/15"
                                                value={cidr.value}
                                                onChange={(e) => {
                                                  const value = e.target.value;

                                                  for (const [
                                                    idxOtherCidr,
                                                    otherCidr,
                                                  ] of networkSegment.cidrs.entries()) {
                                                    if (
                                                      idxCidr !== idxOtherCidr
                                                    ) {
                                                      if (
                                                        otherCidr.isValid &&
                                                        otherCidr.value !== ""
                                                      ) {
                                                        if (
                                                          otherCidr.value ===
                                                          value
                                                        ) {
                                                          setErrorMsg(
                                                            "Cidr must be unique"
                                                          );
                                                          return false;
                                                        }
                                                      }
                                                    }
                                                  }

                                                  for (const [
                                                    idxOtherNS,
                                                    otherNS,
                                                  ] of region.networkSegments.entries()) {
                                                    if (idxNs !== idxOtherNS) {
                                                      for (const [
                                                        idxOtherCidr,
                                                        otherCidr,
                                                      ] of otherNS.cidrs.entries()) {
                                                        if (
                                                          otherCidr.isValid &&
                                                          otherCidr.value !== ""
                                                        ) {
                                                          if (
                                                            otherCidr.value ===
                                                            value
                                                          ) {
                                                            setErrorMsg(
                                                              "Cidr must be unique"
                                                            );
                                                            return false;
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }

                                                  for (const [
                                                    idxOtherRegion,
                                                    otherRegion,
                                                  ] of formData.regions.entries()) {
                                                    if (
                                                      idxRegion !==
                                                      idxOtherRegion
                                                    ) {
                                                      for (const ns of otherRegion.networkSegments) {
                                                        for (const cidr of ns.cidrs) {
                                                          if (
                                                            cidr.isValid &&
                                                            cidr.value !== ""
                                                          ) {
                                                            if (
                                                              cidr.value ===
                                                              value
                                                            ) {
                                                              setErrorMsg(
                                                                "Cidr must be unique"
                                                              );
                                                              return false;
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }

                                                  onCidrFieldArrayChange(
                                                    idxRegion,
                                                    idxNs,
                                                    idxCidr,
                                                    e.target.value
                                                  );
                                                }}
                                                onBlur={(e) => {
                                                  onCidrFieldArrayBlur(
                                                    idxRegion,
                                                    idxNs,
                                                    idxCidr,
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </React.Fragment>
                                      )
                                    )}
                                    <button disabled={disabled}
                                      type="button"
                                      onClick={() => addCidr(idxRegion, idxNs)}
                                    >
                                      <img src="../images/purplePlusIcon.svg" />
                                    </button>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              </fieldset>
              {console.log(formData)}
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(ROUTES.VPC_FACTORY + "/" + projectId)
                    }
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">VPC Factory</p>
                  </button>
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.CROSS_REGION_CONNECTIVITY + "/" + projectId
                      )
                    }
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">
                      Cross-region Connectivity
                    </p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default InterVPCConnectivity;
