import React, { useState, useEffect } from "react";

import LeftHandMenu from "../LeftHandMenu";
import TopMenu from "../../TopMenu";

import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../hooks/useHideLeftMenu";
import DomainMenu from "../DomainMenu";

import * as ROUTES from "../../../constants/routes";
import { getError } from "../../../helpers/errorHelper";

import update from "immutability-helper";
import { validateRequired } from "../../../helpers/validator";

//const projectId = "google_1";

const PREFIX = "/aws/builder";

const Prelaunch = () => {
  const history = useHistory();
  const { projectId } = useParams();

  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const [builtRight, setBuiltRight] = useState(false);
  const [headStart, setHeadStart] = useState(false);
  const [enableLaunch, setEnableLaunch] = useState(false);
  const [enableDeploy, setEnableDeploy] = useState(false);
  const [projectStatus, setProjectStatus] = useState(null);
  const [domainStatus, setDomainStatus] = useState(false);
  const [alertClass, setAlertClass] = useState("");

  const [prelaunchData, setPrelaunchData] = useState({
    enterpriseCoreAccounts: { status: "" },
    environmentStrategy: { status: "" },
    applicationAccounts: { status: "" },
    developerSandboxAccounts: { status: "" },
    oktaIntegration: { status: "" },
    adfsIntegration: { status: "" },
    awsSsoIntegration: { status: "" },
    dataCenters: { status: "" },
    awsEnvironment: { status: "" },
    vpcDesign: { status: "" },
    vpcFactory: { status: "" },
    ipam: { status: "" },
    interVpcConnectivity: { status: "" },
    crossRegionConnectivity: { status: "" },
    vpnConnectivity: { status: "" },
    directConnect: { status: "" },
    trafficInspection: { status: "" },
    vpcEndpoints: { status: "" },
    privatedns: {status: ""},
    //egressServices: { status: "" },
    //ingressServices: { status: "" },
    // inlineServices: { status: "" },
    backupArchival: { status: "" },
    certManagement: { status: "" },
    keyManagement: { status: "" },
    complianceManagement: {status: ""},
    perimeterProtection: {status: ""},
    intelThreatDetection: {status: ""},
    vulnerabilityManagement: {status: ""},
    endpointProtection: {status: ""},
    secretsManagement: {status: ""},
    siem: {status: ""},
    activeDirectory: {status: ""},
    imageManagement: {status: ""},
    patchManagement: {status: ""},
    vdiSolution: {status: ""},
    taggingStrategy: {status: ""},
    serviceControlPolicy: {status: ""},
    configRules: {status: ""},
    budgetEnforcement: {status: ""},
    awsLimitsMonitor: {status: ""},
    serviceNowIntegration: {status: ""},
    cloudthwatchEvents: {status: ""},
    centralizedLogging: {status: ""},
    logAnalytics: {status: ""},
    monitoringTools: {status: ""},
    acctFrameworkEmailDomain: {status: ""},
    identityAccessEmailDomain: {status: ""},
    networkEmailDomain: {status: ""},
    securityEmailDomain: {status: ""},
    infrastructureEmailDomain: {status: ""},
    governanceEmailDomain: {status: ""},
    monitoringEmailDomain: {status: ""},
    dataProtectionEmailDomain: {status: ""}
  });

  const [formData, setFormData] = useState({
    masteraccountid: {
      value: "",
      isValid: false,
    },
    builderiamusername: {
      value: "",
      isValid: false,
    },
    builderiamuserpassword: {
      value: "",
      isValid: false,
    },
    builderaccesskeyid: {
      value: "",
      isValid: false,
    },
    buildersecretaccesskey: {
      value: "",
      isValid: false,
    },
  });

  const awsAmplify = useAwsAmplify();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);
        const result = await awsAmplify.prelaunch(projectId);

        console.log(result);

        if (
          result.data &&
          result.data.domainStatus &&
          result.data.projectStatus
        ) {
          const domainStatus = result.data.domainStatus,
            projectStatus = result.data.projectStatus;

          setPrelaunchData(domainStatus);

          let status = true;

          for (const domain in domainStatus) {
            if (domainStatus[domain].status === "FAIL") {
              status = false;
              break;
            }
          }

          setDomainStatus(status);
          setProjectStatus(projectStatus);
        }

        const projectDataResult = await awsAmplify.loadProjectData(
          projectId,
          PREFIX
        );
        setIsLoading(false);

        setFormData(convertBEToFE(projectDataResult.data));
      } catch (err) {
        setIsLoading(false);
        const errorMsg = getError(err, setErrorMsg);

        if (errorMsg === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  useEffect(() => {
    const getProjectStatus = async () => {
      try {
        setErrorMsg(null);
        //setIsLoading(true);
        const result = await awsAmplify.getProjectStatus(projectId);
        //setIsLoading(false);

        console.log(result);

        if (result && result.data && result.data.getProjectStatus) {
          setProjectStatus(result.data.getProjectStatus);
        }
      } catch (err) {
        setIsLoading(false);
        getError(err, setErrorMsg);
      }
    };

    if (projectStatus && projectStatus.status) {
      switch (projectStatus.status) {
        case "Created":
        case "Saved":
          setAlertClass("bluealert");
          break;
        case "Launching":
        case "Deploying":  
          setAlertClass("orangealert");
          break;
        case "Launched Successfully":  
        case "Deployed Successfully":
          setAlertClass("greenalert");
          break;
        case "Launch Failed":  
        case "Deployment Failed":
          setAlertClass("redalert");
          break;
        default:
          setAlertClass("");
          break;
      }
    }

    if (
      projectStatus &&
      (projectStatus.status === "Launching" ||
        projectStatus.status === "Deploying")
    ) {
      let handler = setInterval(getProjectStatus, 5000);

      return () => clearInterval(handler);
    }
  }, [projectStatus]);

  useHideLeftMenu();

  const onClick = (key) => {
    if (prelaunchData[key].status === "FAIL") {
      setErrorMsg(prelaunchData[key].message);
    }
  };

  const getClassName = (key) => {
    let className;

    switch (prelaunchData[key].status) {
      case "NA":
        className = "naInput";
        break;
      case "FAIL":
        className = "cancelInput";
        break;
      case "PASS":
        className = "checkmarkInput";
        break;
      default:
        className = "signsInput";
        break;
    }

    return className;
  };

  const getGrayText = (key) => {
    return prelaunchData[key].status === "NA" ? "text-color-gray" : "";
  };

  const launch = async (action) => {
    try {
      setErrorMsg(null);
      setIsLoading(true);

      const credentials = {};

      for (const field in formData) {
        credentials[`${PREFIX}/${field}`] = formData[field].value;
      }

      console.log(credentials);

      const result = await awsAmplify.launch(projectId, credentials, action);
      setIsLoading(false);

      console.log(result);
      console.log(result.data);
      setProjectStatus(result.data);
    } catch (err) {
      setIsLoading(false);
      const errorMsg = getError(err, setErrorMsg);

      if (errorMsg === "You have no access") {
        history.goBack();
      }
    }
  };

  const onFieldChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setErrorMsg(null);
    setIsDirty(true);

    setFormData((state) =>
      update(state, { [name]: { value: { $set: value } } })
    );
  };

  const onFieldBlur = (e, validateFunc) => {
    const value = e.target.value;
    const name = e.target.name;

    if (value !== "") {
      const errorMsg = validateFunc(value);

      setFormData((state) =>
        update(state, {
          [name]: { isValid: { $set: errorMsg === undefined } },
        })
      );

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      setFormData((state) =>
        update(state, {
          [name]: { isValid: { $set: false } },
        })
      );
    }
  };

  useEffect(() => {
    let isFormValid = true;

    for (const prop in formData) {
      if (!formData[prop].isValid) {
        isFormValid = false;
        break;
      }
    }

    setEnableLaunch(
      isFormValid &&
        projectStatus &&
        (projectStatus.status !== "Launching" && projectStatus.status !== "Deploying") &&
        //domainStatus &&
        (builtRight || headStart)
    );

    setEnableDeploy(
        isFormValid &&
        projectStatus &&
        (projectStatus.status === "Launched Successfully" || projectStatus.status === "Deployed Successfully" || projectStatus.status === "Deployment Failed") &&
        //domainStatus &&
        (builtRight || headStart)
    );
  }, [formData, domainStatus, projectStatus, builtRight, headStart]);

  const convertBEToFE = (data) => {
    if (!data) {
      data = {};
    }

    const fe = {
      masteraccountid: {
        value: data[`${PREFIX}/masteraccountid`] || "",
        isValid: data.hasOwnProperty(`${PREFIX}/masteraccountid`),
      },
      builderaccesskeyid: {
        value: data[`${PREFIX}/builderaccesskeyid`] || "",
        isValid: data.hasOwnProperty(`${PREFIX}/builderaccesskeyid`),
      },
      builderiamusername: {
        value: data[`${PREFIX}/builderiamusername`] || "",
        isValid: data.hasOwnProperty(`${PREFIX}/builderiamusername`),
      },
      builderiamuserpassword: {
        value: data[`${PREFIX}/builderiamuserpassword`] || "",
        isValid: data.hasOwnProperty(`${PREFIX}/builderiamuserpassword`),
      },
      buildersecretaccesskey: {
        value: data[`${PREFIX}/buildersecretaccesskey`] || "",
        isValid: data.hasOwnProperty(`${PREFIX}/buildersecretaccesskey`),
      },
    };

    return fe;
  };

  const save = async () => {
    try {
      setErrorMsg(null);
      setIsLoading(true);

      const credentials = {};

      for (const field in formData) {
        credentials[`${PREFIX}/${field}`] = formData[field].value;
      }

      console.log(credentials);

      const result = await awsAmplify.saveCredentials(projectId, credentials);
      setIsLoading(false);

      setIsDirty(false);

      //console.log(result);
      //console.log(result.data);
      //setProjectStatus(result.data);
    } catch (err) {
      setIsLoading(false);
      const errorMsg = getError(err, setErrorMsg);

      if (errorMsg === "You have no access") {
        history.goBack();
      }
    }
  };

  const [showPwd1, setShowPwd1] = useState(false);
  const [showPwd2, setShowPwd2] = useState(false);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Pre-launch Checks</div>
      <LeftHandMenu></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu></TopMenu>
            <div className="tableArea accountsTableArea aplicationTableArea container-fluid p-0 d-flex flex-column">
              <DomainMenu
                title="Pre-launch Checks"
                isDashboard={false}
                isConfigure={false}
                isPrelaunch={true}
                dashboardRoute={ROUTES.ORGANIZATIONAL_UNITS}
                designRoute={ROUTES.DESIGN_ACCOUNT_FRAMEWORK}
                configureRoute={ROUTES.ENTERPRISE_CORE_ACCOUNTS}
              ></DomainMenu>

              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                <div className="row">
                  {projectStatus && (
                    <div className="status-bar">
                      <div className={alertClass}>
                        <div className="warnImg">
                          {alertClass === "orangealert" ? (
                            <img
                              className="rotating"
                              src="../images/rotating-arrow.svg"
                              alt="rotating arrow"
                            />
                          ) : (
                            <img src="../images/warning.svg" alt="warning" />
                          )}
                        </div>
                        {/* <span className="closebtn">&times;</span> */}
                        <div className="messageText">
                          {`Project ${projectStatus.status} on ${projectStatus.updatedOn}. `}
                          {projectStatus.message}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="mainContentArea preLaunchArea row px-2 py-2 mt-2">
                    <div className="col-xl-6 col-lg-6 col-12 p-0">
                      <div className="row">
                        <div className="col-md-6 col-12 d-flex flex-column">
                          <h3 className="font-15">Account Framework</h3>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("enterpriseCoreAccounts")}
                              onClick={() => onClick("enterpriseCoreAccounts")}
                            ></span>
                            <span
                              className={getGrayText("enterpriseCoreAccounts")}
                            >
                              Enterprise Core Accounts
                            </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("environmentStrategy")}
                              onClick={() => onClick("environmentStrategy")}
                            ></span>
                            <span
                              className={getGrayText("environmentStrategy")}
                            >
                              Envionment Strategy
                            </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("applicationAccounts")}
                              onClick={() => onClick("applicationAccounts")}
                            ></span>
                            <span
                              className={getGrayText("applicationAccounts")}
                            >
                              Application Accounts
                            </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName(
                                "developerSandboxAccounts"
                              )}
                              onClick={() =>
                                onClick("developerSandboxAccounts")
                              }
                            ></span>
                            <span
                              className={getGrayText(
                                "developerSandboxAccounts"
                              )}
                            >
                              Developer Sandbox Accounts
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-12 d-flex flex-column">
                          <h3 className="font-15">Identity & Access</h3>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("oktaIntegration")}
                              onClick={() => onClick("oktaIntegration")}
                            ></span>
                            <span className={getGrayText("oktaIntegration")}>
                              Okta Integration
                            </span>
                          </div>

                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("adfsIntegration")}
                              onClick={() => onClick("adfsIntegration")}
                            ></span>
                            <span className={getGrayText("adfsIntegration")}>
                              Azure ADFS Integration
                            </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("awsSsoIntegration")}
                              onClick={() => onClick("awsSsoIntegration")}
                            ></span>
                            <span className={getGrayText("awsSsoIntegration")}>
                              AWS SSO Integration
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-12 d-flex flex-column">
                          <h3 className="font-15">Infrastructure</h3>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("activeDirectory")}
                              onClick={() => onClick("activeDirectory")}
                            ></span>
                            <span className={getGrayText("activeDirectory")}>
                            Active Directorry Integration
                            </span>
                          </div>
                     

                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("imageManagement")}
                              onClick={() => onClick("imageManagement")}
                            ></span>
                              <span className={getGrayText("imageManagement")}>
                              Image Management
                              </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("patchManagement")}
                              onClick={() => onClick("patchManagement")}
                            ></span>
                              <span className={getGrayText("patchManagement")}>
                              Patch Management
                              </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("vdiSolution")}
                              onClick={() => onClick("vdiSolution")}
                            ></span>
                              <span className={getGrayText("vdiSolution")}>
                              VDI Solution
                              </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <a className="naInput"></a>
                            <span>Container Platform</span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <a className="naInput"></a>
                            <span>API Gateway</span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <a className="naInput"></a>
                            <span>Shared File System</span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <a className="naInput"></a>
                            <span>Secure File Exchange</span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <a className="naInput"></a>
                            <span>Enterprise Service Bus</span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <a className="naInput"></a>
                            <span>Message Queue</span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <a className="naInput"></a>
                            <span>Data Lake</span>
                          </div>
                        </div>
                        <div className="col-md-6 col-12 d-flex flex-column">
                          <h3 className="font-15">Governance</h3>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("taggingStrategy")}
                              onClick={() => onClick("taggingStrategy")}
                            ></span>
                              <span className={getGrayText("taggingStrategy")}>
                              Tagging Strategy
                              </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("serviceControlPolicy")}
                              onClick={() => onClick("serviceControlPolicy")}
                            ></span>
                              <span className={getGrayText("serviceControlPolicy")}>
                              Service Contol Polices
                              </span>
                          </div>

                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("configRules")}
                              onClick={() => onClick("configRules")}
                            ></span>
                              <span className={getGrayText("configRules")}>
                              Config Rules
                              </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("budgetEnforcement")}
                              onClick={() => onClick("budgetEnforcement")}
                            ></span>
                              <span className={getGrayText("budgetEnforcement")}>
                              Budget Enforcement
                              </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("awsLimitsMonitor")}
                              onClick={() => onClick("awsLimitsMonitor")}
                            ></span>
                              <span className={getGrayText("awsLimitsMonitor")}>
                              AWS Limits Monitor
                              </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("serviceNowIntegration")}
                              onClick={() => onClick("serviceNowIntegration")}
                            ></span>
                              <span className={getGrayText("serviceNowIntegration")}>
                              ServiceNow Integration
                              </span>
                          </div>
                        </div> 
                      </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-12 p-0">
                      <div className="row">
                        <div className="col-md-6 col-12 d-flex flex-column">
                          <h3 className="font-15">Network</h3>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("dataCenters")}
                              onClick={() => onClick("dataCenters")}
                            ></span>
                            <span className={getGrayText("dataCenters")}>
                              Data Centers
                            </span>
                          </div>

                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("awsEnvironment")}
                              onClick={() => onClick("awsEnvironment")}
                            ></span>
                            <span className={getGrayText("awsEnvironment")}>
                              AWS Environment
                            </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("vpcDesign")}
                              onClick={() => onClick("vpcDesign")}
                            ></span>
                            <span className={getGrayText("vpcDesign")}>
                              VPC Design
                            </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("vpcFactory")}
                              onClick={() => onClick("vpcFactory")}
                            ></span>
                            <span className={getGrayText("vpcFactory")}>
                              VPC Factory
                            </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("ipam")}
                              onClick={() => onClick("ipam")}
                            ></span>
                            <span className={getGrayText("ipam")}>IPAM</span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("interVpcConnectivity")}
                              onClick={() => onClick("interVpcConnectivity")}
                            ></span>
                            <span
                              className={getGrayText("interVpcConnectivity")}
                            >
                              Inter VPC Connectivity
                            </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName(
                                "crossRegionConnectivity"
                              )}
                              onClick={() => onClick("crossRegionConnectivity")}
                            ></span>
                            <span
                              className={getGrayText("crossRegionConnectivity")}
                            >
                              Cross-region Connectivity
                            </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("vpnConnectivity")}
                              onClick={() => onClick("vpnConnectivity")}
                            ></span>
                            <span className={getGrayText("vpnConnectivity")}>
                              VPN Connectivity
                            </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("directConnect")}
                              onClick={() => onClick("directConnect")}
                            ></span>
                            <span className={getGrayText("directConnect")}>
                              DirectConnect
                            </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("trafficInspection")}
                              onClick={() => onClick("trafficInspection")}
                            ></span>
                            <span className={getGrayText("trafficInspection")}>
                              Traffic Inspection
                            </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("vpcEndpoints")}
                              onClick={() => onClick("vpcEndpoints")}
                            ></span>
                            <span className={getGrayText("vpcEndpoints")}>
                              Shared VPC Endpoints
                            </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("privatedns")}
                              onClick={() => onClick("privatedns")}
                            ></span>
                              <span className={getGrayText("privatedns")}>
                              Private DNS
                              </span>
                          </div>
                         
                          {/*  <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("egressServices")}
                              onClick={() => onClick("egressServices")}
                            ></span>
                            <span className={getGrayText("egressServices")}>
                              Egress Services
                            </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("ingressServices")}
                              onClick={() => onClick("ingressServices")}
                            ></span>
                            <span className={getGrayText("ingressServices")}>
                              Ingress Services
                            </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("inlineServices")}
                              onClick={() => onClick("inlineServices")}
                            ></span>
                            <span className={getGrayText("inlineServices")}>
                              Inline Services
                            </span>
                              </div>*/}
                        </div>
                         <div className="col-md-6 flex-column d-flex">
                          <div className="row">
                            <div className="col-12 d-flex flex-column">
                              <h3 className="font-15">Security</h3>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                className={getClassName("complianceManagement")}
                                onClick={() => onClick("complianceManagement")}>
                                </span>
                                <span className={getGrayText("complianceManagement")}>
                                    Compliance Management
                                </span>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                              <span
                                className={getClassName("intelThreatDetection")}
                                onClick={() => onClick("intelThreatDetection")}>
                                </span>
                                <span className={getGrayText("intelThreatDetection")}>Intelligent Threat Detection</span>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                              <span
                                className={getClassName("vulnerabilityManagement")}
                                onClick={() => onClick("vulnerabilityManagement")}>
                                </span>
                                <span className={getGrayText("vulnerabilityManagement")}>
                                  Vulnerability Management
                                </span>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                              <span
                                className={getClassName("endpointProtection")}
                                onClick={() => onClick("endpointProtection")}>
                                </span>
                                <span className={getGrayText("endpointProtection")}>
                                  Endpoint Protection
                                </span>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                              <span
                                className={getClassName("secretsManagement")}
                                onClick={() => onClick("secretsManagement")}>
                                </span>
                                <span className={getGrayText("secretsManagement")}>
                                  Secrets Management
                                </span>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                              <span
                                className={getClassName("perimeterProtection")}
                                onClick={() => onClick("perimeterProtection")}>
                                </span>
                                <span className={getGrayText("perimeterProtection")}>Perimeter Protection</span>
                              </div>
                              
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                              <span
                                className={getClassName("siem")}
                                onClick={() => onClick("siem")}>
                                </span>
                                <span className={getGrayText("siem")}>
                                  SIEM
                                </span>
                              </div>
                            </div>
                            <div className="col-12 d-flex flex-column">
                              <h3 className="font-15">Monitoring</h3>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                                <span
                                className={getClassName("cloudthwatchEvents")}
                                onClick={() => onClick("cloudthwatchEvents")}>
                                </span>
                                <span className={getGrayText("cloudthwatchEvents")}>
                                CloudWatch Event Rules
                                </span>
                              </div>

                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                              <span
                                className={getClassName("centralizedLogging")}
                                onClick={() => onClick("centralizedLogging")}>
                                </span>
                                <span className={getGrayText("centralizedLogging")}>
                                Centralized Logging
                                </span>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                              <span
                                className={getClassName("logAnalytics")}
                                onClick={() => onClick("logAnalytics")}>
                                </span>
                                <span className={getGrayText("logAnalytics")}>
                                Log Analytics
                                </span>
                              </div>
                              <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                              <span
                                className={getClassName("monitoringTools")}
                                onClick={() => onClick("monitoringTools")}>
                                </span>
                                <span className={getGrayText("monitoringTools")}>
                                Monitoring Tools Integration
                                </span>
                              </div>
                              </div>
                          </div>
                        </div> 
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-12 p-0">
                      <div className="row">
                        <div className="col-md-6 col-12 d-flex flex-column">
                          <h3 className="font-15">Data Protection</h3>

                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("backupArchival")}
                              onClick={() => onClick("backupArchival")}
                            ></span>
                            <span className={getGrayText("backupArchival")}>
                              Backup & Archival
                            </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("certManagement")}
                              onClick={() => onClick("certManagement")}
                            ></span>
                            <span className={getGrayText("certManagement")}>
                              Certificate Management
                            </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("keyManagement")}
                              onClick={() => onClick("keyManagement")}
                            ></span>
                            <span className={getGrayText("keyManagement")}>
                              Key Management
                            </span>
                          </div>
                        </div>
                          <div className="col-md-6 col-12 d-flex flex-column">
                          <h3 className="font-15">Digital</h3>

                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <a className="naInput"></a>
                            <span>Developer Portal</span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <a className="naInput"></a>
                            <span>Developer Tools</span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <a className="naInput"></a>
                            <span>Starter Kits</span>
                          </div>
                      </div> 
                      </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-12 p-0">
                      <div className="row">
                         <div className="col-md-6 col-12 d-flex flex-column">
                          <h3 className="font-15">Domain Notifications</h3>

                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span className={getClassName("acctFrameworkEmailDomain")}
                              onClick={() => onClick("acctFrameworkEmailDomain")}
                            ></span>
                            <span className={getGrayText("acctFrameworkEmailDomain")}>
                               Account Framewok
                            </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span className={getClassName("identityAccessEmailDomain")}
                              onClick={() => onClick("identityAccessEmailDomain")}
                            ></span>
                            <span className={getGrayText("identityAccessEmailDomain")}>
                                Identity & Access
                            </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span className={getClassName("networkEmailDomain")}
                              onClick={() => onClick("networkEmailDomain")}
                            ></span>
                            <span className={getGrayText("networkEmailDomain")}>
                                  Network
                            </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span className={getClassName("securityEmailDomain")}
                              onClick={() => onClick("securityEmailDomain")}
                            ></span>
                            <span className={getGrayText("securityEmailDomain")}>
                                Security
                            </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span className={getClassName("infrastructureEmailDomain")}
                              onClick={() => onClick("infrastructureEmailDomain")}
                            ></span>
                            <span className={getGrayText("infrastructureEmailDomain")}>
                              Infrastructure
                            </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span className={getClassName("governanceEmailDomain")}
                              onClick={() => onClick("governanceEmailDomain")}
                            ></span>
                            <span className={getGrayText("governanceEmailDomain")}>
                                Governance
                            </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span className={getClassName("monitoringEmailDomain")}
                              onClick={() => onClick("monitoringEmailDomain")}
                            ></span>
                            <span className={getGrayText("monitoringEmailDomain")}>
                                Monitoring
                            </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span className={getClassName("dataProtectionEmailDomain")}
                              onClick={() => onClick("dataProtectionEmailDomain")}
                            ></span>
                            <span className={getGrayText("dataProtectionEmailDomain")}>
                                Data Protection
                            </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <a className="naInput"></a>
                            <span>Digital</span>
                          </div>
                        </div> 
                      </div>
                    </div>
                  </div>

                  <div className="d-inline-flex pt-3 pb-1 px-0 flex-wrap">
                    <h3 className="mb-0 mr-4 mt-0 ml-0 font-15">Launch</h3>

                    <div className="d-inline-flex mr-3 my-1 flex-wrap">
                      <label className="container font-12 font-weight-normal pl-5">
                        BuiltRight Cloud Foundation
                        <input
                          type="checkbox"
                          name="builtRight"
                          checked={builtRight}
                          onChange={(e) => setBuiltRight(e.target.checked)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>

                    <div className="d-inline-flex mr-3 my-1 flex-wrap">
                      <label className="container font-12 font-weight-normal pl-5">
                        HeadStart Digital Accelerator
                        <input
                          type="checkbox"
                          name="headStart"
                          checked={headStart}
                          onChange={(e) => setHeadStart(e.target.checked)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div className="mainContentArea show-hide-content row px-md-5 px-2 py-2 mt-2">
                    <div className="col-12 px-2">
                      <span className="mb-0 font-12 font-weight-bold">
                        Credentials
                      </span>
                    </div>
                    <div className="d-inline-flex col-md-4 my-1 col-12">
                      <span className="font-12 d-flex align-items-center text-nowrap mr-2">
                        Master Account ID
                      </span>
                      <input
                        type="text"
                        className="form-control py-2 gray"
                        placeholder=""
                        name="masteraccountid"
                        value={formData.masteraccountid.value}
                        onChange={onFieldChange}
                        onBlur={(e) => {
                          onFieldBlur(e, validateRequired);
                        }}
                      />
                    </div>
                    <div className="d-inline-flex col-md-4 my-1 col-12">
                      <span className="font-12 d-flex align-items-center text-nowrap mr-2">
                        IAM Username
                      </span>
                      <input
                        type="text"
                        className="form-control py-2 gray"
                        placeholder=""
                        name="builderiamusername"
                        value={formData.builderiamusername.value}
                        onChange={onFieldChange}
                        onBlur={(e) => {
                          onFieldBlur(e, validateRequired);
                        }}
                      />
                    </div>
                    <div className="d-inline-flex col-md-4 my-1 col-12">
                      <span className="font-12 d-flex align-items-center text-nowrap mr-2">
                        IAM Password
                      </span>
                      <input
                        type={showPwd1 ? "text" : "password"}
                        className="form-control py-2 gray pwd"
                        placeholder=""
                        name="builderiamuserpassword"
                        value={formData.builderiamuserpassword.value}
                        onChange={onFieldChange}
                        onBlur={(e) => {
                          onFieldBlur(e, validateRequired);
                        }}
                      />
                      <img
                        className="toggle-password gray"
                        src="../images/icons/eye.svg"
                        alt=""
                        onClick={() => setShowPwd1(!showPwd1)}
                      />
                    </div>
                    <div className="d-inline-flex col-md-4 my-1 col-12">
                      <span className="font-12 d-flex align-items-center text-nowrap mr-2">
                        Access Key ID
                      </span>
                      <input
                        type="text"
                        className="form-control py-2 gray"
                        placeholder=""
                        name="builderaccesskeyid"
                        value={formData.builderaccesskeyid.value}
                        onChange={onFieldChange}
                        onBlur={(e) => {
                          onFieldBlur(e, validateRequired);
                        }}
                      />
                    </div>
                    <div className="d-inline-flex col-md-4 my-1 col-12">
                      <span className="font-12 d-flex align-items-center text-nowrap mr-2">
                        Secret Access Key
                      </span>
                      <input
                        type={showPwd2 ? "text" : "password"}
                        className="form-control py-2 gray pwd"
                        placeholder=""
                        name="buildersecretaccesskey"
                        value={formData.buildersecretaccesskey.value}
                        onChange={onFieldChange}
                        onBlur={(e) => {
                          onFieldBlur(e, validateRequired);
                        }}
                      />
                      <img
                        className="toggle-password gray"
                        src="../images/icons/eye.svg"
                        alt=""
                        onClick={() => setShowPwd2(!showPwd2)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    className={
                      (isDirty && "active-state  ") +
                      "confirmBtn cancelBtn btn btn-link greyBorderButton btn-animation mr-md-2 mr-0"
                    }
                    disabled={!isDirty}
                    onClick={() => save()}
                  >
                    Save
                  </button>
                  <button
                    className="confirmBtn cancelBtn btn btn-link greyBorderButton btn-animation mr-md-2 mr-0"
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </button>
                  <button
                    className={
                      (enableLaunch && "active-state  ") +
                      "confirmBtn cancelBtn btn btn-link blueBorderButton btn-animation mr-md-2 mr-0 "
                    }
                    disabled={!enableLaunch}
                    onClick={() => launch("Launch")}
                  >
                    Launch
                  </button>
                  <button
                    className={
                      (enableDeploy && "active-state  ") +
                      "confirmBtn cancelBtn btn btn-link blueBorderButton btn-animation mr-md-2 mr-0 "
                    }
                    disabled={!enableDeploy}
                    onClick={() => launch("Deploy")}
                  >
                    Deploy
                  </button>
                  <button
                    className={
                      (enableLaunch && "active-state  ") +
                      "confirmBtn cancelBtn btn btn-link blueBorderButton btn-animation mr-md-2 mr-0 "
                    }
                    disabled={!enableLaunch}
                    onClick={() => launch("Launch_and_Deploy")}
                  >
                    {"Launch & Deploy"}
                  </button>
                  {/* <button className="confirmBtn btn btn-link greenButton btn-animation">
                    Save
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {console.log(formData)}
    </>
  );
};

export default Prelaunch;
