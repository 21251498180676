import React, { useState, useEffect, useRef } from "react";
import update from "immutability-helper";

import axios from "axios";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import RadioGroup from "../../RadioGroup";
import {
  validateAccountEmailId,
  validateDomainName,
  validatePortNumber,
} from "../../../../helpers/validator";
import useServiceAccounts from "../../../../hooks/useServiceAccounts";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

const PREFIX = "/aws/monitoring/loganalytics";

const FILE_SIZE_LIMIT_IN_BYTE = 1000000;

const UPLOAD_FOLDER = "templates";

const getInitialFormData = (data) => {
  const initialFormData = {
    deployloganalyticssolution: {
      value: data[`${PREFIX}/deployloganalyticssolution`] || "yes",
    },
    loganalyticsaccount: {
      value: data[`${PREFIX}/loganalyticsaccount`] || "",
    },
    solution: {
      value: data[`${PREFIX}/solution`] || "splunk",
    },
    integrationoption: {
      value: data[`${PREFIX}/integrationoption`] || "integratewithonpremsplunk",
    },
    setupsplunkdeploymentserver: {
      value: data[`${PREFIX}/setupsplunkdeploymentserver`] || "yes",
    },
    onpremsplunkserverfqdn: {
      value: data[`${PREFIX}/onpremsplunkserverfqdn`] || "",
      isValid: data[`${PREFIX}/onpremsplunkserverfqdn`] ? true : false,
    },
    onpremsplunkserverport: {
      value: data[`${PREFIX}/onpremsplunkserverport`] || "",
      isValid: data[`${PREFIX}/onpremsplunkserverport`] ? true : false,
    },
    splunkcloudcredentialsfile: {
      value: data[`${PREFIX}/splunkcloudcredentialsfile`] || "",
      isValid: data[`${PREFIX}/splunkcloudcredentialsfile`] ? true : false,
    },
    splunkserverinstancetype: {
      value: data[`${PREFIX}/splunkserverinstancetype`] || "",
      isValid: data[`${PREFIX}/splunkserverinstancetype`] ? true : false,
    },
    onpremelkserverfqdn: {
      value: data[`${PREFIX}/onpremelkserverfqdn`] || "",
      isValid: data[`${PREFIX}/onpremelkserverfqdn`] ? true : false,
    },
    onpremelkserverport: {
      value: data[`${PREFIX}/onpremelkserverport`] || "",
      isValid: data[`${PREFIX}/onpremelkserverport`] ? true : false,
    },
    ingestcloudtraillogs: {
      value: data[`${PREFIX}/ingestcloudtraillogs`] || "yes",
    },
    ingestvpcflowlogs: {
      value: data[`${PREFIX}/ingestvpcflowlogs`] || "yes",
    },
    ingestaccesslogs: {
      value: data[`${PREFIX}/ingestaccesslogs`] || "yes",
    },
  };

  return initialFormData;
};

const LogAnalytics = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState(() => getInitialFormData({}));

  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const awsAmplify = useAwsAmplify();
  const serviceAccounts = useServiceAccounts(projectId);

  const [xmlFile, setXmlFile] = useState(null);
  const [existingXmlFile, setExistingXmlFile] = useState(null);
  const [xmlLicenseFile, setXmlLicenseFile] = useState(null);
  const [existingXmlLicenseFile, setExistingXmlLicenseFile] = useState(null);
  const fileInputRef = useRef(null);
  const licenseFileInputRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        setIsLoading(false);

        setEmpty(result.data.empty);
        setFormData(convertBEToFE(result.data));

        if (
          result.data &&
          result.data[`${PREFIX}/splunkcloudcredentialsfile`]
        ) {
          setXmlFile({
            name: result.data[`${PREFIX}/splunkcloudcredentialsfile`],
          });
          setExistingXmlFile({
            name: result.data[`${PREFIX}/splunkcloudcredentialsfile`],
          });
        }
        else{
          setXmlFile(null);
          setExistingXmlFile(null);
        }

        if (
          result.data &&
          result.data[`${PREFIX}/splunklicensefile`]
        ) {
          setXmlLicenseFile({
            name: result.data[`${PREFIX}/splunklicensefile`],
          });
          setExistingXmlLicenseFile({
            name: result.data[`${PREFIX}/splunklicensefile`],
          });
        }
        else{
          setXmlLicenseFile(null);
          setExistingXmlLicenseFile(null);
        }
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);

        if (err.response.data.message === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);

      let xmlFileName = null;
      let xmlLicenseFileName = null;

      if (xmlFile && xmlFile.size < FILE_SIZE_LIMIT_IN_BYTE && 
        formData.integrationoption.value === "integratewithsplunkcloud") {
        const initiateUploadResult = await awsAmplify.initiateUpload(
          projectId,
          UPLOAD_FOLDER,
          xmlFile.name
        );

        const uploadResult = await axios.put(
          initiateUploadResult.data.signedUrl,
          xmlFile,
          {
            headers: {
              "Content-Type": xmlFile.type,
            },
          }
        );

        console.log(uploadResult);

        if (uploadResult.status === 200) {
          xmlFileName = xmlFile.name;
        }
      }

      if (xmlLicenseFile && xmlLicenseFile.size < FILE_SIZE_LIMIT_IN_BYTE && 
        formData.solution.value === "splunk" ) {
        const initiateUploadResult = await awsAmplify.initiateUpload(
          projectId,
          UPLOAD_FOLDER,
          xmlLicenseFile.name
        );

        const uploadResult = await axios.put(
          initiateUploadResult.data.signedUrl,
          xmlLicenseFile,
          {
            headers: {
              "Content-Type": xmlLicenseFile.type,
            },
          }
        );

        console.log(uploadResult);

        if (uploadResult.status === 200) {
          xmlLicenseFileName = xmlLicenseFile.name;
        }
      }

      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(xmlFileName,xmlLicenseFileName),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (data) => {
    if (!data) {
      data = {};
    }

    const fe = getInitialFormData(data);

    return fe;
  };

  const convertFEToBE = (xmlFileName, xmlLicenseFileName) => {
    const be = {
      [`${PREFIX}/deployloganalyticssolution`]:
        formData.deployloganalyticssolution.value,
    };

    if (formData.deployloganalyticssolution.value === "yes") {
      if (formData.loganalyticsaccount.value !== "") {
        be[`${PREFIX}/loganalyticsaccount`] =
          formData.loganalyticsaccount.value;
      }

      be[`${PREFIX}/solution`] = formData.solution.value;
      be[`${PREFIX}/integrationoption`] = formData.integrationoption.value;

      if (formData.solution.value === "splunk") {
        if (formData.integrationoption.value === "integratewithonpremsplunk") {
          be[`${PREFIX}/setupsplunkdeploymentserver`] =
            formData.setupsplunkdeploymentserver.value;

          if (formData.onpremsplunkserverfqdn.isValid) {
            be[`${PREFIX}/onpremsplunkserverfqdn`] =
              formData.onpremsplunkserverfqdn.value;
          }

          if (formData.onpremsplunkserverport.isValid) {
            be[`${PREFIX}/onpremsplunkserverport`] =
              formData.onpremsplunkserverport.value;
          }
        } else if (
          formData.integrationoption.value === "integratewithsplunkcloud"
        ) {
          if (xmlFileName) {
            be[`${PREFIX}/splunkcloudcredentialsfile`] = `${xmlFileName}`;
          } else {
            if (existingXmlFile) {
              be[
                `${PREFIX}/splunkcloudcredentialsfile`
              ] = `${existingXmlFile.name}`;
            }
          }
        } else if (formData.integrationoption.value === "deploysplunkinaws") {
          if (formData.splunkserverinstancetype.value !== "") {
            be[`${PREFIX}/splunkserverinstancetype`] =
              formData.splunkserverinstancetype.value;
          }
        }

        if (xmlLicenseFileName) {
          be[`${PREFIX}/splunklicensefile`] = `${xmlLicenseFileName}`;
        } else {
          if (existingXmlLicenseFile) {
            be[
              `${PREFIX}/splunklicensefile`
            ] = `${existingXmlLicenseFile.name}`;
          }
        }
        
      } else if (formData.solution.value === "elk") {
        if (formData.integrationoption.value === "integratewithonpremelk") {
          if (formData.onpremelkserverfqdn.isValid) {
            be[`${PREFIX}/onpremelkserverfqdn`] =
              formData.onpremelkserverfqdn.value;
          }

          if (formData.onpremelkserverport.isValid) {
            be[`${PREFIX}/onpremelkserverport`] =
              formData.onpremelkserverport.value;
          }
        } else if (formData.integrationoption.value === "deployelkinaws") {
        }
      }

      be[`${PREFIX}/ingestcloudtraillogs`] =
        formData.ingestcloudtraillogs.value;
      be[`${PREFIX}/ingestvpcflowlogs`] = formData.ingestvpcflowlogs.value;
      be[`${PREFIX}/ingestaccesslogs`] = formData.ingestaccesslogs.value;
    }

    return be;
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  const updateRadio = (key, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        [key]: {
          value: { $set: value },
        },
      })
    );
  };

  const onFieldChange = (value, key) => {
    setErrorMsg(null);
    setIsDirty(true);

    setFormData((state) =>
      update(state, { [key]: { value: { $set: value } } })
    );
  };

  const onFieldBlur = (validateFunc, value, key) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      setFormData((state) =>
        update(state, {
          [key]: { isValid: { $set: errorMsg === undefined } },
        })
      );

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      setFormData((state) =>
        update(state, { [key]: { isValid: { $set: false } } })
      );
    }
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Monitoring</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Monitoring"
                isDirty={isDirty}
                //dashboardRoute={ROUTES.GOVERNANCE_SERVICE_CONTROL}
                designRoute={ROUTES.DESIGN_MONITORING}
              ></DomainMenu>
              <fieldset
                disabled={disabled}
                className="mainArea fitDeviceHeight"
              >
                <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <div className="row d-flex align-items-center">
                    <div className="col-xl-6 col-lg-5 col-md-4 pl-lg-0 px-2">
                      <h3>Log Analytics</h3>
                    </div>
                    <div className="col-xl-6 col-lg-7 col-md-8 d-flex pr-0 align-items-center justify-content-end rightNavArea">
                      <div className="d-inline-flex yes-no-area align-items-center">
                        <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                          Deploy Log Analytics Solution
                        </div>
                        <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                          <RadioGroup
                            field="deployloganalyticssolution"
                            value={formData.deployloganalyticssolution.value}
                            onChange={updateRadio}
                            isLarge={true}
                          ></RadioGroup>
                        </div>
                      </div>
                    </div>
                  </div>

                  {empty && (
                    <NoInitialData />
                  )}

                  {formData.deployloganalyticssolution.value === "yes" && (
                    <div className="show-hide-content">
                      <div className="d-flex flex-column  pb-3 ">
                        <div className="d-inline-flex flex-wrap w-100 my-1 row">
                          <div className="d-inline-flex col-md-2 pl-lg-0 px-2 my-1">
                            <label className="font-12 d-flex align-items-center mb-0 ">
                              Log Analytics Account
                            </label>
                          </div>
                          <div className="col-md-10 px-4 col-12">
                            <div className="w-250 bg-gray border-10 my-1  p-relative   d-inline-flex align-items-center">
                              <select
                                className="form-control gray"
                                value={formData.loganalyticsaccount.value}
                                onChange={(e) => {
                                  setIsDirty(true);
                                  const value = e.target.value;
                                  setFormData((state) =>
                                    update(state, {
                                      loganalyticsaccount: {
                                        value: {
                                          $set: value,
                                        },
                                      },
                                    })
                                  );
                                }}
                              >
                                <option value="">Select</option>
                                {serviceAccounts.security
                                  .concat(serviceAccounts.shared)
                                  .map((sa, idxSa) => (
                                    <option value={sa} key={idxSa}>
                                      {sa}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>

                          <div className="col-12 d-inline-flex selectionArea  px-3  flex-wrap my-1 py-1">
                            <span className="font-12 col-md-2 px-0 col-12 my-2 text-color-black">
                              Log Analytics Solution
                            </span>
                            <div className=" col-md-2 col-6 my-2 px-2">
                              <label className="container font-12">
                                Splunk
                                <input
                                  type="radio"
                                  name="solution"
                                  checked={formData.solution.value === "splunk"}
                                  onChange={(e) => {
                                    setIsDirty(true);

                                    setFormData((state) =>
                                      update(state, {
                                        solution: { value: { $set: "splunk" } },
                                        integrationoption: {
                                          value: {
                                            $set: "integratewithonpremsplunk",
                                          },
                                        },
                                      })
                                    );
                                  }}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="my-2 col-md-2 col-6 px-2">
                              <label className="container">
                                ELK
                                <input
                                  type="radio"
                                  name="solution"
                                  checked={formData.solution.value === "elk"}
                                  onChange={(e) => {
                                    setIsDirty(true);

                                    setFormData((state) =>
                                      update(state, {
                                        solution: { value: { $set: "elk" } },
                                        integrationoption: {
                                          value: {
                                            $set: "integratewithonpremelk",
                                          },
                                        },
                                      })
                                    );
                                  }}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="show-hide-sub-content">
                          <div className="d-flex flex-column mainContentArea  pl-3 pr-3 my-1 py-1 ">
                            {formData.solution.value === "splunk" && (
                              <>
                                <div className=" row d-inline-flex selectionArea noBorder px-0 flex-wrap my-1 py-1">
                                  <span className="font-12 col-md-2 px-0 col-12 my-2 text-color-black">
                                    Splunk Integration Option
                                  </span>
                                  <div className=" col-md-3 col-6 my-2 px-2">
                                    <label className="container font-12">
                                      Integrate with on-premise Splunk
                                      <input
                                        type="radio"
                                        name="integrationoption"
                                        checked={
                                          formData.integrationoption.value ===
                                          "integratewithonpremsplunk"
                                        }
                                        onChange={(e) => {
                                          setIsDirty(true);

                                          setFormData((state) =>
                                            update(state, {
                                              integrationoption: {
                                                value: {
                                                  $set:
                                                    "integratewithonpremsplunk",
                                                },
                                              },
                                            })
                                          );
                                        }}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="my-2 col-md-3 col-6 px-2">
                                    <label className="container">
                                      Integrate with Splunk Cloud
                                      <input
                                        type="radio"
                                        name="integrationoption"
                                        checked={
                                          formData.integrationoption.value ===
                                          "integratewithsplunkcloud"
                                        }
                                        onChange={(e) => {
                                          setIsDirty(true);

                                          setFormData((state) =>
                                            update(state, {
                                              integrationoption: {
                                                value: {
                                                  $set:
                                                    "integratewithsplunkcloud",
                                                },
                                              },
                                            })
                                          );
                                        }}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="my-2 col-md-4 col-6 px-2">
                                    <label className="container">
                                      Deploy Splunk in AWS
                                      <input
                                        type="radio"
                                        name="integrationoption"
                                        checked={
                                          formData.integrationoption.value ===
                                          "deploysplunkinaws"
                                        }
                                        onChange={(e) => {
                                          setIsDirty(true);

                                          setFormData((state) =>
                                            update(state, {
                                              integrationoption: {
                                                value: {
                                                  $set: "deploysplunkinaws",
                                                },
                                              },
                                            })
                                          );
                                        }}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </div>
                                </div>

                                {formData.integrationoption.value ===
                                  "integratewithonpremsplunk" && (
                                  <>
                                    <div className="d-inline-flex align-items-center row ">
                                      <div className="col-md-2 my-1 p-0">
                                        <span className="my-1 font-12 ">
                                          Setup Splunk Deployment Server
                                        </span>
                                      </div>
                                      <div className="col-md-10 my-1 flex-wrap d-inline-flex">
                                        <div className="mr-3 ">
                                          <div className="d-inline-flex yes-no-area row align-items-center">
                                            <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                                              <RadioGroup
                                                field="setupsplunkdeploymentserver"
                                                value={
                                                  formData
                                                    .setupsplunkdeploymentserver
                                                    .value
                                                }
                                                onChange={updateRadio}
                                                isLarge={true}
                                              ></RadioGroup>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-inline-flex align-items-center row ">
                                      <div className="col-md-2 my-1 p-0">
                                        <span className="my-1 font-12 ">
                                          Splunk Server FQDN
                                        </span>
                                      </div>
                                      <div className="col-md-10 my-1 flex-wrap d-inline-flex">
                                        <div className="mr-3 ">
                                          <div className="w-250 bg-gray border-10 d-inline-flex align-items-center">
                                            <input
                                              type="text"
                                              className={`form-control bg-transparent ${
                                                formData.onpremsplunkserverfqdn
                                                  .value !== "" &&
                                                formData.onpremsplunkserverfqdn
                                                  .isValid === false
                                                  ? "invalid"
                                                  : ""
                                              }`}
                                              placeholder="splunk-onprem.client.com"
                                              value={
                                                formData.onpremsplunkserverfqdn
                                                  .value
                                              }
                                              onChange={(e) =>
                                                onFieldChange(
                                                  e.target.value,
                                                  "onpremsplunkserverfqdn"
                                                )
                                              }
                                              onBlur={(e) =>
                                                onFieldBlur(
                                                  validateDomainName,
                                                  e.target.value,
                                                  "onpremsplunkserverfqdn"
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-inline-flex align-items-center row ">
                                      <div className="col-md-2 my-1 p-0">
                                        <span className="mb-0 font-12">
                                          Port
                                        </span>
                                      </div>
                                      <div className="col-md-10 my-1 flex-wrap d-inline-flex">
                                        <div className="mr-3">
                                          <div className="w-250 bg-gray border-10 d-inline-flex align-items-center">
                                            <input
                                              type="text"
                                              className={`form-control bg-transparent ${
                                                formData.onpremsplunkserverport
                                                  .value !== "" &&
                                                formData.onpremsplunkserverport
                                                  .isValid === false
                                                  ? "invalid"
                                                  : ""
                                              }`}
                                              placeholder=""
                                              value={
                                                formData.onpremsplunkserverport
                                                  .value
                                              }
                                              onChange={(e) =>
                                                onFieldChange(
                                                  e.target.value,
                                                  "onpremsplunkserverport"
                                                )
                                              }
                                              onBlur={(e) =>
                                                onFieldBlur(
                                                  validatePortNumber,
                                                  e.target.value,
                                                  "onpremsplunkserverport"
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {formData.integrationoption.value ===
                                  "integratewithsplunkcloud" && (
                                  <div className="d-inline-flex align-items-center row ">
                                    <div className="col-md-2 my-1 p-0">
                                      <span className="mb-0 font-12 ">
                                        Upload SplunkCloud Credentials File
                                      </span>
                                    </div>
                                    <div className="col-md-10 my-1 flex-wrap d-inline-flex">
                                      <div className="mr-3 d-inline-flex">
                                        <label htmlFor="upload-xml">
                                          <img src="../images/upload.svg" />
                                        </label>
                                        <input
                                          type="file"
                                          style={{
                                            width: "5px",
                                            visibility: "hidden",
                                          }}
                                          name="xml"
                                          className="form-control bg-transparent"
                                          id="upload-xml"
                                          ref={fileInputRef}
                                          onChange={(e) => {
                                            const file = e.target.files[0];

                                            if ( file &&
                                              file.size >
                                              FILE_SIZE_LIMIT_IN_BYTE
                                            ) {
                                              setErrorMsg(
                                                `${file.name} has exceeded ${FILE_SIZE_LIMIT_IN_BYTE} Bytes`
                                              );
                                              return;
                                            }
                                            console.log(file);

                                            if (file.name.split(".")[1].toLowerCase() !== "spl") {
                                              setErrorMsg(
                                                "Only SPL file is allowed"
                                              );
                                              return;
                                            }

                                            const splunkCloudFileName = "monitoring-loganalytics-splunk-cloud-credentialsfile.spl";
                                            var blob = file.slice(0, file.size, file.type); 
                                            var newFile = new File([blob], splunkCloudFileName, {type: file.type});


                                            setXmlFile(newFile);

                                            setIsDirty(true);
                                          }}
                                        />
                                        <div className="w-300 bg-gray border-10  p-relative d-inline-flex align-items-center">
                                          <button
                                            disabled={disabled || !xmlFile}
                                            className="closableInput"
                                            onClick={() => {
                                              setIsDirty(true);
                                              setXmlFile(null);
                                              setExistingXmlFile(null);
                                            }}
                                          ></button>
                                          <input
                                            placeholder={
                                              xmlFile
                                                ? xmlFile.name
                                                : "Sample_file.spl"
                                            }
                                            type="text"
                                            disabled
                                            className="form-control bg-transparent"
                                            onClick={() =>
                                              fileInputRef.current.click()
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {formData.integrationoption.value ===
                                  "deploysplunkinaws" && (
                                  <div className="d-inline-flex flex-wrap w-100 my-1 row">
                                    <div className="d-inline-flex col-md-2 pl-lg-0 px-2 my-1">
                                      <label className="font-12 d-flex align-items-center mb-0 ">
                                        Splunk Server Instance Type
                                      </label>
                                    </div>
                                    <div className="col-md-10 px-4 col-12">
                                      <div className="w-250 bg-gray border-10 my-1  p-relative   d-inline-flex align-items-center">
                                        <select
                                          className="form-control gray"
                                          value={
                                            formData.splunkserverinstancetype
                                              .value
                                          }
                                          onChange={(e) => {
                                            setIsDirty(true);
                                            const value = e.target.value;
                                            setFormData((state) =>
                                              update(state, {
                                                splunkserverinstancetype: {
                                                  value: {
                                                    $set: value,
                                                  },
                                                },
                                              })
                                            );
                                          }}
                                        >
                                          <option value="">Select</option>
                                          <option value="ExtraSmall">
                                            Extra Small
                                          </option>
                                          <option value="Small">Small</option>
                                          <option value="Medium">Medium</option>
                                          <option value="Large">Large</option>
                                          <option value="ExtraLarge">
                                            Extra Large
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                  <div className="d-inline-flex align-items-center row ">
                                    <div className="col-md-2 my-1 p-0">
                                      <span className="mb-0 font-12 ">
                                        Upload Splunk License File
                                      </span>
                                    </div>
                                    <div className="col-md-10 my-1 flex-wrap d-inline-flex">
                                      <div className="mr-3 d-inline-flex">
                                        <label htmlFor="license-upload-xml">
                                          <img src="../images/upload.svg" />
                                        </label>
                                        <input
                                          type="file"
                                          style={{
                                            width: "5px",
                                            visibility: "hidden",
                                          }}
                                          name="xml"
                                          className="form-control bg-transparent"
                                          id="license-upload-xml"
                                          ref={licenseFileInputRef}
                                          onChange={(e) => {
                                            const licenseFile = e.target.files[0];

                                            if ( licenseFile &&
                                              licenseFile.size >
                                              FILE_SIZE_LIMIT_IN_BYTE
                                            ) {
                                              setErrorMsg(
                                                `${licenseFile.name} has exceeded ${FILE_SIZE_LIMIT_IN_BYTE} Bytes`
                                              );
                                              return;
                                            }

                                            if (licenseFile.name.split(".")[1].toLowerCase() !== "lic") {
                                              setErrorMsg(
                                                "Only LIC file is allowed"
                                              );
                                              return;
                                            }

                                            const splunkLicenseFileName = "monitoring-loganalytics-splunk-licensefile.lic";
                                            var blob = licenseFile.slice(0, licenseFile.size, licenseFile.type); 
                                            var newFile = new File([blob], splunkLicenseFileName, {type: licenseFile.type});

                                            setXmlLicenseFile(newFile);

                                            setIsDirty(true);
                                          }}
                                        />
                                        <div className="w-300 bg-gray border-10  p-relative d-inline-flex align-items-center">
                                          <button
                                            disabled={disabled || !xmlLicenseFile}
                                            className="closableInput"
                                            onClick={() => {
                                              setIsDirty(true);
                                              setXmlLicenseFile(null);
                                              setExistingXmlLicenseFile(null);
                                            }}
                                          ></button>
                                          <input
                                            placeholder={
                                              xmlLicenseFile
                                                ? xmlLicenseFile.name
                                                : "Sample_file.lic"
                                            }
                                            type="text"
                                            disabled
                                            className="form-control bg-transparent"
                                            onClick={() =>
                                              licenseFileInputRef.current.click()
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                              
                              </>
                            )}

                            {formData.solution.value === "elk" && (
                              <>
                                <div className="row d-inline-flex selectionArea noBorder px-0 flex-wrap my-1 py-1">
                                  <span className="font-12 col-md-2 px-0 col-12 my-2 text-color-black">
                                    ELK Integration Option
                                  </span>
                                  <div className=" col-md-4 col-6 my-2 px-2">
                                    <label className="container font-12">
                                      Integrate with on-premise Elastic Search
                                      Indexer
                                      <input
                                        type="radio"
                                        name="integrationoption"
                                        checked={
                                          formData.integrationoption.value ===
                                          "integratewithonpremelk"
                                        }
                                        onChange={(e) => {
                                          setIsDirty(true);

                                          setFormData((state) =>
                                            update(state, {
                                              integrationoption: {
                                                value: {
                                                  $set:
                                                    "integratewithonpremelk",
                                                },
                                              },
                                            })
                                          );
                                        }}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </div>
                                  <div className="my-2 col-md-4 col-6 px-2">
                                    <label className="container">
                                      {" "}
                                      Deploy ELK Stack in AWS
                                      <input
                                        type="radio"
                                        name="integrationoption"
                                        checked={
                                          formData.integrationoption.value ===
                                          "deployelkinaws"
                                        }
                                        onChange={(e) => {
                                          setIsDirty(true);

                                          setFormData((state) =>
                                            update(state, {
                                              integrationoption: {
                                                value: {
                                                  $set: "deployelkinaws",
                                                },
                                              },
                                            })
                                          );
                                        }}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </div>
                                </div>

                                {formData.integrationoption.value ===
                                  "integratewithonpremelk" && (
                                  <>
                                    <div className="d-inline-flex align-items-center row ">
                                      <div className="col-md-2 my-1 p-0">
                                        <span className="my-1 font-12 ">
                                          Elastic Search Indexer FQDN
                                        </span>
                                      </div>
                                      <div className="col-md-10 my-1 flex-wrap d-inline-flex">
                                        <div className="mr-3 ">
                                          <div className="w-250 bg-gray border-10 d-inline-flex align-items-center">
                                            <input
                                              type="text"
                                              placeholder="elk-onprem.client.com"
                                              className={`form-control bg-transparent ${
                                                formData.onpremelkserverfqdn
                                                  .value !== "" &&
                                                formData.onpremelkserverfqdn
                                                  .isValid === false
                                                  ? "invalid"
                                                  : ""
                                              }`}
                                              value={
                                                formData.onpremelkserverfqdn
                                                  .value
                                              }
                                              onChange={(e) =>
                                                onFieldChange(
                                                  e.target.value,
                                                  "onpremelkserverfqdn"
                                                )
                                              }
                                              onBlur={(e) =>
                                                onFieldBlur(
                                                  validateDomainName,
                                                  e.target.value,
                                                  "onpremelkserverfqdn"
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-inline-flex align-items-center row ">
                                      <div className="col-md-2 my-1 p-0">
                                        <span className="mb-0 font-12">
                                          Port
                                        </span>
                                      </div>
                                      <div className="col-md-10 my-1 flex-wrap d-inline-flex">
                                        <div className="mr-3">
                                          <div className="w-250 bg-gray border-10 d-inline-flex align-items-center">
                                            <input
                                              type="text"
                                              placeholder=""
                                              className={`form-control bg-transparent ${
                                                formData.onpremelkserverport
                                                  .value !== "" &&
                                                formData.onpremelkserverport
                                                  .isValid === false
                                                  ? "invalid"
                                                  : ""
                                              }`}
                                              value={
                                                formData.onpremelkserverport
                                                  .value
                                              }
                                              onChange={(e) =>
                                                onFieldChange(
                                                  e.target.value,
                                                  "onpremelkserverport"
                                                )
                                              }
                                              onBlur={(e) =>
                                                onFieldBlur(
                                                  validatePortNumber,
                                                  e.target.value,
                                                  "onpremelkserverport"
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            )}

                            <div className=" row px-0  align-items-center justify-content-between flex-wrap">
                              <div className="col-lg-2 my-1 col-md-6 col-12 d-inline-flex align-items-center px-0">
                                <span className="switchLabel ">
                                  {" "}
                                  Logs to be ingested
                                </span>
                              </div>

                              <div className="col-lg-2 my-1 col-md-6 col-12 form-line d-inline-flex align-items-center px-md-2 px-0">
                                <div className="d-inline-flex align-items-center bg-special border-10 w-100 pr-2 py-2">
                                  <label className="switch mb-0 ml-lg-1 ml-0">
                                    <input
                                      type="checkbox"
                                      checked={
                                        formData.ingestcloudtraillogs.value ===
                                        "yes"
                                      }
                                      onChange={(e) => {
                                        const value = e.target.checked;
                                        setIsDirty(true);

                                        setFormData((state) =>
                                          update(state, {
                                            ingestcloudtraillogs: {
                                              value: {
                                                $set: value ? "yes" : "no",
                                              },
                                            },
                                          })
                                        );
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <span className="switchLabel ">
                                    CloudTrail Logs
                                  </span>
                                </div>
                              </div>
                              <div className="col-lg-2 my-1 col-md-6 col-12 form-line d-inline-flex align-items-center px-md-2 px-0">
                                <div className="d-inline-flex align-items-center bg-special border-10 w-100 pr-2 py-2">
                                  <label className="switch mb-0 ml-lg-3 ml-0">
                                    <input
                                      type="checkbox"
                                      checked={
                                        formData.ingestvpcflowlogs.value ===
                                        "yes"
                                      }
                                      onChange={(e) => {
                                        const value = e.target.checked;
                                        setIsDirty(true);

                                        setFormData((state) =>
                                          update(state, {
                                            ingestvpcflowlogs: {
                                              value: {
                                                $set: value ? "yes" : "no",
                                              },
                                            },
                                          })
                                        );
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <span className="switchLabel ">
                                    VPC Flow Logs
                                  </span>
                                </div>
                              </div>
                              <div className="col-lg-6 my-1 col-md-12 col-12 form-line d-inline-flex align-items-center px-md-2 px-0">
                                <div className="d-inline-flex align-items-center bg-special border-10 w-100 pr-2 py-2">
                                  <label className="switch mb-0 ml-lg-3 ml-0">
                                    <input
                                      type="checkbox"
                                      checked={
                                        formData.ingestaccesslogs.value ===
                                        "yes"
                                      }
                                      onChange={(e) => {
                                        const value = e.target.checked;
                                        setIsDirty(true);

                                        setFormData((state) =>
                                          update(state, {
                                            ingestaccesslogs: {
                                              value: {
                                                $set: value ? "yes" : "no",
                                              },
                                            },
                                          })
                                        );
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <span className="switchLabel ">
                                    Centralized Logs from S3 Bucket
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </fieldset>
              {console.log(formData)}
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.MONITORING_CENTRALIZED_LOGGING + "/" + projectId
                      )
                    }
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                      Centralized Logging
                    </p>
                  </button>
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.MONITORING_MONITORING_TOOLS_INTEGRATION +
                          "/" +
                          projectId
                      )
                    }
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">
                      Monitoring Tools Integration
                    </p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default LogAnalytics;
