import React, { useEffect, useState } from "react";
import { getError } from "../../helpers/errorHelper";

import useAwsAmplify from "../../hooks/useAwsAmplify";

const ProjectRemoveForm = ({ close, project, createdByUser }) => {
  const awsAmplify = useAwsAmplify();

  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const removeProject = async () => {
    try {
      setIsLoading(true);

      const result = await awsAmplify.removeProject(project.ProjectID);
      console.log(result);

      setIsLoading(false);

      close(true, "remove", "Successfully deleted the project");
    } catch (err) {
      setIsLoading(false);

      const errorMsg = getError(err);
      if (errorMsg === "Project doesn't exist") {
        close(true, "remove", null, errorMsg);
      }
      else{
        setErrorMsg(errorMsg);
      }
    }
  };

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="justify-content-center align-items-center popupArea delete-client">
        <div className="auth-main">
          <div className="auth-form">
            <button
              className="exit btn-animation"
              onClick={() => close(false, "remove")}
            >
              Exit
            </button>
            <div className="title">Delete Project</div>
            <div className="auth-form-content d-flex flex-column justify-content-center">
              <div className="projectInfo d-flex flex-column justify-content-center w-100 my-1">
                <h1 className="text-center">{project.ProjectName}</h1>
                <p className="text-center clientName">{project.ClientName}</p>
              </div>

              <div className="halfBox d-flex flex-column">
                <div className="inlineRow d-inline-flex justify-content-between my-1">
                  <div className="titleOfContent flex-1">Created By</div>
                  <p className="m-0 flex-1 font-12">{createdByUser && `${createdByUser.FirstName} ${createdByUser.LastName}`}</p>
                </div>
              </div>

              <div className="halfBox d-flex flex-column">
                <div className="inlineRow d-inline-flex justify-content-between my-1">
                  <div className="titleOfContent flex-1">Created On</div>
                  <p className="m-0 flex-1 font-12">{project.CreatedOn}</p>
                </div>
                <div className="inlineRow d-inline-flex justify-content-between my-1">
                  <div className="titleOfContent flex-1">Status</div>
                  <p className="m-0 flex-1 font-12">{project.ProjectStatus}</p>
                </div>
                <div className="inlineRow d-inline-flex justify-content-between my-1">
                  <div className="titleOfContent flex-1">Project Type</div>
                  <p className="m-0 flex-1 font-12">{project.ProjectType}</p>
                </div>
              </div>

              <div className="halfBox d-flex flex-column">
                <div className="warningMessageArea d-flex flex-column justify-content-center w-100 my-1">
                  <p className="redText text-center ">
                    You are about to delete this Project.{" "}
                  </p>
                  <p className="boldText text-center ">
                    Doing so will delete all data associated with this Project
                  </p>
                </div>
              </div>
            </div>
            <div className="auth-form-footer d-inline-flex justify-content-center w-100">
              <div>
                <a
                  onClick={() => close(false, "remove")}
                  className="confirmBtn cancelBtn btn btn-link btn-green btn-animation mr-1"
                >
                  Cancel
                </a>
              </div>
              <div>
                <a
                  onClick={removeProject}
                  className="confirmBtn deleteBtn btn btn-link btn-green btn-animation ml-1"
                >
                  Delete
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectRemoveForm;
