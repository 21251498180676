import React, { useState, useEffect, useRef } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import {
  validate1to10AlphaNumeric,
  validate1to20AlpheNumHyphen,
  validateDomainName,
  validateIPAddress,
  validatePassword,
  validatePasswordMax127,
  validate1to10AlphaNumericHyphen,
} from "../../../../helpers/validator";
import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

//const projectId = "google_1";
const PREFIX = "/aws/iam/awsssointegration";

const AwsSsoIntegration = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    deployawsssointegration: {
      value: "yes",
    },
    awsssodirectory: {
      value: "integratewithexistingad",
    },
    integratewithexistingad: {
      directorydnsdomainname: {
        value: "",
        isValid: false,
      },
      directorydnsserveripaddresses: [
        {
          value: "",
          isValid: false,
        },
        {
          value: "",
          isValid: false,
        },
      ],
      adconnectorserviceaccountusername: {
        value: "",
        isValid: false,
      },
      adconnectorserviceaccountpassword: {
        value: "",
        isValid: false,
      },
    },
    deploysimplead: {
      directoryedition: {
        value: "standard",
      },
      directorydnsnetbiosname: {
        value: "",
        isValid: false,
      },
      directorydnsdomainname: {
        value: "",
        isValid: false,
      },
      adadminpassword: {
        value: "",
        isValid: false,
      },
      repeatedpassword: {
        value: "",
      },
    },
    deploybaselinepermissionsets: {
      value: "yes",
    },
    array: [
      "CloudAdmin",
      "IAMAdmin",
      "SecurityAuditor",
      "SecurityViewOnly",
      "NetworkAdmin",
      "OperationsSupport",
      "OperationsViewOnly",
      "AnalyticsAdmin",
      "Finance",
    ],
  });

  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const awsAmplify = useAwsAmplify();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        console.log(result);

        setIsLoading(false);

        setEmpty(result.data.empty);

        if (result.data) {
          if(!result.data.empty){
            setFormData(convertBEToFE(result.data));
          }
          else{
            setFormData({
              deployawsssointegration: {
                value: "yes",
              },
              awsssodirectory: {
                value: "integratewithexistingad",
              },
              integratewithexistingad: {
                directorydnsdomainname: {
                  value: "",
                  isValid: false,
                },
                directorydnsserveripaddresses: [
                  {
                    value: "",
                    isValid: false,
                  },
                  {
                    value: "",
                    isValid: false,
                  },
                ],
                adconnectorserviceaccountusername: {
                  value: "",
                  isValid: false,
                },
                adconnectorserviceaccountpassword: {
                  value: "",
                  isValid: false,
                },
              },
              deploysimplead: {
                directoryedition: {
                  value: "standard",
                },
                directorydnsnetbiosname: {
                  value: "",
                  isValid: false,
                },
                directorydnsdomainname: {
                  value: "",
                  isValid: false,
                },
                adadminpassword: {
                  value: "",
                  isValid: false,
                },
                repeatedpassword: {
                  value: "",
                },
              },
              deploybaselinepermissionsets: {
                value: "yes",
              },
              array: [
                "CloudAdmin",
                "IAMAdmin",
                "SecurityAuditor",
                "SecurityViewOnly",
                "NetworkAdmin",
                "OperationsSupport",
                "OperationsViewOnly",
                "AnalyticsAdmin",
                "Finance",
              ],
            });
          }
        }
        else {
          setFormData({
            deployawsssointegration: {
              value: "yes",
            },
            awsssodirectory: {
              value: "integratewithexistingad",
            },
            integratewithexistingad: {
              directorydnsdomainname: {
                value: "",
                isValid: false,
              },
              directorydnsserveripaddresses: [
                {
                  value: "",
                  isValid: false,
                },
                {
                  value: "",
                  isValid: false,
                },
              ],
              adconnectorserviceaccountusername: {
                value: "",
                isValid: false,
              },
              adconnectorserviceaccountpassword: {
                value: "",
                isValid: false,
              },
            },
            deploysimplead: {
              directoryedition: {
                value: "standard",
              },
              directorydnsnetbiosname: {
                value: "",
                isValid: false,
              },
              directorydnsdomainname: {
                value: "",
                isValid: false,
              },
              adadminpassword: {
                value: "",
                isValid: false,
              },
              repeatedpassword: {
                value: "",
              },
            },
            deploybaselinepermissionsets: {
              value: "yes",
            },
            array: [
              "CloudAdmin",
              "IAMAdmin",
              "SecurityAuditor",
              "SecurityViewOnly",
              "NetworkAdmin",
              "OperationsSupport",
              "OperationsViewOnly",
              "AnalyticsAdmin",
              "Finance",
            ],
          });
        }
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);

        if(err.response.data.message === "You have no access"){
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);

      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);

      console.log(err);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/deployawsssointegration`]: formData.deployawsssointegration
        .value,
    };

    if (formData.deployawsssointegration.value === "yes") {
      be[`${PREFIX}/awsssodirectory`] = formData.awsssodirectory.value;

      const additionalData = formData[formData.awsssodirectory.value];

      for (const prop in additionalData) {
        if (prop === "directorydnsserveripaddresses") {
          const ipaddresses = [];

          for (const data of additionalData[prop]) {
            if (data.isValid) {
              ipaddresses.push(data.value);
            }
          }

          if (ipaddresses.length > 0) {
            be[`${PREFIX}/directorydnsserveripaddresses`] = ipaddresses.join(
              ","
            );
          }
        } else {
          if (prop !== "repeatedpassword") {
            if (additionalData[prop].hasOwnProperty("isValid")) {
              if (additionalData[prop].isValid) {
                be[`${PREFIX}/${prop}`] = additionalData[prop].value;
              }
            } else {
              be[`${PREFIX}/${prop}`] = additionalData[prop].value;
            }
          }
        }
      }

      be[`${PREFIX}/deploybaselinepermissionsets`] =
        formData.deploybaselinepermissionsets.value;

      if (formData.deploybaselinepermissionsets.value === "yes" && formData.array.length > 0) {
        be[`${PREFIX}/baselinepermissionsets`] = formData.array.join(",");
      }
    }

    return be;
  };

  const convertBEToFE = (be) => {
    const fe = {
      deployawsssointegration: {
        value: be[`${PREFIX}/deployawsssointegration`],
      },
      awsssodirectory: {
        value: be[`${PREFIX}/awsssodirectory`] || "integratewithexistingad",
      },
      integratewithexistingad: {
        directorydnsdomainname: {
          value: be[`${PREFIX}/directorydnsdomainname`] || "",
          isValid: be.hasOwnProperty(`${PREFIX}/directorydnsdomainname`),
        },
        directorydnsserveripaddresses: [
          {
            value: "",
            isValid: false,
          },
          {
            value: "",
            isValid: false,
          },
        ],
        adconnectorserviceaccountusername: {
          value: be[`${PREFIX}/adconnectorserviceaccountusername`] || "",
          isValid: be.hasOwnProperty(
            `${PREFIX}/adconnectorserviceaccountusername`
          ),
        },
        adconnectorserviceaccountpassword: {
          value: be[`${PREFIX}/adconnectorserviceaccountpassword`] || "",
          isValid: be.hasOwnProperty(
            `${PREFIX}/adconnectorserviceaccountpassword`
          ),
        },
      },
      deploysimplead: {
        directoryedition: {
          value: be[`${PREFIX}/directoryedition`] || "standard",
        },
        directorydnsnetbiosname: {
          value: be[`${PREFIX}/directorydnsnetbiosname`] || "",
          isValid: be.hasOwnProperty(`${PREFIX}/directorydnsnetbiosname`),
        },
        directorydnsdomainname: {
          value: be[`${PREFIX}/directorydnsdomainname`] || "",
          isValid: be.hasOwnProperty(`${PREFIX}/directorydnsdomainname`),
        },
        adadminpassword: {
          value: be[`${PREFIX}/adadminpassword`] || "",
          isValid: be.hasOwnProperty(`${PREFIX}/adadminpassword`),
        },
        repeatedpassword: {
          value: be[`${PREFIX}/adadminpassword`] || "",
        },
      },
      deploybaselinepermissionsets: {
        value: be[`${PREFIX}/deploybaselinepermissionsets`] || "yes",
      },
      array: [
        "CloudAdmin",
        "IAMAdmin",
        "SecurityAuditor",
        "SecurityViewOnly",
        "NetworkAdmin",
        "OperationsSupport",
        "OperationsViewOnly",
        "AnalyticsAdmin",
        "Finance",
      ],
    };

    if (be[`${PREFIX}/baselinepermissionsets`]) {
      fe.array = be[`${PREFIX}/baselinepermissionsets`].split(",");
    }
    else{
      fe.array = [];
    }

    if (be[`${PREFIX}/directorydnsserveripaddresses`]) {
      fe.integratewithexistingad.directorydnsserveripaddresses = be[
        `${PREFIX}/directorydnsserveripaddresses`
      ]
        .split(",")
        .map((ip) => {
          return { value: ip, isValid: true };
        });

      for (
        let loop =
          fe.integratewithexistingad.directorydnsserveripaddresses.length;
        loop < 2;
        loop++
      ) {
        fe.integratewithexistingad.directorydnsserveripaddresses.push({
          value: "",
          isValid: false,
        });
      }
    }

    return fe;
  };

  const exists = (value) => {
    return formData.array.indexOf(value) !== -1;
  };

  const modifyArray = (value) => {
    setIsDirty(true);

    if (exists(value)) {
      setFormData((state) => {
        return {
          ...state,
          array: update(state.array, {
            $splice: [[state.array.indexOf(value), 1]],
          }),
        };
      });
    } else {
      setFormData((state) => {
        return {
          ...state,
          array: update(state.array, { $push: [value] }),
        };
      });
    }
  };

  const addFieldArrayItem = (key1, key2) => {
    setIsDirty(true);

    if (key2) {
      setFormData((state) =>
        update(state, {
          [key1]: { [key2]: { $push: [{ value: "", isValid: false }] } },
        })
      );
    } else {
      setFormData((state) =>
        update(state, { [key1]: { $push: [{ value: "", isValid: false }] } })
      );
    }
  };

  const removeFieldArrayItem = (idx, key1, key2) => {
    let canRemove = false;

    if (key2) {
      canRemove = formData[key1][key2].length > 2;
    } else {
      canRemove = formData[key1].length > 2;
    }

    if (!canRemove) {
      setErrorMsg(`Cannot less than 2 items`);
      return;
    }

    setIsDirty(true);

    if (key2) {
      setFormData((state) =>
        update(state, {
          [key1]: { [key2]: { $splice: [[idx, 1]] } },
        })
      );
    } else {
      setFormData((state) =>
        update(state, { [key1]: { $splice: [[idx, 1]] } })
      );
    }
  };

  const getFieldValidationClass = (key1, key2) => {
    if (key2) {
      return !formData[key1][key2].isValid && formData[key1][key2].value !== ""
        ? "invalid"
        : "";
    } else {
      return !formData[key1].isValid && formData[key1].value !== ""
        ? "invalid"
        : "";
    }
  };

  const getFieldArrayValidationClass = (idx, key1, key2) => {
    if (key2) {
      return !formData[key1][key2][idx].isValid &&
        formData[key1][key2][idx].value !== ""
        ? "invalid"
        : "";
    } else {
      return !formData[key1][idx].isValid && formData[key1][idx].value !== ""
        ? "invalid"
        : "";
    }
  };

  const onFieldChange = (value, key1, key2) => {
    setErrorMsg(null);
    setIsDirty(true);

    if (key2) {
      setFormData((state) =>
        update(state, { [key1]: { [key2]: { value: { $set: value } } } })
      );
    } else {
      setFormData((state) =>
        update(state, { [key1]: { value: { $set: value } } })
      );
    }
  };

  const onFieldBlur = (validateFunc, value, key1, key2) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      if (key2) {
        setFormData((state) =>
          update(state, {
            [key1]: { [key2]: { isValid: { $set: errorMsg === undefined } } },
          })
        );
      } else {
        setFormData((state) =>
          update(state, {
            [key1]: { isValid: { $set: errorMsg === undefined } },
          })
        );
      }

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      if (key2) {
        setFormData((state) =>
          update(state, {
            [key1]: { [key2]: { isValid: { $set: false } } },
          })
        );
      } else {
        setFormData((state) =>
          update(state, {
            [key1]: { isValid: { $set: false } },
          })
        );
      }
    }
  };

  const onFieldArrayChange = (idx, value, key1, key2) => {
    setErrorMsg(null);
    setIsDirty(true);

    if (key2) {
      setFormData((state) =>
        update(state, {
          [key1]: { [key2]: { [idx]: { value: { $set: value } } } },
        })
      );
    } else {
      setFormData((state) =>
        update(state, { [key1]: { [idx]: { value: { $set: value } } } })
      );
    }
  };

  const onFieldArrayBlur = (validateFunc, idx, value, key1, key2) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      if (key2) {
        setFormData((state) =>
          update(state, {
            [key1]: {
              [key2]: { [idx]: { isValid: { $set: errorMsg === undefined } } },
            },
          })
        );
      } else {
        setFormData((state) =>
          update(state, {
            [key1]: { [idx]: { isValid: { $set: errorMsg === undefined } } },
          })
        );
      }

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      if (key2) {
        setFormData((state) =>
          update(state, {
            [key1]: {
              [key2]: { [idx]: { isValid: { $set: false } } },
            },
          })
        );
      } else {
        setFormData((state) =>
          update(state, {
            [key1]: { [idx]: { isValid: { $set: false } } },
          })
        );
      }
    }
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  const confirmPwdRef = useRef(null);

  useHideLeftMenu();
  useUnload();

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Identity&Access</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
          <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Identity & Access"
                isDirty={isDirty}
                dashboardRoute={ROUTES.IDENTITY_PROVIDERS}
                designRoute={ROUTES.DESIGN_IAM}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 ">
                <div className="row mb-2 d-flex align-items-center">
                  <div className="col-xl-6 col-lg-5 col-md-4 pl-lg-0 px-2">
                    <h3>AWS SSO Integration</h3>
                  </div>
                  <div className="col-xl-6 col-lg-7 col-md-8 d-flex pr-0 align-items-center justify-content-end rightNavArea">
                    <div className="d-inline-flex yes-no-area align-items-center">
                      <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                        Deploy Integration With AWS SSO
                      </div>
                      <div className="btn-group btn-group-toggle btn-group-radio mw-100 mr-0">
                        <label
                          className={`createElementBtn btn btn-link btn-linkYes ${
                            formData.deployawsssointegration.value === "yes"
                              ? "active"
                              : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name="options"
                            checked={
                              formData.deployawsssointegration.value === "yes"
                            }
                            value="yes"
                            onChange={() => {
                              setIsDirty(true);
                              setFormData((state) => {
                                return {
                                  ...state,
                                  deployawsssointegration: {
                                    value: "yes",
                                  },
                                };
                              });
                            }}
                          />{" "}
                          YES
                        </label>
                        <label
                          className={`dontCreateElementBtn btn btn-link btn-linkNo ${
                            formData.deployawsssointegration.value === "no"
                              ? "active"
                              : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name="options"
                            checked={
                              formData.deployawsssointegration.value === "no"
                            }
                            value="no"
                            onChange={() => {
                              setIsDirty(true);
                              setFormData((state) => {
                                return {
                                  ...state,
                                  deployawsssointegration: {
                                    value: "no",
                                  },
                                };
                              });
                            }}
                          />{" "}
                          NO
                        </label>
                      </div>
                    </div>
                  </div>
                  {empty && (
                    <NoInitialData />
                  )}
                  {formData.deployawsssointegration.value === "yes" && (
                    <div className="show-hide-content px-0">
                      <div className=" col-12 d-inline-flex selectionArea my-1 px-3 justify-content-between flex-wrap py-1">
                        <label className="font-weight-bold my-2 font-12">
                          Directory for SSO Users
                        </label>
                        <div className="my-2">
                          <label className="container">
                            Integrate with existing Microsoft AD
                            <input
                              type="radio"
                              name="single"
                              value="integratewithexistingad"
                              checked={
                                formData.awsssodirectory.value ===
                                "integratewithexistingad"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                setFormData((state) => {
                                  return {
                                    ...state,
                                    awsssodirectory: {
                                      value: "integratewithexistingad",
                                    },
                                  };
                                });
                              }}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <div className="my-2">
                          <label className="container">
                            Deploy AWS Managed Microsoft AD
                            <input
                              type="radio"
                              name="single"
                              value="deployawsmanagedmsad"
                              checked={
                                formData.awsssodirectory.value ===
                                "deployawsmanagedmsad"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                setFormData((state) => {
                                  return {
                                    ...state,
                                    awsssodirectory: {
                                      value: "deployawsmanagedmsad",
                                    },
                                  };
                                });
                              }}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <div className="my-2">
                          <label className="container">
                            Deploy Simple AD
                            <input
                              type="radio"
                              name="single"
                              value="deploysimplead"
                              checked={
                                formData.awsssodirectory.value ===
                                "deploysimplead"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                setFormData((state) => {
                                  return {
                                    ...state,
                                    awsssodirectory: {
                                      value: "deploysimplead",
                                    },
                                  };
                                });
                              }}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                
                {formData.deployawsssointegration.value === "yes" && (
                  <div className="show-hide-content">
                    <div className="d-flex flex-column">
                      {formData.awsssodirectory.value ===
                        "integratewithexistingad" && (
                        <div className="d-flex flex-column mainContentArea  pl-3 pr-3 py-1 my-1 ">
                          <div className="d-inline-flex align-items-center row">
                            <div className="col-md-3 my-1 p-0">
                              <span className="mb-0 font-12">
                                Directory DNS Domain Name
                              </span>
                            </div>
                            <div className="col-md-9 flex-wrap d-inline-flex">
                              <div className="mr-3 my-1">
                                <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                  <input
                                    type="text"
                                    className={`form-control bg-transparent ${getFieldValidationClass(
                                      "integratewithexistingad",
                                      "directorydnsdomainname"
                                    )}`}
                                    placeholder="client.com"
                                    value={
                                      formData.integratewithexistingad
                                        .directorydnsdomainname.value
                                    }
                                    onChange={(e) => {
                                      onFieldChange(
                                        e.target.value,
                                        "integratewithexistingad",
                                        "directorydnsdomainname"
                                      );
                                    }}
                                    onBlur={(e) => {
                                      onFieldBlur(
                                        validateDomainName,
                                        e.target.value,
                                        "integratewithexistingad",
                                        "directorydnsdomainname"
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-inline-flex align-items-center row">
                            <div className="col-md-3 my-1 p-0">
                              <span className="mb-0 font-12">
                                Directory DNS Server IP Addresses
                              </span>
                            </div>
                            <div className="col-md-9 flex-wrap d-inline-flex">
                              {formData.integratewithexistingad.directorydnsserveripaddresses.map(
                                (item, idx) => (
                                  <div className="mr-3 my-1" key={idx}>
                                    <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                      <button disabled={disabled}
                                        type="button"
                                        className="closableInput"
                                        onClick={() =>
                                          removeFieldArrayItem(
                                            idx,
                                            "integratewithexistingad",
                                            "directorydnsserveripaddresses"
                                          )
                                        }
                                      ></button>
                                      <input
                                        type="text"
                                        className={`form-control bg-transparent ${getFieldArrayValidationClass(
                                          idx,
                                          "integratewithexistingad",
                                          "directorydnsserveripaddresses"
                                        )}`}
                                        placeholder="192.168.1.56"
                                        value={item.value}
                                        onChange={(e) => {
                                          const value = e.target.value;

                                  for (const [
                                    idxOtherItem,
                                    otherItem,
                                  ] of formData.integratewithexistingad.directorydnsserveripaddresses.entries()) {
                                    if (idx !== idxOtherItem) {
                                      if (
                                        otherItem.isValid &&
                                        otherItem.value !== ""
                                      ) {
                                        if (
                                          otherItem.value.toLowerCase() ===
                                          value.toLowerCase()
                                        ) {
                                          setErrorMsg(
                                            "Directory DNS Server IP Addresses must be unique"
                                          );
                                          return false;
                                        }
                                      }
                                    }
                                  }

                                          onFieldArrayChange(
                                            idx,
                                            e.target.value,
                                            "integratewithexistingad",
                                            "directorydnsserveripaddresses"
                                          );
                                        }}
                                        onBlur={(e) => {
                                          onFieldArrayBlur(
                                            validateIPAddress,
                                            idx,
                                            e.target.value,
                                            "integratewithexistingad",
                                            "directorydnsserveripaddresses"
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                )
                              )}

                              <button disabled={disabled}
                                type="button"
                                onClick={() =>
                                  addFieldArrayItem(
                                    "integratewithexistingad",
                                    "directorydnsserveripaddresses"
                                  )
                                }
                              >
                                <img src="../images/coloredPlus.svg" />
                              </button>
                            </div>
                          </div>
                          <div className="d-inline-flex align-items-center row">
                            <div className="col-md-3 my-1 p-0">
                              <span className="mb-0 font-12">
                                AD Connector Service Account Username
                              </span>
                            </div>
                            <div className="col-md-9 flex-wrap d-inline-flex">
                              <div className="mr-3 my-1">
                                <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                  <input
                                    type="text"
                                    className={`form-control bg-transparent ${getFieldValidationClass(
                                      "integratewithexistingad",
                                      "adconnectorserviceaccountusername"
                                    )}`}
                                    placeholder=""
                                    value={
                                      formData.integratewithexistingad
                                        .adconnectorserviceaccountusername.value
                                    }
                                    onChange={(e) => {
                                      onFieldChange(
                                        e.target.value,
                                        "integratewithexistingad",
                                        "adconnectorserviceaccountusername"
                                      );
                                    }}
                                    onBlur={(e) => {
                                      onFieldBlur(
                                        validate1to20AlpheNumHyphen,
                                        e.target.value,
                                        "integratewithexistingad",
                                        "adconnectorserviceaccountusername"
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-inline-flex align-items-center row">
                            <div className="col-md-3 my-1 p-0">
                              <span className="mb-0 font-12">Password</span>
                            </div>
                            <div className="col-md-9 flex-wrap d-inline-flex">
                              <div className="mr-3 my-1">
                                <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                  <input
                                    type="password"
                                    className={`form-control show-psw bg-transparent ${getFieldValidationClass(
                                      "integratewithexistingad",
                                      "adconnectorserviceaccountpassword"
                                    )}`}
                                    placeholder="*******"
                                    value={
                                      formData.integratewithexistingad
                                        .adconnectorserviceaccountpassword.value
                                    }
                                    onChange={(e) => {
                                      onFieldChange(
                                        e.target.value,
                                        "integratewithexistingad",
                                        "adconnectorserviceaccountpassword"
                                      );
                                    }}
                                    onBlur={(e) => {
                                      onFieldBlur(
                                        validatePasswordMax127,
                                        e.target.value,
                                        "integratewithexistingad",
                                        "adconnectorserviceaccountpassword"
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {formData.awsssodirectory.value === "deploysimplead" && (
                        <div className="d-flex flex-column mainContentArea  pl-3 pr-3 py-1 my-1 ">
                          <div className="d-inline-flex align-items-center row">
                            <div className="col-md-3 my-1 p-0">
                              <span className="mb-0 font-12">
                                Directory Edition
                              </span>
                            </div>
                            <div className="col-md-9 flex-wrap d-inline-flex">
                              <div className="mr-3 my-1">
                                <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                  <select
                                    className="form-control gray"
                                    value={
                                      formData.deploysimplead.directoryedition
                                        .value
                                    }
                                    onChange={(e) =>
                                      onFieldChange(
                                        e.target.value,
                                        "deploysimplead",
                                        "directoryedition"
                                      )
                                    }
                                  >
                                    <option value="standard">Standard</option>
                                    <option value="enterprise">
                                      Enterprise
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-inline-flex align-items-center row">
                            <div className="col-xl-3 my-1 col-md-3  p-0">
                              <span className="mb-0 font-12">
                                Directory DNS Domain Name
                              </span>
                            </div>
                            <div className="col-xl-3 col-lg-9  flex-wrap d-inline-flex">
                              <div className="mr-3 my-1">
                                <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                  <input
                                    type="text"
                                    className={`form-control bg-transparent ${getFieldValidationClass(
                                      "deploysimplead",
                                      "directorydnsdomainname"
                                    )}`}
                                    placeholder="e.g. client.com"
                                    value={
                                      formData.deploysimplead
                                        .directorydnsdomainname.value
                                    }
                                    onChange={(e) => {
                                      onFieldChange(
                                        e.target.value,
                                        "deploysimplead",
                                        "directorydnsdomainname"
                                      );
                                    }}
                                    onBlur={(e) => {
                                      onFieldBlur(
                                        validateDomainName,
                                        e.target.value,
                                        "deploysimplead",
                                        "directorydnsdomainname"
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-3 my-1 col-md-3  p-0 d-flex rightOfLineType1">
                              <span className="mb-0 font-12">
                                Directory NetBIOS Name
                              </span>
                            </div>
                            <div className="col-xl-3 col-lg-9  flex-wrap d-inline-flex">
                              <div className="mr-3 my-1 ">
                                <div className="w-250 bg-gray border-10  p-relative d-inline-flex align-items-center">
                                  <input
                                    type="text"
                                    className={`form-control bg-transparent ${getFieldValidationClass(
                                      "deploysimplead",
                                      "directorydnsnetbiosname"
                                    )}`}
                                    placeholder="optional"
                                    value={
                                      formData.deploysimplead
                                        .directorydnsnetbiosname.value
                                    }
                                    onChange={(e) => {
                                      onFieldChange(
                                        e.target.value,
                                        "deploysimplead",
                                        "directorydnsnetbiosname"
                                      );
                                    }}
                                    onBlur={(e) => {
                                      onFieldBlur(
                                        validate1to10AlphaNumericHyphen,
                                        e.target.value,
                                        "deploysimplead",
                                        "directorydnsnetbiosname"
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-inline-flex align-items-center row">
                            <div className="col-md-3 my-1 p-0">
                              <span className="mb-0 font-12">
                                Admin Password
                              </span>
                            </div>
                            <div className="col-md-9 flex-wrap d-inline-flex">
                              <div className="mr-3 my-1">
                                <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                  <input
                                    type="password"
                                    className={`form-control bg-transparent ${getFieldValidationClass(
                                      "deploysimplead",
                                      "adadminpassword"
                                    )}`}
                                    placeholder="*******"
                                    value={
                                      formData.deploysimplead.adadminpassword
                                        .value
                                    }
                                    onChange={(e) => {
                                      onFieldChange(
                                        e.target.value,
                                        "deploysimplead",
                                        "adadminpassword"
                                      );
                                    }}
                                    onBlur={(e) => {
                                      const validatePwd = (value) => {
                                        const errorMsg = validatePasswordMax127(
                                          value
                                        );

                                        if (!errorMsg) {
                                          if (
                                            value ===
                                            formData.deploysimplead
                                              .repeatedpassword.value
                                          ) {
                                            return;
                                          } else {
                                            return "Password must match";
                                          }
                                        } else {
                                          return errorMsg;
                                        }
                                      };

                                      onFieldBlur(
                                        validatePwd,
                                        e.target.value,
                                        "deploysimplead",
                                        "adadminpassword"
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-inline-flex align-items-center row">
                            <div className="col-md-3 my-1 p-0">
                              <span className="mb-0 font-12">
                                Confirm Password
                              </span>
                            </div>
                            <div className="col-md-9 flex-wrap d-inline-flex">
                              <div className="mr-3 my-1">
                                <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                  <input
                                    type="password"
                                    className="form-control bg-transparent"
                                    placeholder="*******"
                                    /* ref={confirmPwdRef}
                                    value={
                                      formData.deploysimplead.adadminpassword
                                        .value || ""
                                    } */
                                    value={
                                      formData.deploysimplead.repeatedpassword
                                        .value
                                    }
                                    onChange={(e) => {
                                      const value = e.target.value;

                                      setErrorMsg(null);
                                      setIsDirty(true);

                                      setFormData((state) =>
                                        update(state, {
                                          deploysimplead: {
                                            repeatedpassword: {
                                              value: { $set: value },
                                            },
                                          },
                                        })
                                      );
                                    }}
                                    onBlur={(e) => {
                                      const value = e.target.value;

                                      if (
                                        value !==
                                        formData.deploysimplead.adadminpassword
                                          .value
                                      ) {
                                        setFormData((state) =>
                                          update(state, {
                                            deploysimplead: {
                                              adadminpassword: {
                                                isValid: { $set: false },
                                              },
                                            },
                                          })
                                        );

                                        setErrorMsg(
                                          "Admin password doesn't match"
                                        );
                                      } else {
                                        setFormData((state) =>
                                          update(state, {
                                            deploysimplead: {
                                              adadminpassword: {
                                                isValid: { $set: true },
                                              },
                                            },
                                          })
                                        );

                                        setErrorMsg(null);
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="d-flex flex-column mainContentArea  pl-3 pr-3 my-1 py-1">
                        <div className="d-inline-flex w-100 my-1">
                          <div className="pl-lg-0 px-2 d-flex align-items-center mr-xl-5 mr-3 font-12">
                            <div>Deploy Baseline Permission Sets</div>
                          </div>
                          <div className="btn-group btn-group-toggle btn-group-radio mw-100 mr-0">
                            <label
                              className={`createSubElementBtn btn btn-link btn-linkYes ${
                                formData.deploybaselinepermissionsets.value ===
                                "yes"
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <input
                                type="radio"
                                name="options"
                                value="yes"
                                checked={
                                  formData.deploybaselinepermissionsets
                                    .value === "yes"
                                }
                                onChange={() => {
                                  setIsDirty(true);
                                  setFormData((state) => {
                                    return {
                                      ...state,
                                      deploybaselinepermissionsets: {
                                        value: "yes",
                                      },
                                    };
                                  });
                                }}
                              />{" "}
                              YES
                            </label>
                            <label
                              className={`dontCreateSubElementBtn btn btn-link btn-linkNo ${
                                formData.deploybaselinepermissionsets.value ===
                                "no"
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <input
                                type="radio"
                                name="options"
                                checked={
                                  formData.deploybaselinepermissionsets
                                    .value === "no"
                                }
                                onChange={() => {
                                  setIsDirty(true);
                                  setFormData((state) => {
                                    return {
                                      ...state,
                                      deploybaselinepermissionsets: {
                                        value: "no",
                                      },
                                    };
                                  });
                                }}
                              />{" "}
                              NO
                            </label>
                          </div>
                        </div>

                        {formData.deploybaselinepermissionsets.value ===
                          "yes" && (
                          <div className="show-hide-sub-content">
                            <div className=" row  px-md-2 px-0  align-items-center justify-content-between">
                              <div className="col-lg-3 my-1 col-md-6 col-6 form-line d-inline-flex align-items-center ">
                                <div className="d-inline-flex align-items-center bg-special border-10 w-100 pr-2 py-2">
                                  <label className="switch mb-0 ml-0">
                                    <input
                                      type="checkbox"
                                      checked={exists("CloudAdmin")}
                                      onChange={() => modifyArray("CloudAdmin")}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <span className="switchLabel ">
                                    Cloud Admin
                                  </span>
                                </div>
                              </div>
                              <div className="col-lg-3 my-1 col-md-6 col-6  form-line d-inline-flex align-items-center">
                                <div className="d-inline-flex align-items-center bg-special border-10 w-100 pr-2 py-2">
                                  <label className="switch mb-0 ml-lg-3 ml-0">
                                    <input
                                      type="checkbox"
                                      checked={exists("IAMAdmin")}
                                      onChange={() => modifyArray("IAMAdmin")}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <span className="switchLabel">IAM Admin</span>
                                </div>
                              </div>
                              <div className="col-lg-3 my-1 col-md-6 col-6 form-line d-inline-flex align-items-center">
                                <div className="d-inline-flex align-items-center bg-special border-10 w-100 pr-2 py-2">
                                  <label className="switch mb-0 ml-lg-3 ml-0">
                                    <input
                                      type="checkbox"
                                      checked={exists("SecurityAuditor")}
                                      onChange={() =>
                                        modifyArray("SecurityAuditor")
                                      }
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <span className="switchLabel ">
                                    Security Auditor
                                  </span>
                                </div>
                              </div>
                              <div className="col-lg-3 my-1 col-md-6 col-6 form-line d-inline-flex align-items-center">
                                <div className="d-inline-flex align-items-center bg-special border-10 w-100  pr-2 py-2">
                                  <label className="switch mb-0 ml-lg-3 ml-0">
                                    <input
                                      type="checkbox"
                                      checked={exists("SecurityViewOnly")}
                                      onChange={() =>
                                        modifyArray("SecurityViewOnly")
                                      }
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <span className="switchLabel">
                                    Security View Only
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className=" row  px-md-2 px-0  align-items-center">
                              <div className="col-lg-3 my-1 col-md-6 col-6 form-line d-inline-flex align-items-center ">
                                <div className="d-inline-flex align-items-center bg-special border-10 w-100 pr-2 py-2">
                                  <label className="switch mb-0 ml-0">
                                    <input
                                      type="checkbox"
                                      checked={exists("NetworkAdmin")}
                                      onChange={() =>
                                        modifyArray("NetworkAdmin")
                                      }
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <span className="switchLabel ">
                                    Network Admin
                                  </span>
                                </div>
                              </div>
                              <div className="col-lg-3 my-1 col-md-6 col-6  form-line d-inline-flex align-items-center">
                                <div className="d-inline-flex align-items-center bg-special border-10 w-100 pr-2 py-2">
                                  <label className="switch mb-0 ml-lg-3 ml-0">
                                    <input
                                      type="checkbox"
                                      checked={exists("OperationsSupport")}
                                      onChange={() =>
                                        modifyArray("OperationsSupport")
                                      }
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <span className="switchLabel">
                                    Operations Support
                                  </span>
                                </div>
                              </div>
                              <div className="col-lg-3 my-1 col-md-6 col-6 form-line d-inline-flex align-items-center">
                                <div className="d-inline-flex align-items-center bg-special border-10 w-100 pr-2 py-2">
                                  <label className="switch mb-0 ml-lg-3 ml-0">
                                    <input
                                      type="checkbox"
                                      checked={exists("OperationsViewOnly")}
                                      onChange={() =>
                                        modifyArray("OperationsViewOnly")
                                      }
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <span className="switchLabel">
                                    Operations View Only
                                  </span>
                                </div>
                              </div>
                              <div className="col-lg-3 my-1 col-md-6 col-6 form-line d-inline-flex align-items-center">
                                <div className="d-inline-flex align-items-center bg-special border-10 w-100  pr-2 py-2">
                                  <label className="switch mb-0 ml-lg-3 ml-0">
                                    <input
                                      type="checkbox"
                                      checked={exists("AnalyticsAdmin")}
                                      onChange={() =>
                                        modifyArray("AnalyticsAdmin")
                                      }
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <span className="switchLabel">
                                    Analytics Admin
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className=" row  px-md-2 px-0  align-items-center">
                              <div className="col-lg-3 my-1 col-md-6 col-6 form-line d-inline-flex align-items-center ">
                                <div className="d-inline-flex align-items-center bg-special border-10 w-100 pr-2 py-2">
                                  <label className="switch mb-0 ml-0">
                                    <input
                                      type="checkbox"
                                      checked={exists("Finance")}
                                      onChange={() => modifyArray("Finance")}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <span className="switchLabel">
                                    Finance Billing
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              </fieldset>
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() => saveDataIfDirty(ROUTES.ADFS_INTEGRATION + "/" + projectId)}
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                      Azure ADFS Integration
                    </p>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {console.log(formData)}
    </>
  );
};

export default AwsSsoIntegration;
