import React, { useEffect, useState } from "react";
import update from "immutability-helper";

import useAwsAmplify from "../../hooks/useAwsAmplify";
import {
  validate2to32AlphaNumeric,
  validateRequired,
} from "../../helpers/validator";
import useSession from "../../hooks/useSession";
import { getError } from "../../helpers/errorHelper";

const lookupObj = {
  clientId: "Client Name",
  projectType: "Project Type",
  projectName: "Project Name",
};

const ProjectForm = ({ close, project }) => {
  const { group, authUser } = useSession();
  const awsAmplify = useAwsAmplify();

  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    projectName: {
      value: project ? project.ProjectName : "",
      isValid: project ? true : false,
    },
    projectType: {
      value: project ? project.ProjectType : "",
      isValid: project ? true : false,
    },
    clientId: {
      value: project ? project.ClientID : "",
      isValid: project ? true : false,
    },
    enableLaunchAccess: project ? project.EnableLaunchAccess : "yes",
    assignedUsers: project ? project.AssignedUsers : [],
    selectedAssignedUserID: {
      value: "",
    },
    projectStatus: {
      value: project ? project.ProjectStatus : "",
    },
    domainUsers: project
      ? project.DomainUsers.filter(
          (du) =>
            project.AssignedUsers &&
            project.AssignedUsers.indexOf(du.UserID) === -1
        )
      : [],
    getLatestCode: {
      value: false
    }  
  });

  const [isDirty, setIsDirty] = useState(false);

  const [clientId, setClientId] = useState("");
  const [domainUsers, setDomainUsers] = useState(
    project ? project.DomainUsers : []
  );
  const [clients, setClients] = useState([]);
  const [clientName, setClientName] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    console.log(formData);

    const errorMsgs = [];

    for (const prop in formData) {
      if (formData[prop].hasOwnProperty("isValid") && !formData[prop].isValid) {
        if (formData[prop].errorMsg) {
          errorMsgs.push(formData[prop].errorMsg);
        } else {
          errorMsgs.push(`${lookupObj[prop]} must be provided`);

          setFormData((state) =>
            update(state, {
              [prop]: {
                errorMsg: {
                  $set: errorMsg
                    ? `${lookupObj[prop]} ${errorMsg}`
                    : `${lookupObj[prop]} must be provided`,
                },
              },
            })
          );
        }
      }
    }

    if (errorMsgs.length > 0) {
      setErrorMsg(errorMsgs);
      return;
    }

    try {
      setIsLoading(true);

      if (project) {
        const result = await awsAmplify.updateProject(
          project.ProjectID,
          formData.projectName.value,
          formData.projectStatus.value,
          formData.enableLaunchAccess,
          formData.assignedUsers,
          formData.getLatestCode.value
        );
        console.log(result);
      } else {
        const result = await awsAmplify.createProject(
          formData.projectName.value,
          formData.clientId.value,
          formData.projectType.value,
          formData.enableLaunchAccess,
          formData.assignedUsers
        );
        console.log(result);

        //projectId = result.data.createProject;
      }

      setIsLoading(false);
      setIsDirty(false);

      close(true, "create", `Successfully ${project? "updated" : "added"} the project`);
    } catch (err) {
      setIsLoading(false);

      const errorMsg = getError(err);
      if (errorMsg === "Project doesn't exist") {
        close(true, "create", null, errorMsg);
      }
      else{
        setErrorMsg(errorMsg);
      }
    }
  };

  const onFieldChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    setErrorMsg(null);
    setIsDirty(true);

    setFormData((state) =>
      update(state, { [name]: { value: { $set: value } } })
    );
  };

  const onFieldBlur = (validateFunc, event) => {
    const value = event.target.value;
    const name = event.target.name;

    let errorMsg;

    if (value === "") {
      errorMsg = "must be provided";
    } else {
      errorMsg = validateFunc(value);
    }

    setFormData((state) =>
      update(state, {
        [name]: {
          isValid: { $set: errorMsg === undefined },
          errorMsg: {
            $set: errorMsg ? `${lookupObj[name]} ${errorMsg}` : errorMsg,
          },
        },
      })
    );

    if (errorMsg) {
      setErrorMsg(`${lookupObj[name]} ${errorMsg}`);
    }

    return errorMsg === undefined;
  };

  const addAssignedUser = (userId) => {
    const exists = (value) => {
      return formData.assignedUsers.indexOf(value) !== -1;
    };

    if (userId) {
      if (!exists(userId)) {
        setFormData((state) => {
          let idx = -1;

          state.domainUsers.forEach((du, duIdx) => {
            if (du.UserID === userId) {
              idx = duIdx;
            }
          });

          console.log(`idx: ${idx}`);

          return update(state, {
            assignedUsers: { $push: [userId] },
            domainUsers: { $splice: [[idx, 1]] },
          });
        });
      }
    }
  };

  const removeAssignedUser = (userId, email) => {
    setFormData((state) =>
      update(state, {
        assignedUsers: {
          $splice: [[state.assignedUsers.indexOf(userId), 1]],
        },
        domainUsers: {
          $push: [{ UserID: userId, EmailID: email }],
        },
        selectedAssignedUserID: {
          value: {
            $set: "",
          },
        },
      })
    );
  };

  const fetchDomainUsers = async (clientId) => {
    try {
      setErrorMsg(null);
      setIsLoading(true);
      const results = await awsAmplify.findUsersByClientID(clientId);
      setIsLoading(false);

      console.log(results);

      if (results.data && results.data.findUsersByClientID) {
        setDomainUsers(results.data.findUsersByClientID);

        setFormData((state) =>
          update(state, {
            domainUsers: { $set: results.data.findUsersByClientID },
          })
        );
      }
    } catch (err) {
      setIsLoading(false);

      getError(err, setErrorMsg);
    }
  };

  useEffect(() => {
    //setGroup(getGroup(authUser));

    if (group === "ClientAdmin") {
      fetchDomainUsers();

      setFormData((state) =>
        update(state, { clientId: { isValid: { $set: true } } })
      );
    }
  }, [group]);

  useEffect(() => {
    if (clientId !== "") {
      fetchDomainUsers(clientId);
    }
  }, [clientId]);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);
        const results = await awsAmplify.getClientIDAndNamesAndDomainName();
        setIsLoading(false);

        if (results.data && results.data.getClients) {
          setClients(results.data.getClients);

          if (project) {
            const client = results.data.getClients.find(
              (client) => client.ClientID === formData.clientId.value
            );

            if (client) {
              setClientName(client.ClientName);
            }
          }
        }
      } catch (err) {
        setIsLoading(false);
  
        getError(err, setErrorMsg);
      }
    };
    //console.log(`Group: ${getGroup(authUser)}`);

    if (group === "SuperAdmin") {
      console.log("fetching clients...");
      fetchClients();
    }
  }, [group]);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong>
              {Array.isArray(errorMsg)
                ? errorMsg.map((em) => (
                    <>
                      <span>{em}</span>
                    </>
                  ))
                : errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="justify-content-center align-items-center popupArea create-new-project">
        <div className="auth-main">
          <form className="auth-form" onSubmit={onSubmit}>
            <button
              className="exit btn-animation"
              onClick={() => close(false, "create")}
            >
              Exit
            </button>
            <div className="title">{project ? "Update" : "Create"} Project</div>

            <div className="auth-form-content d-flex flex-column justify-content-center">
              {group === "SuperAdmin" && (
                <div className="halfBox d-flex flex-column">
                  <div className="inlineRow d-inline-flex justify-content-between">
                    <div className="titleOfContent fixedArea">Client Name</div>
                    {project ? (
                      <input
                        type="text"
                        disabled={true}
                        className={"form-control my-1 "}
                        value={clientName}
                      />
                    ) : (
                      <div
                        className={formData.clientId.errorMsg ? "invalid" : ""}
                      >
                        <select
                          //disabled={project !== null}
                          className="selectInput my-1"
                          name="clientId"
                          value={formData.clientId.value}
                          onChange={(e) => {
                            onFieldChange(e);
                            if (e.target.value !== "") {
                              setClientId(e.target.value);
                            }
                          }}
                          onBlur={(e) => onFieldBlur(validateRequired, e)}
                        >
                          <option value="">Select</option>
                          {clients.map((client, idxClient) => (
                            <option key={idxClient} value={client.ClientID}>
                              {`${client.ClientName}`}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="halfBox d-flex flex-column my-1">
                <label className="mb-0 mt-2 font-12">Project Name</label>
              </div>
              <input
                type="text"
                disabled={project !== null}
                className={
                  "form-control cname my-1 " +
                  (formData.projectName.errorMsg && "invalid")
                }
                placeholder="Project Name"
                name="projectName"
                value={formData.projectName.value}
                onChange={onFieldChange}
                onBlur={(e) => onFieldBlur(validate2to32AlphaNumeric, e)}
                maxLength="10"
              />
              <div className="halfBox d-flex flex-column">
                <div className="inlineRow d-inline-flex justify-content-between">
                  <div className="titleOfContent fixedArea">Project Type</div>
                  {project ? (
                    <input
                      type="text"
                      disabled={true}
                      className={"form-control my-1 "}
                      value={formData.projectType.value}
                    />
                  ) : (
                    <div
                      className={formData.projectType.errorMsg ? "invalid" : ""}
                    >
                      <select
                        className="selectInput my-1"
                        name="projectType"
                        value={formData.projectType.value}
                        onChange={onFieldChange}
                        onBlur={(e) => onFieldBlur(validateRequired, e)}
                      >
                        <option value="">Select</option>
                        <option value="AWS">AWS</option>
                        <option value="Azure">Azure</option>
                        <option value="GCP">GCP</option>
                        <option value="AliCloud">AliCloud</option>
                      </select>
                    </div>
                  )}
                </div>

                <div className="inlineRow d-inline-flex justify-content-between">
                  <div className="titleOfContent my-1">
                    Enable Launch Access
                  </div>
                  <div>
                    <div className="btn-group btn-group-toggle btn-group-radio mw-100 my-1">
                      <label
                        className={`btn btn-link btn-linkYes ${
                          formData.enableLaunchAccess === "yes" && "active"
                        }`}
                      >
                        <input
                          type="radio"
                          name="options"
                          checked={formData.enableLaunchAccess === "yes"}
                          onChange={(e) =>
                            setFormData((state) =>
                              update(state, {
                                enableLaunchAccess: { $set: "yes" },
                              })
                            )
                          }
                        />{" "}
                        YES
                      </label>
                      <label
                        className={`btn btn-link btn-linkNo ${
                          formData.enableLaunchAccess === "no" && "active"
                        }`}
                      >
                        <input
                          type="radio"
                          name="options"
                          checked={formData.enableLaunchAccess === "no"}
                          onChange={(e) =>
                            setFormData((state) =>
                              update(state, {
                                enableLaunchAccess: { $set: "no" },
                              })
                            )
                          }
                        />{" "}
                        NO
                      </label>
                    </div>
                  </div>
                </div>

                {/* <div className="columnRow w-100 d-flex flex-column">
                  <div className="titleOfContent  my-1">
                    Source Code S3 Bucket
                  </div>

                  <input
                    type="text"
                    className="form-control name w-100 my-1"
                    placeholder="e2e-sourcecode-UUID"
                  />
                </div> */}

                <label className="my-1 titleLabel">Assigned Users</label>

                {formData.assignedUsers &&
                  formData.assignedUsers.map((userId, idxUserId) => {
                    const assignedUser = domainUsers.find(
                      (du) => du.UserID === userId
                    );
                    console.log(assignedUser);

                    if (assignedUser) {
                      return (
                        <div
                          key={idxUserId}
                          className="assignedUser inlineRow d-inline-flex justify-content-between my-1 align-items-center"
                          style={{ position: "relative" }}
                        >
                          <button
                            className="negativeIcon"
                            type="button"
                            onClick={() =>
                              removeAssignedUser(
                                assignedUser.UserID,
                                assignedUser.EmailID
                              )
                            }
                          >
                            <img src="../image/negative.svg" />
                          </button>
                          <p className="adminMail text-center py-1 m-0 spec-padding">
                            {`${assignedUser.EmailID}`}
                          </p>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}

                <div
                  className="inlineRow d-inline-flex justify-content-between my-1"
                  style={{ position: "relative" }}
                >
                  <button
                    type="button"
                    className="plusIcon"
                    onClick={() =>
                      addAssignedUser(formData.selectedAssignedUserID.value)
                    }
                  >
                    <img src="../image/Layer%2020.svg" />
                  </button>
                  <div className="titleOfContent fixedArea">Assign User</div>
                  <select
                    className="selectInput my-1"
                    name="selectedAssignedUserID"
                    value={formData.selectedAssignedUserID.value}
                    onChange={onFieldChange}
                  >
                    <option value="">Select</option>
                    {formData.domainUsers.map((dm, idxDm) => (
                      <option key={idxDm} value={dm.UserID}>
                        {`${dm.EmailID}`}
                      </option>
                    ))}
                  </select>
                </div>
                {project ? (
                  <>
                    <div
                      className="inlineRow d-inline-flex justify-content-between mt-1"
                      style={{ position: "relative" }}
                    >
                      <div className="titleOfContent fixedArea">Status</div>
                      <select
                        className="selectInput fixedArea"
                        name="projectStatus"
                        value={formData.projectStatus.value}
                        onChange={onFieldChange}
                      >
                        <option value="">Select</option>
                        <option value="Created">Created</option>
                        <option value="Saved">Saved</option>
                        <option value="Launching">Launching</option>
                        <option value="Launch Failed">Launch Failed</option>
                        <option value="Deploying">Deploying</option>
                        <option value="Deployment Failed">
                          Deployment Failed
                        </option>
                        <option value="Launched Successfully">
                          Launched Successfully
                        </option>
                        <option value="Deployed Successfully">
                          Deployed Successfully
                        </option>
                      </select>
                    </div>

                    <div className="form-line my-lg-0 my-1 col-12 d-inline-flex align-items-center inlineRow d-inline-flex justify-content-between mt-1">
                      <label className="switch mb-0">
                        <input
                          type="checkbox"
                          checked={formData.getLatestCode.value === true}
                          onChange={(e) => {
                            const value = e.target.checked;
                            setFormData((state) =>
                              update(state, {
                                getLatestCode: {
                                  value: { $set: value },
                                },
                              })
                            );
                          }}
                        />
                        <span className="slider round"></span>
                      </label>
                      <span className="switchLabel ">Get the latest code</span>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            <div className="auth-form-footer">
              <div>
                <button
                  type="submit"
                  className="confirmBtn btn btn-link btn-green btn-animation"
                >
                  {project ? "Update" : "Create"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {console.log(formData)}
      {console.log(group)}
    </>
  );
};

export default ProjectForm;
