import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

import * as ROUTES from "../constants/routes";

import useSession from "../hooks/useSession";
import useAwsAmplify from "../hooks/useAwsAmplify";
import $ from "jquery";

import { getGroup } from "../helpers/userHelper";
import SwitchUserForm from "./SwitchUserForm";
import SwitchUserContainer from "./SwitchUserContainer";

import {getFirstFeatureRoute} from "../helpers/projectHelper";

const TopMenu = ({
  currentRouteIsAdmin = false,
  reload = false,
  saveDataIfDirty,
}) => {
  const { projectId } = useParams();
  const { authUser, isUser, isClientAdmin, isSuperAdmin, group } = useSession();

  const awsAmplify = useAwsAmplify();

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [isSwitchUser, setIsSwitchUser] = useState(false);

  const [myProjectsData, setMyProjectsData] = useState({
    projects: [],
    clientName: "",
  });

  const [clientLogo, setClientLogo] = useState("");
  const [showEpic9Logo, setShowEpic9Logo] = useState(true);

  const signOut = () => {
    awsAmplify.signOut();
    window.location.href = "/";
    //history.push(ROUTES.LANDING);
  };

  const switchUser = () => {
    //awsAmplify.signOut();
    //window.location.href = "/signin";

    if (saveDataIfDirty) {
      saveDataIfDirty();
    }
    setIsSwitchUser(true);
  };

  /* useEffect(() => {
    $(".headerUserNavMobil .linkToDrop").click(function () {
      $(this).find(".drop").toggleClass("nonclosed");
    });

    $(".mobilShow img").click(function () {
      console.log("here");
      $(".headerUserNavMobil").toggleClass("opened");
    });
  }); */

  const [toggleLinktodrop, setToggleLinktodrop] = useState(false);
  const [toggleMobileshow, setToggleMobileshow] = useState(false);

  const getMyProjects = async () => {
    try {
      setError(null);
      setIsLoading(true);

      const results = await awsAmplify.getMyProjects();
      setIsLoading(false);

      console.log(results);

      if (results.data && results.data.getMyProjects) {
        setMyProjectsData(results.data.getMyProjects);

        if (currentRouteIsAdmin) {
          setClientLogo(results.data.getMyProjects.clientLogo);

          if (results.data.getMyProjects.clientName === "Epic9") {
            setShowEpic9Logo(false);
          } else {
            setShowEpic9Logo(true);
          }
        }
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);

      if (err && err.message) {
        setError(err.message);
      } else if (err && err.response && err.response.data) {
        setError(err.response.data.message);
      }
    }
  };

  useEffect(() => {
    if (authUser) {
      getMyProjects();
    }
  }, [authUser]);

  useEffect(() => {
    if (reload) {
      getMyProjects();
    }
  }, [reload]);

  useEffect(() => {
    if (projectId) {
      const project = myProjectsData.projects.find(
        (p) => p.ProjectID === projectId
      );

      if (project) {
        setClientLogo(project.ClientLogo);

        if (project.ClientName === "Epic9") {
          setShowEpic9Logo(false);
        } else {
          setShowEpic9Logo(true);
        }
      }
    }
  }, [projectId, myProjectsData]);

  const closeHandler = () => {
    setIsSwitchUser(false);
  };

  return (
    <>
      {!authUser ? null : (
        <>
          {error && (
            <div className="alert-area">
              <div className="redalert">
                <div className="warnImg">
                  <img alt="" src="../images/warning.svg" />
                </div>
                <span className="closebtn" onClick={() => setError(null)}>
                  &times;
                </span>
                <div className="messageText">
                  <strong>Error!</strong> {error}
                </div>
              </div>
            </div>
          )}

          {/* {isLoading && (
            <div className="alert-area">
              <div className="bluealert">
                <div className="messageText">Please wait...</div>
              </div>
            </div>
          )} */}
          {/*  */}

          {isSwitchUser && (
            <SwitchUserContainer
              close={() => setIsSwitchUser(false)}
            ></SwitchUserContainer>
          )}

          <div className="header-bar">
            <div className="d-inline-flex w-100 align-items-center justify-content-between">
              <div className="headerLogo py-2">
                {showEpic9Logo && (
                  <img
                    alt="logo"
                    src="../images/mainLogo.svg"
                    style={{ marginRight: "5px" }}
                  />
                )}
                {clientLogo && (
                <img
                  className="clientLogo"
                  alt="client logo"
                  src={clientLogo}
                />
                )}
              </div>
              <div className="headerUserNav">
                <ul className="m-0 p-0 userNavList  align-items-center">
                  <li className="d-inline-flex">
                    <a className="mr-2" href="#">
                      <img src="../images/tools-and-utensils%20(2).svg" />
                    </a>
                    <a className="ml-2" href="#">
                      <img src="../images/Notification.svg" />
                    </a>
                  </li>
                  {currentRouteIsAdmin && (
                    <>
                      <li className="linkToDrop projectLinkToDrop">
                        <button className="selectedProject pr-2">
                          My Projects{" "}
                          <div className="iconDrop">
                            <img src="../images/small-down.svg" />
                          </div>
                        </button>
                        <ul className="drop m-0 px-2 py-0">
                          <div className="d-flex flex-column">
                            {myProjectsData.projects.length === 0 ? (
                              <li>No Projects</li>
                            ) : (
                              <>
                                {myProjectsData.projects.map(
                                  (project, idxProject) => (
                                    <li key={idxProject}>
                                      <Link
                                        to={`${getFirstFeatureRoute(project.ProjectType)}/${project.ProjectID}`}
                                      >
                                        {project.ClientName
                                          ? `${project.ClientName} | ${project.ProjectName}`
                                          : project.ProjectName}
                                      </Link>
                                    </li>
                                  )
                                )}
                              </>
                            )}
                          </div>
                        </ul>
                      </li>
                    </>
                  )}
                  {/* {currentRouteIsAdmin ? (
                    <li className="userInfo linkToDrop">
                      <a className="pr-2" href="#">
                        <p className="ml-0 my-0 mr-1 p-0">{`${authUser.attributes.given_name} ${authUser.attributes.family_name}`}</p>
                        <img src="../images/Avatar.svg" />
                      </a>
                      <ul className="drop m-0 px-2 py-0">
                        <li className="d-flex flex-column">
                          <a className="userName" href="#">
                            {`${authUser.attributes.given_name} ${authUser.attributes.family_name}`}
                          </a>
                          <a className="userMail" href="#">
                            {`${authUser.attributes.email}`}
                          </a>
                          <a className="userCompany" href="#">
                            {myProjectsData.clientName}
                          </a>
                        </li>
                        <li>
                          <a
                            className="d-inline-flex switch-user-link pt-2"
                            onClick={switchUser}
                          >
                            <p className="m-0 p-0">Switch User</p>
                          </a>
                        </li>
                        <li>
                          <a className="d-inline-flex pb-2" onClick={signOut}>
                            <p className="m-0 p-0">Sign Out</p>
                          </a>
                        </li>
                      </ul>
                    </li>
                  ) : ( */}
                  <li className="userInfo linkToDrop">
                    <a className=" py-0 userName" href="#">
                      <p className="ml-0 my-0 mr-2 p-0 mainUserName">{`${authUser.attributes.given_name} ${authUser.attributes.family_name}`}</p>
                      <img src="../images/Avatar.svg" />
                    </a>
                    <ul className="drop m-0 px-2">
                      <li className="d-flex flex-column py-2 userInfoArea1">
                        <div className="d-inline-flex justify-content-between pb-2">
                          <a
                            href="#"
                            className="userName"
                          >{`${authUser.attributes.given_name} ${authUser.attributes.family_name}`}</a>
                          <img src="../images/Avatar.svg" />
                        </div>
                        <a
                          href="#"
                          className="justify-content-start userMail"
                        >{`${authUser.attributes.email}`}</a>
                        <a
                          href="#"
                          className="justify-content-start userCompany py-2"
                        >
                          {myProjectsData.clientName}
                        </a>
                      </li>
                      <li className="no-border">
                        <button
                          type="button"
                          onClick={switchUser}
                          className="py-2 d-inline-flex switch-user-link "
                        >
                          <p className="m-0 p-0">Switch User</p>
                        </button>
                      </li>
                      <li className=" signout-link border-spc-bottom">
                        <button
                          type="button"
                          className="d-inline-flex pb-2"
                          onClick={signOut}
                        >
                          <p className="m-0 p-0">Sign Out</p>
                        </button>
                      </li>

                      {!currentRouteIsAdmin && !isUser && (
                        <li className="px-2 no-border">
                          <Link
                            className="userRole"
                            to={
                              isSuperAdmin
                                ? ROUTES.ADMIN_CLIENTS
                                : ROUTES.ADMIN_PROJECTS
                            }
                          >
                            Admin
                          </Link>
                        </li>
                      )}
                    </ul>
                  </li>
                  {/* )} */}
                </ul>
              </div>

              <div
                className={
                  "headerUserNavMobil " + (toggleMobileshow ? "opened" : "")
                }
              >
                <div
                  className="mobilShow"
                  onClick={() => setToggleMobileshow(!toggleMobileshow)}
                >
                  <img src="../images/Avatar.svg" />
                </div>
                <ul className="m-0 p-0 userNavListMobil  flex-column justify-content-start">
                  <li>
                    <ul className=" m-0 px-2 py-0">
                      <li className="d-flex flex-column py-2 userInfoArea1">
                        <span className="userName">
                          {`${authUser.attributes.given_name} ${authUser.attributes.family_name}`}
                        </span>
                        <span className="userMail">
                          {`${authUser.attributes.email}`}
                        </span>
                        <span className="userCompany">
                          {myProjectsData.clientName}
                        </span>
                      </li>
                      <li>
                        <button
                          className="py-2 d-inline-flex switch-user-link"
                          type="button"
                          onClick={switchUser}
                        >
                          <p className="m-0 p-0">Switch User</p>
                        </button>
                      </li>
                      <li
                        className={isUser ? "no-border" : "border-bottom-spc"}
                      >
                        <button
                          className="d-inline-flex"
                          onClick={signOut}
                          type="button"
                        >
                          <p className="m-0 p-0">Sign Out</p>
                        </button>
                      </li>
                    </ul>
                  </li>

                  {!currentRouteIsAdmin && !isUser && (
                    <li className="px-2 ">
                      <Link
                        className="pr-2 no-border userRole"
                        to={
                          isSuperAdmin
                            ? ROUTES.ADMIN_CLIENTS
                            : ROUTES.ADMIN_PROJECTS
                        }
                      >
                        Admin
                      </Link>
                    </li>
                  )}
                  {/* <li
                      className="linkToDrop px-2"
                      onClick={() => setToggleLinktodrop(!toggleLinktodrop)}
                    >
                      <a className="pr-2 userProjectList" href="#">
                        My Projects{" "}
                        <div className="iconDrop">
                          <img src="../images/small-down.svg" />
                        </div>
                      </a>
                      <ul
                        className={
                          "drop m-0 px-0 py-0 " +
                          (toggleLinktodrop ? "nonclosed" : "")
                        }
                      >
                        {myProjectsData.projects.length === 0 ? (
                          <li>No Projects</li>
                        ) : (
                          <>
                            {myProjectsData.projects.map(
                              (project, idxProject) => (
                                <li key={idxProject}>
                                  <Link
                                    to={`${ROUTES.ENTERPRISE_CORE_ACCOUNTS}/${project.ProjectID}`}
                                  >
                                    {project.ClientName
                                      ? `${project.ClientName} | ${project.ProjectName}`
                                      : project.ProjectName}
                                  </Link>
                                </li>
                              )
                            )}
                          </>
                        )}
                      </ul>
                    </li> */}
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TopMenu;
