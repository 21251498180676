
import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "../../../dashboard-common/DetailsTags";
import { getArrayValue, getBooleanValue} from "../../../dashboard-common/DashboardHelper";
import DetailsGrid from "../../../dashboard-common/DetailsGrid";

const dashboardName = "Service Catalog Portfolios";

const SubMenu = () => {
    const { projectId } = useParams();
  
    return (
      <>
        <h3>Service Catalog</h3>
        <div className="otherTableTitles ">
          <h3 className="font-15 activetable">   
           Portfolios
          </h3>
          <h3 className="font-15">  
          <Link className="text-color-gray" to={ROUTES.GOVERNANCE_PRODUCTS + "/" + projectId}>  
             Products
         </Link>
          </h3>
        </div>
      </>
    );
  };


  const Details = ({ close, selectedRow }) => {
    const [type, setType] = useState("Details");
  
    return (
      <div className="justify-content-center align-items-end account-detail">
        <div className="w-100 s-flex flex-column account-detail-area">
          <button className="exitAccount" onClick={() => close()}>
            <img src="../images/close.svg" />
          </button>
          <div className="">
            <ul className="account-detail-link m-0 p-0 d-inline-flex">
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Details" && "my_active"
                }`}
                onClick={() => setType("Details")}
              >
                Details
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Products" && "my_active"
                }`}
                onClick={() => setType("Products")}
              >
               Products
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Constraints" && "my_active"
                }`}
                onClick={() => setType("Constraints")}
              >
              Constraints
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Groups, Roles & Users" && "my_active"
                }`}
                onClick={() => setType("Groups, Roles & Users")}
              >
              Groups, Roles & Users
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Tags" && "my_active"
                }`}
                onClick={() => setType("Tags")}
              >
              Tags
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Tag Options" && "my_active"
                }`}
                onClick={() => setType("Tag Options")}
              >
              Tag Options
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Budgets" && "my_active"
                }`}
                onClick={() => setType("Budgets")}
              >
              Budgets
              </li>
            </ul>
          </div>
          <div className="d-flex w-100 account-detail-container-area">
            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Details" && "inaktif"
              }`}
            >
              <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.AccountId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Region</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow && selectedRow.Region}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Portfolio Name</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  { selectedRow && selectedRow.PortfolioDetail && selectedRow.PortfolioDetail.DisplayName} 
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                    ID
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  { selectedRow && selectedRow.PortfolioDetail && selectedRow.PortfolioDetail.Id}
                  </p>
                </div>
              
               
              </div>
              <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                    Description
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  { selectedRow && selectedRow.PortfolioDetail && selectedRow.PortfolioDetail.Description}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                      Provider Name
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  { selectedRow && selectedRow.PortfolioDetail && selectedRow.PortfolioDetail.ProviderName}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Created Time</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.PortfolioDetail && selectedRow.PortfolioDetail.CreatedTime}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                    ARN
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  { selectedRow && selectedRow.PortfolioDetail && selectedRow.PortfolioDetail.ARN}
                  </p>
                </div>
                </div>
            </div>


            <DetailsGrid
             type={type}
             typeName="Products"
             array={selectedRow.Products}
             metadata={[
               { header: "Product Name", key: "ProductViewSummary.Name" },
               { header: "Product Id", key: "ProductViewSummary.ProductId" },
               { header: "Description", key: "ProductViewSummary.ShortDescription" },
               { header: "Owner", key: "ProductViewSummary.Owner" },
               { header: "Type", key: "ProductViewSummary.Type" }, 
               { header: "Status", key: "Status" }, 
               { header: "Created Time", key: "CreatedTime" }, 
               { header: "Product ARN", key: "ProductARN" }, 
               { header: "Has Default Path", key: "ProductViewSummary.HasDefaultPath" }, 
             ]}
           ></DetailsGrid>
          
          <DetailsGrid
             type={type}
             typeName="Constraints"
             array={selectedRow.Constraints}
             metadata={[
               { header: "Constraint Id", key: "ConstraintId" },
               { header: "Type", key: "Type" },
               { header: "Owner", key: "Owner" },
               { header: "Product Id", key: "ProductId" }, 
               { header: "Portfolio Id", key: "PortfolioId" }, 
             ]}
           ></DetailsGrid>

            <div
             className={`bolgeler-container w-100 row p-5 ${
               type !== "Groups, Roles & Users" && "inaktif"
             }`}
           >
            <div className="d-flex flex-column">
                <table className="details-table">
                  <thead>
                    <tr>
                      <th>Principal ARN</th>
                      <th>Principal Type</th>
                    </tr>
                  </thead>
                  <tbody>
                  {selectedRow["Groups,Roles and Users"] &&  selectedRow["Groups,Roles and Users"].map(
                     item => (
                    <tr>
                        <td>{item.PrincipalARN}</td>
                        <td>{item.PrincipalType}</td>
                    </tr>
                    ))}  
                  </tbody>
                </table>
            </div>
            </div>
         
            
            <DetailsTags type={type} tags={selectedRow.Tags}></DetailsTags> 

         
            <div
             className={`bolgeler-container w-100 row p-5 ${
               type !== "Tag Options" && "inaktif"
             }`}
           >
            <div className="d-flex flex-column">
                <table className="details-table">
                  <thead>
                    <tr>
                      <th>Key</th>
                      <th>Value</th>
                      <th>Active</th>
                      <th>Id</th>
                    </tr>
                  </thead>
                  <tbody>  
                     {selectedRow.TagOptions && selectedRow.TagOptions.map(item => (
                            <tr> 
                            <td>{item.Key}</td>
                            <td>{item.Value}</td>
                            <td>{getBooleanValue(item,"Active")}</td>
                            <td>{item.Id}</td>
                        </tr>
                     ))}                 
                  </tbody>
                </table>
            </div>
            </div>

            <div
             className={`bolgeler-container w-100 row p-5 ${
               type !== "Budgets" && "inaktif"
             }`}
           >
            <div className="d-flex flex-column">
                <table className="details-table">
                  <thead>
                    <tr>
                      <th>Budget Name</th>
                      <th>Budget Type</th>
                      <th>Time Unit</th>
                      <th>Current Spend</th>
                      <th>Budgeted</th>
                      <th>Forecasted</th>
                      <th>Current vs. Budgeted</th>
                      <th>Forecasted vs. Budgeted</th>
                      <th>Last Updated</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                        <td>{selectedRow["Budgets"].BudgetName}</td>
                        <td>{selectedRow["Budgets"].BudgetType}</td>
                        <td>{selectedRow["Budgets"].TimeUnit}</td>
                        <td>{selectedRow["Budgets"].CalculatedSpend  && 
                         selectedRow["Budgets"].CalculatedSpend.ActualSpend && 
                         selectedRow["Budgets"].CalculatedSpend.ActualSpend.Amount}</td>
                         <td>{selectedRow["Budgets"].BudgetLimit &&
                              selectedRow["Budgets"].BudgetLimit.Amount }</td>
                        <td>{selectedRow["Budgets"].CalculatedSpend  && 
                         selectedRow["Budgets"].CalculatedSpend.ForecastedSpend && 
                         selectedRow["Budgets"].CalculatedSpend.ForecastedSpend.Amount}</td>
                         <td>{ getCurrentVsBudgeted(selectedRow) ? getCurrentVsBudgeted(selectedRow) + '%': "-" }</td>
                         <td>{ getForecastedVsBudgeted(selectedRow) ?  getForecastedVsBudgeted(selectedRow) + '%': "-"} </td>                       
                         <td>{ selectedRow["Budgets"].LastUpdatedTime}</td>
                    </tr>
                  </tbody>
                </table>
            </div>
            </div>
         
            
          </div>
        </div>
      </div>
    );
  };

  const getCurrentVsBudgeted = (data) => {

     var current =  parseFloat(data["Budgets"] && data["Budgets"].CalculatedSpend  && 
     data["Budgets"].CalculatedSpend.ActualSpend && 
     data["Budgets"].CalculatedSpend.ActualSpend.Amount);

    var budgeted = parseFloat(data["Budgets"] && data["Budgets"].BudgetLimit &&
                    data["Budgets"].BudgetLimit.Amount);
                    
    var result;
    
    if(current && budgeted)
    {
       result = current / budgeted;
    }
    return result;
  }

  const getForecastedVsBudgeted = (data) => {
    var forecasted = parseFloat(data["Budgets"]  && data["Budgets"].CalculatedSpend  && 
    data["Budgets"].CalculatedSpend.ForecastedSpend && 
    data["Budgets"].CalculatedSpend.ForecastedSpend.Amount);

    var budgeted = parseFloat(data["Budgets"] && data["Budgets"].BudgetLimit &&
    data["Budgets"].BudgetLimit.Amount);

    var result;
    if(forecasted && budgeted)
    {
       result = forecasted / budgeted;
    }
    return result;
  }

  const Navigations = () => {
    const { projectId } = useParams();
    return (
      <>
       <Link
      className="btn-post btn-animation d-inline-flex"
      to={ROUTES.GOVERNANCE_AWS_BUDGETS + "/" + projectId}>
       <img src="../images/ribbon-design.svg" /> 
      <p className="m-0 p-0 mr-2 font-weight-bold"> AWS Budgets</p>
      </Link>

      <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.GOVERNANCE_SERVICE_CONTROL + "/" + projectId} >
        <p className="m-0 p-0 ml-2 font-weight-bold"> Organization Policies</p>
        <img src="../images/ribbon-designRight.svg" />    
      </Link> 
    </>
    );
  };
  
  
const Portfolios = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Account Id",
        accessor: "AccountId",
        id:"Account Id"
      },
      {
        Header: "Region",
        accessor: "Region",
        id:"Region"
      },
      {
        Header: "Portfolio Name",
        accessor: "PortfolioDetail.DisplayName",
        id: "Portfolio Name"
      },
      {
        Header: "Portfolio Id",
        accessor: "PortfolioDetail.Id",
        id: "Portfolio Id"
      },
      {
        Header: "Description",
        accessor: "PortfolioDetail.Description",
        id: "Description"
      },
      {
        Header: "Provider Name",
        accessor: "PortfolioDetail.ProviderName",
        id: "Provider Name"
      },
      {
        Header: "Created Time",
        accessor: "PortfolioDetail.CreatedTime",
        id: "Created Time"
      },
      {
        Header: "ARN",
        accessor: "PortfolioDetail.ARN",
        id: "ARN"
      },
    ],
    []
  );

  return Dashboard({
    domain: "governance",
    title: "Governance",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.GOVERNANCE_SERVICE_CONTROL_POLICIES,
    designRoute: ROUTES.DESIGN_GOVERNANCE,
    SubMenu: SubMenu,
  });
};

export default Portfolios;