import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import {
  validateASN,
  validateCidr,
  validateIPAddress,
  validateTgwasn,
  validateVifVlanId,
} from "../../../../helpers/validator";
import { getError } from "../../../../helpers/errorHelper";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

//const projectId = "google_1";
const PREFIX = "/aws/network/directconnect";

const REGION_KEY = "/aws/network/aws/awsregionslist";
const DATACENTER_KEY = "/aws/network/datacenters/datacenterslist";
const ONPREMINTEGRATION_KEY = "/aws/network/datacenters/establishonpremintegration";

const DirectConnect = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    deploydirectconnectconnectivity: {
      value: "no",
    },
    directconnectgatewayasn: {
      value: "",
    },
    array: [],
    regions: [],
    dataCenters: [],
    dgws: [],
  });

  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const awsAmplify = useAwsAmplify();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        console.log(result);

        /* const regionResult = await awsAmplify.loadProjectData(
          projectId,
          REGION_KEY
        );
        console.log(regionResult);

        const dataCenterResult = await awsAmplify.loadProjectData(
          projectId,
          DATACENTER_KEY
        );
        console.log(dataCenterResult); */

        const dcLocationsResult = await awsAmplify.getDCLocations(projectId);
        console.log(dcLocationsResult);

        setIsLoading(false);

        setEmpty(result.data.empty);

        const fe = convertBEToFE(
          result.data,
          //regionResult.data,
          //dataCenterResult.data,
          dcLocationsResult.data
        );

        setFormData(fe);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        const errorMsg = getError(err, setErrorMsg);
        //setErrorMsg(err.response.data.message);

        if (errorMsg === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);

      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (data, dcLocationsData) => {
    if (!data) {
      data = {};
    }

    const fe = {
      deploydirectconnectconnectivity: {
        value: data[`${PREFIX}/deploydirectconnectconnectivity`] || "yes",
        isValid: data.hasOwnProperty(
          `${PREFIX}/deploydirectconnectconnectivity`
        ),
      },
      directconnectgatewayasn: {
        value: data[`${PREFIX}/directconnectgatewayasn`] || "",
        isValid: data.hasOwnProperty(`${PREFIX}/directconnectgatewayasn`),
      },
      array: [createEntity()],
      regions: data[REGION_KEY] ? data[REGION_KEY].split(",") : [],
      dataCenters: data[DATACENTER_KEY] ? data[DATACENTER_KEY].split(",") : [],
      dcLocations: dcLocationsData || {},
      dgws: [],
      onPremIntegration: data[ONPREMINTEGRATION_KEY],
    };

    // initialize dgws if no saved data
    const noOfDgws = Math.ceil(fe.regions.length / 3);
    fe.dgws = [];

    console.log(noOfDgws);

    for (let idx = 1; idx <= noOfDgws; idx++) {
      fe.dgws.push(createDgw());
    }

    // iinitialize dgws from saved data
    if (data[`${PREFIX}/dgwlist`]) {
      fe.dgws = [];

      const dgwlist = data[`${PREFIX}/dgwlist`].split(",");

      dgwlist.forEach((dgw, idx) => {
        const item = {
          dgwasn: {
            value: data[`${PREFIX}/${dgw}/dgwasn`] || "",
            isValid: data.hasOwnProperty(`${PREFIX}/${dgw}/dgwasn`),
          },
          dgwregion: data[`${PREFIX}/${dgw}/dgwregion`] || "",
          connectingregion1: "",
          connectingregion2: "",
          connectingregion3: "",
        };

        if (data[`${PREFIX}/${dgw}/connectingregions`]) {
          const connectingregions = data[
            `${PREFIX}/${dgw}/connectingregions`
          ].split(",");

          console.log(connectingregions);

          if (connectingregions.length === 1) {
            item.connectingregion1 = connectingregions[0];
          }

          if (connectingregions.length === 2) {
            item.connectingregion1 = connectingregions[0];
            item.connectingregion2 = connectingregions[1];
          }

          if (connectingregions.length === 3) {
            item.connectingregion1 = connectingregions[0];
            item.connectingregion2 = connectingregions[1];
            item.connectingregion3 = connectingregions[2];
          }
        }

        fe.dgws.push(item);
      });

      if (fe.dgws.length < noOfDgws) {
        for (let idx = fe.dgws.length + 1; idx <= noOfDgws; idx++) {
          fe.dgws.push(createDgw());
        }
      }
    }

    if (data[`${PREFIX}/directconnectconnectionslist`]) {
      fe.array = [];

      const directconnectconnectionslist = data[
        `${PREFIX}/directconnectconnectionslist`
      ].split(",");

      directconnectconnectionslist.forEach((cr, idx) => {
        const item = {
          awsregion: data[`${PREFIX}/${cr}/awsregion`] || "",
          datacenter: data[`${PREFIX}/${cr}/datacenter`] || "",
          dcxlocationid: data[`${PREFIX}/${cr}/dcxlocationid`] || "",
          provider: data[`${PREFIX}/${cr}/provider`] || "",
          portSpeed: data[`${PREFIX}/${cr}/portspeed`] || "",
          datacenterasn: {
            value: data[`${PREFIX}/${cr}/datacenterasn`] || "",
            isValid: data.hasOwnProperty(`${PREFIX}/${cr}/datacenterasn`),
          },
          transitvifvlanid: {
            value: data[`${PREFIX}/${cr}/transitvifvlanid`] || "",
            isValid: data.hasOwnProperty(`${PREFIX}/${cr}/transitvifvlanid`),
          },
          createpublicvif: {
            value: data[`${PREFIX}/${cr}/createpublicvif`] || "yes",
          },
          publicvifvlanid: {
            value: data[`${PREFIX}/${cr}/publicvifvlanid`] || "",
            isValid: data.hasOwnProperty(`${PREFIX}/${cr}/publicvifvlanid`),
          },
          datacenterpublicipcidr: {
            value: data[`${PREFIX}/${cr}/datacenterpublicipcidr`] || "",
            isValid: data.hasOwnProperty(
              `${PREFIX}/${cr}/datacenterpublicipcidr`
            ),
          },
          publicvifcustomerpeerip: {
            value: data[`${PREFIX}/${cr}/publicvifcustomerpeerip`] || "",
            isValid: data.hasOwnProperty(
              `${PREFIX}/${cr}/publicvifcustomerpeerip`
            ),
          },
          publicvifamazonpeerip: {
            value: data[`${PREFIX}/${cr}/publicvifamazonpeerip`] || "",
            isValid: data.hasOwnProperty(
              `${PREFIX}/${cr}/publicvifamazonpeerip`
            ),
          },
          locations: [],
          providers: [],
          portSpeeds: [],
        };

        if (item.awsregion) {
          item.locations =
            (fe.dcLocations && fe.dcLocations[item.awsregion]) || [];

          if (item.dcxlocationid) {
            for (const loc of item.locations) {
              if (item.dcxlocationid === loc.locationCode) {
                item.providers = loc.availableProviders;
                item.portSpeeds = loc.availablePortSpeeds;

                break;
              }
            }
          }
        }

        fe.array.push(item);
      });
    }

    console.log(fe);

    return fe;
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/deploydirectconnectconnectivity`]: formData
        .deploydirectconnectconnectivity.value,
    };

    if (formData.deploydirectconnectconnectivity.value === "yes") {
      if (
        formData.directconnectgatewayasn.value !== "" &&
        formData.directconnectgatewayasn.isValid
      ) {
        be[`${PREFIX}/directconnectgatewayasn`] =
          formData.directconnectgatewayasn.value;
      }

      const validDgws = [];

      formData.dgws.forEach((dgw, idxDgw) => {
        let hasValidItem = false;
        const connectingRegions = [];

        if (dgw.dgwasn.isValid && dgw.dgwasn.value !== "") {
          hasValidItem = true;
          be[`${PREFIX}/dgw${idxDgw + 1}/dgwasn`] = dgw.dgwasn.value;
        }

        if (dgw.dgwregion !== "") {
          hasValidItem = true;
          be[`${PREFIX}/dgw${idxDgw + 1}/dgwregion`] = dgw.dgwregion;
        }

        if (dgw.connectingregion1 !== "") {
          hasValidItem = true;
          connectingRegions.push(dgw.connectingregion1);
          be[`${PREFIX}/${dgw.connectingregion1}/connectingdgw`] = `dgw${
            idxDgw + 1
          }`;
        }

        if (dgw.connectingregion2 !== "") {
          hasValidItem = true;
          connectingRegions.push(dgw.connectingregion2);
          be[`${PREFIX}/${dgw.connectingregion2}/connectingdgw`] = `dgw${
            idxDgw + 1
          }`;
        }

        if (dgw.connectingregion3 !== "") {
          hasValidItem = true;
          connectingRegions.push(dgw.connectingregion3);
          be[`${PREFIX}/${dgw.connectingregion3}/connectingdgw`] = `dgw${
            idxDgw + 1
          }`;
        }

        if (connectingRegions.length > 0) {
          be[
            `${PREFIX}/dgw${idxDgw + 1}/connectingregions`
          ] = connectingRegions.join(",");
        }

        if (hasValidItem) {
          validDgws.push(`dgw${idxDgw + 1}`);
        }
      });

      if (validDgws.length > 0) {
        be[`${PREFIX}/dgwlist`] = validDgws.join(",");
      }

      const validItems = [];
      const createPublicConnections = [];

      formData.array.forEach((item, idxItem) => {
        let hasValidItem = false;

        if (item.awsregion !== "") {
          be[`${PREFIX}/directconnectconnection${idxItem + 1}/awsregion`] =
            item.awsregion;
          hasValidItem = true;
        }

        if (item.datacenter !== "") {
          be[`${PREFIX}/directconnectconnection${idxItem + 1}/datacenter`] =
            item.datacenter;
          hasValidItem = true;
        }

        if (item.dcxlocationid !== "") {
          be[`${PREFIX}/directconnectconnection${idxItem + 1}/dcxlocationid`] =
            item.dcxlocationid;
          hasValidItem = true;
        }

        if (item.provider !== "") {
          be[`${PREFIX}/directconnectconnection${idxItem + 1}/provider`] =
            item.provider;
          hasValidItem = true;
        }

        if (item.portSpeed !== "") {
          be[`${PREFIX}/directconnectconnection${idxItem + 1}/portspeed`] =
            item.portSpeed;
          hasValidItem = true;
        }

        if (item.datacenterasn.value !== "" && item.datacenterasn.isValid) {
          be[`${PREFIX}/directconnectconnection${idxItem + 1}/datacenterasn`] =
            item.datacenterasn.value;
          hasValidItem = true;
        }

        if (
          item.transitvifvlanid.value !== "" &&
          item.transitvifvlanid.isValid
        ) {
          be[
            `${PREFIX}/directconnectconnection${idxItem + 1}/transitvifvlanid`
          ] = item.transitvifvlanid.value;
          hasValidItem = true;
        }

        if (item.createpublicvif.value) {
          be[
            `${PREFIX}/directconnectconnection${idxItem + 1}/createpublicvif`
          ] = item.createpublicvif.value;
          hasValidItem = true;
        }

        if (item.createpublicvif.value === "yes") {
          createPublicConnections.push(`directconnectconnection${idxItem + 1}`);

          if (
            item.publicvifvlanid.value !== "" &&
            item.publicvifvlanid.isValid
          ) {
            be[
              `${PREFIX}/directconnectconnection${idxItem + 1}/publicvifvlanid`
            ] = item.publicvifvlanid.value;
            hasValidItem = true;
          }

          if (
            item.datacenterpublicipcidr.value !== "" &&
            item.datacenterpublicipcidr.isValid
          ) {
            be[
              `${PREFIX}/directconnectconnection${
                idxItem + 1
              }/datacenterpublicipcidr`
            ] = item.datacenterpublicipcidr.value;
            hasValidItem = true;
          }

          if (
            item.publicvifcustomerpeerip.value !== "" &&
            item.publicvifcustomerpeerip.isValid
          ) {
            be[
              `${PREFIX}/directconnectconnection${
                idxItem + 1
              }/publicvifcustomerpeerip`
            ] = item.publicvifcustomerpeerip.value;
            hasValidItem = true;
          }

          if (
            item.publicvifamazonpeerip.value !== "" &&
            item.publicvifamazonpeerip.isValid
          ) {
            be[
              `${PREFIX}/directconnectconnection${
                idxItem + 1
              }/publicvifamazonpeerip`
            ] = item.publicvifamazonpeerip.value;
            hasValidItem = true;
          }
        }

        if (hasValidItem) {
          validItems.push(`directconnectconnection${idxItem + 1}`);
        }
      });

      if (validItems.length > 0) {
        be[`${PREFIX}/directconnectconnectionslist`] = validItems.join(",");
      }

      if (createPublicConnections.length > 0) {
        be[
          `${PREFIX}/directconnectconnectionswithpublicvif`
        ] = createPublicConnections.join(",");
      }
      else{
        be[
          `${PREFIX}/directconnectconnectionswithpublicvif`
        ] = "none";
      }
    }

    return be;
  };

  const addArrayItem = () => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, { array: { $push: [createEntity()] } })
    );
  };

  const removeArrayItem = (idx) => {
    setIsDirty(true);

    setFormData((state) => update(state, { array: { $splice: [[idx, 1]] } }));
  };

  const onArrayItemChange = (idx, key, value) => {
    setErrorMsg(null);
    setIsDirty(true);
    setFormData((state) =>
      update(state, {
        array: { [idx]: { [key]: { value: { $set: value } } } },
      })
    );
  };

  const onArrayItemBlur = (idx, validateFunc, key, value) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      setFormData((state) =>
        update(state, {
          array: {
            [idx]: { [key]: { isValid: { $set: errorMsg === undefined } } },
          },
        })
      );

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      setFormData((state) =>
        update(state, {
          array: {
            [idx]: { [key]: { isValid: { $set: false } } },
          },
        })
      );
    }
  };

  const getFieldArrayValidationClass = (idx, key1) => {
    return !formData.array[idx][key1].isValid &&
      formData.array[idx][key1].value !== ""
      ? "invalid"
      : "";
  };

  const isAllowed = (key, item, idxItem) => {
    // transitvifvlanid Transit VIF VLAN Id
    // publicvifvlanid Public VIF VLAN Id
    if (item[key].value !== "") {
      const otherKey =
        key === "transitvifvlanid" ? "publicvifvlanid" : "transitvifvlanid";
      if (
        item[otherKey].value !== "" &&
        item[otherKey].isValid &&
        item[otherKey].value === item[key].value
      ) {
        setErrorMsg("VLAN Id must be unique");
        return false;
      }

      for (const [idxOtherItem, otherItem] of formData.array.entries()) {
        if (idxItem !== idxOtherItem) {
          if (otherItem[key].value !== "" && otherItem[key].isValid) {
            if (item[key].value === otherItem[key].value) {
              setErrorMsg(
                `Same ${
                  key === "transitvifvlanid"
                    ? "Transit VIF VLAN Id"
                    : "Public VIF VLAN Id"
                } is not allowed in any 2 rows`
              );
              return false;
            }
          }

          const otherKey =
            key === "transitvifvlanid" ? "publicvifvlanid" : "transitvifvlanid";
          if (
            otherItem[otherKey].value !== "" &&
            otherItem[otherKey].isValid &&
            otherItem[otherKey].value === item[key].value
          ) {
            setErrorMsg("VLAN Id must be unique");
            return false;
          }
        }
      }
    }
    return true;
  };

  const createEntity = () => {
    return {
      awsregion: "",
      datacenter: "",
      locations: [],
      providers: [],
      portSpeeds: [],
      datacenterasn: { value: "", isValid: false },
      transitvifvlanid: { value: "", isValid: false },
      createpublicvif: { value: "yes" },
      publicvifvlanid: { value: "", isValid: false },
      datacenterpublicipcidr: { value: "", isValid: false },
      publicvifcustomerpeerip: { value: "", isValid: false },
      publicvifamazonpeerip: { value: "", isValid: false },
    };
  };

  const createDgw = () => {
    return {
      dgwasn: { value: "", isValid: false },
      dgwregion: "",
      connectingregion1: "",
      connectingregion2: "",
      connectingregion3: "",
    };
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Network</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Network"
                isDirty={isDirty}
                dashboardRoute={ROUTES.VPC}
                designRoute={ROUTES.DESIGN_NETWORK}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-1 px-lg-4 ">
                <div className="row d-flex align-items-center ">
                  <div className="col-xl-6 col-lg-5 col-md-4 pl-lg-0 px-2">
                    <h3 className="font-weight-bold">DirectConnect</h3>
                  </div>
                  {/* {(formData.onPremIntegration === "no" ||
                    (formData.regions.length !== 0 &&
                      formData.dataCenters.length !== 0)) && ( */}
                    <div className="col-xl-6 col-lg-7 col-md-8 d-flex pr-0 align-items-center justify-content-end rightNavArea">
                      <div className="d-inline-flex yes-no-area align-items-center">
                        <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                          Deploy DirectConnect Connectivity
                        </div>
                        <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                          <label
                            className={`createElementBtn btn btn-link btn-linkYes mw-70 ${
                              formData.deploydirectconnectconnectivity.value ===
                                "yes" && "active"
                            }`}
                          >
                            <input
                              type="radio"
                              name="options"
                              checked={
                                formData.deploydirectconnectconnectivity
                                  .value === "yes"
                              }
                              onChange={() => {
                                if (formData.onPremIntegration === "no") {
                                  return false;
                                }

                                setIsDirty(true);
                                setFormData((state) =>
                                  update(state, {
                                    deploydirectconnectconnectivity: {
                                      value: { $set: "yes" },
                                    },
                                  })
                                );
                              }}
                            />{" "}
                            YES
                          </label>
                          <label
                            className={`dontCreateElementBtn btn btn-link btn-linkNo mw-70 ${
                              formData.deploydirectconnectconnectivity.value ===
                                "no" && "active"
                            }`}
                          >
                            <input
                              type="radio"
                              name="options"
                              checked={
                                formData.deploydirectconnectconnectivity
                                  .value === "no"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                setFormData((state) =>
                                  update(state, {
                                    deploydirectconnectconnectivity: {
                                      value: { $set: "no" },
                                    },
                                  })
                                );
                              }}
                            />{" "}
                            NO
                          </label>
                        </div>
                      </div>
                    </div>
                  {/* )} */}
                </div>
                {empty && (
                  <NoInitialData />
                )}

                {formData.deploydirectconnectconnectivity.value === "yes" && (!formData.onPremIntegration || formData.onPremIntegration === "yes") &&
                (formData.regions.length === 0 ||
                  formData.dataCenters.length === 0) ? (
                  <span className="warning">
                    This section depends on data in AWS Environment and Data
                    Centers sections in Network domain, which are yet to be
                    provided
                  </span>
                ) : formData.onPremIntegration === "no" ? (
                  <span className="warning">
                    This section is not applicable as On-prem Integration is not
                    required. Check 'Establish On-prem Integration' in
                    DataCenters section
                  </span>
                ) : (
                  formData.deploydirectconnectconnectivity.value === "yes" && (
                    <div className="show-hide-content">
                      <div className="d-flex flex-column">
                        {/* {!formData.onPremIntegration &&
                        formData.regions.length === 0 ? (
                          <span className="warning">
                            This section depends on data in AWS Environment and
                            Data Centers sections in Network domain, which are
                            yet to be provided
                          </span>
                        ) : ( */}
                          <div className="show-hide-content d-flex flex-column">
                            <div className=" row mainContentArea p-relative px-md-2 px-2 my-1 py-2 pr-md-2 pr-5 align-items-start justify-content-start">
                              <div className="row">
                                <div className="bg-special2 border-10">
                                  {formData.dgws.map((dgwItem, idxDgwItem) => (
                                    <>
                                      <div className="col-xl-6 col-xxl-5 col-md-6 my-1  col-12 flex-wrap mb-md-0 mb-2  d-inline-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center px-0 py-1 my-1 ">
                                          <span className="font-12 sectionTitle text-nowrap">
                                            DirectConnect Gateway #{" "}
                                            {idxDgwItem + 1} ASN
                                          </span>
                                        </div>
                                        <div className="d-inline-flex  align-items-center bg-special2 border-10  mr-1">
                                          <div className="d-inline-flex flex-wrap align-items-center my-2 py-1 px-3">
                                            <div className="d-inline-flex mw-120 my-1 mr-md-3 mr-0 flex-1">
                                              <input
                                                type="text"
                                                placeholder="64900"
                                                className={`form-control ${
                                                  !dgwItem.dgwasn.isValid &&
                                                  dgwItem.dgwasn.value !== ""
                                                    ? "invalid"
                                                    : ""
                                                }`}
                                                value={dgwItem.dgwasn.value}
                                                onChange={(e) => {
                                                  const value = e.target.value;
                                                  setErrorMsg(null);
                                                  setIsDirty(true);

                                                  for (const [
                                                    idxOtherItem,
                                                    otherItem,
                                                  ] of formData.dgws.entries()) {
                                                    if (
                                                      idxDgwItem !==
                                                      idxOtherItem
                                                    ) {
                                                      if (
                                                        otherItem.dgwasn
                                                          .isValid &&
                                                        otherItem.dgwasn
                                                          .value !== "" &&
                                                        otherItem.dgwasn
                                                          .value === value
                                                      ) {
                                                        setErrorMsg(
                                                          "DGW number must be unique"
                                                        );
                                                        return false;
                                                      }
                                                    }
                                                  }

                                                  setFormData((state) =>
                                                    update(state, {
                                                      dgws: {
                                                        [idxDgwItem]: {
                                                          dgwasn: {
                                                            value: {
                                                              $set: value,
                                                            },
                                                          },
                                                        },
                                                      },
                                                    })
                                                  );
                                                }}
                                                onBlur={(e) => {
                                                  const value = e.target.value;
                                                  if (value !== "") {
                                                    const errorMsg = validateTgwasn(
                                                      value
                                                    );

                                                    setFormData((state) =>
                                                      update(state, {
                                                        dgws: {
                                                          [idxDgwItem]: {
                                                            dgwasn: {
                                                              isValid: {
                                                                $set:
                                                                  errorMsg ===
                                                                  undefined,
                                                              },
                                                            },
                                                          },
                                                        },
                                                      })
                                                    );

                                                    if (errorMsg) {
                                                      setErrorMsg(errorMsg);
                                                    }
                                                  } else {
                                                    setFormData((state) =>
                                                      update(state, {
                                                        dgws: {
                                                          [idxDgwItem]: {
                                                            dgwasn: {
                                                              isValid: {
                                                                $set: false,
                                                              },
                                                            },
                                                          },
                                                        },
                                                      })
                                                    );
                                                  }
                                                }}
                                              />
                                            </div>

                                            <select
                                              className="my-1 mw-120 form-control flex-1"
                                              value={dgwItem.dgwregion}
                                              onChange={(e) => {
                                                setErrorMsg(null);
                                                setIsDirty(true);

                                                const value = e.target.value;

                                                for (const [
                                                  idxOtherItem,
                                                  otherItem,
                                                ] of formData.dgws.entries()) {
                                                  if (
                                                    idxDgwItem !== idxOtherItem
                                                  ) {
                                                    if (
                                                      (otherItem.dgwregion !==
                                                        "" &&
                                                        otherItem.dgwregion ===
                                                          value) ||
                                                      (otherItem.connectingregion1 !==
                                                        "" &&
                                                        otherItem.connectingregion1 ===
                                                          value) ||
                                                      (otherItem.connectingregion2 !==
                                                        "" &&
                                                        otherItem.connectingregion2 ===
                                                          value) ||
                                                      (otherItem.connectingregion3 !==
                                                        "" &&
                                                        otherItem.connectingregion3 ===
                                                          value)
                                                    ) {
                                                      setFormData((state) =>
                                                        update(state, {
                                                          dgws: {
                                                            [idxDgwItem]: {
                                                              dgwregion: {
                                                                $set: "",
                                                              },
                                                            },
                                                          },
                                                        })
                                                      );

                                                      setErrorMsg(
                                                        "DGW Region must be unique"
                                                      );
                                                      return false;
                                                    }
                                                  }
                                                }

                                                setFormData((state) =>
                                                  update(state, {
                                                    dgws: {
                                                      [idxDgwItem]: {
                                                        dgwregion: {
                                                          $set: value,
                                                        },
                                                      },
                                                    },
                                                  })
                                                );
                                              }}
                                            >
                                              <option value="">Select</option>
                                              {formData.regions.map(
                                                (region, idxRegion) => (
                                                  <option
                                                    key={idxRegion}
                                                    value={region}
                                                  >
                                                    {region}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-xl-6 col-xxl-7 my-1 col-md-6  col-12 flex-wrap">
                                        <div className=" row bg-special2 border-10  align-items-center">
                                          <span className="sectionTitle pt-4 align-self-start my-1 col-xl-3 col-lg-4 col-12 font-12 d-flex align-items-center text-nowrap  text-nowrap">
                                            Connecting Regions
                                          </span>
                                          <div className="d-inline-flex col-xl-9 col-lg-8 col-12 align-items-center flex-wrap  py-1  my-1">
                                            <div className="d-inline-flex mw-120 mr-3 my-1 ">
                                              <div className="d-flex w-100 align-items-center">
                                                <select
                                                  className=" my-1 w-100 form-control "
                                                  value={
                                                    dgwItem.connectingregion1
                                                  }
                                                  onChange={(e) => {
                                                    setErrorMsg(null);
                                                    setIsDirty(true);

                                                    const value =
                                                      e.target.value;

                                                    if (value !== "") {
                                                      for (const [
                                                        idxOtherItem,
                                                        otherItem,
                                                      ] of formData.dgws.entries()) {
                                                        if (
                                                          idxDgwItem !==
                                                          idxOtherItem
                                                        ) {
                                                          if (
                                                            (otherItem.dgwregion !==
                                                              "" &&
                                                              otherItem.dgwregion ===
                                                                value) ||
                                                            (otherItem.connectingregion1 !==
                                                              "" &&
                                                              otherItem.connectingregion1 ===
                                                                value) ||
                                                            (otherItem.connectingregion2 !==
                                                              "" &&
                                                              otherItem.connectingregion2 ===
                                                                value) ||
                                                            (otherItem.connectingregion3 !==
                                                              "" &&
                                                              otherItem.connectingregion3 ===
                                                                value)
                                                          ) {
                                                            setFormData(
                                                              (state) =>
                                                                update(state, {
                                                                  dgws: {
                                                                    [idxDgwItem]: {
                                                                      connectingregion1: {
                                                                        $set:
                                                                          "",
                                                                      },
                                                                    },
                                                                  },
                                                                })
                                                            );

                                                            setErrorMsg(
                                                              "DGW Connecting Region must be unique"
                                                            );
                                                            return false;
                                                          }
                                                        } else {
                                                          if (
                                                            (otherItem.connectingregion2 !==
                                                              "" &&
                                                              otherItem.connectingregion2 ===
                                                                value) ||
                                                            (otherItem.connectingregion3 !==
                                                              "" &&
                                                              otherItem.connectingregion3 ===
                                                                value)
                                                          ) {
                                                            setFormData(
                                                              (state) =>
                                                                update(state, {
                                                                  dgws: {
                                                                    [idxDgwItem]: {
                                                                      connectingregion1: {
                                                                        $set:
                                                                          "",
                                                                      },
                                                                    },
                                                                  },
                                                                })
                                                            );

                                                            setErrorMsg(
                                                              "DGW Connecting Region must be unique"
                                                            );
                                                            return false;
                                                          }
                                                        }
                                                      }
                                                    }

                                                    setFormData((state) =>
                                                      update(state, {
                                                        dgws: {
                                                          [idxDgwItem]: {
                                                            connectingregion1: {
                                                              $set: value,
                                                            },
                                                          },
                                                        },
                                                      })
                                                    );
                                                  }}
                                                >
                                                  <option value="">
                                                    Select
                                                  </option>
                                                  {formData.regions.map(
                                                    (region, idxRegion) => (
                                                      <option
                                                        key={idxRegion}
                                                        value={region}
                                                      >
                                                        {region}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              </div>
                                            </div>
                                            <div className="d-inline-flex mw-120 mr-3 my-1 ">
                                              <div className="d-flex w-100 align-items-center">
                                                <select
                                                  className=" my-1 w-100 form-control "
                                                  value={
                                                    dgwItem.connectingregion2
                                                  }
                                                  onChange={(e) => {
                                                    setErrorMsg(null);
                                                    setIsDirty(true);

                                                    const value =
                                                      e.target.value;

                                                    if (value !== "") {
                                                      for (const [
                                                        idxOtherItem,
                                                        otherItem,
                                                      ] of formData.dgws.entries()) {
                                                        if (
                                                          idxDgwItem !==
                                                          idxOtherItem
                                                        ) {
                                                          if (
                                                            (otherItem.dgwregion !==
                                                              "" &&
                                                              otherItem.dgwregion ===
                                                                value) ||
                                                            (otherItem.connectingregion1 !==
                                                              "" &&
                                                              otherItem.connectingregion1 ===
                                                                value) ||
                                                            (otherItem.connectingregion2 !==
                                                              "" &&
                                                              otherItem.connectingregion2 ===
                                                                value) ||
                                                            (otherItem.connectingregion3 !==
                                                              "" &&
                                                              otherItem.connectingregion3 ===
                                                                value)
                                                          ) {
                                                            setFormData(
                                                              (state) =>
                                                                update(state, {
                                                                  dgws: {
                                                                    [idxDgwItem]: {
                                                                      connectingregion2: {
                                                                        $set:
                                                                          "",
                                                                      },
                                                                    },
                                                                  },
                                                                })
                                                            );

                                                            setErrorMsg(
                                                              "DGW Connecting Region must be unique"
                                                            );
                                                            return false;
                                                          }
                                                        } else {
                                                          if (
                                                            (otherItem.connectingregion1 !==
                                                              "" &&
                                                              otherItem.connectingregion1 ===
                                                                value) ||
                                                            (otherItem.connectingregion3 !==
                                                              "" &&
                                                              otherItem.connectingregion3 ===
                                                                value)
                                                          ) {
                                                            setFormData(
                                                              (state) =>
                                                                update(state, {
                                                                  dgws: {
                                                                    [idxDgwItem]: {
                                                                      connectingregion2: {
                                                                        $set:
                                                                          "",
                                                                      },
                                                                    },
                                                                  },
                                                                })
                                                            );

                                                            setErrorMsg(
                                                              "DGW Connecting Region must be unique"
                                                            );
                                                            return false;
                                                          }
                                                        }
                                                      }
                                                    }

                                                    setFormData((state) =>
                                                      update(state, {
                                                        dgws: {
                                                          [idxDgwItem]: {
                                                            connectingregion2: {
                                                              $set: value,
                                                            },
                                                          },
                                                        },
                                                      })
                                                    );
                                                  }}
                                                >
                                                  <option value="">
                                                    Select
                                                  </option>
                                                  {formData.regions.map(
                                                    (region, idxRegion) => (
                                                      <option
                                                        key={idxRegion}
                                                        value={region}
                                                      >
                                                        {region}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              </div>
                                            </div>
                                            <div className="d-inline-flex mw-120  my-1 ">
                                              <div className="d-flex w-100 align-items-center">
                                                <select
                                                  className=" my-1 w-100 form-control "
                                                  value={
                                                    dgwItem.connectingregion3
                                                  }
                                                  onChange={(e) => {
                                                    setErrorMsg(null);
                                                    setIsDirty(true);

                                                    const value =
                                                      e.target.value;

                                                    if (value !== "") {
                                                      for (const [
                                                        idxOtherItem,
                                                        otherItem,
                                                      ] of formData.dgws.entries()) {
                                                        if (
                                                          idxDgwItem !==
                                                          idxOtherItem
                                                        ) {
                                                          if (
                                                            (otherItem.dgwregion !==
                                                              "" &&
                                                              otherItem.dgwregion ===
                                                                value) ||
                                                            (otherItem.connectingregion1 !==
                                                              "" &&
                                                              otherItem.connectingregion1 ===
                                                                value) ||
                                                            (otherItem.connectingregion2 !==
                                                              "" &&
                                                              otherItem.connectingregion2 ===
                                                                value) ||
                                                            (otherItem.connectingregion3 !==
                                                              "" &&
                                                              otherItem.connectingregion3 ===
                                                                value)
                                                          ) {
                                                            setFormData(
                                                              (state) =>
                                                                update(state, {
                                                                  dgws: {
                                                                    [idxDgwItem]: {
                                                                      connectingregion3: {
                                                                        $set:
                                                                          "",
                                                                      },
                                                                    },
                                                                  },
                                                                })
                                                            );

                                                            setErrorMsg(
                                                              "DGW Connecting Region must be unique"
                                                            );
                                                            return false;
                                                          }
                                                        } else {
                                                          if (
                                                            (otherItem.connectingregion1 !==
                                                              "" &&
                                                              otherItem.connectingregion1 ===
                                                                value) ||
                                                            (otherItem.connectingregion2 !==
                                                              "" &&
                                                              otherItem.connectingregion2 ===
                                                                value)
                                                          ) {
                                                            setFormData(
                                                              (state) =>
                                                                update(state, {
                                                                  dgws: {
                                                                    [idxDgwItem]: {
                                                                      connectingregion3: {
                                                                        $set:
                                                                          "",
                                                                      },
                                                                    },
                                                                  },
                                                                })
                                                            );

                                                            setErrorMsg(
                                                              "DGW Connecting Region must be unique"
                                                            );
                                                            return false;
                                                          }
                                                        }
                                                      }
                                                    }

                                                    setFormData((state) =>
                                                      update(state, {
                                                        dgws: {
                                                          [idxDgwItem]: {
                                                            connectingregion3: {
                                                              $set: value,
                                                            },
                                                          },
                                                        },
                                                      })
                                                    );
                                                  }}
                                                >
                                                  <option value="">
                                                    Select
                                                  </option>
                                                  {formData.regions.map(
                                                    (region, idxRegion) => (
                                                      <option
                                                        key={idxRegion}
                                                        value={region}
                                                      >
                                                        {region}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        {/* )} */}
                        {formData.array.map((item, idxItem) => (
                          <div
                            key={idxItem}
                            className="row mainContentArea d-flex flex-column px-md-2 p-2 my-1 py-1"
                          >
                            <div className="row p-relative">
                              <div className="col-12 px-0 my-1">
                                <p className="color-spc3 font-12 mb-0">
                                  DirectConnect Connection {idxItem + 1}
                                </p>
                              </div>
                              <div className="col-md-11 col-12 py-1  formRow d-flex flex-column">
                                <div className="d-inline-flex row w-100">
                                  <div className="d-inline-flex col-lg-4 col-md-4  my-1 col-12">
                                    <span className="font-12  d-flex align-items-center flex-1">
                                      AWS Region
                                    </span>
                                    <select
                                      className="form-control flex-1"
                                      value={item.awsregion}
                                      onChange={(e) => {
                                        setErrorMsg(null);
                                        setIsDirty(true);

                                        const value = e.target.value;

                                        setFormData((state) =>
                                          update(state, {
                                            array: {
                                              [idxItem]: {
                                                awsregion: {
                                                  $set: value,
                                                },
                                                locations: {
                                                  $set: value
                                                    ? formData.dcLocations[
                                                        value
                                                      ]
                                                    : [],
                                                },
                                                providers: {
                                                  $set: [],
                                                },
                                                portSpeeds: {
                                                  $set: [],
                                                },
                                              },
                                            },
                                          })
                                        );
                                      }}
                                    >
                                      <option value="">Select</option>
                                      {formData.regions.map(
                                        (region, idxRegion) => (
                                          <option
                                            key={idxRegion}
                                            value={region}
                                          >
                                            {region}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                  <div className="d-inline-flex col-lg-4  my-1 col-md-4 col-12 ">
                                    <span className="font-12  d-flex align-items-center flex-1  pl-0 pr-lg-1 pr-xl-2 justify-content-lg-end justify-content-start">
                                      Data Center
                                    </span>
                                    <select
                                      className="form-control flex-1"
                                      value={item.datacenter}
                                      onChange={(e) => {
                                        setErrorMsg(null);
                                        setIsDirty(true);

                                        const value = e.target.value;

                                        setFormData((state) =>
                                          update(state, {
                                            array: {
                                              [idxItem]: {
                                                datacenter: {
                                                  $set: value,
                                                },
                                              },
                                            },
                                          })
                                        );
                                      }}
                                    >
                                      <option value="">Select</option>
                                      {formData.dataCenters.map((dc, idxDc) => (
                                        <option key={idxDc} value={dc}>
                                          {dc}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                <div className="d-inline-flex row w-100 ">
                                  <div className="d-inline-flex col-lg-4 my-1 col-md-4 col-12">
                                    <span className="font-12  d-flex align-items-center flex-1 ">
                                      DCX Location
                                    </span>
                                    <select
                                      className="form-control flex-1"
                                      value={item.dcxlocationid}
                                      onChange={(e) => {
                                        const value = e.target.value;

                                        setErrorMsg(null);
                                        setIsDirty(true);

                                        let providers = [],
                                          portSpeeds = [];

                                        for (const loc of item.locations) {
                                          if (loc.locationCode === value) {
                                            providers = loc.availableProviders;
                                            portSpeeds =
                                              loc.availablePortSpeeds;
                                            break;
                                          }
                                        }

                                        setFormData((state) =>
                                          update(state, {
                                            array: {
                                              [idxItem]: {
                                                dcxlocationid: {
                                                  $set: value,
                                                },
                                                providers: {
                                                  $set: providers,
                                                },
                                                portSpeeds: {
                                                  $set: portSpeeds,
                                                },
                                              },
                                            },
                                          })
                                        );
                                      }}
                                    >
                                      <option value="">Select</option>
                                      {item.locations.map((lo, idxlo) => (
                                        <option
                                          key={idxlo}
                                          value={lo.locationCode}
                                        >
                                          {lo.locationCode} ({lo.locationName})
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="d-inline-flex col-lg-4 my-1 col-md-4 col-12 ">
                                    <span className="font-12  d-flex align-items-center flex-1 pr-lg-1 pr-xl-2 justify-content-lg-end justify-content-start pl-0">
                                      Provider
                                    </span>
                                    <select
                                      className="form-control flex-1"
                                      value={item.provider}
                                      onChange={(e) => {
                                        setErrorMsg(null);
                                        setIsDirty(true);

                                        const value = e.target.value;

                                        setFormData((state) =>
                                          update(state, {
                                            array: {
                                              [idxItem]: {
                                                provider: {
                                                  $set: value,
                                                },
                                              },
                                            },
                                          })
                                        );
                                      }}
                                    >
                                      <option value="">Select</option>
                                      {item.providers.map(
                                        (provider, idxProvider) => (
                                          <option
                                            key={idxProvider}
                                            value={provider}
                                          >
                                            {provider}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                  <div className="d-inline-flex col-lg-4 my-1 col-md-4 col-12">
                                    <span className="font-12  d-flex align-items-center flex-1 pr-lg-1 pr-xl-2 justify-content-lg-end justify-content-start pl-0">
                                      Port Speed
                                    </span>
                                    <select
                                      className="form-control flex-1"
                                      value={item.portSpeed}
                                      onChange={(e) => {
                                        setErrorMsg(null);
                                        setIsDirty(true);

                                        const value = e.target.value;

                                        setFormData((state) =>
                                          update(state, {
                                            array: {
                                              [idxItem]: {
                                                portSpeed: {
                                                  $set: value,
                                                },
                                              },
                                            },
                                          })
                                        );
                                      }}
                                    >
                                      <option value="">Select</option>
                                      {item.portSpeeds.map(
                                        (portSpeed, idxPortSpeed) => (
                                          <option
                                            key={idxPortSpeed}
                                            value={portSpeed}
                                          >
                                            {portSpeed}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </div>
                                <div className="d-inline-flex row w-100 ">
                                  <div className="d-inline-flex col-lg-4 my-1 col-md-4 col-12">
                                    <span className="font-12  d-flex align-items-center flex-1 ">
                                      Data Center ASN
                                    </span>
                                    <input
                                      type="number"
                                      className={`form-control flex-1 ${getFieldArrayValidationClass(
                                        idxItem,
                                        "datacenterasn"
                                      )}`}
                                      placeholder="64800"
                                      value={item.datacenterasn.value}
                                      onChange={(e) =>
                                        onArrayItemChange(
                                          idxItem,
                                          "datacenterasn",
                                          e.target.value
                                        )
                                      }
                                      onBlur={(e) =>
                                        onArrayItemBlur(
                                          idxItem,
                                          validateASN,
                                          "datacenterasn",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="d-inline-flex col-lg-4 my-1 col-md-4 col-12">
                                    <span className="font-12  d-flex align-items-center pr-lg-1 pr-xl-2 flex-1 pl-0 justify-content-lg-end justify-content-start">
                                      Transit VIF VLAN Id
                                    </span>
                                    <input
                                      type="number"
                                      className={`form-control flex-1 ${getFieldArrayValidationClass(
                                        idxItem,
                                        "transitvifvlanid"
                                      )}`}
                                      placeholder="4020"
                                      value={item.transitvifvlanid.value}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        if (
                                          isAllowed(
                                            "transitvifvlanid",
                                            {
                                              ...item,
                                              transitvifvlanid: {
                                                ...item.transitvifvlanid,
                                                value: value,
                                              },
                                            },
                                            idxItem
                                          )
                                        ) {
                                          onArrayItemChange(
                                            idxItem,
                                            "transitvifvlanid",
                                            value
                                          );
                                        }
                                      }}
                                      onBlur={(e) =>
                                        onArrayItemBlur(
                                          idxItem,
                                          validateVifVlanId,
                                          "transitvifvlanid",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-1 py-1 mt-1 col-12 justify-content-end d-flex closeFormRow align-items-start  px-0">
                                <button disabled={disabled}
                                  className="d-flex justify-content-end "
                                  type="button"
                                  onClick={() => removeArrayItem(idxItem)}
                                >
                                  <img src="../images/close.svg" />
                                </button>
                              </div>
                              <div
                                className="col-md-1 col-12 closeFormRowMobil tr-10"
                                type="button"
                                onClick={() => removeArrayItem(idxItem)}
                              >
                                <button disabled={disabled} className="d">
                                  <img src="../images/close.svg" />
                                </button>
                              </div>
                            </div>

                            <div className="row px-0 my-1">
                              <div className="col-xl-6 col-lg-7 col-md-8 d-flex pr-0 align-items-center justify-content-startrightNavArea">
                                <div className="d-inline-flex yes-no-area align-items-center">
                                  <div className="content-label d-flex align-items-center mr-xl-5 mr-lg-3 mr-1 font-12">
                                    Create Public Virtual Interface
                                  </div>
                                  <div className="btn-group btn-group-toggle btn-group-radio mw-100 mr-0">
                                    <label
                                      className={`createSubElementBtn btn btn-link btn-linkYes mw-70 btn-linkYes ${
                                        item.createpublicvif.value === "yes" &&
                                        "active"
                                      }`}
                                    >
                                      <input
                                        type="radio"
                                        name="options"
                                        checked={
                                          item.createpublicvif.value === "yes"
                                        }
                                        onChange={() =>
                                          onArrayItemChange(
                                            idxItem,
                                            "createpublicvif",
                                            "yes"
                                          )
                                        }
                                      />{" "}
                                      YES
                                    </label>
                                    <label
                                      className={`dontCreateSubElementBtn btn btn-link btn-linkNo mw-70 btn-linkNo" ${
                                        item.createpublicvif.value === "no" &&
                                        "active"
                                      }`}
                                    >
                                      <input
                                        type="radio"
                                        name="options"
                                        checked={
                                          item.createpublicvif.value === "no"
                                        }
                                        onChange={() => {
                                          setErrorMsg(null);
                                          setIsDirty(true);

                                          setFormData((state) =>
                                            update(state, {
                                              array: {
                                                [idxItem]: {
                                                  createpublicvif: {
                                                    value: { $set: "no" },
                                                  },
                                                  publicvifvlanid: {
                                                    value: { $set: "" },
                                                    isValid: { $set: false },
                                                  },
                                                },
                                              },
                                            })
                                          );
                                        }}
                                      />{" "}
                                      NO
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {item.createpublicvif.value === "yes" && (
                              <div className="show-hide-sub-content">
                                <div className="row p-relative ">
                                  <div className="col-md-11 col-12   formRow d-flex flex-column">
                                    <div className="d-inline-flex row w-100 ">
                                      <div className="d-inline-flex col-lg-4 my-1 col-md-4 col-12">
                                        <span className="font-12  d-flex align-items-center flex-1 ">
                                          Public VIF VLAN Id
                                        </span>
                                        <input
                                          type="text"
                                          className={`form-control flex-1 ${getFieldArrayValidationClass(
                                            idxItem,
                                            "publicvifvlanid"
                                          )}`}
                                          placeholder="4021"
                                          value={item.publicvifvlanid.value}
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            if (
                                              isAllowed(
                                                "publicvifvlanid",
                                                {
                                                  ...item,
                                                  publicvifvlanid: {
                                                    ...item.publicvifvlanid,
                                                    value: value,
                                                  },
                                                },
                                                idxItem
                                              )
                                            ) {
                                              onArrayItemChange(
                                                idxItem,
                                                "publicvifvlanid",
                                                value
                                              );
                                            }
                                          }}
                                          onBlur={(e) =>
                                            onArrayItemBlur(
                                              idxItem,
                                              validateVifVlanId,
                                              "publicvifvlanid",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="d-inline-flex col-lg-4 my-1 col-md-4 col-12 ">
                                        <span className="font-12  d-flex align-items-center flex-1 justify-content-lg-end justify-content-start pl-0 pr-lg-1 pr-xl-2">
                                          Data Center Public CIDR Block{" "}
                                        </span>
                                        <input
                                          type="text"
                                          className={`form-control flex-1 ${getFieldArrayValidationClass(
                                            idxItem,
                                            "datacenterpublicipcidr"
                                          )}`}
                                          placeholder=""
                                          value={
                                            item.datacenterpublicipcidr.value
                                          }
                                          onChange={(e) => 
                                            onArrayItemChange(
                                              idxItem,
                                              "datacenterpublicipcidr",
                                              e.target.value
                                            )
                                          }
                                          onBlur={(e) =>
                                            onArrayItemBlur(
                                              idxItem,
                                              validateCidr,
                                              "datacenterpublicipcidr",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="d-inline-flex col-lg-4 my-1 px-1 col-md-4 col-12">
                                        <span className="font-12  d-flex align-items-center flex-1 justify-content-lg-end justify-content-start pr-lg-1 pr-xl-2 pl-0">
                                          Customer Peer IP{" "}
                                        </span>
                                        <input
                                          type="text"
                                          className={`form-control flex-1 ${getFieldArrayValidationClass(
                                            idxItem,
                                            "publicvifcustomerpeerip"
                                          )}`}
                                          placeholder=""
                                          value={
                                            item.publicvifcustomerpeerip.value
                                          }
                                          onChange={(e) => {
                                            const value = e.target.value;

                                            if(item.publicvifamazonpeerip.isValid && item.publicvifamazonpeerip.value !== "" && item.publicvifamazonpeerip.value === value){
                                              setErrorMsg(
                                                "Customer Peer IP and Amazon Peer IP must be unique"
                                              );
                                              return false;
                                            }

                                  for (const [
                                    idxOtherItem,
                                    otherItem,
                                  ] of formData.array.entries()) {
                                    if (idxItem !== idxOtherItem) {
                                      if(otherItem.publicvifamazonpeerip.isValid && otherItem.publicvifamazonpeerip.value !== "" && otherItem.publicvifamazonpeerip.value === value){
                                        setErrorMsg(
                                          "Customer Peer IP and Amazon Peer IP must be unique"
                                        );
                                        return false;
                                      }

                                      if(otherItem.publicvifcustomerpeerip.isValid && otherItem.publicvifcustomerpeerip.value !== "" && otherItem.publicvifcustomerpeerip.value === value){
                                        setErrorMsg(
                                          "Customer Peer IP and Amazon Peer IP must be unique"
                                        );
                                        return false;
                                      }
                                    }
                                  }

                                            onArrayItemChange(
                                              idxItem,
                                              "publicvifcustomerpeerip",
                                              e.target.value
                                            )
                                          }}
                                          onBlur={(e) =>
                                            onArrayItemBlur(
                                              idxItem,
                                              validateIPAddress,
                                              "publicvifcustomerpeerip",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="d-inline-flex col-lg-4 my-1 col-md-4 col-12">
                                        <span className="font-12  d-flex align-items-center flex-1 ">
                                          Amazon Peer IP
                                        </span>
                                        <input
                                          type="text"
                                          className={`form-control flex-1 ${getFieldArrayValidationClass(
                                            idxItem,
                                            "publicvifamazonpeerip"
                                          )}`}
                                          placeholder=""
                                          value={
                                            item.publicvifamazonpeerip.value
                                          }
                                          onChange={(e) => {
                                            const value = e.target.value;

                                            if(item.publicvifcustomerpeerip.isValid && item.publicvifcustomerpeerip.value !== "" && item.publicvifcustomerpeerip.value === value){
                                              setErrorMsg(
                                                "Customer Peer IP and Amazon Peer IP must be unique"
                                              );
                                              return false;
                                            }

                                  for (const [
                                    idxOtherItem,
                                    otherItem,
                                  ] of formData.array.entries()) {
                                    if (idxItem !== idxOtherItem) {
                                      if(otherItem.publicvifamazonpeerip.isValid && otherItem.publicvifamazonpeerip.value !== "" && otherItem.publicvifamazonpeerip.value === value){
                                        setErrorMsg(
                                          "Customer Peer IP and Amazon Peer IP must be unique"
                                        );
                                        return false;
                                      }

                                      if(otherItem.publicvifcustomerpeerip.isValid && otherItem.publicvifcustomerpeerip.value !== "" && otherItem.publicvifcustomerpeerip.value === value){
                                        setErrorMsg(
                                          "Customer Peer IP and Amazon Peer IP must be unique"
                                        );
                                        return false;
                                      }
                                    }
                                  }

                                            onArrayItemChange(
                                              idxItem,
                                              "publicvifamazonpeerip",
                                              e.target.value
                                            )
                                          }}
                                          onBlur={(e) =>
                                            onArrayItemBlur(
                                              idxItem,
                                              validateIPAddress,
                                              "publicvifamazonpeerip",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}

                        <div className="w-100 px-md-2 p-2 mb-2  px-2">
                          <div className="row d-flex align-items-center">
                            <div className="add-button d-flex  justify-content-end">
                              <button disabled={disabled}
                                className="add-new-account-link btn-animation d-inline-flex align-items-center"
                                type="button"
                                onClick={() => addArrayItem()}
                              >
                                <p className="my-0 mr-2 p-0 green-style2">
                                  Add DirectConnect Connection
                                </p>
                                <img src="../images/coloredPlus.svg" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
              </fieldset>
              {console.log(formData)}
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(ROUTES.VPC_CONNECTIVITY + "/" + projectId)
                    }
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                      VPN Connectivity
                    </p>
                  </button>
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.TRAFFIC_INSPECTION + "/" + projectId
                      )
                    }
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">
                      Traffic Inspection
                    </p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default DirectConnect;