import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../Dashboard";
import * as ROUTES from "../../../../constants/routes";

//const projectId = "google_1";
const dashboardName = "Organizational Units";

const Details = ({ close, selectedRow }) => {
  const [showPolicyTypes, setShowPolicyTypes] = useState(false);
  return (
    <div className="justify-content-center align-items-end account-detail">
      <div className="w-100 s-flex flex-column account-detail-area">
        <button className="exitAccount" onClick={() => close()}>
          <img src="../images/close.svg" />
        </button>
        <div className="">
          <ul className="account-detail-link m-0 p-0 d-inline-flex">
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                !showPolicyTypes && "my_active"
              }`}
              onClick={() => setShowPolicyTypes(false)}
            >
              Details
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                showPolicyTypes && "my_active"
              }`}
              onClick={() => setShowPolicyTypes(true)}
            >
              Policy Types
            </li>
          </ul>
        </div>
        <div className="d-flex w-100 account-detail-container-area">
          <div
            className={`bolgeler-container w-100 row p-5 ${
              showPolicyTypes && "inaktif"
            }`}
          >
            <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">OU Name</p>{" "}
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Name}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Parent Organization Unit
                </p>{" "}
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.ParentName}
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">OU Id</p>{" "}
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Id}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">ARN</p>{" "}
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Arn}
                </p>
              </div>
            </div>
          </div>

          <div
            className={`bolgeler-container w-100 row p-5 ${
              !showPolicyTypes && "inaktif"
            }`}
          >
            <table className="details-table">
              <thead>
                <tr>
                  <th>Policy Types</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Tag Policy</td>
                  <td>Enabled</td>
                </tr>
                <tr>
                  <td>Service Control Policy</td>
                  <td>Enabled</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const Navigations = () => {
  const { projectId } = useParams();

  return (
    <Link
      className="btn-post btn-animation d-inline-flex"
      to={ROUTES.AZURE_ACCOUNTS + "/" + projectId}
    >
      <p className="m-0 p-0 mr-2 font-weight-bold">Accounts</p>
      <img src="../images/ribbon-designRight.svg" />
    </Link>
  );
};

const AzureOrganizationalUnits = () => {
  const columns = useMemo(
    () => [
      {
        Header: "OU Name",
        accessor: "Name",
        width: 35,
      },
      {
        Header: "Parent Organizational Unit",
        accessor: "ParentName",
        width: 40,
      },
      {
        Header: "OU ID",
        accessor: "Id",
        width: 30,
      },
      {
        Header: "ARN",
        accessor: "Arn",
      },
    ],
    []
  );

  return Dashboard({
    //projectId,
    domain: "accountframework",
    title: "Account Framework",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.AZURE_ENTERPRISE_CORE_ACCOUNTS,
    designRoute: ROUTES.AZURE_DESIGN_ACCOUNT_FRAMEWORK
  });
};

export default AzureOrganizationalUnits;
