const cidrRegex = require("cidr-regex");
const ipRegexp = require("ip-regex");
const domainRegexp = require("domain-regex");

export const validateRequired = (value) => {
  if(value === null || value === undefined || value === ""){
    return "must be provided";
  }
}

export const validateEmail = (email) => {
  if (
    !/^([a-zA-Z0-9+_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(
      email
    )
  ) {
    return "must be email format";
  }
};

export const validateAccountEmailId = (accountEmailId) => {
  if (
    !/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(
      accountEmailId
    )
  ) {
    return "must be account email id format";
  }
};

export const validateAccountName = (accountName) => {
  if (!/^[a-zA-Z0-9_-]{1,50}$/.test(accountName)) {
    return "must be account name format";
  }
};

export const validate1to30AlpheNumHyphen = (name) => {
  if (!/^[a-zA-Z0-9-]{1,30}$/.test(name)) {
    return "must be 1-30 alphanumeric format including -";
  }
};

export const validate1to20AlpheNumHyphen = (name) => {
  if (!/^[a-zA-Z0-9-]{1,20}$/.test(name)) {
    return "must be 1-20 alphanumeric format including -";
  }
};

export const validateAccountId = (accountId) => {
  if (!/^\d{12}$/.test(accountId)) {
    return "must be 12 digits";
  }
};

export const validate1to15AlphaNumeric = (name) => {
  if (!/^[a-zA-Z0-9_]{1,15}$/.test(name)) {
    return "must be 1-15 alphanumeric format";
  }
};

export const validate1to10AlphaNumeric = (name) => {
  if (!/^[a-zA-Z0-9]{1,10}$/.test(name)) {
    return "must be 1-10 alphanumeric format";
  }
};

export const validate1to10AlphaNumericHyphen = (name) => {
  if (!/^[a-zA-Z0-9-]{1,10}$/.test(name)) {
    return "must be 1-10 alphanumeric format including -";
  }
};

export const validateDomainName = (domainName) => {
  if (
    !/^[a-zA-Z0-9-]{0,30}\.{0,1}[a-zA-Z0-9-]{1,30}\.[a-zA-Z]{2,}$/.test(
      domainName
    )
  ) {
    return "must be domain format";
  }
};

export const validateAwsPublicPrivateDomainName = (domainName) => {

  if(!domainRegexp().test(domainName)){
  /* if (
    !/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9].[a-zA-Z]{2,}$/.test(
      domainName
    )
  ) { */
    return "must be domain format";
  }
};

export const validateIPAddress = (ipAddress) => {
  if(!(ipRegexp.v4({exact: true}).test(ipAddress))){
    return "must be ip address format";
  }
  else if(/^0/.test(ipAddress)){
    return "must be ip address format";
  }

  /* if (
    !/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      ipAddress
    )
  ) {
    return "must be ip address format";
  } */

  /* if(!/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(ipAddress)){
    return "must be ip address format";
  } */
};

export const validateDataCenterName = (name) => {
  if (!/^[a-zA-Z0-9-]{2,20}$/.test(name)) {
    return "must be 2-20 alphanumeric format";
  }
};

export const validateCidr = (cidr) => {
  if (!(cidrRegex.v4({ exact: true }).test(cidr))){
    return "must be CIDR format";
  }
  else if(/^0/.test(cidr)){
    return "must be CIDR format";
  }


  /* if (!/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\/\d{1,2}$/.test(cidr)) {
    return "must be CIDR format";
  } */
};

export const validatePassword = (password) => {
  if (
     !/^(?=^.{8,64}$)((?=.*\d)(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[^A-Za-z0-9\s])(?=.*[a-z])|(?=.*[^A-Za-z0-9\s])(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[A-Z])(?=.*[^A-Za-z0-9\s]))^.*$/.test(
    password))
   // !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])\S{6,99}$/.test(
  {
    return "must be minimum 8 (upper, lower, special char and number)";
  }
};

export const validatePasswordMax127 = (password) => {
  if (
    !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])\S{6,127}$/.test(
      password
    )
  ) {
    return "must be minimum 6 and maximum 127 (upper, lower, special char and number)";
  }
};

export const validateTgwasn = (number) => {
  if (
    number < 64512 ||
    (number > 65534 && number < 4200000000) ||
    number > 4294967294
  ) {
    return "must be between 64512 to 65534 for 16-bit ASNs and 4200000000 to 4294967294 for 32-bit ASNs";
  }
};

export const validateASN = (number) => {
  console.log(number);
  if (number < 1 || number > 2147483647) {
    return "must be between 1 to 2147483647";
  }
};

export const validateVifVlanId = (number) => {
  if (number < 0 || number > 4094) {
    return "must be between 0 to 4094";
  }
};

export const validate2to32AlphaNumeric = (name) => {
  if (!/^[a-zA-Z0-9]{2,32}$/.test(name)) {
    return "must be 2-32 alphanumeric format";
  }
};

export const validate2to50AlphaNumeric = (name) => {
  if (!/^[a-zA-Z0-9-_]{2,50}$/.test(name)) {
    return "must be 2-50 alphanumeric format";
  }
};

export const validate2to128AlphaNumeric = (name) => {
  if (!/^[a-zA-Z0-9-_]{2,128}$/.test(name)) {
    return "must be 2-128 alphanumeric format";
  }
};

export const validateTagKey = (name) => {
  if (!/^[a-zA-Z0-9-_.:+=@/]{2,128}$/.test(name)) {
    return "must be 2-128 alphanumeric format";
  }
};

export const validateTagValue = (name) => {
  if (!/^[a-zA-Z0-9-_ .:+=@/]{2,256}$/.test(name)) {
    return "must be 2-256 alphanumeric format";
  }
};

export const validateUrl = (url) => {
  if (!/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(url)) {
    return "must be 2-256 alphanumeric format";
  }
};

export const validateTime = (time) => {
  if(!/^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(time)){
    return "must be HH:mm 24 hours format";
  }
}

export const validateSpendingLimit = (value) => {
  if(!/^(([0-9]*\.)?[0-9]+)$/.test(value)){
    return "must be spending limit format";
  }
}

export const validateSNSTopic = (value) => {
  if (!/^[a-zA-Z0-9-_ ]{1,256}$/.test(value)) {
    return "must be SNS topic format";
  }
}

export const validatePortNumber = (value) => {
  if (!/^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/.test(value)) {
    return "must be 1 to 65535";
  }
}

export const validateSourceAccountId = (value) => {
  if(!/^(\d{0,9})(\d{0,9})(\d{0,9})(\d{0,9})(\d{0,9})(\d{0,9})(\d{0,9})(\d{0,9})(\d{0,9})(\d{0,9})(\d{0,9})(\d{0,9})$/.test(value)){
     return "must be 12 digits";
  }
}

export const validateExternalId = (value) => {
  if(!/^[a-zA-Z0-9._+=,@/:-]{2,1224}$/.test(value)) {
    return "must be 2-1224 alphanumeric"
  }

}
