
import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import { validateEmail, validate2to50AlphaNumeric, validate2to128AlphaNumeric, validateTagKey, validateTagValue } from "../../../../helpers/validator";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

const PREFIX = "/aws/dataprotection/backup";

const AWS_REGION_KEY = "/aws/network/aws/awsregionslist";

const DOMAIN_NOTIFICATIONS_KEY =
  "/aws/dataprotection/emailidfordomainnotifications";


const BackupArchival = () => {
    const { projectId } = useParams();
  
    const history = useHistory();
    const [isDirty, setIsDirty] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [formData, setFormData] = useState({
        domainEmail: {  
            value: "",
            isValid: true},
        deploycentralizedbackupandarchival: "yes",
        backupvault: [],
        regions: [],
        backuppolicy: [],
        frequency: [],
        timeperiod: [],
        deleteafterlist: [],
        backupwindowtimelist: [],
        backupwindowtimelimitlist:[],
        startwithinlist: []
      });

      const [empty, setEmpty] = useState(false);

      const disabled = useProjectDisabledStatus(projectId, ["Launched"]);
    
      const awsAmplify = useAwsAmplify();
    
    
useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        console.log(result);

        setEmpty(result.data.empty);

        const domainEmaildata = await awsAmplify.loadProjectData(projectId, DOMAIN_NOTIFICATIONS_KEY);
        console.log(result);
    
         const awsRegionData = await awsAmplify.loadProjectData(projectId, AWS_REGION_KEY);
          console.log(awsRegionData);
          setIsLoading(false);
         

        const fe = convertBEToFE(
          result.data,
          domainEmaildata.data,
          awsRegionData.data
        );

        setFormData(fe);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response && err.response.data.message);

        if(err.response && err.response.data.message === "You have no access"){
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);


const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);

      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);

      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

const convertBEToFE = (
    data,
    domainEmaildata,
    awsRegionData
      ) => {
    if (!data) {
      data = {};
    }
    if(!domainEmaildata) {
      domainEmaildata = {};
    }
    if(!awsRegionData) {
      awsRegionData = {};
    }

    const fe = {
        domainEmail: {value: domainEmaildata[DOMAIN_NOTIFICATIONS_KEY] || "",
                      isValid: domainEmaildata[DOMAIN_NOTIFICATIONS_KEY] !== "" },
        deploycentralizedbackupandarchival: data[`${PREFIX}/deploycentralizedbackupandarchival`] || "yes",

        backupvault: [createVault()],
        regions:  awsRegionData[AWS_REGION_KEY] ? awsRegionData[AWS_REGION_KEY].split(",") : [],
        backuppolicy: [createPolicy()],
        frequency: [
            'every12hours',
            'daily',
            'weekly',
            'monthly'
        ],
        timeperiod: [
            { period: '1 month', days: '30'},{period: '3 months' , days: '90'},{period: '6 months', days: '180'},
            { period: '1 year' , days: '365'},{period: '3 years', days: '1095'}, { period: '5 years', days: '1825'},
            { period: '7 years', days: '2555'}
        ],
        backupwindowtimelist :[],
        backupwindowtimelimitlist : [],
        startwithinlist : [],
        policytaglist : []
    };

        if (data[`${PREFIX}/backupvaultlist`]) {
            fe.backupvault = []
        
        let backupvaultlist = data[`${PREFIX}/backupvaultlist`].split( ",");
        backupvaultlist.forEach((name) => {
            fe.backupvault.push({
                vaultName: {
                    value: name || "",
                    isValid: true
                }
            });
        });
    }

        for (var i = 0; i < 24; i++) {
            fe.backupwindowtimelist.push({text:`${i}:00`, value: `${i}`});
        };

        for(var i = 2; i <= 23; i++)
        {
              fe.backupwindowtimelimitlist.push(`${i} hours`);
        }
        if(fe.backupwindowtimelimitlist !== "")
        {
            for(var i = 1; i <= 7; i++)
            {
                if(i == "1")
                fe.backupwindowtimelimitlist.push(`${i} day`);
                else
                 fe.backupwindowtimelimitlist.push(`${i} days`);
            }
        }

        for(var i = 1; i <= 18; i++)
        {
            if(i == "1")
            fe.startwithinlist.push(`${i} hour`);
            else
             fe.startwithinlist.push(`${i} hours`);
        }
        if(fe.startwithinlist !== "")
        {
            for(var i = 1; i <= 7; i++)
            {
                if(i == "1")
                fe.startwithinlist.push(`${i} day`);
                else
                 fe.startwithinlist.push(`${i} days`);
            }
        }



        if(data[`${PREFIX}/backuppolicylist`])
        {
            let backuppolicylist = [];
            backuppolicylist = data[`${PREFIX}/backuppolicylist`].split(",");
            if(backuppolicylist.length > 0)
            {
                fe.backuppolicy = [];
            backuppolicylist.forEach((policy,idxPolicy) => {              
                fe.backuppolicy.push({
                    policyName: {
                        value: data[`${PREFIX}/backuppolicy${idxPolicy + 1}/policyname`] || "",
                        isValid: data.hasOwnProperty(`${PREFIX}/backuppolicy${idxPolicy + 1}/policyname`)
                    },
                    vaultName: {value: data[`${PREFIX}/backuppolicy${idxPolicy + 1}/vaultname`] || "" },
                    awsregions:  data[`${PREFIX}/backuppolicy${idxPolicy + 1}/regionslist`] ? 
                                data[`${PREFIX}/backuppolicy${idxPolicy + 1}/regionslist`].split(",") : [],
                    backupfrequency :  {value: data[`${PREFIX}/backuppolicy${idxPolicy + 1}/backupfrequency`] || "" },
                    retentionperiod:  {value: data[`${PREFIX}/backuppolicy${idxPolicy + 1}/retentionperiod`] || ""},
                    starttime:  {value: data[`${PREFIX}/backuppolicy${idxPolicy + 1}/starttime`] || ""},
                    startwithin: {value: data[`${PREFIX}/backuppolicy${idxPolicy + 1}/startwithin`] || ""},
                    completewithin: {value: data[`${PREFIX}/backuppolicy${idxPolicy + 1}/completewithin`] || ""},
                    copytosecondaryregion: {value: data[`${PREFIX}/backuppolicy${idxPolicy + 1}/copytosecondaryregion`] || "yes"},
                    secondaryregion: {value: data[`${PREFIX}/backuppolicy${idxPolicy + 1}/secondaryregion`] || ""},
                    deleteafter: {value: data[`${PREFIX}/backuppolicy${idxPolicy + 1}/deleteafter`] || ""},
                    tagkey: { value: data[`${PREFIX}/backuppolicy${idxPolicy + 1}/tagkey`] || "",
                              isValid: data.hasOwnProperty(`${PREFIX}/backuppolicy${idxPolicy + 1}/tagkey`)
                             },
                    taglist : data[`${PREFIX}/backuppolicy${idxPolicy + 1}/tagvalues`] ? 
                             data[`${PREFIX}/backuppolicy${idxPolicy + 1}/tagvalues`].split(",") : [],
                    tagvalues: [{value: "", isValid: false}]                   
                });

               if(fe.backuppolicy[idxPolicy].taglist.length > 0){
                  fe.backuppolicy[idxPolicy].tagvalues = [];    
                  fe.backuppolicy[idxPolicy].taglist.forEach((tagvalue) => {                   
                     fe.backuppolicy[idxPolicy].tagvalues.push({
                            value: tagvalue || "" ,
                            isValid: tagvalue == "" ? false : true   
                     });
               }) }
            }
          );
            } 
        }   
    
    return fe;       

};

const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/deploycentralizedbackupandarchival`]: formData.deploycentralizedbackupandarchival
    };
    
      if(formData.domainEmail && formData.domainEmail.value !== "")
      {
        if(formData.domainEmail.isValid)
              be[DOMAIN_NOTIFICATIONS_KEY] = formData.domainEmail.value;
      }
      if (formData.deploycentralizedbackupandarchival === "yes") {
      const validVaultItems = [];
      const validPolicyItems = [];
  
      if(formData.backupvault)
      {
          formData.backupvault.forEach((item, idxItem) => {
          if (item.vaultName !== "" ) {
              if(item.vaultName.isValid)
                  validVaultItems.push(item.vaultName.value);
          }
          });
          
        }    
      if (validVaultItems.length > 0) {
          be[`${PREFIX}/backupvaultlist`] = validVaultItems.join(",");
      }
      if(formData.backuppolicy)
      {
          formData.backuppolicy.forEach((policyItem, idxPolicy) => {
              let hasValidItems = false;
              if(policyItem.policyName && policyItem.policyName.isValid)
              {
                  be[`${PREFIX}/backuppolicy${idxPolicy + 1}/policyname`] = policyItem.policyName.value;
                  hasValidItems = true;
              }
              if(policyItem.awsregions && policyItem.awsregions.length > 0)
                {
                    be[`${PREFIX}/backuppolicy${idxPolicy + 1}/regionslist`] = policyItem.awsregions.join(",");
                    hasValidItems = true;
                }
                if(policyItem.vaultName.value !== "")
                {
                  if(existInVault(validVaultItems,policyItem.vaultName.value))
                        be[`${PREFIX}/backuppolicy${idxPolicy + 1}/vaultname`] = policyItem.vaultName.value;
                  hasValidItems = true;
                }        
                if(policyItem.backupfrequency.value != "")   
                {
                  be[`${PREFIX}/backuppolicy${idxPolicy + 1}/backupfrequency`] = policyItem.backupfrequency.value;
                  hasValidItems = true;
                }   
                if(policyItem.retentionperiod.value != "")   
                {
                  be[`${PREFIX}/backuppolicy${idxPolicy + 1}/retentionperiod`] = policyItem.retentionperiod.value;
                  hasValidItems = true;
                }   
                if(policyItem.starttime.value  != "")   
                {
                  be[`${PREFIX}/backuppolicy${idxPolicy + 1}/starttime`] = policyItem.starttime.value;
                  hasValidItems = true;
                }
                if(policyItem.startwithin.value != "")   
                {
                  be[`${PREFIX}/backuppolicy${idxPolicy + 1}/startwithin`] = policyItem.startwithin.value;
                  hasValidItems = true;
                }
                if(policyItem.completewithin.value != "")   
                {
                  be[`${PREFIX}/backuppolicy${idxPolicy + 1}/completewithin`] = policyItem.completewithin.value;
                  hasValidItems = true;
                }
                if(policyItem.copytosecondaryregion.value != "")   
                {
                  be[`${PREFIX}/backuppolicy${idxPolicy + 1}/copytosecondaryregion`] = policyItem.copytosecondaryregion.value;
                  hasValidItems = true;
                  if(policyItem.copytosecondaryregion.value === "yes")
                  {
                    if(policyItem.secondaryregion.value != "")   
                    {
                      be[`${PREFIX}/backuppolicy${idxPolicy + 1}/secondaryregion`] = policyItem.secondaryregion.value;
                      hasValidItems = true;
                    }
                    if(policyItem.deleteafter.value != "")   
                    {
                      be[`${PREFIX}/backuppolicy${idxPolicy + 1}/deleteafter`] = policyItem.deleteafter.value;
                      hasValidItems = true;
                    }
                }                  
                }
                if(policyItem.tagkey.value != "")
                {
                  be[`${PREFIX}/backuppolicy${idxPolicy + 1}/tagkey`] = policyItem.tagkey.value;
                  hasValidItems = true;
                }
                if(policyItem.tagvalues && policyItem.tagvalues.length > 0)
                {
                    const tagarray = [];
                    policyItem.tagvalues.forEach((tagvalue) => {
                      if(tagvalue.isValid && tagvalue.value != "")
                      {
                        tagarray.push(tagvalue.value);
                        hasValidItems = true;
                      }
                    });
                    if(tagarray.length > 0)
                      be[`${PREFIX}/backuppolicy${idxPolicy + 1}/tagvalues`] = tagarray.join(",");
                
                }


                if (hasValidItems) {
                  validPolicyItems.push(`backuppolicy${idxPolicy + 1}`);
                }
          })
          if (validPolicyItems.length > 0) {
              be[`${PREFIX}/backuppolicylist`] = validPolicyItems.join(",");
            }
      }
    
                       
         };  
  return be;
};

 const validateDomainEmail = (name, value) => {
    setFormData((state) => 
           update(state, { domainEmail : {isValid: { $set: value } } }) );
};
    
  const onFieldChange = (value1, key1) => {
    setErrorMsg(null);
    setIsDirty(true);

    setFormData((state) => {
    return {
      ...state,
      [key1]: { value: value1 },
     }
    }    
   );
  };

const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };


  const createVault = () => {
    return {
     vaultName: {value: "", isValid: false}
    };
  };

  const addVaultItem = () => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, { backupvault: { $push: [createVault()] } })
    );
  };

  const removeVaultItem = (idx,e) => {
        e.preventDefault();
    if (formData.backupvault.length > 1) {      
        setIsDirty(true);

        setFormData((state) => update(state, { backupvault: { $splice: [[idx, 1]] } }));
    }
  };

  const onVaultItemChange = (idx, key, value1) => {
    setErrorMsg(null);
    setIsDirty(true);
    setFormData((state) =>
      update(state, {
        backupvault: { [idx]: { [key]: { value: { $set: value1 } } } },
      })
    );
  };

  const onVaultItemBlur = (idx, validateFunc, key, value) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      setFormData((state) =>
        update(state, {
            backupvault: {
            [idx]: { [key]: { isValid: { $set: errorMsg === undefined } } },
          },
        })
      );

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      setFormData((state) =>
        update(state, {
            backupvault: {
            [idx]: { [key]: { isValid: { $set: false } } },
          },
        })
      );
    }
  };

   const existInVault = (array,name) => {
     return array.indexOf(name) !== -1;
  };


  const getVaultFieldValidationClass = (idx, key1) => {
    return !formData.backupvault[idx][key1].isValid &&
      formData.backupvault[idx][key1].value !== ""
      ? "invalid"
      : "";
  };


  const createPolicy = () => {
    return {
     policyName: {value: "", isValid: false},
     awsregions: [],
     vaultName: {value: ""},
     backupfrequency: {value: ""},
     retentionperiod: {value: ""},
     copytosecondaryregion: {value: ""},
     secondaryregion: {value: ""},
     deleteafter: {value: ""},
     starttime: {value: ""},
     startwithin: {value: ""},
     completewithin: {value: ""},
     tagkey: {value: "", isValid: false},
     tagvalues: [ 
                    { value : "",
                      isValid: false 
                    }
                ],
     taglist:[]
    };
  };


  const addTags = (policyIdx) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        backuppolicy: { [policyIdx]: { tagvalues: { $push: [{ value: "", isValid: false }] } },
        },
      })
    );
  };

  const removeTags = (policyIdx, tagIdx) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        backuppolicy: { [policyIdx]: { tagvalues: { $splice: [[tagIdx, 1]] } } },
      })
    );
  };

  const onTagsChange = (policyIdx, tagIdx, value1) => {
    setErrorMsg(null);
    setIsDirty(true);
    setFormData((state) =>
      update(state, {
        backuppolicy: {
          [policyIdx]: { tagvalues: { [tagIdx]: { value: { $set: value1 } } } },
        },
      })
    );
  };

  const onTagsBlur = (policyIdx, tagIdx, validateFunc, value) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      setFormData((state) =>
        update(state, {
          backuppolicy: {
            [policyIdx]: {
                tagvalues: {
                [tagIdx]: { isValid: { $set: errorMsg === undefined } },
              },
            },
          },
        })
      );

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      setFormData((state) =>
        update(state, {
          backuppolicy: {
            [policyIdx]: {
              tagvalues: {
                [tagIdx]: { isValid: { $set: false } },
              },
            },
          },
        })
      );
    }
  };

  const getTagsValidationClass = (policyIdx, tagIdx) => {
    return formData.backuppolicy[policyIdx].tagvalues[tagIdx].value !== "" &&
      !formData.backuppolicy[policyIdx].tagvalues[tagIdx].isValid
      ? "invalid"
      : "";
  };



  const removeAWSRegion = (policyIdx, regionIdx) => {
    setIsDirty(true);
    setFormData((state) =>
      update(state, {
        backuppolicy: { [policyIdx]: { awsregions: { $splice: [[regionIdx, 1]] } } },
      })
    );
  };

  const existsAWSRegionInPolicy = (policyIdx,value) => {
    return formData.backuppolicy[policyIdx].awsregions.indexOf(value.region) !== -1;
  };

  const getPolicyFieldValidationClass = (idx, key1) => {
    return !formData.backuppolicy[idx][key1].isValid &&
      formData.backuppolicy[idx][key1].value !== ""
      ? "invalid"
      : "";
  };

  const addPolicyItem = () => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, { backuppolicy: { $push: [createPolicy()] } })
    );
  };

  const removePolicyItem = (idx,event) => {
    event.preventDefault();
    if (formData.backuppolicy.length > 1) {       
        setIsDirty(true);
        
        setFormData((state) => update(state, { backuppolicy: { $splice: [[idx, 1]] } }));
    }
  };

  const onPolicyItemChange = (idx, key, value1) => {
    setErrorMsg(null);
    setIsDirty(true);
    setFormData((state) =>
      update(state, {
        backuppolicy: { [idx]: { [key]: { value: { $set: value1 } } } },
      })
    );
  };

  const onPolicyItemBlur = (idx, validateFunc, key, value) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      setFormData((state) =>
        update(state, {
            backuppolicy: {
            [idx]: { [key]: { isValid: { $set: errorMsg === undefined } } },
          },
        })
      );

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      setFormData((state) =>
        update(state, {
            backuppolicy: {
            [idx]: { [key]: { isValid: { $set: false } } },
          },
        })
      );
    }
  };

  
  

useHideLeftMenu();
useUnload(isDirty);

return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Data Protection</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
          <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
        <form
            onSubmit={onSubmit}
            className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
        >
            <DomainMenu
            saveDataIfDirty={saveDataIfDirty}
            title="Data Protection"
            isDirty={isDirty}
            isConfigure={true}
            dashboardRoute={ROUTES.DATA_PROTECTION_BACKUP_POLICY}
            designRoute={ROUTES.DESIGN_DATA_PROTECTION}
            isDashboard={false}
            ></DomainMenu>

<fieldset disabled={disabled} className="mainArea fitDeviceHeight">
        <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-1 px-lg-4 p-0">
               
        <div className="filter pr-xl-5  px-lg-4 ">
                  <div className="left">
                    <div className="title">
                    Data Protection Domain Notifications
                    </div>
                  </div>
                  <div className="right align-items-center">
                    <div className="subscribe">Email Id</div>
                    <div className="account">
                      <input
                        type="text"
                        className={`form-control ${
                          formData.domainEmail&&
                          formData.domainEmail.value !== "" &&
                          !formData.domainEmail.isValid &&
                          "invalid"
                        } `}
                        placeholder="dataprotection@client.com"
                        value={
                          (formData.domainEmail &&
                            formData.domainEmail.value) ||
                          ""
                        }
                        onChange={(e) => {
                          setErrorMsg(null);
                          setIsDirty(true);
                          onFieldChange(e.target.value,"domainEmail");
                        }}
                        onBlur={(e) => {
                          if (e.target.value !== "") {
                            const errorMsg = validateEmail(
                              e.target.value
                            );

                            validateDomainEmail(
                              "domainEmail",
                              errorMsg === undefined
                            );

                            if (errorMsg) {
                              setErrorMsg(
                                `Data Protection Domain Notifications ${errorMsg}`
                              );
                            }
                          } else {
                            validateDomainEmail("domainEmail", false);
                           
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                {empty && (
                  <NoInitialData />
                )}

                             
        <div className="row d-flex align-items-center">
                    <div className="col-xl-6 col-lg-5 col-md-4 pl-lg-0 px-2">
                        <h3>Backup & Archival</h3>
                    </div>
                    <div className="col-xl-6 col-lg-7 col-md-8 d-flex pr-0 align-items-center justify-content-end rightNavArea">
                        <div className="d-inline-flex yes-no-area align-items-center">
                        <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12 text-nowrap">
                            Deploy Backup & Archival Solution
                        </div>
                        <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                            <label
                                className={`createElementBtn btn btn-link btn-linkYes h-20 ${
                                formData &&  formData.deploycentralizedbackupandarchival ===  "yes" && "active"
                                }`}
                                >
                                <input
                                    type="radio"
                                    name={`${PREFIX}/deploycentralizedbackupandarchival`}
                                    checked={
                                    formData.deploycentralizedbackupandarchival === "yes"
                                    }
                                    onChange={() => {
                                    setIsDirty(true);
                                    setFormData((state) =>
                                        update(state, {
                                            deploycentralizedbackupandarchival: { $set: "yes" },
                                        })
                                    );
                                    }}
                                />{" "}
                                YES
                                </label>
                            <label
                              className={`dontCreateElementBtn btn btn-link btn-linkNo h-20 ${
                                formData.deploycentralizedbackupandarchival === "no" && "active"
                              }`}
                            >
                              <input
                                type="radio"
                                name={`${PREFIX}/deploycentralizedbackupandarchival`}
                                checked={
                                  formData.deploycentralizedbackupandarchival === "no"
                                }
                                onChange={() => {
                                  setIsDirty(true);
                                  setFormData((state) =>
                                    update(state, {
                                        deploycentralizedbackupandarchival: { $set: "no" }
                                    })
                                  );
                                }}
                              />{" "}
                              NO
                            </label>
                        </div>  
                        
                        </div>
                     </div>

                    { formData.deploycentralizedbackupandarchival === "yes" && (
                        <>                    
                         <div className="row show-hide-content">
                            {formData.backupvault.map((item, idxItem) => (
                                <div key={idxItem}
                                    className="mainContentArea row px-2 py-1 pt-md-1 pt-3 my-1 p-relative ">
                                        <button disabled={disabled}  className="closeFormRowMobil" onClick={(e) => removeVaultItem(idxItem,e)}><img src="../images/bigfontclose.svg"></img></button>
                                       <div><button disabled={disabled}  href="#" className="closeFormRow closeThis tr-10" 
                                        onClick={ (event) => 
                                          removeVaultItem(idxItem,event)
                                         }><img src="../images/bigfontclose.svg"></img></button></div>
                                           
                                    <div className="flex-wrap px-1">
                                        <div className="d-inline-flex mr-lg-5 mr-md-2 mr-0  my-1">
                                            <span className="font-12 d-flex align-items-center mr-2 w-150">Backup Vault #{idxItem + 1} Name</span>
                                            <div className=" bg-gray border-10  p-relative d-inline-flex align-items-center w-md-250">
                                                <input placeholder="Prod Vault" type="text" 
                                                  className={`form-control bg-gray mw-150 ${getVaultFieldValidationClass(
                                                    idxItem,
                                                    "vaultName"
                                                  )}`}
                                                  value={item.vaultName.value}
                                                  onChange={(e) => {
                                                    onVaultItemChange(
                                                      idxItem,
                                                      "vaultName",
                                                      e.target.value
                                                    );}}
                                                    onBlur={(e) => {
                                                        onVaultItemBlur(
                                                          idxItem,
                                                          validate2to50AlphaNumeric,
                                                          "vaultName",
                                                          e.target.value
                                                        );
                                                  }}
                                                  >
                                                </input>

                                            </div>
                                        </div>
                                    </div>
                                </div>                               
                                    ))}
                         </div>
                                    
                         <div className="w-100 px-md-2 p-2 mb-2  px-2">
                                        <div className="row d-flex align-items-center">
                                            <div className="add-button d-flex  justify-content-end">
                                                <button disabled={disabled} 
                                                    className="add-new-account-link btn-animation d-inline-flex align-items-center"
                                                    type="button"
                                                    onClick={() => addVaultItem()}
                                                >
                                                    <p className="my-0 mr-2 p-0 green-style2">
                                                        Add Vault
                                                     </p>
                                                    <img src="../images/coloredPlus.svg" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                     

                   <div>
                         {formData.backuppolicy.map((item, idxItem) => (
                    <div key={idxItem}
                    className="mainContentArea row px-2 py-1 pt-md-1 pt-3 my-1 p-relative"> 
                    <button disabled={disabled}  className="closeFormRowMobil" onClick={(e) => removePolicyItem(idxItem,e)}><img src="../images/bigfontclose.svg"></img></button>
                   <div><button disabled={disabled}  className="closeFormRow closeThis tr-10" onClick={(e) => removePolicyItem(idxItem,e)}><img src="../images/bigfontclose.svg"></img></button></div>                
                    <div className="col-12 flex-wrap px-1">                     
                        <div className="d-inline-flex mr-lg-5 mr-md-2 mr-0  my-1">
                        <span className="font-12 d-flex align-items-center mr-2 w-150">Backup Policy #{idxItem + 1} Name</span>
                        <div className=" bg-gray border-10  p-relative d-inline-flex align-items-center w-md-250">
                            <input placeholder="Production Backup Policy" type="text"  
                            name= {`${PREFIX}/backuppolicy/${idxItem}/policyname`}
                            className={`form-control bg-gray mw-150 ${getPolicyFieldValidationClass(
                                idxItem,
                                "policyName"
                            )}`}
                            value={item.policyName ? item.policyName.value : ""}
                            onChange={(e) => {
                                onPolicyItemChange(
                                idxItem,
                                "policyName",
                                e.target.value
                                );}}
                                onBlur={(e) => {
                                    onPolicyItemBlur(
                                    idxItem,
                                    validate2to128AlphaNumeric,
                                    "policyName",
                                    e.target.value
                                    );
                            }}
                            >
                            </input>                               
                        </div>
                    </div>
                        <div className=" d-inline-flex  my-1">                 
                             <div className=" border-10  p-relative d-inline-flex align-items-center w-md-250">      
                                        <span className="font-12 text-nowrap mr-2">
                                        Backup Vault</span>
                                        
                                        <select className="form-control bg-gray" 
                                         value={item.vaultName.value}
                                         onChange={(e) => {
                                            onPolicyItemChange(
                                                idxItem,
                                                "vaultName",
                                             e.target.value                                            
                                         );
                                       }}>
                                    <option value="">Select</option>
                                    {formData.backupvault.map((vault, idxV) => (
                                    (vault.vaultName.value !== "") && (
                                      <option key={idxV} value={vault.vaultName.value}>
                                        {vault.vaultName.value}
                                      </option>
                                    )))}</select>                                       
                            </div>                      
                        </div>                      
                        </div>            
                    
                    
                    <div className="col-12 flex-wrap px-1">
                        <div className="d-inline-flex flex-wrap ">
                        <span className="font-12 d-flex align-items-center mr-2 w-150  my-1">AWS Regions</span>                      
                        {formData.regions.length === 0 ? (
                            <span className="warning">
                              This depends on the AWS Regions information in
                              'AWS Environment" section of 'Network' domain that
                              is yet to be provided.
                            </span>
                          ) : (                      
                         formData.regions  && formData.regions.map((region,idxregion) => (
                        <div key={idxregion}
                        className="one mr-2 bg-special-lightblue form-line w-md-250 py-2  my-1  d-inline-flex align-items-center">
                            <label className="switch mb-0"> 
                                 <input type="checkbox" 
                                 name= {`${PREFIX}/backuppolicy${idxItem}/${idxregion}/${region}`} 
                                 value = {region}
                                 checked = {existsAWSRegionInPolicy(idxItem,{region})}
                                 onChange={() => {
                                    setIsDirty(true);
                                    const newValue = {region};                                   
                                    if(existsAWSRegionInPolicy(idxItem,newValue))
                                    {
                                      let arrIndex = formData.backuppolicy[idxItem].awsregions.indexOf(newValue.region);
                                      removeAWSRegion(idxItem,arrIndex);  
                                    } 
                                    else{
                                                                                                         
                                        setFormData((state) =>
                                        update(state, {
                                          backuppolicy: {[idxItem] : {awsregions: { $push: [region] } }} }))  
                                    }}}
                                ></input>
                                <span className="slider round"></span>
                                
                            </label>
                            <span className="switchLabel">{region}</span>
                        </div>
                        )))}                     
                        </div>
                    </div>
                        
                    <div className="col-12 flex-wrap px-1">
                        <div className="d-inline-flex mr-lg-5 mr-md-2  my-1 mr-0">
                            <span className="font-12 d-flex align-items-center mr-2 w-150">Frequency of Backup</span>
                            <div className=" bg-gray border-10  p-relative d-inline-flex align-items-center w-md-250">
                            <select className="form-control bg-gray pr-lg-0 pr-5" 
                                         value={item.backupfrequency.value}
                                         onChange={(e) => {
                                            onPolicyItemChange(
                                                idxItem,
                                                "backupfrequency",
                                             e.target.value,                                            
                                         );
                                       }}>
                                    <option value="">Select</option>
                                    {formData.frequency && formData.frequency.map((frequencyItem, idxV) => (
                                    (frequencyItem !== "") &&
                                      <option key={idxV} value={frequencyItem}>
                                        {frequencyItem}
                                      </option>
                                    ))}</select>             
                            </div>
                        </div>
                        <div className=" d-inline-flex  my-1">                   
                            <div className=" border-10  p-relative d-inline-flex align-items-center w-md-250">      
                                        <span className="font-12 text-nowrap mr-2">Retention Period</span>                                       
                                        <select className="form-control bg-gray pr-lg-0 pr-5"
                                         value={item.retentionperiod.value}
                                         onChange={(e) => {
                                            onPolicyItemChange(
                                                idxItem,
                                                "retentionperiod",
                                             e.target.value,                                            
                                         );
                                         }}>
                                    <option value="">Select</option>
                                    {formData.timeperiod && formData.timeperiod.map((tp, idx) => (
                                    (tp.period !== "") &&
                                      <option key={idx} value={tp.days}>
                                        {tp.period}
                                      </option>
                                    ))}</select>                      
                            </div>
                        </div>               
                    </div>  

                    <div className="col-12 flex-wrap px-1">
                   <div className="d-inline-flex mr-lg-5 mr-md-2  my-1 mr-0">
                      <span className="font-12 d-flex align-items-center mr-2 w-150">Resources to include </span>
                      <div className=" border-10  p-relative d-inline-flex align-items-center w-md-250">
                                 <span className="font-12 d-flex align-items-center mr-2">Tag </span>
                                <input placeholder="Tag Key" type="text" key={`${idxItem}/tagkey`}  
                                name= {`${PREFIX}/backuppolicy/${idxItem}/tagkey`}
                            className={`form-control bg-gray mw-150 ${getPolicyFieldValidationClass(
                                idxItem,
                                "tagkey"
                            )}`}
                            value={item.tagkey.value}
                            onChange={(e) => {
                                onPolicyItemChange(
                                idxItem,
                                "tagkey",
                                e.target.value
                                );}}
                                onBlur={(e) => {
                                    onPolicyItemBlur(
                                    idxItem,
                                    validateTagKey,
                                    "tagkey",
                                    e.target.value
                                    );
                            }}
                            >
                            </input>
                                 
                                 
                      </div>
                   </div>
                    <div className=" d-inline-flex  my-1">                    
                   
                    <div className=" border-10  p-relative d-inline-flex align-items-center">
                      <span className="font-12 text-nowrap mr-2">
                        Tag Value
                      </span>                    
                        {item.tagvalues.map((tagitem, tagIdx) => (                                                                                                 
                            <div key={tagIdx}  className="p-relative form-line d-flex align-items-center">
                              <button disabled={disabled} 
                                type="button"
                                className="closableInput"
                                onClick={() =>
                                  removeTags(idxItem, tagIdx)
                                }
                              ></button>
                              <input
                                type="text"
                                className={`form-control bg-gray ${getTagsValidationClass(
                                  idxItem,
                                  tagIdx
                                )}`}
                                placeholder="Tag Value"
                                value={tagitem.value}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  onTagsChange(
                                    idxItem,
                                    tagIdx,
                                    e.target.value
                                  )
                                }}
                                onBlur={(e) =>
                                  onTagsBlur(
                                    idxItem,
                                    tagIdx,
                                    validateTagValue,
                                    e.target.value
                                  )
                                }
                              />
                            </div>                         
                     
                        ))}
                        <button disabled={disabled} 
                          type="button"
                          className="btn-animation d-flex align-items-center pl-2"
                          onClick={() => addTags(idxItem)}
                        >
                          <img src="../images/coloredPlus.svg" />
                        </button>
                      
                    </div>
                                                       
                   </div>
                                    </div>
              

                    <div className="col-12 flex-wrap px-1">
                   <div className="d-inline-flex mr-lg-5 mr-md-2 mr-0  my-1">
                      <span className="font-12 d-flex align-items-center mr-2 w-150">Backup Window</span>
                      <div className=" border-10  p-relative d-inline-flex align-items-center w-md-250">
                                 <span className="font-12 text-nowrap mr-2">Start Time</span>
                                 <select className="form-control bg-gray h-20 pr-lg-0 pr-5 "
                                 value={item.starttime.value}
                                         onChange={(e) => {
                                            onPolicyItemChange(
                                                idxItem,
                                                "starttime",
                                             e.target.value,                                            
                                         );
                                         }}>
                                    <option value="">Select</option>
                                    {formData.backupwindowtimelist && formData.backupwindowtimelist.map((tp, idx) => (
                                    (tp !== "") &&
                                      <option key={idx} value={tp.value}>
                                        {tp.text}
                                      </option>
                                    ))}</select>
                                 
                                 
                      </div>
                   </div>
                    <div className=" d-inline-flex  my-1">
                      
                      <div className=" border-10  p-relative d-inline-flex align-items-center mr-3 w-md-250">      
                                 <span className="font-12 text-nowrap mr-2">Start within</span>
                                 <select className="form-control bg-gray pr-lg-0 pr-5"
                                    value={item.startwithin.value}
                                    onChange={(e) => {
                                       onPolicyItemChange(
                                           idxItem,
                                           "startwithin",
                                        e.target.value,                                            
                                    );
                                    }}>
                               <option value="">Select</option>
                               {formData.startwithinlist && formData.startwithinlist.map((tp, idx) => (
                               (tp !== "") &&
                                 <option key={idx} value={tp}>
                                   {tp}
                                 </option>
                               ))}</select> 
                      </div>
                     <div className=" border-10  p-relative d-inline-flex align-items-center w-md-250">      
                                 <span className="font-12 text-nowrap mr-2">Complete within</span>
                               
                                 <select className="form-control bg-gray pr-lg-0 pr-5"
                                  value={item.completewithin.value}
                                  onChange={(e) => {
                                     onPolicyItemChange(
                                         idxItem,
                                         "completewithin",
                                         e.target.value,                                            
                                  );
                                  }}>
                             <option value="">Select</option>
                             {formData.backupwindowtimelimitlist && formData.backupwindowtimelimitlist.map((tp, idx) => (
                             (tp !== "") &&
                               <option key={idx} value={tp}>
                                 {tp}
                               </option>
                             ))}</select>           
                      </div>
                    
                   </div>
                </div>
                <div className="col-12 my-1 formRow py-0 flex-wrap px-1">
                     <div className="d-inline-flex showRightElements  mr-0 flex-wrap  my-1">
                     <div className="one mr-2  form-line w-250 py-2  my-1  d-inline-flex align-items-center">
                          <label className="switch mb-0">
                            <input type="checkbox" 
                             name= {`${PREFIX}/backuppolicy${idxItem}/copytosecondaryregion`} 
                             value = {item.copytosecondaryregion.value}
                             checked = {item.copytosecondaryregion.value === "yes"}
                             onChange={(e) => {
                              setIsDirty(true);
                              let targetvalue = e.target.checked  ? "yes" : "no";
                              onPolicyItemChange(
                                  idxItem,
                                  "copytosecondaryregion",
                                   targetvalue,                                            
                                  );
                              }}                             
                             >
                              </input>
                            <span className="slider round"></span>
                             
                          </label>
                           <span className="switchLabel">Copy to secondary region</span>
                      </div>
                                         
                     { (item.copytosecondaryregion.value === "yes") && (
                     <div className = "row">
                      <div className=" border-10  p-relative d-inline-flex align-items-center w-md-250">                                
                        <select className="form-control pr-lg-0 pr-3"
                        value={item.secondaryregion.value}
                                onChange={(e) => {
                                    onPolicyItemChange(
                                        idxItem,
                                        "secondaryregion",
                                    e.target.value,                                            
                                );
                                }}>
                            <option value="">Select</option>
                            {formData.regions && formData.regions.map((region, idx) => (
                            (region !== "") &&
                            <option key={idx} value={region}>
                                {region}
                            </option>
                            ))}</select>
                    
                       </div>                 
                       <div className=" border-10  p-relative d-inline-flex align-items-center pl-3 w-md-250">      
                                 <span className="font-12 text-nowrap mr-2">Delete after</span>                               
                                 <select className="form-control  pr-lg-0 pr-5"
                                  value={item.deleteafter.value}
                                  onChange={(e) => {
                                     onPolicyItemChange(
                                         idxItem,
                                         "deleteafter",
                                         e.target.value,                                            
                                     );
                                  }}>
                             <option value="">Select</option>
                              {formData.timeperiod && formData.timeperiod.map((tp, idx) => (
                              (tp.period !== "") &&
                                <option key={idx} value={tp.days}>
                                  {tp.period}
                                </option>
                             ))}</select>                                
                      </div>                                     
                   </div>   
                     )}
                   </div>             
                               
                   </div>                  
 
                 </div>


                    ))}                    
                   
                   <div className="w-100 px-md-2 p-2 mb-2  px-2">
                        <div className="row d-flex align-items-center">
                            <div className="add-button d-flex  justify-content-end">
                                <button disabled={disabled} 
                                    className="add-new-account-link btn-animation d-inline-flex align-items-center"
                                    type="button"
                                    onClick={() => addPolicyItem()}
                                >
                                    <p className="my-0 mr-2 p-0 green-style2">
                                        Add Backup Policy
                                        </p>
                                    <img src="../images/coloredPlus.svg" />
                                </button>
                            </div>
                        </div>
                     </div>

                   </div> 

                  
                    </>
                    )}                
                </div>        
            </div>
            </fieldset>
           {<div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
              <button className="btn-post btn-animation d-inline-flex"
              onClick={() =>
                saveDataIfDirty(ROUTES.DATA_PROTECTION_CERTIFICATE_MANAGEMENT + "/" + projectId)
              }>
                  <p className="m-0 p-0 mr-2 font-weight-bold">Certificate Management</p>
                <img src="../images/ribbon-designRight.svg">
                </img>
            </button>


            </div>}
            </form>
 
        
       </div></div>
       </div>
   </>
    );
 };

export default BackupArchival;

