


import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "../../../dashboard-common/DetailsTags";
import { getArrayValue, getBooleanValue} from "../../../dashboard-common/DashboardHelper";
import DetailsGrid from "../../../dashboard-common/DetailsGrid";


const dashboardName = "Service Control Policies";

const SubMenu = () => {
    const { projectId } = useParams();
  
    return (
      <>
        <h3>Organization Policies</h3>
        <div className="otherTableTitles ">
          <h3 className="font-15 activetable">   
             Service Control
          </h3>
          <h3 className="font-15">  
          <Link className="text-color-gray" to={ROUTES.GOVERNANCE_TAGGING + "/" + projectId}>  
             Tagging
         </Link>
          </h3>
        </div>
      </>
    );
  };


  const Details = ({ close, selectedRow }) => {
    const [type, setType] = useState("Details");
  
    return (
      <div className="justify-content-center align-items-end account-detail">
        <div className="w-100 s-flex flex-column account-detail-area">
          <button className="exitAccount" onClick={() => close()}>
            <img src="../images/close.svg" />
          </button>
          <div className="">
            <ul className="account-detail-link m-0 p-0 d-inline-flex">
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Details" && "my_active"
                }`}
                onClick={() => setType("Details")}
              >
                Details
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Targets" && "my_active"
                }`}
                onClick={() => setType("Targets")}
              >
               Targets
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Policy JSON" && "my_active"
                }`}
                onClick={() => setType("Policy JSON")}
              >
                Policy JSON
              </li>
            </ul>
          </div>
          <div className="d-flex w-100 account-detail-container-area">
            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Details" && "inaktif"
              }`}
            >
              <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Name</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Policy && selectedRow.Policy.PolicySummary &&
                     selectedRow.Policy.PolicySummary.Name}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Description</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Policy && selectedRow.Policy.PolicySummary &&
                     selectedRow.Policy.PolicySummary.Description}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Policy && selectedRow.Policy.PolicySummary &&
                  selectedRow.Policy.PolicySummary.Id}
                  </p>
                </div>
              </div>
              <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                    ARN
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Policy && selectedRow.Policy.PolicySummary &&
                  selectedRow.Policy.PolicySummary.Arn}
                  </p>
                </div>
  
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                 Type
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Policy && selectedRow.Policy.PolicySummary &&
                  selectedRow.Policy.PolicySummary.Type}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">AWS Managed</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {getBooleanValue((selectedRow.Policy && selectedRow.Policy.PolicySummary &&
                  selectedRow.Policy.PolicySummary), "AwsManaged")}
                  </p>
                </div>
              </div>
            </div>

            <DetailsGrid
              type={type}
              typeName="Targets"
              array={selectedRow.Targets}
              metadata={[
                { header: "Name", key: "Name" },
                { header: "Type", key: "Type" },
                { header: "Target ID", key: "TargetId" },  
                { header: "ARN", key: "Arn" },        
              ]}
            ></DetailsGrid>

        
            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Policy JSON" && "inaktif"
              }`}
            >
             <div className="col-12">
              <p className="color-spc3">
                <pre className="font-12">
                  {JSON.stringify(selectedRow.Policy.Content, null,4)}
                </pre>
              </p>
            </div>
         </div>

          </div>
        </div>
      </div>
    );
  };

  const Navigations = () => {
    const { projectId } = useParams();
    return (
      <>
    <Link
      className="btn-post btn-animation d-inline-flex"
      to={ROUTES.GOVERNANCE_RECORDERS + "/" + projectId}
    >
      <p className="m-0 p-0 mr-2 font-weight-bold">Config</p>
      <img src="../images/ribbon-designRight.svg" />
    </Link>
    </>
    );
  };
  
  
const ServiceControl = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "Policy.PolicySummary.Name",
        id:"Name"
      },
      {
        Header: "Description",
        accessor: "Policy.PolicySummary.Description",
        id:"Description"
      },
      {
        Header: "ID",
        accessor: "Policy.PolicySummary.Id",
        id: "ID"
      },
      {
        Header: "ARN",
        accessor: "Policy.PolicySummary.Arn",
        id: "ARN"
      },
      {
        Header: "Type",
        accessor: "Policy.PolicySummary.Type",
        id: "Type"
      },
      {
        Header: "AWS Managed",
        accessor: (d) => getBooleanValue(d.Policy.PolicySummary, "AwsManaged"),
        id: "AWS Managed"
      }
    ],
    []
  );

  return Dashboard({
    domain: "governance",
    title: "Governance",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.GOVERNANCE_SERVICE_CONTROL_POLICIES,
    designRoute: ROUTES.DESIGN_GOVERNANCE,
    SubMenu: SubMenu,
  });
};


export default ServiceControl;