import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import RadioGroup from "../../RadioGroup";
import { validateAccountEmailId } from "../../../../helpers/validator";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

const PREFIX = "/aws/monitoring/cloudwatchevents";
const DOMAIN_NOTIFICATION_KEY = "/aws/monitoring/emailidfordomainnotifications";

const getInitialFormData = (data) => {
  const initialFormData = {
    [DOMAIN_NOTIFICATION_KEY]: {
      value: data[DOMAIN_NOTIFICATION_KEY] || "",
      isValid: data[DOMAIN_NOTIFICATION_KEY] ? true : false,
    },
    deploybaselinecloudwatcheventrules: {
      value: data[`${PREFIX}/deploybaselinecloudwatcheventrules`] || "yes",
    },
  };

  return initialFormData;
};

const CloudwatchEvents = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState(() => getInitialFormData({}));
  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);
  const awsAmplify = useAwsAmplify();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        setIsLoading(false);

        setEmpty(result.data.empty);

        setFormData(convertBEToFE(result.data));
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);

        if (err.response.data.message === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (data) => {
    if (!data) {
      data = {};
    }

    const fe = getInitialFormData(data);

    return fe;
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/deploybaselinecloudwatcheventrules`]: formData
        .deploybaselinecloudwatcheventrules.value,
    };

    if (formData[DOMAIN_NOTIFICATION_KEY].isValid) {
      be[DOMAIN_NOTIFICATION_KEY] = formData[DOMAIN_NOTIFICATION_KEY].value;
    }

    return be;
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  const updateRadio = (key, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        [key]: {
          value: { $set: value },
        },
      })
    );
  };

  const onFieldChange = (value, key) => {
    setErrorMsg(null);
    setIsDirty(true);

    setFormData((state) =>
      update(state, { [key]: { value: { $set: value } } })
    );
  };

  const onFieldBlur = (validateFunc, value, key) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      setFormData((state) =>
        update(state, {
          [key]: { isValid: { $set: errorMsg === undefined } },
        })
      );

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      setFormData((state) =>
        update(state, { [key]: { isValid: { $set: false } } })
      );
    }
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Monitoring</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Monitoring"
                isDirty={isDirty}
                //dashboardRoute={ROUTES.GOVERNANCE_SERVICE_CONTROL}
                designRoute={ROUTES.DESIGN_MONITORING}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div class="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                <div class="filter pr-xl-5  px-lg-4 ">
                  <div class="left">
                    <div class="title">Monitoring Domain Notifications</div>
                  </div>
                  <div class="right align-items-center">
                    <div class="subscribe">Email Id</div>
                    <div class="account">
                      <input
                        type="text"
                        placeholder="monitoring@client.com"
                        className={`form-control ${
                          formData[DOMAIN_NOTIFICATION_KEY].value !== "" &&
                          formData[DOMAIN_NOTIFICATION_KEY].isValid === false
                            ? "invalid"
                            : ""
                        }`}
                        value={formData[DOMAIN_NOTIFICATION_KEY].value}
                        onChange={(e) =>
                          onFieldChange(e.target.value, DOMAIN_NOTIFICATION_KEY)
                        }
                        onBlur={(e) =>
                          onFieldBlur(
                            validateAccountEmailId,
                            e.target.value,
                            DOMAIN_NOTIFICATION_KEY
                          )
                        }
                      />
                    </div>
                  </div>
                </div>

                <div class="row d-flex align-items-center">
                  <div class="col-12 pl-lg-0 px-2">
                    <h3>CloudWatch Event Rules</h3>
                  </div>
                </div>

                <div class="row">
                  <div class="mainContentArea row px-2 py-1 my-1">
                    <div class="col-12 px-1 my-1">
                      <div class="formRow w-100">
                        <div class="d-inline-flex yes-no-area align-items-center">
                          <div class="content-label mr-xl-5 mr-lg-3 mr-1 font-12 ">
                            Deploy Baseline CloudWatch Event Rules
                          </div>
                          <div class="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center mr-0">
                            <label
                              className={`createElementBtn btn btn-link btn-linkYes mw-70 ${
                                formData.deploybaselinecloudwatcheventrules
                                  .value === "yes" && "active"
                              }`}
                            >
                              <input
                                type="radio"
                                name="options"
                                checked={
                                  formData.deploybaselinecloudwatcheventrules
                                    .value === "yes"
                                }
                                onChange={() => {
                                  setIsDirty(true);
                                  setFormData((state) =>
                                    update(state, {
                                      deploybaselinecloudwatcheventrules: {
                                        value: { $set: "yes" },
                                      },
                                    })
                                  );
                                }}
                              />{" "}
                              YES
                            </label>
                            <label
                              className={`dontCreateElementBtn btn btn-link btn-linkNo mw-70 ${
                                formData.deploybaselinecloudwatcheventrules
                                  .value === "no" && "active"
                              }`}
                            >
                              <input
                                type="radio"
                                name="options"
                                checked={
                                  formData.deploybaselinecloudwatcheventrules
                                    .value === "no"
                                }
                                /* onChange={() => {
                                setIsDirty(true);
                                setFormData((state) =>
                                  update(state, {
                                    deploybaselinecloudwatcheventrules: {
                                      value: { $set: "no" },
                                    },
                                  })
                                );
                              }} */
                              />{" "}
                              NO
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {empty && (
                  <NoInitialData />
                )}
              </div>
              {console.log(formData)}
              </fieldset>
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.MONITORING_CENTRALIZED_LOGGING + "/" + projectId
                      )
                    }
                  >
                    <p class="m-0 p-0 mr-2 font-weight-bold">
                      Centralized Logging
                    </p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CloudwatchEvents;
