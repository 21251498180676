import React, { useState, useEffect } from "react";

import LeftHandMenu from "../LeftHandMenu";
import TopMenu from "../../TopMenu";

import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../hooks/useHideLeftMenu";
import DomainMenu from "../DomainMenu";

import * as ROUTES from "../../../constants/routes";
import { getError } from "../../../helpers/errorHelper";

import update from "immutability-helper";
import { validateRequired } from "../../../helpers/validator";

//const projectId = "google_1";

const PREFIX = "/azure/builder";

const AzurePrelaunch = () => {
  const history = useHistory();
  const { projectId } = useParams();

  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const [builtRight, setBuiltRight] = useState(false);
  const [headStart, setHeadStart] = useState(false);
  const [enableLaunch, setEnableLaunch] = useState(false);
  const [enableDeploy, setEnableDeploy] = useState(false);
  const [projectStatus, setProjectStatus] = useState(null);
  const [domainStatus, setDomainStatus] = useState(false);
  const [alertClass, setAlertClass] = useState("");

  const [prelaunchData, setPrelaunchData] = useState({
    enterpriseCoreAccounts: { status: "" },
    environmentStrategy: { status: "" },
    applicationAccounts: { status: "" },
    developerSandboxAccounts: { status: "" },
  });

  const [formData, setFormData] = useState({
    masteraccountid: {
      value: "",
      isValid: false,
    },
    builderiamusername: {
      value: "",
      isValid: false,
    },
    builderiamuserpassword: {
      value: "",
      isValid: false,
    },
    builderaccesskeyid: {
      value: "",
      isValid: false,
    },
    buildersecretaccesskey: {
      value: "",
      isValid: false,
    },
  });

  const awsAmplify = useAwsAmplify();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);
        const result = await awsAmplify.prelaunch(projectId);

        console.log(result);

        if (
          result.data &&
          result.data.domainStatus &&
          result.data.projectStatus
        ) {
          const domainStatus = result.data.domainStatus,
            projectStatus = result.data.projectStatus;

          setPrelaunchData(domainStatus);

          let status = true;

          for (const domain in domainStatus) {
            if (domainStatus[domain].status === "FAIL") {
              status = false;
              break;
            }
          }

          setDomainStatus(status);
          setProjectStatus(projectStatus);
        }

        const projectDataResult = await awsAmplify.loadProjectData(
          projectId,
          PREFIX
        );
        setIsLoading(false);

        setFormData(convertBEToFE(projectDataResult.data));
      } catch (err) {
        setIsLoading(false);
        const errorMsg = getError(err, setErrorMsg);

        if (errorMsg === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  useEffect(() => {
    const getProjectStatus = async () => {
      try {
        setErrorMsg(null);
        //setIsLoading(true);
        const result = await awsAmplify.getProjectStatus(projectId);
        //setIsLoading(false);

        console.log(result);

        if (result && result.data && result.data.getProjectStatus) {
          setProjectStatus(result.data.getProjectStatus);
        }
      } catch (err) {
        setIsLoading(false);
        getError(err, setErrorMsg);
      }
    };

    if (projectStatus && projectStatus.status) {
      switch (projectStatus.status) {
        case "Created":
        case "Saved":
          setAlertClass("bluealert");
          break;
        case "Launching":
        case "Deploying":  
          setAlertClass("orangealert");
          break;
        case "Launched Successfully":  
        case "Deployed Successfully":
          setAlertClass("greenalert");
          break;
        case "Launch Failed":  
        case "Deployment Failed":
          setAlertClass("redalert");
          break;
        default:
          setAlertClass("");
          break;
      }
    }

    if (
      projectStatus &&
      (projectStatus.status === "Launching" ||
        projectStatus.status === "Deploying")
    ) {
      let handler = setInterval(getProjectStatus, 5000);

      return () => clearInterval(handler);
    }
  }, [projectStatus]);

  useHideLeftMenu();

  const onClick = (key) => {
    if (prelaunchData[key].status === "FAIL") {
      setErrorMsg(prelaunchData[key].message);
    }
  };

  const getClassName = (key) => {
    let className;

    switch (prelaunchData[key].status) {
      case "NA":
        className = "naInput";
        break;
      case "FAIL":
        className = "cancelInput";
        break;
      case "PASS":
        className = "checkmarkInput";
        break;
      default:
        className = "signsInput";
        break;
    }

    return className;
  };

  const getGrayText = (key) => {
    return prelaunchData[key].status === "NA" ? "text-color-gray" : "";
  };

  const launch = async (action) => {
    try {
      setErrorMsg(null);
      setIsLoading(true);

      const credentials = {};

      for (const field in formData) {
        credentials[`${PREFIX}/${field}`] = formData[field].value;
      }

      console.log(credentials);

      const result = await awsAmplify.launch(projectId, credentials, action);
      setIsLoading(false);

      console.log(result);
      console.log(result.data);
      setProjectStatus(result.data);
    } catch (err) {
      setIsLoading(false);
      const errorMsg = getError(err, setErrorMsg);

      if (errorMsg === "You have no access") {
        history.goBack();
      }
    }
  };

  const onFieldChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setErrorMsg(null);
    setIsDirty(true);

    setFormData((state) =>
      update(state, { [name]: { value: { $set: value } } })
    );
  };

  const onFieldBlur = (e, validateFunc) => {
    const value = e.target.value;
    const name = e.target.name;

    if (value !== "") {
      const errorMsg = validateFunc(value);

      setFormData((state) =>
        update(state, {
          [name]: { isValid: { $set: errorMsg === undefined } },
        })
      );

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      setFormData((state) =>
        update(state, {
          [name]: { isValid: { $set: false } },
        })
      );
    }
  };

  useEffect(() => {
    let isFormValid = true;

    for (const prop in formData) {
      if (!formData[prop].isValid) {
        isFormValid = false;
        break;
      }
    }

    setEnableLaunch(
      isFormValid &&
        projectStatus &&
        (projectStatus.status !== "Launching" && projectStatus.status !== "Deploying") &&
        //domainStatus &&
        (builtRight || headStart)
    );

    setEnableDeploy(
        isFormValid &&
        projectStatus &&
        (projectStatus.status === "Launched Successfully" || projectStatus.status === "Deployed Successfully" || projectStatus.status === "Deployment Failed") &&
        //domainStatus &&
        (builtRight || headStart)
    );
  }, [formData, domainStatus, projectStatus, builtRight, headStart]);

  const convertBEToFE = (data) => {
    if (!data) {
      data = {};
    }

    const fe = {
      masteraccountid: {
        value: data[`${PREFIX}/masteraccountid`] || "",
        isValid: data.hasOwnProperty(`${PREFIX}/masteraccountid`),
      },
      builderaccesskeyid: {
        value: data[`${PREFIX}/builderaccesskeyid`] || "",
        isValid: data.hasOwnProperty(`${PREFIX}/builderaccesskeyid`),
      },
      builderiamusername: {
        value: data[`${PREFIX}/builderiamusername`] || "",
        isValid: data.hasOwnProperty(`${PREFIX}/builderiamusername`),
      },
      builderiamuserpassword: {
        value: data[`${PREFIX}/builderiamuserpassword`] || "",
        isValid: data.hasOwnProperty(`${PREFIX}/builderiamuserpassword`),
      },
      buildersecretaccesskey: {
        value: data[`${PREFIX}/buildersecretaccesskey`] || "",
        isValid: data.hasOwnProperty(`${PREFIX}/buildersecretaccesskey`),
      },
    };

    return fe;
  };

  const save = async () => {
    try {
      setErrorMsg(null);
      setIsLoading(true);

      const credentials = {};

      for (const field in formData) {
        credentials[`${PREFIX}/${field}`] = formData[field].value;
      }

      console.log(credentials);

      const result = await awsAmplify.saveCredentials(projectId, credentials);
      setIsLoading(false);

      setIsDirty(false);

      //console.log(result);
      //console.log(result.data);
      //setProjectStatus(result.data);
    } catch (err) {
      setIsLoading(false);
      const errorMsg = getError(err, setErrorMsg);

      if (errorMsg === "You have no access") {
        history.goBack();
      }
    }
  };

  const [showPwd1, setShowPwd1] = useState(false);
  const [showPwd2, setShowPwd2] = useState(false);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Pre-launch Checks</div>
      <LeftHandMenu></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu></TopMenu>
            <div className="tableArea accountsTableArea aplicationTableArea container-fluid p-0 d-flex flex-column">
              <DomainMenu
                title="Pre-launch Checks"
                isDashboard={false}
                isConfigure={false}
                isPrelaunch={true}
                dashboardRoute={ROUTES.AZURE_ORGANIZATIONAL_UNITS}
                designRoute={ROUTES.AZURE_DESIGN_ACCOUNT_FRAMEWORK}
                configureRoute={ROUTES.AZURE_ENTERPRISE_CORE_ACCOUNTS}
              ></DomainMenu>

              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                <div className="row">
                  {projectStatus && (
                    <div className="status-bar">
                      <div className={alertClass}>
                        <div className="warnImg">
                          {alertClass === "orangealert" ? (
                            <img
                              className="rotating"
                              src="../images/rotating-arrow.svg"
                              alt="rotating arrow"
                            />
                          ) : (
                            <img src="../images/warning.svg" alt="warning" />
                          )}
                        </div>
                        {/* <span className="closebtn">&times;</span> */}
                        <div className="messageText">
                          {`Project ${projectStatus.status} on ${projectStatus.updatedOn}. `}
                          {projectStatus.message}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="mainContentArea preLaunchArea row px-2 py-2 mt-2">
                    <div className="col-xl-6 col-lg-6 col-12 p-0">
                      <div className="row">
                        <div className="col-md-6 col-12 d-flex flex-column">
                          <h3 className="font-15">Account Framework</h3>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("enterpriseCoreAccounts")}
                              onClick={() => onClick("enterpriseCoreAccounts")}
                            ></span>
                            <span
                              className={getGrayText("enterpriseCoreAccounts")}
                            >
                              Enterprise Core Accounts
                            </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("environmentStrategy")}
                              onClick={() => onClick("environmentStrategy")}
                            ></span>
                            <span
                              className={getGrayText("environmentStrategy")}
                            >
                              Envionment Strategy
                            </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName("applicationAccounts")}
                              onClick={() => onClick("applicationAccounts")}
                            ></span>
                            <span
                              className={getGrayText("applicationAccounts")}
                            >
                              Application Accounts
                            </span>
                          </div>
                          <div className="w-100 bg-special-lightblue border-10  p-relative p-2 my-1 d-inline-flex align-items-center">
                            <span
                              className={getClassName(
                                "developerSandboxAccounts"
                              )}
                              onClick={() =>
                                onClick("developerSandboxAccounts")
                              }
                            ></span>
                            <span
                              className={getGrayText(
                                "developerSandboxAccounts"
                              )}
                            >
                              Developer Sandbox Accounts
                            </span>
                          </div>
                        </div>
                        </div>
                    </div>
                </div>

                  <div className="d-inline-flex pt-3 pb-1 px-0 flex-wrap">
                    <h3 className="mb-0 mr-4 mt-0 ml-0 font-15">Launch</h3>

                    <div className="d-inline-flex mr-3 my-1 flex-wrap">
                      <label className="container font-12 font-weight-normal pl-5">
                        BuiltRight Cloud Foundation
                        <input
                          type="checkbox"
                          name="builtRight"
                          checked={builtRight}
                          onChange={(e) => setBuiltRight(e.target.checked)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>

                    <div className="d-inline-flex mr-3 my-1 flex-wrap">
                      <label className="container font-12 font-weight-normal pl-5">
                        HeadStart Digital Accelerator
                        <input
                          type="checkbox"
                          name="headStart"
                          checked={headStart}
                          onChange={(e) => setHeadStart(e.target.checked)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div className="mainContentArea show-hide-content row px-md-5 px-2 py-2 mt-2">
                    <div className="col-12 px-2">
                      <span className="mb-0 font-12 font-weight-bold">
                        Credentials
                      </span>
                    </div>
                    <div className="d-inline-flex col-md-4 my-1 col-12">
                      <span className="font-12 d-flex align-items-center text-nowrap mr-2">
                        Master Account ID
                      </span>
                      <input
                        type="text"
                        className="form-control py-2 gray"
                        placeholder=""
                        name="masteraccountid"
                        value={formData.masteraccountid.value}
                        onChange={onFieldChange}
                        onBlur={(e) => {
                          onFieldBlur(e, validateRequired);
                        }}
                      />
                    </div>
                    <div className="d-inline-flex col-md-4 my-1 col-12">
                      <span className="font-12 d-flex align-items-center text-nowrap mr-2">
                        IAM Username
                      </span>
                      <input
                        type="text"
                        className="form-control py-2 gray"
                        placeholder=""
                        name="builderiamusername"
                        value={formData.builderiamusername.value}
                        onChange={onFieldChange}
                        onBlur={(e) => {
                          onFieldBlur(e, validateRequired);
                        }}
                      />
                    </div>
                    <div className="d-inline-flex col-md-4 my-1 col-12">
                      <span className="font-12 d-flex align-items-center text-nowrap mr-2">
                        IAM Password
                      </span>
                      <input
                        type={showPwd1 ? "text" : "password"}
                        className="form-control py-2 gray pwd"
                        placeholder=""
                        name="builderiamuserpassword"
                        value={formData.builderiamuserpassword.value}
                        onChange={onFieldChange}
                        onBlur={(e) => {
                          onFieldBlur(e, validateRequired);
                        }}
                      />
                      <img
                        className="toggle-password gray"
                        src="../images/icons/eye.svg"
                        alt=""
                        onClick={() => setShowPwd1(!showPwd1)}
                      />
                    </div>
                    <div className="d-inline-flex col-md-4 my-1 col-12">
                      <span className="font-12 d-flex align-items-center text-nowrap mr-2">
                        Access Key ID
                      </span>
                      <input
                        type="text"
                        className="form-control py-2 gray"
                        placeholder=""
                        name="builderaccesskeyid"
                        value={formData.builderaccesskeyid.value}
                        onChange={onFieldChange}
                        onBlur={(e) => {
                          onFieldBlur(e, validateRequired);
                        }}
                      />
                    </div>
                    <div className="d-inline-flex col-md-4 my-1 col-12">
                      <span className="font-12 d-flex align-items-center text-nowrap mr-2">
                        Secret Access Key
                      </span>
                      <input
                        type={showPwd2 ? "text" : "password"}
                        className="form-control py-2 gray pwd"
                        placeholder=""
                        name="buildersecretaccesskey"
                        value={formData.buildersecretaccesskey.value}
                        onChange={onFieldChange}
                        onBlur={(e) => {
                          onFieldBlur(e, validateRequired);
                        }}
                      />
                      <img
                        className="toggle-password gray"
                        src="../images/icons/eye.svg"
                        alt=""
                        onClick={() => setShowPwd2(!showPwd2)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    className={
                      (isDirty && "active-state  ") +
                      "confirmBtn cancelBtn btn btn-link greyBorderButton btn-animation mr-md-2 mr-0"
                    }
                    disabled={!isDirty}
                    onClick={() => save()}
                  >
                    Save
                  </button>
                  <button
                    className="confirmBtn cancelBtn btn btn-link greyBorderButton btn-animation mr-md-2 mr-0"
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </button>
                  <button
                    className={
                      (enableLaunch && "active-state  ") +
                      "confirmBtn cancelBtn btn btn-link blueBorderButton btn-animation mr-md-2 mr-0 "
                    }
                    disabled={!enableLaunch}
                    onClick={() => launch("Launch")}
                  >
                    Launch
                  </button>
                  <button
                    className={
                      (enableDeploy && "active-state  ") +
                      "confirmBtn cancelBtn btn btn-link blueBorderButton btn-animation mr-md-2 mr-0 "
                    }
                    disabled={!enableDeploy}
                    onClick={() => launch("Deploy")}
                  >
                    Deploy
                  </button>
                  <button
                    className={
                      (enableLaunch && "active-state  ") +
                      "confirmBtn cancelBtn btn btn-link blueBorderButton btn-animation mr-md-2 mr-0 "
                    }
                    disabled={!enableLaunch}
                    onClick={() => launch("Launch_and_Deploy")}
                  >
                    {"Launch & Deploy"}
                  </button>
                  {/* <button className="confirmBtn btn btn-link greenButton btn-animation">
                    Save
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {console.log(formData)}
    </>
  );
};

export default AzurePrelaunch;
