


import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "../../../dashboard-common/DetailsTags";
import { getArrayValue, getBooleanValue } from "../../../dashboard-common/DashboardHelper";
import DetailsGrid from "../../../dashboard-common/DetailsGrid";

const dashboardName = "Image Builder Pipelines"; //To do:  Mapping Pending

const SubMenu = () => {
    const { projectId } = useParams();
  
    return (
        <>
          <h3>Image Builder</h3>
          <div className="otherTableTitles ">
          <h3 className="font-15">   
            <Link className="text-color-gray" to={ROUTES.INFRASTRUCTURE_COMPONENTS + "/" + projectId}>  
              Components
            </Link>          
            </h3>
            <h3 className="font-15">   
            <Link className="text-color-gray" to={ROUTES.INFRASTRUCTURE_RECIPES + "/" + projectId}>  
              Recipes
            </Link>   
            </h3>
            <h3 className="font-15 activetable">  
               Pipelines  
            </h3>
            <h3 className="font-15">   
            <Link className="text-color-gray" to={ROUTES.INFRASTRUCTURE_IMAGES + "/" + projectId}>  
               Images
            </Link>
            </h3>                
          </div>
        </>
      );
  };

  const Details = ({ close, selectedRow }) => {
    const [type, setType] = useState("Details");
  
    return (
      <div className="justify-content-center align-items-end account-detail">
        <div className="w-100 s-flex flex-column account-detail-area">
          <button className="exitAccount" onClick={() => close()}>
            <img src="../images/close.svg" />
          </button>
          <div className="">
            <ul className="account-detail-link m-0 p-0 d-inline-flex">
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Details" && "my_active"
                }`}
                onClick={() => setType("Details")}
              >
                Details
              </li>
		    <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Output Image" && "my_active"
                }`}
                onClick={() => setType("Output Image")}
              >
                Output Image
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Configuration" && "my_active"
                }`}
                onClick={() => setType("Configuration")}
              >
               Configuration
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Recipe" && "my_active"
                }`}
                onClick={() => setType("Recipe")}
              >
               Recipe
              </li>
             </ul>
          </div>
          <div className="d-flex w-100 account-detail-container-area">
            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Details" && "inaktif"
              }`}
            >
               <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">

                  <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.AccountId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Region</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Pipeline Name</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Date Created</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Version</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Status</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Date of Last Run</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {}
                  </p>
                </div>
              </div>

              <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                    <p className="m-0 p-0 account-detail-subtitle">
                        ARN
                    </p>
                    <p className="m-0 p-0 account-title-subcontent">
                        {}
                    </p>
                 </div>
                <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                    Platform
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                    {}
                </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                    Build Schedule
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                {}
                </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Description
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                    {}
                </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Enhanced Image Metadata
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                    {}
                </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  IAM Role
                </p>
                <p className="m-0 p-0 account-title-subcontent">
                    {}
                </p>
                </div>
                </div>
            
            </div>
           
           {/* TODO:  null values to be replaced with exact keys from json */}
        {/*} <DetailsGrid
            type={type}
            typeName="Output Image"
            array={}
            metadata={[
              { header: "Version", key: "" },
              { header: "Date Created", key: "" },
              { header: "Status", key: "" },
              { header: "Reason for failure", key: "" },
              { header: "ARN", key: "arn" },
            ]}
        ></DetailsGrid> */}

            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Configuration" && "inaktif"
              }`}
            >
            <div class="d-flex flex-column">
            <table class="details-table mb-4">
                <thead>
                <tr><th><strong class="font-15">Infrastructure Settings</strong></th></tr>
                <tr>
                <th>
                Instance Type
                </th>
                <th>
                    VPC ID
                </th>
                <th>
                    Subnet
                </th>
                <th>
                Security Group
                </th>
                    <th>
                    SNS 
                </th>
                <th>
                    Key Pair
                </th>
                <th>
                Terminate Instance on Failure
                </th>
                <th>
                Logs
                </th> 
                </tr>   
                </thead> 
                <tbody>
                </tbody>
                </table>
                              
             <table class="details-table">
                <thead>
                <tr><th><strong class="font-15">AMI Distribution Settings</strong></th></tr>
                <tr>
                <th>
                    Region
                </th>
                <th>
                    Launch Permissions
                </th>
                <th>
                    Associated License Configuration
                </th>

                </tr>   
                </thead> 
                <tbody>
 
                </tbody>
                </table>
                
             </div>
             </div>


           {/* <DetailsGrid
            type={type}
            typeName="Recipe"
            array={}
            metadata={[
              { header: "Recipe Name", key: "" },
              { header: "Version", key: "" },
              { header: "Image OS", key: "" },
              { header: "Source Image", key: "" },
              { header: "Owner", key: "" },
              { header: "ARN", key: "" },
            ]}
        ></DetailsGrid> */}
            </div>
          </div>
        </div>
    );
  };

  function Navigations() {
  const { projectId } = useParams();
  return (
    <>
      <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.INFRASTRUCTURE_INBOUND_ENDPOINTS + "/" + projectId}>
        <img src="../images/ribbon-design.svg" />
        <p className="m-0 p-0 ml-2 font-weight-bold">Private DNS</p>
      </Link>

      <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.INFRASTRUCTURE_BASELINES + "/" + projectId}
      >
        <p className="m-0 p-0 ml-2 font-weight-bold">Patch Management</p>
        <img src="../images/ribbon-designRight.svg" />
      </Link>
    </>
  );
}

const Pipelines = () => {
    const columns = useMemo(
        () => [
          {
            Header: "Account ID",
            accessor: "AccountId",
          },
          {
            Header: "Region",
            accessor: "Region",
          },
          {
            Header: "Pipeline Name",
            accessor: "",
            id: "Pipeline Name"
          },
          {
            Header: "Date Created",
            accessor: "",
            id: "Date Created"
          },
          {
            Header: "Version",
            accessor: "",
            id: "Version"
          },
          {
            Header: "Status",
            accessor: "",
            id: "Status"
          },
          {
            Header: "Date of Last Run",
            accessor: "",
            id: "Date of Last Run"
          },
          {
            Header: "ARN",
            accessor: "",
            id: "ARN"
          },
          {
            Header: "Platform",
            accessor: "",
            id: "Platform"
          },
          {
            Header: "Build Schedule",
            accessor: "",
            id: "Build Schedule"
          },
          {
            Header: "Description",
            accessor: "",
            id: "Description"
          },
          {
            Header: "Enhanced Image Metadata",
            accessor: "",
            id: "Enhanced Image Metadata"
          },
          {
            Header: "IAM Role",
            accessor: "",
            id: "IAM Role"
          }
        ],
        []
      );

    return Dashboard({
        domain: "infrastructure",
        title: "Infrastructure",
        dashboardName,
        columns,
        Details,
        Navigations,
        configureRoute: ROUTES.INFRASTRUCTURE_IMAGEMANAGEMENT,
        designRoute: ROUTES.DESIGN_INFRASTRUCTURE,
        SubMenu: SubMenu,
      });
};

export default Pipelines;