import React from "react";

const DetailsGrid = ({ type, typeName, array, metadata }) => (
  <div
    className={`bolgeler-container w-100 row p-5 ${
      type !== typeName && "inaktif"
    }`}
  >
    <table className="details-table">
      <thead>
        <tr>
          {metadata.map((md, idx) => (
            <th key={idx}>{md.header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {array &&
          array.map((item, idx) => (
            <tr key={idx}>
              {metadata.map((md, idxMd) => {
                if (md.isArray) {
                  const keyArray = md.key.split(".");
                  return (
                    <td key={idxMd}>
                      {item[keyArray[0]].map((v) => v[keyArray[1]]).join(",")}
                    </td>
                  );
                } else {
                  let value,
                    keyArray = md.key.split(".");

                  if (keyArray.length === 1) {
                    value = item[keyArray[0]];
                  } else {
                    value = item[keyArray[0]] && item[keyArray[0]][keyArray[1]];
                  }
                  if(value !== undefined)
                    value = value.toString();
                  return <td key={idxMd}>{value}</td>;
                }
              })}
            </tr>
          ))}
      </tbody>
    </table>
  </div>
);

export default DetailsGrid;
