import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../Dashboard";
import * as ROUTES from "../../../../constants/routes";

//const projectId = "google_1";
const dashboardName = "Accounts";

const Details = ({ close, selectedRow }) => {
  return (
    <div className="justify-content-center align-items-end account-detail">
      <div className="w-100 s-flex flex-column account-detail-area">
        <button className="exitAccount" onClick={() => close()}>
          <img src="../images/close.svg" />
        </button>
        <div className="">
          <ul className="account-detail-link m-0 p-0 d-inline-flex">
            <li className="bolgeler-btn my-active px-3 py-2 my_active">
              Details
            </li>
          </ul>
        </div>
        <div className="d-flex w-100 account-detail-container-area">
          <div className="bolgeler-container w-100 row p-5">
            <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Account Name</p>{" "}
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Name}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Account Email</p>{" "}
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Email}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Account Id</p>{" "}
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Id}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Status</p>{" "}
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Status}
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Organizational Unit
                </p>{" "}
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.OrganizationalUnitName}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">ARN</p>{" "}
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.Arn}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Joined Method</p>{" "}
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.JoinedMethod}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">
                  Joined Timestamp
                </p>{" "}
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow.JoinedTimestamp}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Navigations = () => {
  const { projectId } = useParams();
return (
  <Link
    className="btn-post btn-animation d-inline-flex"
    to={ROUTES.ALICLOUD_ORGANIZATIONAL_UNITS+"/"+projectId}
  >
    <img src="../images/ribbon-design.svg" />
    <p className="m-0 p-0 ml-2 font-weight-bold">Organizational Units</p>
  </Link>
)};

const AlicloudAcounts = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Account Id",
        accessor: "Id",
        width:100
      },
      {
        Header: "Account Name",
        accessor: "Name",
        width:150
      },
      {
        Header: "Account Email",
        accessor: "Email",
        width:200
      },
      {
        Header: "Status",
        accessor: "Status",
        width:80
      },
      {
        Header: "ARN",
        accessor: "Arn",
        width: 420
      },
      {
        Header: "Joined Method",
        accessor: "JoinedMethod",
        width: 100
      },
      {
        Header: "Joined Timestamp",
        accessor: "JoinedTimestamp",
        width: 200
      },
      {
        Header: "Organizational Unit Id",
        accessor: "OrganizationalUnitId",
      },
      {
        Header: "Organizational Unit",
        accessor: "OrganizationalUnitName",
      },
    ],
    []
  );

  return Dashboard({
    //projectId,
    domain: "accountframework",
    title: "Account Framework",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.ALICLOUD_ENTERPRISE_CORE_ACCOUNTS,
    designRoute: ROUTES.ALICLOUD_DESIGN_ACCOUNT_FRAMEWORK
  });
};

export default AlicloudAcounts;
