

import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "../../../dashboard-common/DetailsTags";
import { getArrayValue, getBooleanValue} from "../../../dashboard-common/DashboardHelper";
import DetailsGrid from "../../../dashboard-common/DetailsGrid";

const dashboardName = "Service Catalog Products";

const SubMenu = () => {
    const { projectId } = useParams();
  
    return (
      <>
        <h3>Service Catalog</h3>
        <div className="otherTableTitles ">
          <h3 className="font-15">  
          <Link className="text-color-gray" to={ROUTES.GOVERNANCE_PORTFOLIOS + "/" + projectId}>  
             Portfolios
         </Link>
          </h3>

          <h3 className="font-15 activetable">   
          Products
          </h3>
        </div>
      </>
    );
  };


  const Details = ({ close, selectedRow }) => {
    const [type, setType] = useState("Details");
  
    return (
      <div className="justify-content-center align-items-end account-detail">
        <div className="w-100 s-flex flex-column account-detail-area">
          <button className="exitAccount" onClick={() => close()}>
            <img src="../images/close.svg" />
          </button>
          <div className="">
            <ul className="account-detail-link m-0 p-0 d-inline-flex">
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Details" && "my_active"
                }`}
                onClick={() => setType("Details")}
              >
                Details
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Artifacts" && "my_active"
                }`}
                onClick={() => setType("Artifacts")}
              >
               Artifacts
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Portfolios" && "my_active"
                }`}
                onClick={() => setType("Portfolios")}
              >
               Portfolios
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Tags" && "my_active"
                }`}
                onClick={() => setType("Tags")}
              >
              Tags
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Tag Options" && "my_active"
                }`}
                onClick={() => setType("Tag Options")}
              >
              Tag Options
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Budgets" && "my_active"
                }`}
                onClick={() => setType("Budgets")}
              >
              Budgets
              </li>
            </ul>
          </div>
          <div className="d-flex w-100 account-detail-container-area">
            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Details" && "inaktif"
              }`}
            >
              <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow && selectedRow.AccountId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Region</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow && selectedRow.Region}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Product Name</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  { selectedRow && selectedRow.ProductViewDetail && selectedRow.ProductViewDetail.ProductViewSummary
                   && selectedRow.ProductViewDetail.ProductViewSummary.Name} 
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                    Product ID
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  { selectedRow && selectedRow.ProductViewDetail && selectedRow.ProductViewDetail.ProductViewSummary
                   && selectedRow.ProductViewDetail.ProductViewSummary.Id} 
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                    Description
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  { selectedRow && selectedRow.ProductViewDetail && selectedRow.ProductViewDetail.ProductViewSummary
                   && selectedRow.ProductViewDetail.ProductViewSummary.ShortDescription} 
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                     Owner
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  { selectedRow && selectedRow.ProductViewDetail && selectedRow.ProductViewDetail.ProductViewSummary
                   && selectedRow.ProductViewDetail.ProductViewSummary.Owner} 
                  </p>
                </div>
              </div>
              <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                    Type
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  { selectedRow && selectedRow.ProductViewDetail && selectedRow.ProductViewDetail.ProductViewSummary
                   && selectedRow.ProductViewDetail.ProductViewSummary.Type} 
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Created on</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  { selectedRow && selectedRow.ProductViewDetail 
                   && selectedRow.ProductViewDetail.CreatedTime} 
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Product ARN</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  { selectedRow && selectedRow.ProductViewDetail && 
                  selectedRow.ProductViewDetail.ProductARN} 
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Has Default Path</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  { selectedRow && selectedRow.ProductViewDetail && selectedRow.ProductViewDetail.ProductViewSummary
                   && getBooleanValue(selectedRow.ProductViewDetail.ProductViewSummary, "HasDefaultPath")} 
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Status</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  { selectedRow && selectedRow.ProductViewDetail && 
                     selectedRow.ProductViewDetail.Status} 
                  </p>
                </div>
                </div>
            </div>

            <DetailsGrid
             type={type}
             typeName="Artifacts"
             array={selectedRow.ProvisioningArtifactSummaries}
             metadata={[
               { header: "Artifact Id", key: "Id" },
               { header: "Name", key: "Name" },
               { header: "Description", key: "Description" },
               { header: "Created On", key: "CreatedTime" },
             ]}
           ></DetailsGrid>

            <DetailsGrid
             type={type}
             typeName="Portfolios"
             array={selectedRow.Portfolios}
             metadata={[
               { header: "Portfolio Name", key: "DisplayName" },
               { header: "Portfolio Id", key: "Id" },
               { header: "Description", key: "Description" },
               { header: "Provider Name", key: "ProviderName" },
               { header: "Created Time", key: "CreatedTime" }, 
               { header: "ARN", key: "ARN" }, 
             ]}
           ></DetailsGrid>
          
            <DetailsTags type={type} tags={selectedRow.Tags}></DetailsTags> 

         
            <div
             className={`bolgeler-container w-100 row p-5 ${
               type !== "Tag Options" && "inaktif"
             }`}
           >
            <div className="d-flex flex-column">
                <table className="details-table">
                  <thead>
                    <tr>
                      <th>Key</th>
                      <th>Value</th>
                      <th>Active</th>
                      <th>Id</th>
                    </tr>
                  </thead>
                  <tbody>  
                     {selectedRow.TagOptions && selectedRow.TagOptions.map(item => (
                            <tr> 
                            <td>{item.Key}</td>
                            <td>{item.Value}</td>
                            <td>{getBooleanValue(item,"Active")}</td>
                            <td>{item.Id}</td>
                        </tr>
                     ))}                 
                  </tbody>
                </table>
            </div>
            </div>

            <div
             className={`bolgeler-container w-100 row p-5 ${
               type !== "Budgets" && "inaktif"
             }`}
           >
            <div className="d-flex flex-column">
                <table className="details-table">
                  <thead>
                    <tr>
                      <th>Budget Name</th>
                      <th>Budget Type</th>
                      <th>Time Unit</th>
                      <th>Current Spend</th>
                      <th>Budgeted</th>
                      <th>Forecasted</th>
                      <th>Current vs. Budgeted</th>
                      <th>Forecasted vs. Budgeted</th>
                      <th>Last Updated</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                        <td>{selectedRow["Budgets"].BudgetName}</td>
                        <td>{selectedRow["Budgets"].BudgetType}</td>
                        <td>{selectedRow["Budgets"].TimeUnit}</td>
                        <td>{selectedRow["Budgets"].CalculatedSpend  && 
                         selectedRow["Budgets"].CalculatedSpend.ActualSpend && 
                         selectedRow["Budgets"].CalculatedSpend.ActualSpend.Amount}</td>
                         <td>{selectedRow["Budgets"].BudgetLimit &&
                              selectedRow["Budgets"].BudgetLimit.Amount }</td>
                        <td>{selectedRow["Budgets"].CalculatedSpend  && 
                         selectedRow["Budgets"].CalculatedSpend.ForecastedSpend && 
                         selectedRow["Budgets"].CalculatedSpend.ForecastedSpend.Amount}</td>
                         <td>{ getCurrentVsBudgeted(selectedRow) ? getCurrentVsBudgeted(selectedRow) + '%': "-" }</td>
                         <td>{ getForecastedVsBudgeted(selectedRow) ?  getForecastedVsBudgeted(selectedRow) + '%': "-"} </td>                       
                         <td>{ selectedRow["Budgets"].LastUpdatedTime}</td>
                    </tr>
                  </tbody>
                </table>
            </div>
            </div>
         
            
          </div>
        </div>
      </div>
    );
  };

  const getCurrentVsBudgeted = (data) => {

     var current =  parseFloat(data["Budgets"] && data["Budgets"].CalculatedSpend  && 
     data["Budgets"].CalculatedSpend.ActualSpend && 
     data["Budgets"].CalculatedSpend.ActualSpend.Amount);

    var budgeted = parseFloat(data["Budgets"] && data["Budgets"].BudgetLimit &&
                    data["Budgets"].BudgetLimit.Amount);
                    
    var result;
    
    if(current && budgeted)
    {
       result = current / budgeted;
    }
    return result;
  }

  const getForecastedVsBudgeted = (data) => {
    var forecasted = parseFloat(data["Budgets"]  && data["Budgets"].CalculatedSpend  && 
    data["Budgets"].CalculatedSpend.ForecastedSpend && 
    data["Budgets"].CalculatedSpend.ForecastedSpend.Amount);

    var budgeted = parseFloat(data["Budgets"] && data["Budgets"].BudgetLimit &&
    data["Budgets"].BudgetLimit.Amount);

    var result;
    if(forecasted && budgeted)
    {
       result = forecasted / budgeted;
    }
    return result;
  }

  const Navigations = () => {
    const { projectId } = useParams();
    return (
      <>
       <Link
      className="btn-post btn-animation d-inline-flex"
      to={ROUTES.GOVERNANCE_AWS_BUDGETS + "/" + projectId}>
       <img src="../images/ribbon-design.svg" /> 
      <p className="m-0 p-0 mr-2 font-weight-bold"> AWS Budgets</p>
      </Link>

      <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.GOVERNANCE_SERVICE_CONTROL + "/" + projectId} >
        <p className="m-0 p-0 ml-2 font-weight-bold"> Organization Policies</p>
        <img src="../images/ribbon-designRight.svg" />    
      </Link> 
    </>
    );
  };
  
  
const Products = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Account Id",
        accessor: "AccountId",
        id:"Account Id"
      },
      {
        Header: "Region",
        accessor: "Region",
        id:"Region"
      },
      {
        Header: "Product Name",
        accessor: "ProductViewDetail.ProductViewSummary.Name",
        id: "Product Name"
      },
      {
        Header: "Product Id",
        accessor: "ProductViewDetail.ProductViewSummary.Id",
        id: "Product Id"
      },
      {
        Header: "Description",
        accessor: "ProductViewDetail.ProductViewSummary.ShortDescription",
        id: "Description"
      },
      {
        Header: "Owner",
        accessor: "ProductViewDetail.ProductViewSummary.Owner",
        id: "Owner"
      },
      {
        Header: "Type",
        accessor: "ProductViewDetail.ProductViewSummary.Type",
        id: "Type"
      },
      {
        Header: "Created on",
        accessor: "ProductViewDetail.CreatedTime",
        id: "Created On"
      },
      {
        Header: "Product ARN",
        accessor: "ProductViewDetail.ProductARN",
        id: "ARN"
      },
    ],
    []
  );

  return Dashboard({
    domain: "governance",
    title: "Governance",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.GOVERNANCE_BUDGET_ENFORCEMENT,
    designRoute: ROUTES.DESIGN_GOVERNANCE,
    SubMenu: SubMenu,
  });
};

export default Products;
;




