import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

//const projectId = "google_1";
const PREFIX = "/aws/dataprotection/keymanagement";

const KMS_ADMINISTRATOR_ACCOUNT_KEY = "/aws/accountframework/enterprisecoreaccounts/securityaccountslist";
const PRODUCTION_SDLC_STAGE_NAME_KEY = "/aws/accountframework/environmentstrategy/sdlcstageslist";

const KeyManagement = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    deploycentralizedkeymanagement:  {value: "yes" },
    deploycentralizedkmsscproduct: {value: "yes" },
    kmsadministratoraccountlist: [],
    kmsadministratoraccount: "",
    restrictkeycreationinmemberaccounts: {value: "yes" },
    productionsdlcstagenamelist: [],
    productionsdlcstagename: "",
    monitorhighriskevents:  {value: "yes" }
  });

  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const awsAmplify = useAwsAmplify();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        console.log(result);

        setEmpty(result.data.empty);

        setIsLoading(false);

         const enterpriseCoreAccountResults = await awsAmplify.loadProjectData(projectId, KMS_ADMINISTRATOR_ACCOUNT_KEY);
          console.log(enterpriseCoreAccountResults);
          setIsLoading(false);

          const environmentStrategyResults = await awsAmplify.loadProjectData(projectId, PRODUCTION_SDLC_STAGE_NAME_KEY);
          console.log(environmentStrategyResults);
          setIsLoading(false);

        const fe = convertBEToFE(
          result.data,
          enterpriseCoreAccountResults.data,
          environmentStrategyResults.data
        );

        setFormData(fe);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response && err.response.data.message);

        if(err.response && err.response.data.message === "You have no access"){
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);

      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (
    data,
    enterpriseCoreAccountData,
    environmentStrategyData
  ) => {
    if (!data) {
      data = {};
    }

    const fe = {
      deploycentralizedkeymanagement: data[`${PREFIX}/deploycentralizedkeymanagement`] || "yes", 
      deploycentralizedkmsscproduct: data[`${PREFIX}/deploycentralizedkmsscproduct`] || "yes" ,

      kmsadministratoraccountlist:  enterpriseCoreAccountData &&  
                                enterpriseCoreAccountData[KMS_ADMINISTRATOR_ACCOUNT_KEY] 
                                ? enterpriseCoreAccountData[KMS_ADMINISTRATOR_ACCOUNT_KEY].split(",") : [],

      kmsadministratoraccount: data[`${PREFIX}/kmsadministratoraccount`] || "",                         

      restrictkeycreationinmemberaccounts: data[`${PREFIX}/restrictkeycreationinmemberaccounts`] || "yes" ,

      productionsdlcstagenamelist: environmentStrategyData &&
                             environmentStrategyData[PRODUCTION_SDLC_STAGE_NAME_KEY] 
                             ? environmentStrategyData[PRODUCTION_SDLC_STAGE_NAME_KEY].split(",") : [],

     productionsdlcstagename: data[`${PREFIX}/productionsdlcstagename`] || "", 

      monitorhighriskevents:  data[`${PREFIX}/monitorhighriskevents`] || "yes" ,
      
    };

    return fe;
  };

  const convertFEToBE = ( ) => {
    const be = {
      [`${PREFIX}/deploycentralizedkeymanagement`]: formData
        .deploycentralizedkeymanagement,
    };

    if (formData.deploycentralizedkeymanagement === "yes") {
        if (formData.deploycentralizedkmsscproduct !== "") {
          be[`${PREFIX}/deploycentralizedkmsscproduct`] = formData.deploycentralizedkmsscproduct;
        }
         
        if (formData.kmsadministratoraccount !== "") {
            be[`${PREFIX}/kmsadministratoraccount`] = formData.kmsadministratoraccount;
          }

        if (formData.restrictkeycreationinmemberaccounts !== "") {
        be[`${PREFIX}/restrictkeycreationinmemberaccounts`] = formData.restrictkeycreationinmemberaccounts;

    }

        if (formData.monitorhighriskevents !== "") {
            be[`${PREFIX}/monitorhighriskevents`] = formData.monitorhighriskevents;
            }     
    }

    return be;
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };


  const onFieldChange = (value, key1) => {
    setErrorMsg(null);
    setIsDirty(true);

    setFormData((state) =>
      update(state, { [key1]: { $set: value } })
    );
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Data Protection</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
          <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Data Protection"
                isDirty={isDirty}
                dashboardRoute={ROUTES.DATA_PROTECTION_KEYS}
                designRoute={ROUTES.DESIGN_DATA_PROTECTION}
                isConfigure={true}
                isDesign={false}
                isDashboard={false}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-1 px-lg-4 ">
                <div className="row d-flex align-items-center ">
                  <div className="col-xl-4 col-lg-4 col-md-4 pl-lg-0 px-2">
                    <h3 className="font-weight-bold">
                     Key Management
                    </h3>
                  </div>

                  <div className="col-xl-8 col-lg-8 col-md-8 d-flex align-items-center justify-content-end rightNavArea px-0">
                    <div className="d-inline-flex yes-no-area align-items-center">
                    <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12 text-nowrap">
                        Deploy Centralized Key Management
                    </div>
                          <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                            <label
                              className={`createElementBtn btn btn-link btn-linkYes h-20 ${
                                formData.deploycentralizedkeymanagement ===  "yes" && "active"
                              }`}
                            >
                              <input
                                type="radio"
                                name="deploycentralizedkeymanagement"
                                checked={
                                  formData.deploycentralizedkeymanagement === "yes"
                                }
                                onChange={() => {
                                  setIsDirty(true);
                                  setFormData((state) =>
                                    update(state, {
                                        deploycentralizedkeymanagement: { $set: "yes" },
                                    })
                                  );
                                }}
                              />{" "}
                              YES
                            </label>
                            <label
                              className={`dontCreateElementBtn btn btn-link btn-linkNo h-20 ${
                                formData.deploycentralizedkeymanagement === "no" && "active"
                              }`}
                            >
                              <input
                                type="radio"
                                name="deploycentralizedkeymanagement"
                                checked={
                                  formData.deploycentralizedkeymanagement === "no"
                                }
                                onChange={() => {
                                  setIsDirty(true);
                                  setFormData((state) =>
                                    update(state, {
                                        deploycentralizedkeymanagement: { $set: "no" }
                                    })
                                  );
                                }}
                              />{" "}
                              NO
                            </label>
                          </div>
                        </div>
                      </div>
                </div>

                {empty && (
                  <NoInitialData />
                )}

                 {formData.deploycentralizedkeymanagement === "yes" && (
                 <div className="show-hide-content">                
                    <div className=" row px-2 py-1 my-1">
                     <div className="col-12 px-1 my-1">
                      <div className="w-100">
                         <div className="d-inline-flex yes-no-area align-items-center">
                                 <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12 ">
                                     Deploy Centralized KMS SC Product
                                 </div>
                                 <div className="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center mr-0">
                                 <label className={`createElementBtn btn btn-link btn-linkYes h-20 ${
                                   formData.deploycentralizedkmsscproduct === "yes" && "active disabled" }`} >
                                        <input type="radio" name="deploycentralizedkmsscproduct"
                                        disabled = {true}
                                        checked={ 
                                                    formData.deploycentralizedkmsscproduct === "yes"  
                                                }
                                        onChange={() => {
                                        setIsDirty(true);
                                        setFormData((state) =>
                                            update(state, {
                                                deploycentralizedkmsscproduct: { $set: "yes" }
                                            })
                                        );
                                        }}
                                    />{" "}
                                     YES
                                    </label>
                               
                                    <label
                                    className={`dontCreateElementBtn btn btn-link btn-linkNo h-20 ${
                                        formData.deploycentralizedkmsscproduct === "no" && "disabled"
                                    }`}
                                    >
                                    <input
                                        type="radio"
                                        disabled = {true}
                                        name="deploycentralizedkmsscproduct"
                                        readOnly={true}
                                        checked={
                                        formData.deploycentralizedkmsscproduct === "no"
                                        } 
                                    />{" "}
                                    NO
                                    </label>
                                </div>
                           </div> 
                         </div>   
                        </div>
                     </div> 
                    
                    <div className="mainContentArea row px-2 py-1 my-1">
                     <div className="d-inline-flex align-items-center row px-2">
                        <div className="col-xxl-3 col-md-4 col-12 p-0 my-1">
                          <span className="mb-0 font-12">Delegated KMS Administrator Account </span>
                         </div>
                         
                        <div className="col-xxl-9 col-md-8 col-12 flex-wrap d-inline-flex">
                        {formData.kmsadministratoraccountlist.length === 0 ? (
                            <span className="warning">
                              This value depends on Enterprise Core Accounts section which is yet to be provided.
                            </span>
                          ) : (
                            <div className="mr-3 my-1"> 
                                <div className="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center"> 
                                    <select
                                        className="form-control gray"
                                        value={formData.kmsadministratoraccount}
                                        onChange={(e) => {
                                            onFieldChange(
                                            e.target.value,
                                            "kmsadministratoraccount"
                                );
                              }} >   
                                 <option value="">Select</option>
                                    { formData.kmsadministratoraccountlist && formData.kmsadministratoraccountlist.map(
                                      (account, idxAccount) => (
                                        <option key={idxAccount} value={account}>
                                          {account}
                                        </option>
                                      )
                                    )}
                                    </select>
                                </div>
                            </div> )}
                          </div>
                          
                     </div>
                        
                    <div className="d-inline-flex align-items-center row px-2">
                        <div className="col-xxl-3 col-md-4 col-12 p-0 my-1">
                          <span className="mb-0 font-12">Restrict Users from Creating Keys Locally in Member Accounts</span>
                         </div>
                        <div className="col-xxl-9 col-md-8 col-12 flex-wrap d-inline-flex">
                            <div className="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center mr-0">

                                <label className={`createElementBtn btn btn-link btn-linkYes h-20 ${
                                   formData.restrictkeycreationinmemberaccounts === "yes" && "active" }`} >
                                <input type="radio" name="restrictkeycreationinmemberaccounts"
                                  checked={ 
                                            formData.restrictkeycreationinmemberaccounts === "yes"  
                                         }
                                  onChange={() => {
                                  setIsDirty(true);
                                  setFormData((state) =>
                                    update(state, {
                                        restrictkeycreationinmemberaccounts: { $set: "yes" },
                                    })
                                  );
                               }}
                              />{" "}
                              YES
                            </label>

                            <label
                              className={`dontCreateElementBtn btn btn-link btn-linkNo h-20 ${
                                formData.restrictkeycreationinmemberaccounts ===
                                  "no" && "active"
                              }`}
                            >
                              <input
                                type="radio"
                                name="restrictkeycreationinmemberaccounts"
                                checked={
                                  formData.restrictkeycreationinmemberaccounts === "no"
                                }
                                onChange={() => {
                                  setIsDirty(true);
                                  setFormData((state) =>
                                    update(state, {
                                        restrictkeycreationinmemberaccounts: { $set: "no" }
                                     }) );
                                }}
                              />{" "}
                              NO
                            </label>
                               
                                 </div>
                          </div>
                      </div>
                    
                   </div>
                        
                    <div className="row  py-1 my-1">
                     <div className="col-12 px-1 my-1">
                      <div className="w-100">
                         <div className="d-inline-flex yes-no-area align-items-center">
                            <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12 ">
                                Monitor High-risk Events 
                            </div>
                            <div className="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center mr-0">
                            <label className={`createElementBtn btn btn-link btn-linkYes h-20 ${
                                   formData.monitorhighriskevents === "yes" && "active" }`} >
                                <input type="radio" name="monitorhighriskevents"
                                  checked={ 
                                            formData.monitorhighriskevents === "yes"  
                                         }
                                  onChange={() => {
                                  setIsDirty(true);
                                  setFormData((state) =>
                                    update(state, {
                                        monitorhighriskevents:{ $set: "yes" }
                                      },
                                    )
                                  );
                                }}
                              />{" "}
                              YES
                            </label>

                            <label
                              className={`dontCreateElementBtn btn btn-link btn-linkNo h-20 ${
                                formData.monitorhighriskevents === "no" && "active"
                              }`}
                            >
                              <input
                                type="radio"
                                name="monitorhighriskevents"
                                checked={
                                  formData.monitorhighriskevents === "no"
                                }
                                onChange={() => {
                                  setIsDirty(true);
                                  setFormData((state) =>
                                    update(state, {
                                        monitorhighriskevents: {  $set:  "no" },
                                    })
                                  );
                                }}
                              />{" "}
                              NO
                            </label>
                           </div>
                        </div> 
                      </div>   
                     </div>
                    </div>
    
                </div>
                  
                )}
              </div>
              </fieldset>
              <div class="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
              <button class="btn-post btn-animation d-inline-flex"
               onClick={() =>
                saveDataIfDirty(ROUTES.DATA_PROTECTION_CERTIFICATE_MANAGEMENT + "/" + projectId)
              }>
                  <img src="../images/ribbon-design.svg"></img>
                  <p class="m-0 p-0 ml-2 font-weight-bold">Certificate Management</p>                             
            </button>
          </div>
             </form>
 
          </div>
       </div></div>
 
     
 
   </>
    );
    };

export default KeyManagement;
