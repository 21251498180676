import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import RadioGroup from "../../RadioGroup";
import {
  validate1to10AlphaNumeric,
  validateTagKey,
  validateExternalId,
  validateSourceAccountId,
} from "../../../../helpers/validator";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

const PREFIX = "/aws/security/endpointprotection";

const getInitialFormData = (data) => {
  const initialFormData = {
    deployendpointsecuritymgmt: {
      value: data[`${PREFIX}/deployendpointsecuritymgmt`] || "no",
    },
    crowdstrike_deployintegrationwithcrowdstrike: {
      value:
        data[`${PREFIX}/crowdstrike/deployintegrationwithcrowdstrike`] || "yes",
    },
    crowdstrike_customerid: {
      value: data[`${PREFIX}/crowdstrike/customerid`] || "",
      isValid: data[`${PREFIX}/crowdstrike/customerid`] ? true : false,
    },
    mcafee_deployintegrationwithmcafee: {
      value: data[`${PREFIX}/mcafee/deployintegrationwithmcafee`] || "yes",
    },
    mcafee_tenantid: {
      value: data[`${PREFIX}/mcafee/tenantid`] || "",
      isValid: data[`${PREFIX}/mcafee/tenantid`] ? true : false,
    },
    mcafee_token: {
      value: data[`${PREFIX}/mcafee/token`] || "",
      isValid: data[`${PREFIX}/mcafee/token`] ? true : false,
    },
    mcafee_externalid: {
      value: data[`${PREFIX}/mcafee/externalid`] || "",
      isValid: data[`${PREFIX}/mcafee/externalid`] ? true : false,
    },
    mcafee_workloadsecurityaccountid: {
      value: data[`${PREFIX}/mcafee/workloadsecurityaccountid`] || "",
      isValid: data[`${PREFIX}/mcafee/workloadsecurityaccountid`]
        ? true
        : false,
    },
    trendmicro_deployintegrationwithtrendmicro: {
      value:
        data[`${PREFIX}/trendmicro/deployintegrationwithtrendmicro`] || "yes",
    },
    trendmicro_apikey: {
      value: data[`${PREFIX}/trendmicro/apikey`] || "",
      isValid: data[`${PREFIX}/trendmicro/apikey`] ? true : false,
    },
    trendmicro_externalid: {
      value: data[`${PREFIX}/trendmicro/externalid`] || "",
      isValid: data[`${PREFIX}/trendmicro/externalid`] ? true : false,
    },
    trendmicro_sourceaccountid: {
      value: data[`${PREFIX}/trendmicro/sourceaccountid`] || "",
      isValid: data[`${PREFIX}/trendmicro/sourceaccountid`] ? true : false,
    },
  };

  return initialFormData;
};

const EndpointProtection = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState(() => getInitialFormData({}));

  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const awsAmplify = useAwsAmplify();

  const [showPwd1, setShowPwd1] = useState(false);
  const [showPwd2, setShowPwd2] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        setIsLoading(false);

        setEmpty(result.data.empty);

        setFormData(convertBEToFE(result.data));
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);

        if (err.response.data.message === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (data) => {
    if (!data) {
      data = {};
    }

    const fe = getInitialFormData(data);

    return fe;
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/deployendpointsecuritymgmt`]: formData
        .deployendpointsecuritymgmt.value,
    };

    if (formData.deployendpointsecuritymgmt.value === "yes") {
      be[`${PREFIX}/crowdstrike/deployintegrationwithcrowdstrike`] =
        formData.crowdstrike_deployintegrationwithcrowdstrike.value;

      if (formData.crowdstrike_deployintegrationwithcrowdstrike.value === "yes") {
        if (formData.crowdstrike_customerid.isValid) {
          be[`${PREFIX}/crowdstrike/customerid`] =
            formData.crowdstrike_customerid.value;
        }
      }

      be[`${PREFIX}/mcafee/deployintegrationwithmcafee`] =
        formData.mcafee_deployintegrationwithmcafee.value;

      if (formData.mcafee_deployintegrationwithmcafee.value === "yes") {
        if (formData.mcafee_tenantid.isValid) {
          be[`${PREFIX}/mcafee/tenantid`] = formData.mcafee_tenantid.value;
        }

        if (formData.mcafee_token.isValid) {
          be[`${PREFIX}/mcafee/token`] = formData.mcafee_token.value;
        }

        if (formData.mcafee_externalid.isValid) {
          be[`${PREFIX}/mcafee/externalid`] = formData.mcafee_externalid.value;
        }

        if (formData.mcafee_workloadsecurityaccountid.isValid) {
          be[`${PREFIX}/mcafee/workloadsecurityaccountid`] =
            formData.mcafee_workloadsecurityaccountid.value;
        }
      }

      be[`${PREFIX}/trendmicro/deployintegrationwithtrendmicro`] =
        formData.trendmicro_deployintegrationwithtrendmicro.value;

      if (formData.trendmicro_deployintegrationwithtrendmicro.value === "yes") {
        if (formData.trendmicro_apikey.isValid) {
          be[`${PREFIX}/trendmicro/apikey`] = formData.trendmicro_apikey.value;
        }

        if (formData.trendmicro_externalid.isValid) {
          be[`${PREFIX}/trendmicro/externalid`] =
            formData.trendmicro_externalid.value;
        }

        if (formData.trendmicro_sourceaccountid.isValid) {
          be[`${PREFIX}/trendmicro/sourceaccountid`] =
            formData.trendmicro_sourceaccountid.value;
        }
      }
    }

    return be;
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  const updateRadio = (key, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        [key]: {
          value: { $set: value },
        },
      })
    );
  };

  const onFieldChange = (value, key) => {
    setErrorMsg(null);
    setIsDirty(true);

    setFormData((state) =>
      update(state, { [key]: { value: { $set: value } } })
    );
  };

  const onFieldBlur = (validateFunc, value, key) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      setFormData((state) =>
        update(state, {
          [key]: { isValid: { $set: errorMsg === undefined } },
        })
      );

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      setFormData((state) =>
        update(state, { [key]: { isValid: { $set: false } } })
      );
    }
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Security</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Security"
                isDirty={isDirty}
                dashboardRoute={ROUTES.SECURITY_HUB_MEMBERS}
                designRoute={ROUTES.DESIGN_SECURITY}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div class="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                <div class="row d-flex align-items-center">
                  <div class="col-xl-4 col-lg-4 col-md-4 pl-lg-0 px-2">
                    <h3>Endpoint Protection</h3>
                  </div>
                  <div class="col-xl-8 col-lg-8 col-md-8 d-flex align-items-center justify-content-end rightNavArea">
                    <div class="d-inline-flex yes-no-area align-items-center">
                      <div class="content-label mr-xl-5 mr-lg-3 mr-1 font-12 text-nowrap">
                        Deploy Endpoint Security Management
                      </div>
                      <div class=" btn-group btn-group-toggle btn-group-radio mw-100 ">
                        <RadioGroup
                          field="deployendpointsecuritymgmt"
                          value={formData.deployendpointsecuritymgmt.value}
                          onChange={updateRadio}
                          isLarge={true}
                        ></RadioGroup>
                      </div>
                    </div>
                  </div>
                </div>
                {empty && (
                  <NoInitialData />
                )}

                {formData.deployendpointsecuritymgmt.value === "yes" && (
                  <div class="row show-hide-content">
                    <div class="mainContentArea row px-1 py-1 mt-2">
                      <div class="b-section formRow align-items-start my-1 px-0">
                        <div class="showRightContenBtn flex-1 px-1">
                          <div class=" w-100 h-md-34 d-flex align-items-center">
                            <div class="one form-line d-inline-flex align-items-center">
                              <label class="switch mb-0">
                                <input
                                  type="checkbox"
                                  checked={
                                    formData
                                      .crowdstrike_deployintegrationwithcrowdstrike
                                      .value === "yes"
                                  }
                                  onChange={(e) =>
                                    onFieldChange(
                                      e.target.checked ? "yes" : "no",
                                      "crowdstrike_deployintegrationwithcrowdstrike"
                                    )
                                  }
                                />
                                <span class="slider round"></span>
                              </label>
                              <span class="switchLabel ">
                                CrowdStrike Endpoint Protection
                              </span>
                            </div>
                          </div>
                        </div>

                        {formData.crowdstrike_deployintegrationwithcrowdstrike
                          .value === "yes" && (
                          <div class="rightContent flex-1 px-1">
                            <div class="d-inline-flex w-100 h-md-34 d-flex align-items-center">
                              <div class="d-flex flex-column mr-2">
                                <div class="specialTitle mw-md-150 py-10px d-flex align-items-center">
                                  <p class="m-0 p-0 font-12">
                                    Crowdstrike Customer ID
                                  </p>
                                </div>
                              </div>
                              <div class="d-flex flex-column flex-1">
                                <div class="flex-1  bg-gray border-10  p-relative d-inline-flex align-items-center">
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={
                                      formData.crowdstrike_customerid.value
                                    }
                                    className={`form-control ${
                                      formData.crowdstrike_customerid.value !==
                                        "" &&
                                      formData.crowdstrike_customerid
                                        .isValid === false
                                        ? "invalid"
                                        : ""
                                    }`}
                                    onChange={(e) =>
                                      onFieldChange(
                                        e.target.value,
                                        "crowdstrike_customerid"
                                      )
                                    }
                                    onBlur={(e) =>
                                      onFieldBlur(
                                        validate1to10AlphaNumeric,
                                        e.target.value,
                                        "crowdstrike_customerid"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div class="rightContent flex-1 px-1"></div>
                      </div>

                      <div class="b-section formRow align-items-start px-0 my-1">
                        <div class="showRightContenBtn flex-1 px-1">
                          <div class="w-100 h-md-34 d-flex align-items-center">
                            <div class="one form-line d-inline-flex align-items-center">
                              <label class="switch mb-0">
                                <input
                                  type="checkbox"
                                  checked={
                                    formData.mcafee_deployintegrationwithmcafee
                                      .value === "yes"
                                  }
                                  onChange={(e) =>
                                    onFieldChange(
                                      e.target.checked ? "yes" : "no",
                                      "mcafee_deployintegrationwithmcafee"
                                    )
                                  }
                                />
                                <span class="slider round"></span>
                              </label>
                              <span class="switchLabel">
                                McAfee Endpoint Protection
                              </span>
                            </div>
                          </div>
                        </div>

                        {formData.mcafee_deployintegrationwithmcafee.value ===
                          "yes" && (
                          <>
                            <div class="rightContent flex-1 px-1">
                              <div class="d-inline-flex w-100">
                                <div class="d-flex flex-column mr-2">
                                  <div class="specialTitle mw-md-150 mb-1  py-10px d-flex align-items-center">
                                    <p class="m-0 p-0 font-12">Token</p>
                                  </div>
                                  <div class="specialTitle mw-md-150 mt-1  py-10px d-flex align-items-center">
                                    <p class="m-0 p-0 font-12">Tenant ID</p>
                                  </div>
                                </div>

                                <div class="d-flex flex-column flex-1">
                                  <div class="flex-1 mb-1 bg-gray border-10  p-relative d-inline-flex align-items-center">
                                    <button disabled={disabled}
                                      type="button"
                                      onClick={() => setShowPwd1(!showPwd1)}
                                      class="showableInput"
                                    ></button>
                                    <input
                                      type={showPwd1 ? "text" : "password"}
                                      placeholder=""
                                      value={formData.mcafee_token.value}
                                      className={`form-control ${
                                        formData.mcafee_token.value !== "" &&
                                        formData.mcafee_token.isValid === false
                                          ? "invalid"
                                          : ""
                                      }`}
                                      onChange={(e) =>
                                        onFieldChange(
                                          e.target.value,
                                          "mcafee_token"
                                        )
                                      }
                                      onBlur={(e) =>
                                        onFieldBlur(
                                          validate1to10AlphaNumeric,
                                          e.target.value,
                                          "mcafee_token"
                                        )
                                      }
                                    />
                                  </div>
                                  <div class="flex-1 mt-1  bg-gray border-10  p-relative d-inline-flex align-items-center">
                                    <input
                                      type="text"
                                      class="form-control "
                                      placeholder=""
                                      value={formData.mcafee_tenantid.value}
                                      className={`form-control ${
                                        formData.mcafee_tenantid.value !== "" &&
                                        formData.mcafee_tenantid.isValid ===
                                          false
                                          ? "invalid"
                                          : ""
                                      }`}
                                      onChange={(e) =>
                                        onFieldChange(
                                          e.target.value,
                                          "mcafee_tenantid"
                                        )
                                      }
                                      onBlur={(e) =>
                                        onFieldBlur(
                                          validate1to10AlphaNumeric,
                                          e.target.value,
                                          "mcafee_tenantid"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="rightContent flex-1 px-1">
                              <div class="d-inline-flex w-100">
                                <div class="d-flex flex-column mr-2">
                                  <div class="specialTitle mb-1  mw-md-150 py-10px d-flex align-items-center">
                                    <p class="m-0 p-0 font-12">External ID</p>
                                  </div>
                                  <div class="specialTitle mt-1  mw-md-150 py-10px d-flex align-items-center">
                                    <p class="m-0 p-0 font-12">
                                      Workload Security Account ID
                                    </p>
                                  </div>
                                </div>

                                <div class="d-flex flex-column flex-1">
                                  <div class="flex-1 mb-1  bg-gray border-10  p-relative d-inline-flex align-items-center">
                                    <input
                                      type="text"
                                      class="form-control "
                                      placeholder=""
                                      value={formData.mcafee_externalid.value}
                                      className={`form-control ${
                                        formData.mcafee_externalid.value !==
                                          "" &&
                                        formData.mcafee_externalid.isValid ===
                                          false
                                          ? "invalid"
                                          : ""
                                      }`}
                                      onChange={(e) =>
                                        onFieldChange(
                                          e.target.value,
                                          "mcafee_externalid"
                                        )
                                      }
                                      onBlur={(e) =>
                                        onFieldBlur(
                                          validateExternalId,
                                          e.target.value,
                                          "mcafee_externalid"
                                        )
                                      }
                                    />
                                  </div>
                                  <div class="flex-1 mt-1  bg-gray border-10  p-relative d-inline-flex align-items-center">
                                    <input
                                      type="text"
                                      class="form-control "
                                      placeholder=""
                                      value={
                                        formData
                                          .mcafee_workloadsecurityaccountid
                                          .value
                                      }
                                      className={`form-control ${
                                        formData
                                          .mcafee_workloadsecurityaccountid
                                          .value !== "" &&
                                        formData
                                          .mcafee_workloadsecurityaccountid
                                          .isValid === false
                                          ? "invalid"
                                          : ""
                                      }`}
                                      onChange={(e) =>
                                        onFieldChange(
                                          e.target.value,
                                          "mcafee_workloadsecurityaccountid"
                                        )
                                      }
                                      onBlur={(e) =>
                                        onFieldBlur(
                                          validate1to10AlphaNumeric,
                                          e.target.value,
                                          "mcafee_workloadsecurityaccountid"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <div class="b-section formRow align-items-start px-0 my-1">
                        <div class="showRightContenBtn flex-1 px-1">
                          <div class=" w-100">
                            <div class="one form-line d-inline-flex align-items-center">
                              <label class="switch mb-0">
                                <input
                                  type="checkbox"
                                  checked={
                                    formData
                                      .trendmicro_deployintegrationwithtrendmicro
                                      .value === "yes"
                                  }
                                  onChange={(e) =>
                                    onFieldChange(
                                      e.target.checked ? "yes" : "no",
                                      "trendmicro_deployintegrationwithtrendmicro"
                                    )
                                  }
                                />
                                <span class="slider round"></span>
                              </label>
                              <span class="switchLabel">
                                Trend Micro Endpoint Protection
                              </span>
                            </div>
                          </div>
                        </div>

                        {formData.trendmicro_deployintegrationwithtrendmicro
                          .value === "yes" && (
                          <>
                            <div class="rightContent flex-1 px-1">
                              <div class="d-inline-flex w-100">
                                <div class="d-flex flex-column mr-2">
                                  <div class="specialTitle mw-md-150 py-10px mb-1  d-flex align-items-center">
                                    <p class="m-0 p-0 font-12">API Key</p>
                                  </div>
                                  <div class="specialTitle mw-md-150 py-10px mt-1  d-flex align-items-center">
                                    <p class="m-0 p-0 font-12">
                                      Source Account ID
                                    </p>
                                  </div>
                                </div>

                                <div class="d-flex flex-column flex-1">
                                  <div class="flex-1 mb-1 bg-gray border-10  p-relative d-inline-flex align-items-center">
                                    <button disabled={disabled}
                                      type="button"
                                      onClick={() => setShowPwd2(!showPwd2)}
                                      class="showableInput"
                                    ></button>
                                    <input
                                      type={showPwd2 ? "text" : "password"}
                                      class="form-control "
                                      placeholder=""
                                      value={formData.trendmicro_apikey.value}
                                      className={`form-control ${
                                        formData.trendmicro_apikey.value !==
                                          "" &&
                                        formData.trendmicro_apikey.isValid ===
                                          false
                                          ? "invalid"
                                          : ""
                                      }`}
                                      onChange={(e) =>
                                        onFieldChange(
                                          e.target.value,
                                          "trendmicro_apikey"
                                        )
                                      }
                                      onBlur={(e) =>
                                        onFieldBlur(
                                          validateTagKey,
                                          e.target.value,
                                          "trendmicro_apikey"
                                        )
                                      }
                                    />
                                  </div>
                                  <div class="flex-1 mt-1 bg-gray border-10  p-relative d-inline-flex align-items-center">
                                    <input
                                      type="text"
                                      class="form-control "
                                      placeholder=""
                                      value={
                                        formData.trendmicro_sourceaccountid
                                          .value
                                      }
                                      className={`form-control ${
                                        formData.trendmicro_sourceaccountid
                                          .value !== "" &&
                                        formData.trendmicro_sourceaccountid
                                          .isValid === false
                                          ? "invalid"
                                          : ""
                                      }`}
                                      onChange={(e) =>
                                        onFieldChange(
                                          e.target.value,
                                          "trendmicro_sourceaccountid"
                                        )
                                      }
                                      onBlur={(e) =>
                                        onFieldBlur(
                                          validateSourceAccountId,
                                          e.target.value,
                                          "trendmicro_sourceaccountid"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="rightContent flex-1 px-1">
                              <div class="d-inline-flex w-100">
                                <div class="d-flex flex-column mr-2">
                                  <div class="specialTitle mw-md-150 py-10px mt-md-0 mt-3 d-flex align-items-center">
                                    <p class="m-0 p-0 font-12">External ID</p>
                                  </div>
                                </div>

                                <div class="d-flex flex-column flex-1">
                                  <div class="flex-1  bg-gray border-10  p-relative d-inline-flex align-items-center">
                                    <input
                                      type="text"
                                      class="form-control "
                                      placeholder=""
                                      value={
                                        formData.trendmicro_externalid.value
                                      }
                                      className={`form-control ${
                                        formData.trendmicro_externalid.value !==
                                          "" &&
                                        formData.trendmicro_externalid
                                          .isValid === false
                                          ? "invalid"
                                          : ""
                                      }`}
                                      onChange={(e) =>
                                        onFieldChange(
                                          e.target.value,
                                          "trendmicro_externalid"
                                        )
                                      }
                                      onBlur={(e) =>
                                        onFieldBlur(
                                          validateExternalId,
                                          e.target.value,
                                          "trendmicro_externalid"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              </fieldset>
              {console.log(formData)}
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.SECURITY_VULNERABILITY_MANAGEMENT +
                          "/" +
                          projectId
                      )
                    }
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                      Vulnerability Management
                    </p>
                  </button>
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.SECURITY_SECRETS_MANAGEMENT + "/" + projectId
                      )
                    }
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">
                      Secrets Management
                    </p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EndpointProtection;
