import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import axios from 'axios';

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "../../../dashboard-common/DetailsTags";
import { getArrayValue, getBooleanValue } from "../../../dashboard-common/DashboardHelper";
import DetailsGrid from "../../../dashboard-common/DetailsGrid";

const dashboardName = "Security Hub Member Integrations";


const SubMenu = () => {
  const { projectId } = useParams();

  return (
    <>
      <h3>Security Hub</h3>
      <div className="otherTableTitles ">
        <h3 className="font-15">
          <Link className="text-color-gray" to={ROUTES.SECURITY_HUB_MEMBERS + "/" + projectId}>
           Members
          </Link></h3>
        <h3 className="font-15">
        <Link className="text-color-gray" to={ROUTES.SECURITY_HUB_STANDARDS + "/" + projectId}>
           Standards
          </Link>
        </h3>
        <h3 className="font-15 activeTable">       
          Integrations
        </h3>
      </div>
    </>
  );
};

const formatJSON = (strText) => {
  if(strText !== "")
  {
    JSON.stringify(strText, null, 4);
    return strText;
  }
  return strText;
 };

const Details = ({ close, selectedRow }) => {
  const [type, setType] = useState("Details");
 
  return (
    <div className="justify-content-center align-items-end account-detail">
      <div className="w-100 s-flex flex-column account-detail-area">
        <button className="exitAccount" onClick={() => close()}>
          <img src="../images/close.svg" />
        </button>
        <div className="">
          <ul className="account-detail-link m-0 p-0 d-inline-flex">
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Details" && "my_active"
              }`}
              onClick={() => setType("Details")} >
              Details
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "SubscriptionPolicy" && "my_active"
              }`}
              onClick={() => setType("SubscriptionPolicy")}
            >
              Subscription Policy
            </li>
            <li
              className={`bolgeler-btn my-active px-3 py-2 ${
                type === "Categories" && "my_active"
              }`}
              onClick={() => setType("Categories")}
            >
              Categories
            </li>
          </ul>
        </div>
        <div className="d-flex w-100 account-detail-container-area">
          <div
            className={`bolgeler-container w-100 row p-5 ${
              type !== "Details" && "inaktif"
            }`} >
            <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow && selectedRow.AccountId}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle"> AWS Region</p>
                <p className="m-0 p-0 account-title-subcontent">
                {selectedRow && selectedRow.Region}
                </p>
              </div>
              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Product Name</p>
                <p className="m-0 p-0 account-title-subcontent">
                {selectedRow && selectedRow.ProductName}
                </p>
              </div>

              <div className="d-inline-flex w-100 my-1">
                <p className="m-0 p-0 account-detail-subtitle">Company Name</p>
                <p className="m-0 p-0 account-title-subcontent">
                {selectedRow && selectedRow.CompanyName}
                </p>
              </div>
            </div>

            <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
               <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Description</p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow && selectedRow.Description}
                  </p>
                </div>
  
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Marketplace URL
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow && selectedRow.MarketplaceUrl}
                  </p>
                </div>

                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Activation URL
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                  {selectedRow && selectedRow.ActivationUrl}
                  </p>
                </div>              
              </div>
            </div>
         
          <div className={`bolgeler-container w-100 row p-5 ${type !== "SubscriptionPolicy" && "inaktif" }`} >
                <div className="col-12" >
                  <pre className="font-12"> 
                  {
                    
                    selectedRow.ProductSubscriptionResourcePolicy ?
                    formatJSON(selectedRow.ProductSubscriptionResourcePolicy) :
                    "No Subscription Policy found."
                      
                   }
                 </pre> 
                </div>
            </div>

          <div className={`bolgeler-container w-100 row p-5 ${type !== "Categories" && "inaktif" }`} >
                <div className="col-12" >
                <pre className=" font-12"> 
                  { selectedRow.Categories && (selectedRow.Categories.length > 0)? 
                   JSON.stringify(selectedRow.Categories, null, 2)    :
                    "No categories found." }
                 </pre> 
                </div>
            </div>
      
          </div>

           </div>
    </div>
  );
};

const Navigations = () => {
  const { projectId } = useParams();
  return (
    <Link
    className="btn-post btn-animation d-inline-flex"
    to={ROUTES.SECURITY_GUARDDUTY_MEMBERS + "/" + projectId}
  >
    <p className="m-0 p-0 mr-2 font-weight-bold">Guard Duty</p>
    <img src="../images/ribbon-designRight.svg" />
  </Link>
  );
};


const SecurityHubIntegrations = () => {
    const columns = useMemo(
      () => [
        {
          Header: "Account ID",
          id: "AccountID",
          accessor: "AccountId",
        },
        {
          Header: "AWS Region",
          id:"AWS Region",
          accessor: "Region",
        },
        {
          Header: "Product Name",
          accessor: "ProductName"
        },
        {
          Header: "Company Name",
          accessor: "CompanyName",
        },
        {
          Header: "Description",
          accessor: "Description"
        },
        {
          Header: "Product ARN",
          accessor: "ProductArn",
        }
      ],
      []
    );
  
    return Dashboard({
      domain: "Security",
      title: "Security",
      dashboardName,
      columns,
      Details,
      Navigations,
      configureRoute: ROUTES.SECURITY_COMPLIANCE_MANAGEMENT,
      designRoute: ROUTES.DESIGN_DATA_PROTECTION,
      SubMenu: SubMenu,
    });
  };

export default SecurityHubIntegrations;