import React from "react";

const RadioGroup = ({field, value, onChange, isLarge}) => (
  <>
    <label
      className={`createElementBtn btn btn-link btn-linkYes ${
        value === "yes" && "active"
      } ${isLarge ? "mw-70" : "h20"}`}
    >
      <input
        type="radio"
        name={field}
        checked={value === "yes"}
        onChange={() => onChange(field, "yes")}
      />{" "}
      YES
    </label>
    <label
      className={`createElementBtn btn btn-link btn-linkNo ${
        value === "no" && "active"
      } ${isLarge ? "mw-70" : "h20"}`}
    >
      <input
        type="radio"
        name={field}
        checked={value === "no"}
        onChange={() => onChange(field, "no")}
      />{" "}
      NO
    </label>
  </>
);

export default RadioGroup;
