import Auth from "@aws-amplify/auth";
import API from "@aws-amplify/api";

import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";

import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import gql from "graphql-tag";

class AwsAmplify {
  constructor() {
    Auth.configure({
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,

      region: process.env.REACT_APP_AWS_REGION,

      userPoolId: process.env.REACT_APP_USER_POOL_ID,

      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,

      cookieStorage: {
        domain: process.env.REACT_APP_DOMAIN,
        path: "/",
        secure: false,
        expires: 0.33, // in day
      },
    });

    API.configure({
      endpoints: [
        {
          name: "apiGateway",
          endpoint: process.env.REACT_APP_APIG_ENDPOINT,
          custom_header: async () => {
            return {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            };
          },
        },
        {
          name: "apiGatewayPublic",
          endpoint: process.env.REACT_APP_APIG_ENDPOINT,
        },
      ],
    });

    const appSyncClient = new AWSAppSyncClient({
      url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
      region: process.env.REACT_APP_AWS_REGION,
      auth: {
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        jwtToken: async () =>
          (await Auth.currentSession()).getIdToken().getJwtToken(),
      },
      disableOffline: true
    });

    //console.log(process.env.REACT_APP_AWS_REGION);
    console.log(process.env);

    this.auth = Auth;
    this.api = API;
    this.appSyncClient = appSyncClient;
  }

  signUp = (username, password, firstName, lastName) => {
    return this.auth.signUp({
      username,
      password,
      attributes: {
        email: username,
        given_name: firstName,
        family_name: lastName,
      },
    });
  };

  signIn = (username, password) => this.auth.signIn(username, password);

  confirmSignUp = (username, code) => this.auth.confirmSignUp(username, code);

  resendSignUp = (username) => this.auth.resendSignUp(username);

  signOut = () => this.auth.signOut();

  forgotPassword = (username) => this.auth.forgotPassword(username);

  forgotPasswordSubmit = (username, code, newPassword) =>
    this.auth.forgotPasswordSubmit(username, code, newPassword);

  resendSignUp = (username) => this.auth.resendSignUp(username);

  prelaunch = (projectId) =>
    this.api.get("apiGateway", "/prelaunch", {
      queryStringParameters: {
        projectId: projectId,
      },
    });

  launch = (projectId, credentials, action) =>
    this.api.post("apiGateway", "/launch", { body: { projectId, credentials, action } });

  saveCredentials = (projectId, credentials) =>
    this.api.post("apiGateway", "/savecredentials", { body: { projectId, credentials } });  

  saveProjectData = (data) =>
    this.api.post("apiGateway", "/projectdata", { body: data });

  loadProjectData = (projectId, prefix) =>
    this.api.get("apiGateway", "/projectdata", {
      queryStringParameters: {
        projectId: projectId,
        prefix: prefix,
      },
    });

  validateProjectData = (projectId, feature) =>
    this.api.post("apiGateway", "/projectdata/validate", {
      body: { ResourceProperties: { ProjectID: projectId, Feature: feature } },
    });

  initiateUpload = (projectId, type, name) =>
    this.api.post("apiGateway", "/projectdata/initiate-upload", {
      body: { projectId, type, name },
    });

  getAllRegions = () => this.api.get("apiGateway", "/regions/all");

  getDCLocations = (projectId) =>
    this.api.get("apiGateway", "/directconnect/locations", {
      queryStringParameters: {
        projectId: projectId,
      },
    });

  contactUs = (parameter) =>
    this.api.post("apiGatewayPublic", "/contactus", {
      body: parameter,
    });

  getClients = () =>
    this.appSyncClient.query({
      query: gql(queries.getClients),
      fetchPolicy: "no-cache",
    });

  createClient = (
    clientName,
    domainName,
    enableBuildAccess,
    enableDocAccess,
    clientAdminUserIDs
  ) =>
    this.appSyncClient.mutate({
      mutation: gql(mutations.createClient),
      variables: {
        input: {
          ClientName: clientName,
          DomainName: domainName,
          EnableBuildAccess: enableBuildAccess,
          EnableDocAccess: enableDocAccess,
          ClientAdminUserIDs: clientAdminUserIDs,
        },
      },
    });

  updateClientAccess = (clientId, field, value) =>
    this.appSyncClient.mutate({
      mutation: gql(mutations.updateClientAccess),
      variables: {
        ClientID: clientId,
        Field: field,
        Value: value,
      },
    });

  initiateLogoUpload = (name, type) =>
    this.appSyncClient.mutate({
      mutation: gql(mutations.initiateLogoUpload),
      variables: {
        name,
        type,
      },
    });

  findUsersByDomainName = (domainName) =>
    this.appSyncClient.query({
      query: gql(queries.findUsersByDomainName),
      fetchPolicy: "no-cache",
      variables: {
        domainName,
      },
    });

  revokeClientAdmin = (userId) =>
    this.appSyncClient.mutate({
      mutation: gql(mutations.revokeClientAdmin),
      variables: {
        userId,
      },
    });

  getClientByID = (ClientID) =>
    this.appSyncClient.query({
      query: gql(queries.getClientByID),
      variables: {
        ClientID,
      },
      fetchPolicy: "no-cache",
    });

  removeClient = (ClientID) =>
    this.appSyncClient.mutate({
      mutation: gql(mutations.removeClient),
      variables: {
        ClientID,
      },
    });

  updateClient = (
    clientId,
    clientName,
    enableDocAccess,
    enableBuildAccess,
    clientAdminUserIds,
    existingClientAdminUserIds
  ) =>
    this.appSyncClient.mutate({
      mutation: gql(mutations.updateClient),
      variables: {
        input: {
          ClientID: clientId,
          ClientName: clientName,
          EnableDocAccess: enableDocAccess,
          EnableBuildAccess: enableBuildAccess,
          ClientAdminUserIDs: clientAdminUserIds,
          ExistingClientAdminUserIDs: existingClientAdminUserIds,
        },
      },
    });

  getProjects = () =>
    this.appSyncClient.query({
      query: gql(queries.getProjects),
      fetchPolicy: "no-cache",
    });

  getCopyProjects = () =>
    this.appSyncClient.query({
      query: gql(`query getCopyProjects {
        getProjects {
          ProjectID
          ProjectName
          ClientName
          ProjectType
        }
      }`),
      fetchPolicy: "no-cache",
    });  

  createProject = (
    projectName,
    clientId,
    projectType,
    enableLaunchAccess,
    assignedUsers
  ) =>
    this.appSyncClient.mutate({
      mutation: gql(mutations.createProject),
      variables: {
        input: {
          ProjectName: projectName,
          ClientID: clientId,
          ProjectType: projectType,
          EnableLaunchAccess: enableLaunchAccess,
          AssignedUsers: assignedUsers,
        },
      },
    });

  getClientIDAndNamesAndDomainName = () =>
    this.appSyncClient.query({
      query: gql(`query getClientIDAndNamesAndDomainName {
        getClients {
          ClientID
          ClientName
          DomainName
        }
      }`),
      fetchPolicy: "no-cache",
    });

  findUsersByClientID = (clientId) =>
    this.appSyncClient.query({
      query: gql(queries.findUsersByClientID),
      fetchPolicy: "no-cache",
      variables: {
        clientId,
      },
    });

  updateProjectAccess = (projectId, field, value) =>
    this.appSyncClient.mutate({
      mutation: gql(mutations.updateProjectAccess),
      variables: {
        ProjectID: projectId,
        Field: field,
        Value: value,
      },
    });

  unassignUserFromProject = (projectId, userId) =>
    this.appSyncClient.mutate({
      mutation: gql(mutations.unassignUserFromProject),
      variables: {
        projectId,
        userId,
      },
    });

  getProjectByID = (ProjectID) =>
    this.appSyncClient.query({
      query: gql(queries.getProjectByID),
      variables: {
        ProjectID,
      },
      fetchPolicy: "no-cache",
    });

  removeProject = (ProjectID) =>
    this.appSyncClient.mutate({
      mutation: gql(mutations.removeProject),
      variables: {
        ProjectID,
      },
    });

  updateProject = (
    projectId,
    projectName,
    projectStatus,
    enableLaunchAccess,
    assignedUsers,
    getLatestCode
  ) =>
    this.appSyncClient.mutate({
      mutation: gql(mutations.updateProject),
      variables: {
        input: {
          ProjectID: projectId,
          ProjectName: projectName,
          ProjectStatus: projectStatus,
          EnableLaunchAccess: enableLaunchAccess,
          AssignedUsers: assignedUsers,
          GetLatestCode: getLatestCode
        },
      },
    });

  getMyProjects = () =>
    this.appSyncClient.query({
      query: gql(`query GetMyProjects {
        getMyProjects {
          projects {
            ProjectID
            ProjectName
            ProjectType
            ClientName
            ClientLogo
          }
          clientName
          clientLogo
        }
      }`),
      fetchPolicy: "no-cache",
    });

  getUsers = () =>
    this.appSyncClient.query({
      query: gql(queries.getUsers),
      fetchPolicy: "no-cache",
    });

  createUser = (firstName, lastName, email, privilege, assignedProjects) =>
    this.appSyncClient.mutate({
      mutation: gql(mutations.createUser),
      variables: {
        input: {
          FirstName: firstName,
          LastName: lastName,
          EmailID: email,
          Privilege: privilege,
          AssignedProjects: assignedProjects,
        },
      },
    });

  updateUser = (
    userId,
    firstName,
    lastName,
    email,
    privilege,
    assignedProjects
  ) =>
    this.appSyncClient.mutate({
      mutation: gql(mutations.updateUser),
      variables: {
        input: {
          UserID: userId,
          FirstName: firstName,
          LastName: lastName,
          EmailID: email,
          Privilege: privilege,
          AssignedProjects: assignedProjects,
        },
      },
    });

  removeUser = (UserID) =>
    this.appSyncClient.mutate({
      mutation: gql(mutations.removeUser),
      variables: {
        UserID,
      },
    });

  findProjectsByDomainName = (DomainName) =>
    this.appSyncClient.query({
      query: gql(queries.findProjectsByDomainName),
      fetchPolicy: "no-cache",
      variables: {
        DomainName,
      },
    });

  hasDocAccess = (ProjectID) =>
    this.appSyncClient.query({
      query: gql(queries.hasDocAccess),
      fetchPolicy: "no-cache",
      variables: {
        ProjectID,
      },
    });

  getUserByEmailID = (EmailID) =>
    this.appSyncClient.query({
      query: gql(queries.getUserByEmailID),
      fetchPolicy: "no-cache",
      variables: {
        EmailID,
      },
    });

  getProjectAccess = (ProjectID) =>
    this.appSyncClient.query({
      query: gql(queries.getProjectAccess),
      fetchPolicy: "no-cache",
      variables: {
        ProjectID,
      },
    });

  getProjectStatus = (projectId) =>
    this.appSyncClient.query({
      query: gql(queries.getProjectStatus),
      fetchPolicy: "no-cache",
      variables: {
        projectId,
      },
    });  

  getRemoveProjectInfoByID = (projectId) =>
    this.appSyncClient.query({
      query: gql(queries.getRemoveProjectInfoByID),
      fetchPolicy: "no-cache",
      variables: {
        projectId,
      },
    }); 
    
  copyProject = (sourceProjectId, targetProjectId, copyCode) =>
    this.appSyncClient.mutate({
      mutation: gql(mutations.copyProject),
      variables: {
        sourceProjectId,
        targetProjectId,
        copyCode
      },
    });  

  getProjectType = (ProjectID) =>
    this.appSyncClient.query({
      query: gql(queries.getProjectType),
      fetchPolicy: "no-cache",
      variables: {
        ProjectID,
      },
    });  

  getLogs = (nextToken) =>
    this.appSyncClient.query({
      query: gql(queries.getLogs),
      fetchPolicy: "no-cache",
      variables: {
        nextToken,
      },
    });
  
  log = (action, addInfo, clientId) =>
    this.appSyncClient.mutate({
      mutation: gql(mutations.log),
      variables: {
        action,
        addInfo,
        clientId
      },
    });  

  getComplianceManagement = () =>
    this.appSyncClient.query({
      query: gql(queries.getComplianceManagement),
      fetchPolicy: "no-cache",
    });
    
  getPatchManagement = () =>
    this.appSyncClient.query({
      query: gql(`query GetPatchManagement {
        getPatchManagement {
          APP {
            OS
            Data {
              Data
              Property
            }
          }
          OS {
            OS
            Data {
              Data
              Property
            }
          }
        }
      }`),
      fetchPolicy: "no-cache",
    });  

  getImages = () =>
    this.appSyncClient.query({
      query: gql(queries.getImages),
      fetchPolicy: "no-cache",
    });  

  getWorkspaceBundles = () =>
    this.appSyncClient.query({
      query: gql(queries.getWorkspaceBundles),
      fetchPolicy: "no-cache",
    });    
}

export default AwsAmplify;
