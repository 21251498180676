import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import RadioGroup from "../../RadioGroup";
import useServiceAccounts from "../../../../hooks/useServiceAccounts";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

const PREFIX = "/aws/governance/configrules";
const DATA = {
  "AWS Control Tower Detective Guardrails Conformance Pack":
    "AWS-Control-Tower-Detective-Guardrails",
  "Operational Best Practices for ABS CCIG 2.0 Material Workloads":
    "Operational-Best-Practices-for-ABS-CCIGv2-Material",
  "Operational Best Practices for ABS CCIG 2.0 Standard Workloads":
    "Operational-Best-Practices-for-ABS-CCIGv2-Standard",
  "Operational Best Practices for ACSC Essential 8":
    "Operational-Best-Practices-for-ACSC-Essential8",
  "Operational Best Practices for ACSC ISM":
    "Operational-Best-Practices-for-ACSC-ISM",
  "Operational Best Practices for AI and ML":
    "Operational-Best-Practices-for-AI-and-ML",
  "Operational Best Practices for Amazon DynamoDB":
    "Operational-Best-Practices-for-Amazon-DynamoDB",
  "Operational Best Practices for Amazon S3":
    "Operational-Best-Practices-for-Amazon-S3",
  "Operational Best Practices for APRA CPG 234":
    "Operational-Best-Practices-for-APRA-CPG-234",
  "Operational Best Practices for Asset Management":
    "Operational-Best-Practices-for-Asset-Management",
  "Operational Best Practices for AWS Identity And Access Management":
    "Operational-Best-Practices-for-AWS-Identity-and-Access-Management",
  "Operational Best Practices for AWS Well-Architected Framework Reliability Pillar":
    "Operational-Best-Practices-for-AWS-Well-Architected-Reliability-Pillar",
  "Operational Best Practices for AWS Well-Architected Framework Security Pillar":
    "Operational-Best-Practices-for-AWS-Well-Architected-Security-Pillar",
  "Operational Best Practices for BCP and DR":
    "Operational-Best-Practices-for-BCP-and-DR",
  "Operational Best Practices for BNM RMiT":
    "Operational-Best-Practices-for-BNM-RMiT",
  "Operational Best Practices for CIS AWS Foundations Benchmark v1.3 Level 1":
    "Operational-Best-Practices-for-CIS-AWS-FB-v1.3-Level1",
  "Operational Best Practices for CIS AWS Foundations Benchmark v1.3 Level 2":
    "Operational-Best-Practices-for-CIS-AWS-FB-v1.3-Level2",
  "Operational Best Practices for CIS Top 20":
    "Operational-Best-Practices-for-CIS-Top20",
  "Operational Best Practices for CMMC Level 1":
    "Operational-Best-Practices-for-CMMC-Level-1",
  "Operational Best Practices for CMMC Level 2":
    "Operational-Best-Practices-for-CMMC-Level-2",
  "Operational Best Practices for CMMC Level 3":
    "Operational-Best-Practices-for-CMMC-Level-3",
  "Operational Best Practices for CMMC Level 4":
    "Operational-Best-Practices-for-CMMC-Level-4",
  "Operational Best Practices for CMMC Level 5":
    "Operational-Best-Practices-for-CMMC-Level-5",
  "Operational Best Practices for Compute Services":
    "Operational-Best-Practices-for-Compute-Services",
  "Operational Best Practices for Data Resiliency":
    "Operational-Best-Practices-for-Data-Resiliency",
  "Operational Best Practices for Databases Services":
    "Operational-Best-Practices-for-Database-Services",
  "Operational Best Practices for Data Lakes and Analytics Services":
    "Operational-Best-Practices-for-Datalakes-and-Analytics-Services",
  "Operational Best Practices for EC2": "Operational-Best-Practices-for-EC2",
  "Operational Best Practices for Encryption and Key Management":
    "Operational-Best-Practices-for-Encryption-and-Keys",
  "Operational Best Practices for Esquema Nacional de Seguridad (ENS) Low":
    "Operational-Best-Practices-for-CCN-ENS-Low",
  "Operational Best Practices for Esquema Nacional de Seguridad (ENS) Medium":
    "Operational-Best-Practices-for-CCN-ENS-Medium",
  "Operational Best Practices for FDA Title 21 CFR Part 11":
    "Operational-Best-Practices-for-FDA-21CFR-Part-11",
  "Operational Best Practices for FedRAMP(Low)":
    "Operational-Best-Practices-for-FedRAMP-Low",
  "Operational Best Practices for FedRAMP(Moderate)":
    "Operational-Best-Practices-for-FedRAMP",
  "Operational Best Practices for FFIEC":
    "Operational-Best-Practices-for-FFIEC",
  "Operational Best Practices for HIPAA Security":
    "Operational-Best-Practices-for-HIPAA-Security",
  "Operational Best Practices for K-ISMS":
    "Operational-Best-Practices-for-KISMS",
  "Operational Best Practices for Load Balancing":
    "Operational-Best-Practices-for-Load-Balancing",
  "Operational Best Practices for Logging":
    "Operational-Best-Practices-for-Logging",
  "Operational Best Practices for Management and Governance Services":
    "Operational-Best-Practices-for-Management-Governance-Services",
  "Operational Best Practices for MAS Notice 655":
    "Operational-Best-Practices-for-MAS-Notice-655",
  "Operational Best Practices for MAS TRMG June 2013":
    "Operational-Best-Practices-for-MAS-TRMG",
  "Operational Best Practices for Monitoring":
    "Operational-Best-Practices-for-Monitoring",
  "Operational Best Practices for NBC TRMG":
    "Operational-Best-Practices-for-NBC-TRMG",
  "Operational Best Practices for NERC CIP":
    "Operational-Best-Practices-for-NERC-CIP",
  "Operational Best Practices for NCSC Cloud Security Principles":
    "Operational-Best-Practices-for-NCSC-CloudSec-Principles",
  "Operational Best Practices for NCSC Cyber Assesment Framework":
    "Operational-Best-Practices-for-NCSC-CAF",
  "Operational Best Practices for Networking and Content Delivery Services":
    "Operational-Best-Practices-for-Networking-Services",
  "Operational Best Practices for NIST 800-53 rev 4":
    "Operational-Best-Practices-for-NIST-800-53-rev-4",
  "Operational Best Practices for NIST 800 171":
    "Operational-Best-Practices-for-NIST-800-171",
  "Operational Best Practices for NIST CSF":
    "Operational-Best-Practices-for-NIST-CSF",
  "Operational Best Practices for NYDFS 23":
    "Operational-Best-Practices-for-NYDFS-23-NYCRR-500",
  "Operational Best Practices for PCI DSS 3.2.1":
    "Operational-Best-Practices-for-PCI-DSS",
  "Operational Best Practices for Publicly Accessible Resources":
    "Operational-Best-Practices-for-Publicly-Accessible-Resources",
  "Operational Best Practices for RBI Cyber Security Framework for UCBs":
    "Operational-Best-Practices-for-RBI-Basic-Cyber-Security-Framework",
  "Operational Best Practices for RBI MD-ITF":
    "Operational-Best-Practices-for-RBI-MasterDirection",
  "Operational Best Practices for Security, Identity, and Compliance Services":
    "Operational-Best-Practices-for-Security-Services",
  "Operational Best Practices for Serverless":
    "Operational-Best-Practices-for-Serverless",
  "Operational Best Practices for Storage Services":
    "Operational-Best-Practices-for-Storage-Services",
};

const getInitialFormData = (data) => {
  const initialFormData = {
    deploybaselineconfigrules: {
      value: data[`${PREFIX}/deploybaselineconfigrules`] || "no",
    },
    controltowerdetectiveguardrailsconformancepack: {
      value:
        data[`${PREFIX}/controltowerdetectiveguardrailsconformancepack`] ||
        "yes",
    },
    count: 0,
    delegatedconfigadminaccount: {
      value: data[`${PREFIX}/delegatedconfigadminaccount`] || "",
    },
  };

  const conformancepackslist = data[`${PREFIX}/conformancepackslist`]
  ? data[`${PREFIX}/conformancepackslist`].split(",")
  : [];

  initialFormData.count = conformancepackslist.length;

  for (const [key, value] of Object.entries(DATA)) {
    initialFormData[value] = {
      value: conformancepackslist.includes(value) ? "yes" : "no",
    };
  }

  return initialFormData;
};

const ConfigRules = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState(() => getInitialFormData({}));
  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);
  const awsAmplify = useAwsAmplify();
  const serviceAccounts = useServiceAccounts(projectId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        setIsLoading(false);

        setEmpty(result.data.empty);
        setFormData(convertBEToFE(result.data));
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);

        if (err.response.data.message === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (data) => {
    if (!data) {
      data = {};
    }

    const fe = getInitialFormData(data);

    return fe;
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/deploybaselineconfigrules`]: formData
        .deploybaselineconfigrules.value,
    };

    if (formData.deploybaselineconfigrules.value === "yes") {
      const conformancepackslist = [];

      for (const [key, value] of Object.entries(DATA)) {
        if(formData[value].value === "yes"){
          conformancepackslist.push(value);
        }
      }

      if(conformancepackslist.length > 0){
        be[`${PREFIX}/conformancepackslist`] = conformancepackslist.join(",");
      }

      //if (formData.enableconfigaggregation.value === "yes") {
      if (formData.delegatedconfigadminaccount.value !== "") {
        be[`${PREFIX}/delegatedconfigadminaccount`] =
          formData.delegatedconfigadminaccount.value;
      }
      //}
    }

    return be;
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  const updateRadio = (key, value) => {
    if (value === "yes" && formData.count >= 6) {
      setErrorMsg(
        "Not more than 6 conformance packs should be allowed to be selected"
      );
      return;
    }

    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        [key]: {
          value: { $set: value },
        },
        count: { $set: value === "yes" ? state.count+1 : state.count-1 },
      })
    );
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Governance</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Governance"
                isDirty={isDirty}
                dashboardRoute={ROUTES.GOVERNANCE_SERVICE_CONTROL}
                designRoute={ROUTES.DESIGN_GOVERNANCE}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div class="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                <div class="row d-flex align-items-center ">
                  <div class="col-xl-6 col-lg-5 col-md-4 pl-lg-0 px-2">
                    <h3 class="font-weight-bold">Config Rules</h3>
                  </div>
                  <div class="col-xl-6 col-lg-7 col-md-8 d-flex pr-0 align-items-center justify-content-end rightNavArea">
                    <div class="d-inline-flex yes-no-area align-items-center">
                      <div class="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                        Deploy Baseline Config Rules
                      </div>
                      <div class="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                        <RadioGroup
                          field="deploybaselineconfigrules"
                          value={formData.deploybaselineconfigrules.value}
                          onChange={(key, value) => {
                            setIsDirty(true);
                        
                            setFormData((state) =>
                              update(state, {
                                [key]: {
                                  value: { $set: value },
                                },
                              })
                            );
                          }}
                          isLarge={true}
                        ></RadioGroup>
                      </div>
                    </div>
                  </div>
                </div>

                {empty && (
                  <NoInitialData />
                )}
                {formData.deploybaselineconfigrules.value === "yes" && (
                  <div class="show-hide-content">
                    <div class="mainContentArea px-2  my-1 py-1 show-hide-content">
                      <div class=" align-items-center formRow  py-1 px-0 row">
                        <div class="col-md-12">
                          <div
                            class="rightContent w-100 flex-wrap"
                            style={{ display: "flex" }}
                          >
                            <div class="mr-3 my-1">
                              <span class="font-12 mr-2">
                                Delegated Config Administrator Account
                              </span>
                              <div class="w-250 bg-gray border-10  p-relative   d-inline-flex align-items-center">
                                <select
                                  class="form-control"
                                  value={
                                    formData.delegatedconfigadminaccount.value
                                  }
                                  onChange={(e) =>
                                    updateRadio(
                                      "delegatedconfigadminaccount",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">Select</option>
                                  {serviceAccounts.shared
                                    .concat(serviceAccounts.security)
                                    .map((sa, idxSa) => (
                                      <option value={sa} key={idxSa}>
                                        {sa}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                 
                    <div class="row  mainContentArea my-1 py-1">
                      <div class="col-12 px-3 my-1">
                        <span class="mb-0 font-12">Conformance Packs</span>
                      </div>

                      <div class="d-flex col-lg-6 col-12  pl-md-0 px-1 specialHalfColumn">
                        <div class="d-flex flex-column w-100  px-lg-2 px-1">
                          {Object.keys(DATA)
                            .filter((data, idx) => idx % 2 === 0)
                            .map((key) => (
                              <>
                                <div class=" d-inline-flex formRow  px-md-2 px-3 my-1 align-items-center justify-content-between">
                                  <div class="one form-line d-inline-flex align-items-center">
                                    <label class="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={
                                          formData[DATA[key]].value === "yes"
                                        }
                                        onChange={(e) => {
                                          updateRadio(
                                            DATA[key],
                                            e.target.checked ? "yes" : "no"
                                          );
                                        }}
                                      />
                                      <span class="slider round"></span>
                                    </label>
                                    <span class="switchLabel lh-md-1">
                                      {key}
                                    </span>
                                  </div>
                                </div>
                              </>
                            ))}
                        </div>
                      </div>

                      <div class="d-flex col-lg-6 col-12  pr-md-0 px-1 specialHalfColumn">
                        <div class="d-flex flex-column w-100 px-lg-2 px-1">
                          {Object.keys(DATA)
                            .filter((data, idx) => idx % 2 !== 0)
                            .map((key) => (
                              <>
                                <div class=" d-inline-flex formRow  px-md-2 px-3 my-1 align-items-center justify-content-between">
                                  <div class="one form-line d-inline-flex align-items-center">
                                    <label class="switch mb-0">
                                      <input
                                        type="checkbox"
                                        checked={
                                          formData[DATA[key]].value === "yes"
                                        }
                                        onChange={(e) => {
                                          updateRadio(
                                            DATA[key],
                                            e.target.checked ? "yes" : "no"
                                          );
                                        }}
                                      />
                                      <span class="slider round"></span>
                                    </label>
                                    <span class="switchLabel lh-md-1">
                                      {key}
                                    </span>
                                  </div>
                                </div>
                              </>
                            ))}
                         
                        </div>
                      </div>
                    </div>

                   </div>
                )}
              </div>
              </fieldset>
              {console.log(formData)}
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.GOVERNANCE_SERVICE_CONTROL_POLICIES +
                          "/" +
                          projectId
                      )
                    }
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                      Service Control Policies
                    </p>
                  </button>
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.GOVERNANCE_BUDGET_ENFORCEMENT + "/" + projectId
                      )
                    }
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">
                      Budget Enforcement
                    </p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfigRules;
