import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import RadioGroup from "../../RadioGroup";
import { validateSpendingLimit } from "../../../../helpers/validator";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

const PREFIX = "/aws/governance/budgets";
const DEVELOPERACCOUNTS_PREFIX =
  "/aws/accountframework/developeraccounts/developeraccountslist";

const getInitialFormData = (data, developerAccounts) => {
  const initialFormData = {
    deploybudgetenforcementproduct: {
      value: data[`${PREFIX}/deploybudgetenforcementproduct`] || "no",
    },
    setfixedspendinglimitonsandboxaccounts: {
      value: data[`${PREFIX}/setfixedspendinglimitonsandboxaccounts`] || "yes",
    },
    developeraccounts: developerAccounts
      ? developerAccounts.map((account) => {
          return {
            checked: false,
            account: account,
            spendinglimit: {
              value: "",
              isValid: false,
            },
          };
        })
      : [],
  };

  const developeraccountlist = data[`${PREFIX}/developeraccountlist`]
    ? data[`${PREFIX}/developeraccountlist`].split(",")
    : [];

  initialFormData.developeraccounts.forEach((da) => {
    if (developeraccountlist.indexOf(da.account) !== -1) {
      da.checked = true;
    }

    if (data[`${PREFIX}/${da.account}/spendinglimit`]) {
      da.spendinglimit.value = data[`${PREFIX}/${da.account}/spendinglimit`];
      da.spendinglimit.isValid = true;
    }
  });

  return initialFormData;
};

const BudgetEnforcement = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);
  const awsAmplify = useAwsAmplify();
  const [formData, setFormData] = useState(() => getInitialFormData({}));

  const [empty, setEmpty] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);

        const developerAccountsData = await awsAmplify.loadProjectData(
          projectId,
          DEVELOPERACCOUNTS_PREFIX
        );
        setIsLoading(false);

        setEmpty(result.data.empty);

        setFormData(
          convertBEToFE(
            result.data,
            developerAccountsData.data &&
              developerAccountsData.data[DEVELOPERACCOUNTS_PREFIX]
              ? developerAccountsData.data[DEVELOPERACCOUNTS_PREFIX].split(",")
              : []
          )
        );
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);

        if (err.response.data.message === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (data, developerAccounts) => {
    if (!data) {
      data = {};
    }

    const fe = getInitialFormData(data, developerAccounts);

    return fe;
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/deploybudgetenforcementproduct`]: formData
        .deploybudgetenforcementproduct.value,
    };

    if (formData.deploybudgetenforcementproduct.value === "yes") {
      be[`${PREFIX}/setfixedspendinglimitonsandboxaccounts`] =
        formData.setfixedspendinglimitonsandboxaccounts.value;

      if (formData.setfixedspendinglimitonsandboxaccounts.value === "yes") {
        const developeraccountlist = [];
        formData.developeraccounts.forEach((da) => {
          if (da.checked) {
            developeraccountlist.push(da.account);

            if (da.spendinglimit.isValid) {
              be[`${PREFIX}/${da.account}/spendinglimit`] =
                da.spendinglimit.value;
            }
          }
        });

        if (developeraccountlist.length > 0) {
          be[`${PREFIX}/developeraccountlist`] = developeraccountlist.join(",");
        }
      }
    }

    return be;
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  const updateRadio = (key, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        [key]: {
          value: { $set: value },
        },
      })
    );
  };

  const updateArray = (idxItem, key, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        developeraccounts: {
          [idxItem]: {
            [key]: {
              $set: value,
            },
          },
        },
      })
    );
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Network</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Network"
                isDirty={isDirty}
                dashboardRoute={ROUTES.GOVERNANCE_SERVICE_CONTROL}
                designRoute={ROUTES.DESIGN_GOVERNANCE}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div class="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                <div class="row d-flex align-items-center ">
                  <div class="col-xl-6 col-lg-5 col-md-4 pl-lg-0 px-2">
                    <h3 class="font-weight-bold">Budget Enforcement</h3>
                  </div>
                  <div class="col-xl-6 col-lg-7 col-md-8 d-flex pr-0 align-items-center justify-content-end rightNavArea">
                    <div class="d-inline-flex yes-no-area align-items-center">
                      <div class="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                        Deploy Budget Enforcement Product
                      </div>
                      <div class="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                        <RadioGroup
                          field="deploybudgetenforcementproduct"
                          value={formData.deploybudgetenforcementproduct.value}
                          onChange={updateRadio}
                          isLarge={true}
                        ></RadioGroup>
                      </div>
                    </div>
                  </div>
                </div>

                {empty && (
                  <NoInitialData />
                )}
                {formData.deploybudgetenforcementproduct.value === "yes" && (
                  <div class="show-hide-content">
                    <div class="row  mainContentArea py-1 my-1">
                      <div class="col-12 d-flex pr-0 align-items-center my-1">
                        <div class="d-inline-flex yes-no-area align-items-center">
                          <div class="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                            Set Fixed Spending Limit on Developer Sandbox
                            Accounts
                          </div>
                          <div class="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center  mr-0">
                            <RadioGroup
                              field="setfixedspendinglimitonsandboxaccounts"
                              value={
                                formData.setfixedspendinglimitonsandboxaccounts
                                  .value
                              }
                              onChange={updateRadio}
                            ></RadioGroup>
                          </div>
                        </div>
                      </div>
                      {formData.setfixedspendinglimitonsandboxaccounts.value ===
                        "yes" && (
                        <div class="show-hide-sub-content px-0">
                          <div class="d-flex  col-12  pl-md-0 px-1 specialHalfColumn">
                            <div class="d-flex flex-column w-100  px-3">
                              {formData.developeraccounts.map(
                                (item, idxItem) => (
                                  <div
                                    key={idxItem}
                                    class=" d-inline-flex formRow my-1  px-md-2 px-3  align-items-center justify-content-between"
                                  >
                                    <div class="showRightContenBtn one flex-md-1 my-lg-0 my-1 form-line  d-inline-flex align-items-center">
                                      <label class="switch mb-0">
                                        <input
                                          type="checkbox"
                                          checked={item.checked}
                                          onChange={(e) =>
                                            updateArray(
                                              idxItem,
                                              "checked",
                                              e.target.checked
                                            )
                                          }
                                        />
                                        <span class="slider round"></span>
                                      </label>
                                      <span class="switchLabel ">
                                        {item.account}
                                      </span>
                                    </div>

                                    <div class="rightContentArea flex-md-1 form-line two">
                                      {item.checked && (
                                        <div class="rightContent">
                                          <div class="d-inline-flex my-lg-0 my-1">
                                            <div class="d-flex align-items-center green-style text-nowrap mr-2">
                                              Fixed Spending Limit/month{" "}
                                            </div>
                                            <input
                                              type="text"
                                              class="form-control w-md-250"
                                              placeholder="E.g. $100 "
                                              value={item.spendinglimit.value}
                                              onChange={(e) => {
                                                const value = e.target.value;

                                                setIsDirty(true);
                                                setErrorMsg("");

                                                setFormData((state) =>
                                                  update(state, {
                                                    developeraccounts: {
                                                      [idxItem]: {
                                                        spendinglimit: {
                                                          value: {
                                                            $set: value,
                                                          },
                                                        },
                                                      },
                                                    },
                                                  })
                                                );
                                              }}
                                              onBlur={(e) => {
                                                const value = e.target.value;

                                                if (value !== "") {
                                                  const errorMsg = validateSpendingLimit(
                                                    value
                                                  );

                                                  setFormData((state) =>
                                                    update(state, {
                                                      developeraccounts: {
                                                        [idxItem]: {
                                                          spendinglimit: {
                                                            isValid: {
                                                              $set:
                                                                errorMsg ===
                                                                undefined,
                                                            },
                                                          },
                                                        },
                                                      },
                                                    })
                                                  );

                                                  if (errorMsg) {
                                                    setErrorMsg(errorMsg);
                                                  }
                                                } else {
                                                  setFormData((state) =>
                                                    update(state, {
                                                      developeraccounts: {
                                                        [idxItem]: {
                                                          spendinglimit: {
                                                            isValid: {
                                                              $set: false,
                                                            },
                                                          },
                                                        },
                                                      },
                                                    })
                                                  );
                                                }
                                              }}
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              </fieldset>
              {console.log(formData)}
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(ROUTES.GOVERNANCE_CONFIG_RULES + "/" + projectId)
                    }
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                      Config Rules
                    </p>
                  </button>
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(ROUTES.GOVERNANCE_AWS_LIMITS_MONITOR + "/" + projectId)
                    }
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">AWS Limits Monitor</p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default BudgetEnforcement;
