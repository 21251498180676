import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import {
  validateAccountName,
  validateAccountEmailId,
  validate1to30AlpheNumHyphen,
} from "../../../../helpers/validator";
import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import { getError } from "../../../../helpers/errorHelper";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

//const projectId = "google_1";
const PREFIX = "/gcp/accountframework/applicationaccounts";

const GCPApplicationAccounts = () => {
  const {projectId} = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    createapplicationaccounts: {
      value: "yes",
    },
    array: [],
    stages: [],
  });

  const [showEmpty, setShowEmpty] = useState(false);
  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const awsAmplify = useAwsAmplify();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);
        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        setIsLoading(false);

        console.log(result);

        if (result.data) {
          setEmpty(result.data.empty);

          //if(!result.data.empty){

          const fe = convertBEToFE(result.data);

          if (fe) {
            setShowEmpty(false);
            setFormData(fe);
          } else {
            setShowEmpty(true);
          }
        //}
        } else {
          setShowEmpty(true);

          setFormData({
            createapplicationaccounts: {
              value: "yes",
            },
            array: [],
            stages: [],
          });
        }
      } catch (err) {
        console.log(err);
        setIsLoading(false);

        const errorMsg = getError(err, setErrorMsg);

        if(errorMsg === "You have no access"){
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);
      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (data) => {
    const sdlcstageslist =
      data["/gcp/accountframework/environmentstrategy/sdlcstageslist"];

    if (sdlcstageslist) {
      const stages = sdlcstageslist.split(",");

      const fe = {
        createapplicationaccounts: {
          value: "yes",
        },
        array: [],
        stages: stages,
      };

      if (data[`${PREFIX}/createapplicationaccounts`]) {
        fe.createapplicationaccounts.value =
          data[`${PREFIX}/createapplicationaccounts`];

        if (data[`${PREFIX}/createapplicationaccounts`] === "yes") {
          if (data[`${PREFIX}/applicationslist`]) {
            const appNames = data[`${PREFIX}/applicationslist`].split(",");

            for (const appName of appNames) {
              const app = createApp(stages);

              app.appName.value = appName;
              app.appName.isValid = true;

              if (data[`${PREFIX}/${appName}/businessunitname`]) {
                app.buName.value =
                  data[`${PREFIX}/${appName}/businessunitname`];
                app.buName.isValid = true;
              }

              if (data[`${PREFIX}/${appName}/accountslist`]) {
                const stageNames = data[
                  `${PREFIX}/${appName}/accountslist`
                ].split(",");

                for (const stageName of stageNames) {
                  // In the case that user remove stage in Enviroment Strategy
                  if (stages.indexOf(stageName) === -1) {
                    continue;
                  }

                  app[stageName].value = true;

                  if (data[`${PREFIX}/${appName}/${stageName}/accountname`]) {
                    app[`${stageName}-name`].value =
                      data[`${PREFIX}/${appName}/${stageName}/accountname`];
                    app[`${stageName}-name`].isValid = true;
                  }

                  if (
                    data[`${PREFIX}/${appName}/${stageName}/accountemailid`]
                  ) {
                    app[`${stageName}-email`].value =
                      data[`${PREFIX}/${appName}/${stageName}/accountemailid`];
                    app[`${stageName}-email`].isValid = true;
                  }
                }

                for(const stage of stages){
                  if(stageNames.indexOf(stage) === -1){
                    app[stage].value = false;
                  }
                }
              }

              fe.array.push(app);
            }
          }
        }
      } else {
        fe.array.push(createApp(stages));

        console.log(fe.array);
      }

      return fe;
    } else {
      return null;
    }
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/createapplicationaccounts`]: formData
        .createapplicationaccounts.value,
    };

    if (formData.createapplicationaccounts.value === "yes") {
      const applicationslist = [];

      const buSet = new Set();

      for (const item of formData.array) {
        if (item.appName.isValid) {
          applicationslist.push(item.appName.value);

          const accountslist = [];

          if (item.buName.isValid) {
            be[`${PREFIX}/${item.appName.value}/businessunitname`] =
              item.buName.value;

            buSet.add(item.buName.value);  
          }

          for (const stage of formData.stages) {
            if (item[`${stage}`].value) {
              accountslist.push(stage);

              if (item[`${stage}-name`].isValid) {
                be[`${PREFIX}/${item.appName.value}/${stage}/accountname`] =
                  item[`${stage}-name`].value;
              }
              if (item[`${stage}-email`].isValid) {
                be[`${PREFIX}/${item.appName.value}/${stage}/accountemailid`] =
                  item[`${stage}-email`].value;
              }
            }
          }

          if (accountslist.length > 0) {
            be[
              `${PREFIX}/${item.appName.value}/accountslist`
            ] = accountslist.join(",");
          }
        }
      }

      if (applicationslist.length > 0) {
        be[`${PREFIX}/applicationslist`] = applicationslist.join(",");
      }

      console.log(buSet);
      console.log(buSet.size);
      if(buSet.size > 0){
        be[`${PREFIX}/bulist`] = [...buSet].join(",");
      }
    }
    else{
      be[`${PREFIX}/applicationslist`] = "none";
    }

    return be;
  };

  const createApp = (stages) => {
    const app = {
      appName: {
        value: "",
        isValid: false,
      },
      buName: {
        value: "",
        isValid: false,
      },
    };

    for (const stage of stages) {
      app[`${stage}`] = {
        value: true,
      };
      app[`${stage}-name`] = {
        value: "",
        isValid: false,
      };
      app[`${stage}-email`] = {
        value: "",
        isValid: false,
      };
    }

    return app;
  };

  const addApp = () => {
    setIsDirty(true);

    setFormData((state) => update(state, {array: { $push: [createApp(formData.stages)] }}));
      };

  const removeApp = (idx) => {
    setIsDirty(true);

    setFormData((state) => update(state, {array: { $splice: [[idx, 1]] }}));
      };

  const updateArrayValue = (idx, key, value) => {
    setIsDirty(true);
                                          setErrorMsg("");

    setFormData((state) => update(state, {array: {
          [idx]: {
            [key]: {
              value: { $set: value },
            },
          },
        }}));
      };

  const updateArrayIsValid = (validateFunc, value, idx, key) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      setFormData((state) =>
        update(state, {
          array: {
            [idx]: {
              [key]: {
                isValid: { $set: errorMsg === undefined },
              },
            },
          },
        })
      );

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      setFormData((state) =>
        update(state, {
          array: {
          [idx]: {
            [key]: {
                isValid: { $set: false },
              },
            },
          },
        })
      );
    }
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Account Framework</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
          <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                isDirty={isDirty}
                dashboardRoute={ROUTES.GCP_ORGANIZATIONAL_UNITS}
                designRoute={ROUTES.GCP_DESIGN_ACCOUNT_FRAMEWORK}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 ">
                <div className="row d-flex align-items-center">
                  <div className="col-xl-4 col-lg-4 col-md-4 pl-lg-0 px-2">
                    <h3>Application Accounts</h3>
                  </div>
                  {!showEmpty && (
                    <div className="col-xl-8 col-lg-8 col-md-8 d-flex align-items-center justify-content-end rightNavArea px-0">
                      <div className="d-inline-flex yes-no-area align-items-center">
                        <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12 text-nowrap">
                          Create Application Accounts
                        </div>
                        <div className=" btn-group btn-group-toggle btn-group-radio mw-100 ">
                          <label
                            className={`createElementBtn btn btn-link mw-70 btn-linkYes ${
                              formData.createapplicationaccounts.value === "yes"
                                ? "active"
                                : ""
                            }`}
                          >
                            <input
                              type="radio"
                              name="createapplicationaccounts"
                              value="yes"
                              checked={
                                formData.createapplicationaccounts.value ===
                                "yes"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                setFormData((state) => {
                                  return {
                                    ...state,
                                    createapplicationaccounts: {
                                      value: "yes",
                                    },
                                  };
                                });
                              }}
                            />{" "}
                            YES
                          </label>
                          <label
                            className={`dontCreateElementBtn btn btn-link mw-70 btn-linkNo ${
                              formData.createapplicationaccounts.value === "no"
                                ? "active"
                                : ""
                            }`}
                          >
                            <input
                              type="radio"
                              name="createapplicationaccounts"
                              value="no"
                              checked={
                                formData.createapplicationaccounts.value ===
                                "no"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                setFormData((state) => {
                                  return {
                                    ...state,
                                    createapplicationaccounts: {
                                      value: "no",
                                    },
                                  };
                                });
                              }}
                            />{" "}
                            NO
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {empty && (
                    <NoInitialData />
                )}
                {showEmpty ? (
                  <span className="warning">
                    This section depends on the SDLC stages information in
                    Environment Strategy section that is yet to be provided
                  </span>
                ) : (
                  <>
                    {formData.createapplicationaccounts.value === "yes" && (
                      <div className="show-hide-content">
                        {formData.array.map((item, idx) => {
                          return (
                            <div
                              className="d-flex flex-column mainContentArea  pl-3 pr-3 my-1 py-1"
                              key={idx}
                            >
                              <div className=" d-inline-flex row topOfFormRow  px-md-2  my-1 align-items-center ">
                                <div className="col-lg-4 col-md-5 col-12 d-inline-flex align-items-center px-0">
                                  <div className="d-inline-flex mr-3 align-items-center">
                                    <div className=" my-lg-0 my-1 mr-2">
                                      <h2 className="mb-0 text-nowrap font-12">
                                        Application {idx + 1} Name
                                      </h2>
                                    </div>
                                    <div className="my-lg-0 my-1">
                                      <input
                                        type="text"
                                        className={`form-control gray ${
                                          item.appName.value !== "" &&
                                          !item.appName.isValid &&
                                          "invalid"
                                        } `}
                                        placeholder={"E.g App " + (idx + 1)}
                                        value={
                                          (item.appName &&
                                            item.appName.value) ||
                                          ""
                                        }
                                        onChange={(e) => {
                                          const value = e.target.value;

                                          for (const [
                                            idxOtherItem,
                                            otherItem,
                                          ] of formData.array.entries()) {
                                            if (idx !== idxOtherItem) {
                                              if (
                                                otherItem.appName.isValid &&
                                                otherItem.appName.value !== ""
                                              ) {
                                                if (otherItem.appName.value.toLowerCase() === value.toLowerCase()) {
                                                  setErrorMsg(
                                                    "Application name must be unique"
                                                  );
                                                  return false;
                                                }
                                              }
                                            }
                                          }
                                          updateArrayValue(
                                            idx,
                                            "appName",
                                            e.target.value
                                          );
                                        }}
                                        onBlur={(e) => {
                                          updateArrayIsValid(
                                            validate1to30AlpheNumHyphen,
                                            e.target.value,
                                            idx,
                                            "appName"
                                          );
                                          /* const value = e.target.value;

                                          if (value !== "") {
                                            const errorMsg = validate1to30AlpheNumHyphen(
                                              value
                                            );

                                            if (errorMsg) {
                                              setErrorMsg(errorMsg);
                                              updateArrayIsValid(
                                                idx,
                                                "appName",
                                                false
                                              );
                                            } else {
                                              for (const [
                                                itemIdx,
                                                item,
                                              ] of formData.array.entries()) {
                                                console.log(
                                                  `${itemIdx}, ${item.appName}`
                                                );
                                                if (
                                                  itemIdx !== idx &&
                                                  item.appName.value === value
                                                ) {
                                                  updateArrayIsValid(
                                                    idx,
                                                    "appName",
                                                    false
                                                  );
                                                  setErrorMsg(
                                                    `${item.appName.value} must be unique`
                                                  );
                                                  return;
                                                }
                                              }

                                              updateArrayIsValid(
                                                idx,
                                                "appName",
                                                true
                                              );
                                            }
                                          } else {
                                            updateArrayIsValid(
                                              idx,
                                              "appName",
                                              false
                                            );
                                          } */
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-5 col-12 d-inline-flex align-items-center px-0">
                                  <div className="d-inline-flex mr-3 align-items-center">
                                    <div className=" my-lg-0 my-1 mr-2">
                                      <h2 className="mb-0 text-nowrap font-12">
                                        Business Unit Name
                                      </h2>
                                    </div>
                                    <div className="my-lg-0 my-1">
                                      <input
                                        type="text"
                                        disabled={!item.appName.isValid}
                                        className={`form-control gray ${
                                          item.buName.value !== "" &&
                                          !item.buName.isValid &&
                                          "invalid"
                                        } `}
                                        placeholder="E.g. Corporate Service"
                                        value={
                                          (item.buName && item.buName.value) ||
                                          ""
                                        }
                                        onChange={(e) => {
                                          //setIsDirty(true);
                                          //setErrorMsg("");

                                          updateArrayValue(
                                            idx,
                                            "buName",
                                            e.target.value
                                          );
                                        }}
                                        onBlur={(e) => {
                                          updateArrayIsValid(
                                            validate1to30AlpheNumHyphen,
                                            e.target.value,
                                            idx,
                                            "buName"
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-2 col-1  d-flex align-items-center my-lg-0 my-1 justify-content-end px-0">
                                  <button disabled={disabled}
                                    type="button"
                                    className="closeFormRow closeFormRowBtn four"
                                    onClick={() => removeApp(idx)}
                                  >
                                    <img src="../images/bigfontclose.svg" />
                                  </button>
                                </div>

                                <button disabled={disabled}
                                  type="button"
                                  className="closeFormRowMobil closeFormRowBtn my-lg-0 my-1 four"
                                  onClick={() => removeApp(idx)}
                                >
                                  <img src="../images/bigfontclose.svg" />
                                </button>
                              </div>

                              {formData.stages.map((stage, stageIdx) => {
                                return (
                                  <div
                                    className=" d-inline-flex row formRow px-md-2 px-3 my-1 align-items-center flex-wrap"
                                    key={stageIdx}
                                  >
                                    <div className="form-line my-lg-0 my-1 col-xl-2 col-lg-2 col-12 d-inline-flex align-items-center">
                                      <label className="switch mb-0">
                                        <input
                                          disabled={!item.appName.isValid}
                                          type="checkbox"
                                          checked={
                                            item[`${stage}`] &&
                                            item[`${stage}`].value
                                          }
                                          onChange={(e) => {
                                            //setIsDirty(true);

                                            updateArrayValue(
                                              idx,
                                              `${stage}`,
                                              e.target.checked
                                            );

                                            if(e.target.checked === false){
                                              updateArrayValue(
                                                idx,
                                                `${stage}-name`,
                                                ""
                                              );

                                              updateArrayValue(
                                                idx,
                                                `${stage}-email`,
                                                ""
                                              );

                                            }
                                          }}
                                        />
                                        <span className="slider round"></span>
                                      </label>
                                      <span className="switchLabel ">
                                        {stage}
                                      </span>
                                    </div>
                                    {/* {item[`${stage}`].value === true && (
                                      <> */}
                                        <div className="d-inline-flex my-lg-0 my-1 form-line col-xl-5 col-lg-5 col-12" style={item[`${stage}`] && item[`${stage}`].value === true ? {}:{visibility: "hidden"}} >
                                          <div className="d-flex align-items-center mx-xl-5 mx-lg-3 green-style text-nowrap">
                                            Account Name
                                          </div>
                                          <input
                                            disabled={!item.appName.isValid}
                                            type="text"
                                            className={`form-control w-200 ${
                                              item[`${stage}-name`].value !==
                                                "" &&
                                              !item[`${stage}-name`].isValid &&
                                              "invalid"
                                            } `}
                                            placeholder={`App${
                                              idx + 1
                                            }-${stage}`}
                                            value={
                                              (item[`${stage}-name`] &&
                                                item[`${stage}-name`].value) ||
                                              ""
                                            }
                                            onChange={(e) => {
                                              const value = e.target.value;

                                          for (const [
                                            idxOtherItem,
                                            otherItem,
                                          ] of formData.array.entries()) {
                                            if (idx !== idxOtherItem) {

                                              for(const stage of formData.stages){
                                                if (
                                                  otherItem[`${stage}-name`].isValid &&
                                                  otherItem[`${stage}-name`].value !== ""
                                                ) {
                                                  if (otherItem[`${stage}-name`].value.toLowerCase() === value.toLowerCase()) {
                                                    setErrorMsg(
                                                      "Account name must be unique"
                                                    );
                                                    return false;
                                                  }
                                                }
                                              }
                                            }
                                            else{
                                              for(const otherStage of formData.stages){
                                                if (
                                                  otherStage !== stage && 
                                                  item[`${otherStage}-name`].isValid &&
                                                  item[`${otherStage}-name`].value !== ""
                                                ) {
                                                  if (item[`${otherStage}-name`].value.toLowerCase() === value.toLowerCase()) {
                                                    setErrorMsg(
                                                      "Account name must be unique"
                                                    );
                                                    return false;
                                                  }
                                                }
                                              }

                                            }
                                          }

                                              updateArrayValue(
                                                idx,
                                                `${stage}-name`,
                                                value
                                              );
                                            }}
                                            onBlur={(e) => {
                                              updateArrayIsValid(
                                                validateAccountName,
                                                e.target.value,
                                                idx,
                                                `${stage}-name`
                                              );
                                              /* const value = e.target.value;

                                              if (value !== "") {
                                                const errorMsg = validateAccountName(
                                                  value
                                                );

                                                updateArrayIsValid(
                                                  idx,
                                                  `${stage}-name`,
                                                  errorMsg === undefined
                                                );

                                                if (errorMsg) {
                                                  setErrorMsg(errorMsg);
                                                }
                                              } else {
                                                updateArrayIsValid(
                                                  idx,
                                                  `${stage}-name`,
                                                  false
                                                );
                                              } */
                                            }}
                                          />
                                        </div>
                                        <div className="d-inline-flex my-lg-0 my-1 form-line col-xl-5 col-lg-5 col-12 " style={item[`${stage}`] && item[`${stage}`].value === true ? {}:{visibility: "hidden"}} >
                                          <div className="d-flex align-items-center mx-xl-5 mx-lg-3">
                                            <span>Account Email Id</span>
                                          </div>
                                          <input
                                            type="text"
                                            disabled={!item.appName.isValid}
                                            className={`form-control w-200 ${
                                              item[`${stage}-email`].value !==
                                                "" &&
                                              !item[`${stage}-email`].isValid &&
                                              "invalid"
                                            } `}
                                            placeholder={`app${
                                              idx + 1
                                            }-${stage}@client.com`}
                                            value={
                                              (item[`${stage}-email`] &&
                                                item[`${stage}-email`].value) ||
                                              ""
                                            }
                                            onChange={(e) => {
                                              const value = e.target.value;

                                          for (const [
                                            idxOtherItem,
                                            otherItem,
                                          ] of formData.array.entries()) {
                                            if (idx !== idxOtherItem) {

                                              for(const stage of formData.stages){
                                                if (
                                                  otherItem[`${stage}-email`].isValid &&
                                                  otherItem[`${stage}-email`].value !== ""
                                                ) {
                                                  if (otherItem[`${stage}-email`].value.toLowerCase() === value.toLowerCase()) {
                                                    setErrorMsg(
                                                      "Account email must be unique"
                                                    );
                                                    return false;
                                                  }
                                                }
                                              }
                                            }
                                            else{
                                              for(const otherStage of formData.stages){
                                                if (
                                                  otherStage !== stage && 
                                                  item[`${otherStage}-email`].isValid &&
                                                  item[`${otherStage}-email`].value !== ""
                                                ) {
                                                  if (item[`${otherStage}-email`].value.toLowerCase() === value.toLowerCase()) {
                                                    setErrorMsg(
                                                      "Account email must be unique"
                                                    );
                                                    return false;
                                                  }
                                                }
                                              }

                                            }
                                          }

                                              updateArrayValue(
                                                idx,
                                                `${stage}-email`,
                                                e.target.value
                                              );
                                            }}
                                            onBlur={(e) => {
                                              updateArrayIsValid(
                                                validateAccountEmailId,
                                                e.target.value,
                                                idx,
                                                `${stage}-email`
                                              );

                                              /* const value = e.target.value;

                                              if (value !== "") {
                                                const errorMsg = validateAccountEmailId(
                                                  value
                                                );

                                                updateArrayIsValid(
                                                  idx,
                                                  `${stage}-email`,
                                                  errorMsg === undefined
                                                );

                                                if (errorMsg) {
                                                  setErrorMsg(errorMsg);
                                                }
                                              } else {
                                                updateArrayIsValid(
                                                  idx,
                                                  `${stage}-email`,
                                                  false
                                                );
                                              } */
                                            }}
                                          />
                                        </div>
                                      {/* </>
                                    )} */}
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}

                        <div className="w-100 px-md-2 p-2 mb-2  px-2">
                          <div className="row d-flex align-items-center">
                            <div className="add-button d-flex  justify-content-end">
                              <button disabled={disabled}
                                type="button"
                                className="add-new-account-link btn-animation d-inline-flex align-items-center"
                                onClick={() => addApp()}
                              >
                                <p className="my-0 mr-2 p-0 green-style2">
                                  Add Application
                                </p>
                                <img src="../images/coloredPlus.svg" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
              </fieldset>
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={async () => {
                      if (isDirty) {
                        await submit();
                      }
                      history.push(ROUTES.GCP_ENVIRONMENT_STRATEGY+"/"+projectId);
                    }}
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                      Environment Strategy
                    </p>
                  </button>
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={async () => {
                      if (isDirty) {
                        await submit();
                      }
                      history.push(ROUTES.GCP_DEVELOPER_SANDBOX_ACCOUNTS+"/"+projectId);
                    }}
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">
                      Developer Sandbox Accounts
                    </p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {console.log(formData)}
    </>
  );
};

export default GCPApplicationAccounts;
