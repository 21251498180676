import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { Link, useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import {
  validate1to15AlphaNumeric,
  validateAccountName,
  validateAccountEmailId,
  validateDataCenterName,
  validateCidr,
} from "../../../../helpers/validator";
import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

//const projectId = "google_1";
const PREFIX = "/aws/network/datacenters";
const DOMAIN_NOTIFICATION_KEY = "/aws/network/emailidfordomainnotifications";

const DataCenters = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    [DOMAIN_NOTIFICATION_KEY]: {
      value: "",
      isValid: false,
    },
    establishonpremintegration: {
      value: "yes",
    },
    array: [],
  });

  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const awsAmplify = useAwsAmplify();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);
        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        setIsLoading(false);

        console.log(result);

        setEmpty(result.data.empty);

        setFormData(convertBEToFE(result.data));
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);

        if(err.response.data.message === "You have no access"){
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (data) => {
    const fe = {
      [DOMAIN_NOTIFICATION_KEY]: {
        value: "",
        isValid: false,
      },
      establishonpremintegration: {
        value: "yes",
      },
      array: [createEntity()],
    };

    if (!data.empty) {
      fe.establishonpremintegration.value =
        data[`${PREFIX}/establishonpremintegration`];

      if (data[DOMAIN_NOTIFICATION_KEY]) {
        fe[DOMAIN_NOTIFICATION_KEY].value = data[DOMAIN_NOTIFICATION_KEY];
        fe[DOMAIN_NOTIFICATION_KEY].isValid = true;
      }

      if (data[`${PREFIX}/establishonpremintegration`] === "yes") {
        if (data[`${PREFIX}/datacenterslist`]) {
          fe.array = [];
          const dataCenters = data[`${PREFIX}/datacenterslist`].split(",");

          for (const dataCenter of dataCenters) {
            const entity = createEntity();

            entity.name.value = dataCenter;
            entity.name.isValid = true;

            if (data[`${PREFIX}/${dataCenter}/cidrranges`]) {
              entity.cidrRanges = [];

              const cidrRangesArray = data[
                `${PREFIX}/${dataCenter}/cidrranges`
              ].split(",");

              for (const cidrRange of cidrRangesArray) {
                entity.cidrRanges.push({
                  value: cidrRange,
                  isValid: true,
                });
              }
            }

            fe.array.push(entity);
          }
        }
      }
    }

    return fe;
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/establishonpremintegration`]: formData
        .establishonpremintegration.value,
    };

    if (formData[DOMAIN_NOTIFICATION_KEY].isValid) {
      be[DOMAIN_NOTIFICATION_KEY] = formData[DOMAIN_NOTIFICATION_KEY].value;
    }

    if (formData.establishonpremintegration.value === "yes") {
      const datacenterslist = [];

      const allCidrs = [];

      for (const item of formData.array) {
        if (item.name.isValid) {
          datacenterslist.push(item.name.value);

          const cidrs = [];

          for (const cidrRange of item.cidrRanges) {
            if (cidrRange.isValid) {
              cidrs.push(cidrRange.value);

              allCidrs.push(cidrRange.value);
            }
          }

          if (cidrs.length > 0) {
            be[`${PREFIX}/${item.name.value}/cidrranges`] = cidrs.join(",");
          }
        }
      }

      if (datacenterslist.length > 0) {
        be[`${PREFIX}/datacenterslist`] = datacenterslist.join(",");
      }

      if(allCidrs.length > 0){
        be[`${PREFIX}/onpremcidrblocks`] = allCidrs.join(",");
      }
    }
    else{
      be[`${PREFIX}/onpremcidrblocks`] = "none";
    }

    console.log(be);

    return be;
  };

  const createEntity = () => {
    return {
      name: {
        value: "",
        isValid: false,
      },
      cidrRanges: [
        { value: "", isValid: false },
        { value: "", isValid: false },
      ],
    };
  };

  const addArrayItem = () => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, { array: { $push: [createEntity()] } })
    );
  };

  const removeArrayItem = (idx) => {
    if (formData.array.length > 1) {
      setIsDirty(true);

      setFormData((state) => update(state, { array: { $splice: [[idx, 1]] } }));
    }
  };

  const addCidr = (dcIdx) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        array: {
          [dcIdx]: { cidrRanges: { $push: [{ value: "", isValid: false }] } },
        },
      })
    );
  };

  const removeCidr = (dcIdx, cidrIdx) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        array: { [dcIdx]: { cidrRanges: { $splice: [[cidrIdx, 1]] } } },
      })
    );
  };

  const onArrayItemChange = (idx, value) => {
    setErrorMsg(null);
    setIsDirty(true);
    setFormData((state) =>
      update(state, { array: { [idx]: { name: { value: { $set: value } } } } })
    );
  };

  const onArrayItemBlur = (idx, validateFunc, value) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      setFormData((state) =>
        update(state, {
          array: {
            [idx]: { name: { isValid: { $set: errorMsg === undefined } } },
          },
        })
      );

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      setFormData((state) =>
        update(state, {
          array: {
            [idx]: { name: { isValid: { $set: false } } },
          },
        })
      );
    }
  };

  const onCidrChange = (dcIdx, cidrIdx, value) => {
    setErrorMsg(null);
    setIsDirty(true);
    setFormData((state) =>
      update(state, {
        array: {
          [dcIdx]: { cidrRanges: { [cidrIdx]: { value: { $set: value } } } },
        },
      })
    );
  };

  const onCidrBlur = (dcIdx, cidrIdx, validateFunc, value) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      setFormData((state) =>
        update(state, {
          array: {
            [dcIdx]: {
              cidrRanges: {
                [cidrIdx]: { isValid: { $set: errorMsg === undefined } },
              },
            },
          },
        })
      );

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      setFormData((state) =>
        update(state, {
          array: {
            [dcIdx]: {
              cidrRanges: {
                [cidrIdx]: { isValid: { $set: false } },
              },
            },
          },
        })
      );
    }
  };

  const getArrayItemValidationClass = (idx) => {
    return formData.array[idx].name.value !== "" &&
      !formData.array[idx].name.isValid
      ? "invalid"
      : "";
  };

  const getCidrValidationClass = (dcIdx, cidrIdx) => {
    return formData.array[dcIdx].cidrRanges[cidrIdx].value !== "" &&
      !formData.array[dcIdx].cidrRanges[cidrIdx].isValid
      ? "invalid"
      : "";
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Network</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
          <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Network"
                isDirty={isDirty}
                dashboardRoute={ROUTES.VPC}
                designRoute={ROUTES.DESIGN_NETWORK}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-1 px-lg-4 ">
                <div className="filter pr-xl-5  px-lg-4 ">
                  <div className="left">
                    <div className="title">Network Domain Notifications</div>
                  </div>
                  <div className="right align-items-center">
                    <div className="subscribe">Email id</div>
                    <div className="account">
                      <input
                        type="text"
                        className={`form-control ${
                          formData[DOMAIN_NOTIFICATION_KEY].value !== "" &&
                          formData[DOMAIN_NOTIFICATION_KEY].isValid === false
                            ? "invalid"
                            : ""
                        }`}
                        placeholder="Network@client.com"
                        value={formData[DOMAIN_NOTIFICATION_KEY].value}
                        onChange={(e) => {
                          const value = e.target.value;

                          setIsDirty(true);
                          setErrorMsg("");

                          setFormData((state) =>
                            update(state, {
                              [DOMAIN_NOTIFICATION_KEY]: {
                                value: { $set: value },
                              },
                            })
                          );
                        }}
                        onBlur={(e) => {
                          const value = e.target.value;

                          if (value !== "") {
                            const errorMsg = validateAccountEmailId(value);

                            setFormData((state) =>
                              update(state, {
                                [DOMAIN_NOTIFICATION_KEY]: {
                                  isValid: {
                                    $set: errorMsg === undefined,
                                  },
                                },
                              })
                            );

                            if (errorMsg) {
                              setErrorMsg(errorMsg);
                            }
                          } else {
                            setFormData((state) =>
                              update(state, {
                                [DOMAIN_NOTIFICATION_KEY]: {
                                  isValid: {
                                    $set: false,
                                  },
                                },
                              })
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="row d-flex align-items-center ">
                  <div className="col-xl-6 col-lg-5 col-md-4 pl-lg-0 px-2">
                    <h3 className="">Data Centers</h3>
                  </div>
                  <div className="col-xl-6 col-lg-7 col-md-8 d-flex pr-0 align-items-center justify-content-end rightNavArea">
                    <div className="d-inline-flex yes-no-area align-items-center">
                      <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                        Establish on-premise integration
                      </div>
                      <div className="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                        <label
                          className={`createElementBtn btn btn-link btn-linkYes mw-70 ${
                            formData.establishonpremintegration.value === "yes"
                              ? "active"
                              : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name="options"
                            checked={
                              formData.establishonpremintegration.value ===
                              "yes"
                            }
                            onChange={() => {
                              setIsDirty(true);
                              setFormData((state) =>
                                update(state, {
                                  establishonpremintegration: {
                                    value: { $set: "yes" },
                                  },
                                })
                              );
                            }}
                          />{" "}
                          YES
                        </label>
                        <label
                          className={`dontCreateElementBtn btn btn-link btn-linkNo mw-70 ${
                            formData.establishonpremintegration.value === "no"
                              ? "active"
                              : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name="options"
                            checked={
                              formData.establishonpremintegration.value === "no"
                            }
                            onChange={() => {
                              setIsDirty(true);
                              setFormData((state) =>
                                update(state, {
                                  establishonpremintegration: {
                                    value: { $set: "no" },
                                  },
                                })
                              );
                            }}
                          />{" "}
                          NO
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                {empty && (
                  <NoInitialData />
                )}
                {formData.establishonpremintegration.value === "yes" && (
                  <>
                    <div className="show-hide-content d-flex flex-column">
                      {formData.array.map((dc, dcIdx) => (
                        <div
                          className=" row mainContentArea p-relative px-md-2 px-2 my-1 py-1 pr-md-2 pr-5 align-items-start justify-content-start"
                          key={dcIdx}
                        >
                          <div className="col-xl-4 col-xxl-4 col-md-5  col-12 flex-wrap  d-inline-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center px-0 py-1 my-1 ">
                              <span className="font-12 sectionTitle text-nowrap">
                                Data Center {dcIdx + 1}
                              </span>
                            </div>
                            <div className="d-inline-flex  align-items-center my-1 mr-1">
                              <div className="d-inline-flex align-items-center bg-special2 border-10  py-1 px-3">
                                <span className="font-12 d-flex align-items-center flex-1 mr-2 my-1">
                                  Name
                                </span>
                                <div className="d-inline-flex flex-2 my-1">
                                  <input
                                    type="text"
                                    className={`w-175 form-control ${getArrayItemValidationClass(
                                      dcIdx
                                    )}`}
                                    placeholder="maryland-dc"
                                    value={dc.name.value}
                                    onChange={(e) =>{
                                      const value = e.target.value;

                                  for (const [
                                    idxOtherItem,
                                    otherItem,
                                  ] of formData.array.entries()) {
                                    if (dcIdx !== idxOtherItem) {
                                      if (
                                        otherItem.name.isValid &&
                                        otherItem.name.value !== ""
                                      ) {
                                        if (
                                          otherItem.name.value.toLowerCase() ===
                                          value.toLowerCase()
                                        ) {
                                          setErrorMsg(
                                            "Data Center name must be unique"
                                          );
                                          return false;
                                        }
                                      }
                                    }
                                  }

                                      onArrayItemChange(dcIdx, e.target.value)
                                    }}
                                    onBlur={(e) =>
                                      onArrayItemBlur(
                                        dcIdx,
                                        validateDataCenterName,
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-7 col-xxl-7 col-md-6  col-12 flex-wrap">
                            <div className=" row bg-special2 border-10  align-items-center">
                              <span className="sectionTitle pt-4 align-self-start my-1 col-xl-2 col-lg-3 col-12 font-12 d-flex align-items-center text-nowrap  text-nowrap">
                                CIDR blocks in use
                              </span>
                              <div className="d-inline-flex col-xl-10 col-lg-9 col-12 align-items-center flex-wrap  py-1  my-1">
                                {dc.cidrRanges.map((cidr, cidrIdx) => (
                                  <div
                                    className="d-inline-flex mr-3 my-1"
                                    key={cidrIdx}
                                  >
                                    <div className="p-relative d-flex align-items-center">
                                      <button disabled={disabled}
                                        type="button"
                                        className="closableInput"
                                        onClick={() =>
                                          removeCidr(dcIdx, cidrIdx)
                                        }
                                      ></button>
                                      <input
                                        disabled={!dc.name.isValid}
                                        type="text"
                                        className={`w-175 form-control ${getCidrValidationClass(
                                          dcIdx,
                                          cidrIdx
                                        )}`}
                                        placeholder="10.212.0.0/14"
                                        value={cidr.value}
                                        onChange={(e) => {
                                          const value = e.target.value;

                                          if(dc.cidrRanges.find(cidr => cidr.isValid && cidr.value !== "" && cidr.value === value)){
                                            setErrorMsg(
                                              "Cidr block must be unique"
                                            );
                                            return false;
                                          }

                                  for (const [
                                    idxOtherItem,
                                    otherItem,
                                  ] of formData.array.entries()) {
                                    if (dcIdx !== idxOtherItem) {
                                      for(const otherCidr of otherItem.cidrRanges){
                                      if (
                                        otherCidr.isValid &&
                                        otherCidr.value !== ""
                                      ) {
                                        if (
                                          otherCidr.value.toLowerCase() ===
                                          value.toLowerCase()
                                        ) {
                                          setErrorMsg(
                                            "CIDR block must be unique"
                                          );
                                          return false;
                                        }
                                      }
                                    }
                                    }
                                  }

                                          onCidrChange(
                                            dcIdx,
                                            cidrIdx,
                                            e.target.value
                                          )
                                        }}
                                        onBlur={(e) =>
                                          onCidrBlur(
                                            dcIdx,
                                            cidrIdx,
                                            validateCidr,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                ))}
                                <button disabled={disabled}
                                  type="button"
                                  className="btn-animation d-flex align-items-center pl-2"
                                  onClick={() => addCidr(dcIdx)}
                                >
                                  <img src="../images/purplePlusIcon.svg" />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-1 closeFormRow justify-content-end  d-flex align-items-center col-12 px-0">
                            <button disabled={disabled}
                              type="button"
                              className="btn-animation closeIcon d-flex justify-content-end"
                              onClick={() => removeArrayItem(dcIdx)}
                            >
                              <img src="../images/close.svg" />
                            </button>
                          </div>
                          <div className="closeFormRowMobil tr-10">
                            <button disabled={disabled}
                              type="button"
                              className="btn-animation closeIcon"
                              onClick={() => removeArrayItem(dcIdx)}
                            >
                              <img src="../images/close.svg" />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="d-flex align-items-center justify-content-end px-2">
                      <div className="add-button hide-show-addBtn">
                        <button disabled={disabled}
                          type="button"
                          className="add-new-account-link btn-animation d-inline-flex align-items-center"
                          onClick={() => addArrayItem()}
                        >
                          <p className="my-0 mr-2 p-0 green-style2">
                            Add Data Center
                          </p>
                          <img src="../images/coloredPlus.svg" />
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
              </fieldset>
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(ROUTES.AWS_ENVIRONMENT + "/" + projectId)
                    }
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">
                      AWS Environment
                    </p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {console.log(formData)}
    </>
  );
};

export default DataCenters;
