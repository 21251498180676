import React, { useState, useEffect } from "react";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

//const projectId = "google_1";
const PREFIX = "/aws/network/vpcdesign";

const VPCDesign = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    networkarchitecture: {
      value: "",
    },
  });

  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched", "Deployed"]);

  const awsAmplify = useAwsAmplify();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        setIsLoading(false);

        setEmpty(result.data.empty);

        setFormData(convertBEToFE(result.data));
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);

        if(err.response.data.message === "You have no access"){
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (data) => {
    return {
      networkarchitecture: data[`${PREFIX}/networkarchitecture`] || "",
    };
  };

  const convertFEToBE = () => {
    return {
      [`${PREFIX}/networkarchitecture`]: formData.networkarchitecture,
    };
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Network</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
          <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Network"
                isDirty={isDirty}
                dashboardRoute={ROUTES.VPC}
                designRoute={ROUTES.DESIGN_NETWORK}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-1 px-lg-4 ">
                <div className="row d-flex mb-2 align-items-center">
                  <div className="col-12 col-md-4 pl-lg-0 px-2">
                    <h3 className="font-weight-bold">VPC Design</h3>
                  </div>
                </div>
                {empty && (
                  <NoInitialData />
                )}
                <div class="d-flex flex-column   pb-3 pt-0">
                  <div class=" row px-md-2 p-2 mb-2 align-items-center ">
                    <div class="col-md-4 col-12 px-3">
                      <label class="container d-flex flex-column d-flex flex-column align-items-center">
                        <img
                          alt=""
                          class="flex-1 pt-2"
                          src="../images/data-protection.svg"
                        />
                        <p class="flex-1 mb-0 pt-3 font-15 text-center d-flex align-items-center">
                          Segmented Network Architecture
                          <br />
                          (Dedicated VPCs)
                        </p>
                        <input
                          class="flex-1"
                          type="radio"
                          name="single"
                          checked={formData.networkarchitecture === "dedicated"}
                          onChange={() => {
                            setIsDirty(true);
                            setFormData({
                              networkarchitecture: "dedicated",
                            });
                          }}
                        />
                        <span class="checkmark p-relative my-3"></span>
                      </label>
                    </div>
                    <div class="col-md-4 col-12 px-3 ">
                      <label class="container d-flex flex-column d-flex flex-column align-items-center">
                        <img
                          alt=""
                          class="flex-1 pt-2"
                          src="../images/server.svg"
                        />
                        <p class="flex-1 mb-0 pt-3 font-15 text-center d-flex align-items-center">
                          Flat Network Architecture
                          <br />
                          (Shared VPCs)
                        </p>
                        <input
                          class="flex-1"
                          type="radio"
                          name="single"
                          checked={formData.networkarchitecture === "shared"}
                          onChange={() => {
                            setIsDirty(true);
                            setFormData({
                              networkarchitecture: "shared",
                            });
                          }}
                        />
                        <span class="checkmark p-relative my-3"></span>
                      </label>
                    </div>
                    <div class="col-md-4 col-12 px-3 ">
                      <label class="container d-flex flex-column d-flex flex-column align-items-center">
                        <img
                          alt=""
                          class="flex-1 pt-2"
                          src="../images/multi%20server.svg"
                        />
                        <p class="flex-1 mb-0 pt-3 font-15 text-center d-flex align-items-center">
                          Hybrid Architecture
                        </p>
                        <input
                          class="flex-1"
                          type="radio"
                          name="single"
                          checked={formData.networkarchitecture === "hybrid"}
                          onChange={() => {
                            setIsDirty(true);
                            setFormData({
                              networkarchitecture: "hybrid",
                            });
                          }}
                        />
                        <span class="checkmark p-relative my-3"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              </fieldset>
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() => saveDataIfDirty(ROUTES.AWS_ENVIRONMENT + "/" + projectId)}
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                      AWS Environment
                    </p>
                  </button>
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() => saveDataIfDirty(ROUTES.VPC_FACTORY + "/" + projectId)}
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">VPC Factory</p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default VPCDesign;
