import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import RadioGroup from "../../RadioGroup";
import { validate1to10AlphaNumeric, validateSNSTopic } from "../../../../helpers/validator";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

const PREFIX = "/aws/governance/servicenowintegration";

const getInitialFormData = (data) => {
  const initialFormData = {
    deployintegrationwithservicenow: {
      value: data[`${PREFIX}/deployintegrationwithservicenow`] || "no",
    },
    deployintegrationwithservicenowcmdb: {
      value: data[`${PREFIX}/deployintegrationwithservicenowcmdb`] || "yes",
    },
    deployintegrationwithservicenowchangemgmt: {
      value:
        data[`${PREFIX}/deployintegrationwithservicenowchangemgmt`] || "yes",
    },
    deployintegrationwithservicennowselfservice: {
      value:
        data[`${PREFIX}/deployintegrationwithservicennowselfservice`] || "yes",
    },
    deployintegrationwithservicenowim: {
      value:
        data[`${PREFIX}/deployintegrationwithservicenowim`] || "yes",
    }
  };

  return initialFormData;
};

const ServiceNowIntegration = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState(() => getInitialFormData({}));
  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const awsAmplify = useAwsAmplify();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        setIsLoading(false);

        setEmpty(result.data.empty);
        setFormData(convertBEToFE(result.data));
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);

        if (err.response.data.message === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (data) => {
    if (!data) {
      data = {};
    }

    const fe = getInitialFormData(data);

    return fe;
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/deployintegrationwithservicenow`]: formData
        .deployintegrationwithservicenow.value,
    };

    if (formData.deployintegrationwithservicenow.value === "yes") {
      be[`${PREFIX}/deployintegrationwithservicenowcmdb`] =
        formData.deployintegrationwithservicenowcmdb.value;

      be[`${PREFIX}/deployintegrationwithservicenowchangemgmt`] =
        formData.deployintegrationwithservicenowchangemgmt.value;

      be[`${PREFIX}/deployintegrationwithservicennowselfservice`] =
        formData.deployintegrationwithservicennowselfservice.value;

      be[`${PREFIX}/deployintegrationwithservicenowim`] =
        formData.deployintegrationwithservicenowim.value;  
    }

    return be;
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  const updateRadio = (key, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        [key]: {
          value: { $set: value },
        },
      })
    );
  };

  const onFieldChange = (value, key) => {
    setErrorMsg(null);
    setIsDirty(true);

    setFormData((state) =>
      update(state, { [key]: { value: { $set: value } } })
    );
  };

  const onFieldBlur = (validateFunc, value, key) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      setFormData((state) =>
        update(state, {
          [key]: { isValid: { $set: errorMsg === undefined } },
        })
      );

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      setFormData((state) =>
        update(state, { [key]: { isValid: { $set: false } } })
      );
    }
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Governance</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Governance"
                isDirty={isDirty}
                dashboardRoute={ROUTES.GOVERNANCE_SERVICE_CONTROL}
                designRoute={ROUTES.DESIGN_GOVERNANCE}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div class="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                <div class="row d-flex align-items-center">
                  <div class="col-xl-6 col-lg-5 col-md-4 pl-lg-0 px-2">
                    <h3>ServiceNow Integration</h3>
                  </div>
                  <div class="col-xl-6 col-lg-7 col-md-8 d-flex pr-0 align-items-center justify-content-end rightNavArea">
                    <div class="d-inline-flex yes-no-area align-items-center">
                      <div class="content-label font-12 mr-xl-5 mr-lg-3 mr-1 ">
                        Deploy Integration with ServiceNow
                      </div>
                      <div class="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                        <RadioGroup
                          field="deployintegrationwithservicenow"
                          value={formData.deployintegrationwithservicenow.value}
                          onChange={updateRadio}
                          isLarge={true}
                        ></RadioGroup>
                      </div>
                    </div>
                  </div>
                </div>

                {empty && (
                  <NoInitialData />
                )}

                {formData.deployintegrationwithservicenow.value === "yes" && (
                  <div class="row show-hide-content px-0">
                    <div class="mainContentArea row px-2 my-1 py-1">
                      <div class="col-12 px-1 my-1">
                        <div class="formRow row justify-content-start w-100">
                          <div class=" d-inline-flex col-md-6 col-12 justify-content-between yes-no-area align-items-center">
                            <div class="content-label mr-xl-5 mr-lg-3 mr-1 font-12 ">
                              Integration with ServieNow CMDB
                            </div>
                            <div class="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center mr-0">
                              <RadioGroup
                                field="deployintegrationwithservicenowcmdb"
                                value={
                                  formData.deployintegrationwithservicenowcmdb
                                    .value
                                }
                                onChange={updateRadio}
                              ></RadioGroup>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 px-1 my-1">
                        <div class="formRow row justify-content-start w-100">
                          <div class="showRightContentBtn-v2 d-inline-flex col-md-6 col-12 justify-content-between yes-no-area align-items-center">
                            <div class="content-label mr-xl-5 mr-lg-3 mr-1 font-12 ">
                              Integration with ServieNow Change Management
                            </div>
                            <div class="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center mr-0">
                              <RadioGroup
                                field="deployintegrationwithservicenowchangemgmt"
                                value={
                                  formData
                                    .deployintegrationwithservicenowchangemgmt
                                    .value
                                }
                                onChange={updateRadio}
                              ></RadioGroup>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 px-1 my-1">
                        <div class="formRow row justify-content-start w-100">
                          <div class=" d-inline-flex col-md-6 col-12 justify-content-between yes-no-area align-items-center">
                            <div class="content-label mr-xl-5 mr-lg-3 mr-1 font-12 ">
                              Integration with ServieNow Self-service Portal
                            </div>
                            <div class="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center mr-0">
                              <RadioGroup
                                field="deployintegrationwithservicennowselfservice"
                                value={
                                  formData
                                    .deployintegrationwithservicennowselfservice
                                    .value
                                }
                                onChange={updateRadio}
                              ></RadioGroup>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 px-1 my-1">
                        <div class="formRow row justify-content-start w-100">
                          <div class=" d-inline-flex col-md-6 col-12 justify-content-between yes-no-area align-items-center">
                            <div class="content-label mr-xl-5 mr-lg-3 mr-1 font-12 ">
                              Deploy Integration with ServiceNow IM
                            </div>
                            <div class="btn-group btn-group-toggle btn-group-radio mw-100 align-items-center mr-0">
                              <RadioGroup
                                field="deployintegrationwithservicenowim"
                                value={
                                  formData
                                    .deployintegrationwithservicenowim
                                    .value
                                }
                                onChange={updateRadio}
                              ></RadioGroup>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              </fieldset>
              {console.log(formData)}
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.GOVERNANCE_AWS_LIMITS_MONITOR + "/" + projectId
                      )
                    }
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                      AWS Limits Monitor
                    </p>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceNowIntegration;
