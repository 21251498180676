import React, { useState, useEffect } from "react";
import update from "immutability-helper";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import RadioGroup from "../../RadioGroup";
import { validateAccountEmailId } from "../../../../helpers/validator";
import useServiceAccounts from "../../../../hooks/useServiceAccounts";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

const PREFIX = "/aws/monitoring/centralizedlogging";

const getInitialFormData = (data) => {
  const initialFormData = {
    deploycentralizedlogging: {
      value: data[`${PREFIX}/deploycentralizedlogging`] || "yes",
    },
    cloudtrail: {
      value: "yes",
    },
    configsnapshots: {
      value: "yes",
    },
    s3accesslogs: {
      value: data[`${PREFIX}/s3accesslogs`] || "yes",
    },
    loadbalanceraccesslogs: {
      value: data[`${PREFIX}/loadbalanceraccesslogs`] || "yes",
    },
    cloudfrontlogs: {
      value: data[`${PREFIX}/cloudfrontlogs`] || "yes",
    },
    waflogs: {
      value: data[`${PREFIX}/waflogs`] || "yes",
    },
    route53logs: {
      value: data[`${PREFIX}/route53logs`] || "yes",
    },
    rdslogs: {
      value: data[`${PREFIX}/rdslogs`] || "yes",
    },
    redshiftlogs: {
      value: data[`${PREFIX}/redshiftlogs`] || "yes",
    },
    centralizedloggings3bucketaccount: {
      value: data[`${PREFIX}/centralizedloggings3bucketaccount`] || "",
    },
    archivelogsafter: {
      value: data[`${PREFIX}/archivelogsafter`] || "",
    },
    retentionperiod: {
      value: data[`${PREFIX}/retentionperiod`] || "",
    },
    createcloudtrail: {
      value: data[`${PREFIX}/createcloudtrail`] || "yes",
    },
    array: [],
  };

  return initialFormData;
};

const CentralizedLogging = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState(() => getInitialFormData({}));
  const [empty, setEmpty] = useState(false);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const awsAmplify = useAwsAmplify();
  const serviceAccounts = useServiceAccounts(projectId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        setIsLoading(false);

        setEmpty(result.data.empty);
        setFormData(convertBEToFE(result.data));
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);

        if (err.response.data.message === "You have no access") {
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);
      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertBEToFE = (data) => {
    if (!data) {
      data = {};
    }

    const fe = getInitialFormData(data);

    return fe;
  };

  const convertFEToBE = () => {
    const be = {
      [`${PREFIX}/deploycentralizedlogging`]: formData.deploycentralizedlogging
        .value,
    };

    if (formData.deploycentralizedlogging.value === "yes") {
      be[`${PREFIX}/cloudtrail`] = formData.cloudtrail.value;
      be[`${PREFIX}/configsnapshots`] = formData.configsnapshots.value;
      be[`${PREFIX}/s3accesslogs`] = formData.s3accesslogs.value;
      be[`${PREFIX}/loadbalanceraccesslogs`] =
        formData.loadbalanceraccesslogs.value;
      be[`${PREFIX}/cloudfrontlogs`] = formData.cloudfrontlogs.value;
      be[`${PREFIX}/waflogs`] = formData.waflogs.value;
      be[`${PREFIX}/route53logs`] = formData.route53logs.value;
      be[`${PREFIX}/rdslogs`] = formData.rdslogs.value;
      be[`${PREFIX}/redshiftlogs`] = formData.redshiftlogs.value;

      if (formData.centralizedloggings3bucketaccount.value !== "") {
        be[`${PREFIX}/centralizedloggings3bucketaccount`] =
          formData.centralizedloggings3bucketaccount.value;
      }

      if (formData.archivelogsafter.value !== "") {
        be[`${PREFIX}/archivelogsafter`] = formData.archivelogsafter.value;
      }

      if (formData.retentionperiod.value !== "") {
        be[`${PREFIX}/retentionperiod`] = formData.retentionperiod.value;
      }

      be[`${PREFIX}/createcloudtrail`] = formData.createcloudtrail.value;
    }

    return be;
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  const updateRadio = (key, value) => {
    setIsDirty(true);

    setFormData((state) =>
      update(state, {
        [key]: {
          value: { $set: value },
        },
      })
    );
  };

  const onFieldChange = (value, key) => {
    setErrorMsg(null);
    setIsDirty(true);

    setFormData((state) =>
      update(state, { [key]: { value: { $set: value } } })
    );
  };

  const onFieldBlur = (validateFunc, value, key) => {
    if (value !== "") {
      const errorMsg = validateFunc(value);

      setFormData((state) =>
        update(state, {
          [key]: { isValid: { $set: errorMsg === undefined } },
        })
      );

      if (errorMsg) {
        setErrorMsg(errorMsg);
      }
    } else {
      setFormData((state) =>
        update(state, { [key]: { isValid: { $set: false } } })
      );
    }
  };

  useHideLeftMenu();
  useUnload(isDirty);

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Monitoring</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
            <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Monitoring"
                isDirty={isDirty}
                //dashboardRoute={ROUTES.GOVERNANCE_SERVICE_CONTROL}
                designRoute={ROUTES.DESIGN_MONITORING}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div class="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                <div class="row d-flex align-items-center">
                  <div class="col-xl-6 col-lg-5 col-md-4 pl-lg-0 px-2">
                    <h3>Centralized Logging</h3>
                  </div>
                  <div class="col-xl-6 col-lg-7 col-md-8 d-flex pr-0 align-items-center justify-content-end rightNavArea">
                    <div class="d-inline-flex yes-no-area align-items-center">
                      <div class="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                        Deploy Centralized Logging
                      </div>
                      <div class="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                        <RadioGroup
                          field="deploycentralizedlogging"
                          value={formData.deploycentralizedlogging.value}
                          onChange={updateRadio}
                          isLarge={true}
                        ></RadioGroup>
                      </div>
                    </div>
                  </div>
                </div>

                {empty && (
                  <NoInitialData />
                )}

                {formData.deploycentralizedlogging.value === "yes" && (
                  <div class="show-hide-content">
                    <div class="d-flex flex-column mainContentArea  pl-3 pr-3 py-1 my-1">
                      <div class="d-flex flex-column">
                        <div class=" row  px-md-2 px-0 my-1 align-items-center ">
                          <div class="col-lg-3 col-md-6 col-6 form-line d-inline-flex align-items-center ">
                            <div class="d-inline-flex form-line blueSwitch align-items-center bg-special border-10 w-100 pr-2 py-1">
                              <label class="switch mb-0 ml-0">
                                <input type="checkbox" checked disabled />
                                <span class="slider  round"></span>
                              </label>
                              <span class="switchLabel ">CloudTrail Logs </span>
                            </div>
                          </div>
                          <div class="col-lg-3 col-md-6 col-6  form-line d-inline-flex align-items-center">
                            <div class="d-inline-flex form-line blueSwitch align-items-center bg-special border-10 w-100 pr-2 py-1">
                              <label class="switch  mb-0 ml-0">
                                <input type="checkbox" checked disabled />
                                <span class="slider round"></span>
                              </label>
                              <span class="switchLabel ">
                                {" "}
                                Config Snapshots
                              </span>
                            </div>
                          </div>
                        </div>

                        <div class=" row  px-md-2 px-0 my-1 align-items-center">
                          <div class="col-lg-3 col-md-6 col-6 form-line d-inline-flex align-items-center ">
                            <div class="d-inline-flex align-items-center bg-special border-10 w-100 pr-2 py-1">
                              <label class="switch mb-0 ml-0">
                                <input
                                  type="checkbox"
                                  checked={
                                    formData.s3accesslogs.value === "yes"
                                  }
                                  onChange={(e) => {
                                    setIsDirty(true);
                                    const value = e.target.checked;
                                    setFormData((state) =>
                                      update(state, {
                                        s3accesslogs: {
                                          value: {
                                            $set: value ? "yes" : "no",
                                          },
                                        },
                                      })
                                    );
                                  }}
                                />
                                <span class="slider round"></span>
                              </label>
                              <span class="switchLabel ">S3 Access Logs </span>
                            </div>
                          </div>
                          <div class="col-lg-3 col-md-6 col-6  form-line d-inline-flex align-items-center">
                            <div class="d-inline-flex align-items-center bg-special border-10 w-100 pr-2 py-1">
                              <label class="switch mb-0 mb-0 ml-0">
                                <input
                                  type="checkbox"
                                  checked={
                                    formData.loadbalanceraccesslogs.value ===
                                    "yes"
                                  }
                                  onChange={(e) => {
                                    setIsDirty(true);
                                    const value = e.target.checked;
                                    setFormData((state) =>
                                      update(state, {
                                        loadbalanceraccesslogs: {
                                          value: {
                                            $set: value ? "yes" : "no",
                                          },
                                        },
                                      })
                                    );
                                  }}
                                />
                                <span class="slider round"></span>
                              </label>
                              <span class="switchLabel ">ALB Access Logs</span>
                            </div>
                          </div>
                          <div class="col-lg-3 col-md-6 col-6 form-line d-inline-flex align-items-center">
                            <div class="d-inline-flex align-items-center bg-special border-10 w-100 pr-2 py-1">
                              <label class="switch mb-0 mb-0 ml-0">
                                <input
                                  type="checkbox"
                                  checked={
                                    formData.cloudfrontlogs.value === "yes"
                                  }
                                  onChange={(e) => {
                                    setIsDirty(true);
                                    const value = e.target.checked;
                                    setFormData((state) =>
                                      update(state, {
                                        cloudfrontlogs: {
                                          value: {
                                            $set: value ? "yes" : "no",
                                          },
                                        },
                                      })
                                    );
                                  }}
                                />
                                <span class="slider round"></span>
                              </label>
                              <span class="switchLabel ">CloudFront Logs</span>
                            </div>
                          </div>

                          <div class="col-lg-3 col-md-6 col-6 form-line d-inline-flex align-items-center">
                            <div class="d-inline-flex align-items-center bg-special border-10 w-100 pr-2 py-1">
                              <label class="switch mb-0 mb-0 ml-0">
                                <input
                                  type="checkbox"
                                  checked={formData.waflogs.value === "yes"}
                                  onChange={(e) => {
                                    setIsDirty(true);
                                    const value = e.target.checked;
                                    setFormData((state) =>
                                      update(state, {
                                        waflogs: {
                                          value: {
                                            $set: value ? "yes" : "no",
                                          },
                                        },
                                      })
                                    );
                                  }}
                                />
                                <span class="slider round"></span>
                              </label>
                              <span class="switchLabel">WAF Logs</span>
                            </div>
                          </div>
                        </div>

                        <div class=" row  px-md-2 px-0 my-1 align-items-center">
                          <div class="col-lg-3 col-md-6 col-6 form-line d-inline-flex align-items-center ">
                            <div class="d-inline-flex align-items-center bg-special border-10 w-100 pr-2 py-1">
                              <label class="switch mb-0 ml-0">
                                <input
                                  type="checkbox"
                                  checked={formData.route53logs.value === "yes"}
                                  onChange={(e) => {
                                    setIsDirty(true);
                                    const value = e.target.checked;
                                    setFormData((state) =>
                                      update(state, {
                                        route53logs: {
                                          value: {
                                            $set: value ? "yes" : "no",
                                          },
                                        },
                                      })
                                    );
                                  }}
                                />
                                <span class="slider round"></span>
                              </label>
                              <span class="switchLabel ">Route53 Logs </span>
                            </div>
                          </div>
                          <div class="col-lg-3 col-md-6 col-6  form-line d-inline-flex align-items-center">
                            <div class="d-inline-flex align-items-center bg-special border-10 w-100 pr-2 py-1">
                              <label class="switch mb-0 ml-0">
                                <input
                                  type="checkbox"
                                  checked={formData.rdslogs.value === "yes"}
                                  onChange={(e) => {
                                    setIsDirty(true);
                                    const value = e.target.checked;
                                    setFormData((state) =>
                                      update(state, {
                                        rdslogs: {
                                          value: {
                                            $set: value ? "yes" : "no",
                                          },
                                        },
                                      })
                                    );
                                  }}
                                />
                                <span class="slider round"></span>
                              </label>
                              <span class="switchLabel ">RDS Logs</span>
                            </div>
                          </div>
                          <div class="col-lg-3 col-md-6 col-6 form-line d-inline-flex align-items-center">
                            <div class="d-inline-flex align-items-center bg-special border-10 w-100 pr-2 py-1">
                              <label class="switch mb-0 ml-0">
                                <input
                                  type="checkbox"
                                  checked={
                                    formData.redshiftlogs.value === "yes"
                                  }
                                  onChange={(e) => {
                                    setIsDirty(true);
                                    const value = e.target.checked;
                                    setFormData((state) =>
                                      update(state, {
                                        redshiftlogs: {
                                          value: {
                                            $set: value ? "yes" : "no",
                                          },
                                        },
                                      })
                                    );
                                  }}
                                />
                                <span class="slider round"></span>
                              </label>
                              <span class="switchLabel ">RedShift Logs</span>
                            </div>
                          </div>
                        </div>

                        <div class=" row formRow px-md-2 px-0 my-1 align-items-center">
                          <div class="col-xl-4 col-lg-6 col-12 form-line d-inline-flex align-items-center ">
                            <div class="d-inline-flex justify-content-between align-items-center bg-special border-10 w-100 pr-2 ">
                              <label class="mb-0 font-12 flex-md-1">
                                Centralized Logging S3 Bucket Account
                              </label>
                              <div class="flex-md-1">
                                <select
                                  class="form-control"
                                  value={
                                    formData.centralizedloggings3bucketaccount
                                      .value
                                  }
                                  onChange={(e) => {
                                    setIsDirty(true);
                                    const value = e.target.value;
                                    setFormData((state) =>
                                      update(state, {
                                        centralizedloggings3bucketaccount: {
                                          value: {
                                            $set: value,
                                          },
                                        },
                                      })
                                    );
                                  }}
                                >
                                  <option value="">Select</option>
                                  {serviceAccounts.security.map((sa, idxSa) => (
                                    <option value={sa} key={idxSa}>
                                      {sa}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>

                          <div class="col-xl-3 col-lg-6 col-12 form-line d-inline-flex align-items-center ">
                            <div class="d-inline-flex justify-content-between align-items-center bg-special border-10 w-100 pr-2 ">
                              <label class="mb-0 font-12 flex-md-1">
                                Archive Logs After
                              </label>
                              <div class="flex-md-1">
                                <select
                                  class="form-control"
                                  value={formData.archivelogsafter.value}
                                  onChange={(e) => {
                                    setIsDirty(true);
                                    const value = e.target.value;
                                    setFormData((state) =>
                                      update(state, {
                                        archivelogsafter: {
                                          value: {
                                            $set: value,
                                          },
                                        },
                                      })
                                    );
                                  }}
                                >
                                  <option value="">Select</option>
                                  <option value="90">3 months</option>
                                  <option value="180">6 months</option>
                                  <option value="365">1 year</option>
                                  <option value="730">2 years</option>
                                  <option value="1095">3 years</option>
                                  <option value="1825">5 years</option>
                                  <option value="2555">7 years</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="col-xl-3 col-lg-6 col-12 form-line d-inline-flex align-items-center ">
                            <div class="d-inline-flex justify-content-between align-items-center bg-special border-10 w-100 pr-2 ">
                              <label class="mb-0 font-12 flex-md-1">
                                Retention Period
                              </label>
                              <div class="flex-md-1">
                                <select
                                  class="form-control"
                                  value={formData.retentionperiod.value}
                                  onChange={(e) => {
                                    setIsDirty(true);
                                    const value = e.target.value;
                                    setFormData((state) =>
                                      update(state, {
                                        retentionperiod: {
                                          value: {
                                            $set: value,
                                          },
                                        },
                                      })
                                    );
                                  }}
                                >
                                  <option value="">Select</option>
                                  <option value="90">3 months</option>
                                  <option value="180">6 months</option>
                                  <option value="365">1 year</option>
                                  <option value="730">2 years</option>
                                  <option value="1095">3 years</option>
                                  <option value="1825">5 years</option>
                                  <option value="2555">7 years</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex px-1 my-1 align-items-center">
                      <div class="d-inline-flex yes-no-area align-items-center">
                        <div class="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                          Create CloudTrial in all accounts and all selected
                          regions
                        </div>
                        <div class="btn-group btn-group-toggle btn-group-radio mw-100  mr-0">
                          <RadioGroup
                            field="createcloudtrail"
                            value={formData.createcloudtrail.value}
                            onChange={updateRadio}
                            isLarge={true}
                          ></RadioGroup>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              </fieldset>
              {console.log(formData)}
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.MONITORING_CLOUDWATCH_EVENTS + "/" + projectId
                      )
                    }
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                      CloudWatch Event Rules
                    </p>
                  </button>
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() =>
                      saveDataIfDirty(
                        ROUTES.MONITORING_LOG_ANALYTICS + "/" + projectId
                      )
                    }
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">
                      Log Analytics
                    </p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CentralizedLogging;
