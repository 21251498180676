import React, { useState, useEffect, useRef } from "react";
import update from "immutability-helper";

import axios from "axios";

import LeftHandMenu from "../../LeftHandMenu";
import TopMenu from "../../../TopMenu";
import DomainMenu from "../../DomainMenu";

import * as ROUTES from "../../../../constants/routes";
import { useHistory, useParams } from "react-router-dom";
import useAwsAmplify from "../../../../hooks/useAwsAmplify";

import useHideLeftMenu from "../../../../hooks/useHideLeftMenu";
import useUnload from "../../../../hooks/useUnload";
import useProjectDisabledStatus from "../../../../hooks/useProjectDisabledStatus";
import NoInitialData from "../../configure-common/NoInitialData";

//const projectId = "google_1";
const PREFIX = "/aws/iam/adfsintegration";

const FILE_SIZE_LIMIT_IN_BYTE = 1000000;
const UPLOAD_FOLDER = "templates";

const AdfsIntegration = () => {
  const { projectId } = useParams();

  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    deployadfsintegration: {
      value: "yes",
    },
    deploybaselineiamroles: {
      value: "yes",
    },
    array: [
      "CloudAdmin",
      "IAMAdmin",
      "SecurityAuditor",
      "SecurityViewOnly",
      "NetworkAdmin",
      "OperationsSupport",
      "OperationsViewOnly",
      "AnalyticsAdmin",
      "Finance",
    ],
  });

  const [empty, setEmpty] = useState(false);

  const [xmlFile, setXmlFile] = useState(null);
  const [existingXmlFile, setExistingXmlFile] = useState(null);

  const fileInputRef = useRef(null);

  const disabled = useProjectDisabledStatus(projectId, ["Launched"]);

  const awsAmplify = useAwsAmplify();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setErrorMsg(null);
        setIsLoading(true);

        const result = await awsAmplify.loadProjectData(projectId, PREFIX);
        console.log(result);

        setIsLoading(false);

        setEmpty(result.data.empty);

        if (result.data) {
          if(!result.data.empty){
            setFormData(convertBEToFE(result.data));
          }
          else{
            setFormData({
              deployadfsintegration: {
                value: "yes",
              },
              deploybaselineiamroles: {
                value: "yes",
              },
              array: [
                "CloudAdmin",
                "IAMAdmin",
                "SecurityAuditor",
                "SecurityViewOnly",
                "NetworkAdmin",
                "OperationsSupport",
                "OperationsViewOnly",
                "AnalyticsAdmin",
                "Finance",
              ],
            })
          }
        }
        else{
          setFormData({
            deployadfsintegration: {
              value: "yes",
            },
            deploybaselineiamroles: {
              value: "yes",
            },
            array: [
              "CloudAdmin",
              "IAMAdmin",
              "SecurityAuditor",
              "SecurityViewOnly",
              "NetworkAdmin",
              "OperationsSupport",
              "OperationsViewOnly",
              "AnalyticsAdmin",
              "Finance",
            ],
          })
        }
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMsg(err.response.data.message);

        if(err.response.data.message === "You have no access"){
          history.goBack();
        }
      }
    };

    fetchData();
  }, [projectId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await submit();
  };

  const submit = async () => {
    try {
      setIsLoading(true);

      let xmlFileName = null;

      if (xmlFile && xmlFile.size < FILE_SIZE_LIMIT_IN_BYTE &&
        formData.deployadfsintegration.value === "yes") {
        const initiateUploadResult = await awsAmplify.initiateUpload(
          projectId,
          UPLOAD_FOLDER,
          xmlFile.name
        );

        const uploadResult = await axios.put(
          initiateUploadResult.data.signedUrl,
          xmlFile,
          {
            headers: {
              "Content-Type": xmlFile.type,
            },
          }
        );

        console.log(uploadResult);

        if (uploadResult.status === 200) {
          xmlFileName = xmlFile.name;
        }
      }

      console.log(convertFEToBE(xmlFileName));

      const result = await awsAmplify.saveProjectData({
        prefix: PREFIX,
        projectId: projectId,
        projectData: convertFEToBE(xmlFileName),
      });
      console.log(result);
      setIsLoading(false);

      setIsDirty(false);

      if(empty){
        setEmpty(false);
      }
    } catch (err) {
      setIsLoading(false);

      console.log(err);
      setErrorMsg(err.response.data.message);
    }
  };

  const convertFEToBE = (xmlFileName) => {
    const beData = {
      [`${PREFIX}/deployadfsintegration`]: formData.deployadfsintegration.value,
    };

    if (formData.deployadfsintegration.value === "yes") {
      if (xmlFileName) {
        beData[`${PREFIX}/adfsmetadataxmls3key`] = `${xmlFileName}`;
      } else {
        if (existingXmlFile) {
          beData[
            `${PREFIX}/adfsmetadataxmls3key`
          ] = `${existingXmlFile.name}`;
        }
      }

      beData[`${PREFIX}/deploybaselineiamroles`] =
        formData.deploybaselineiamroles.value;

      if (formData.deploybaselineiamroles.value === "yes" && formData.array.length > 0) {
        console.log(formData.array.length);
        beData[`${PREFIX}/baselineiamroleslist`] = formData.array.join(",");
      }
    }

    return beData;
  };

  const convertBEToFE = (beData) => {
    const feData = {
      deployadfsintegration: {
        value: beData[`${PREFIX}/deployadfsintegration`],
      },
      deploybaselineiamroles: {
        value: "yes",
      },
      array: [
        "CloudAdmin",
        "IAMAdmin",
        "SecurityAuditor",
        "SecurityViewOnly",
        "NetworkAdmin",
        "OperationsSupport",
        "OperationsViewOnly",
        "AnalyticsAdmin",
        "Finance",
      ],
    };

    feData.deployadfsintegration.value =
      beData[`${PREFIX}/deployadfsintegration`];

    if (feData.deployadfsintegration.value === "yes") {
      if (beData[`${PREFIX}/adfsmetadataxmls3key`]) {
        setXmlFile({
          name: beData[`${PREFIX}/adfsmetadataxmls3key`],
        });
        setExistingXmlFile({
          name: beData[`${PREFIX}/adfsmetadataxmls3key`],
        });
      }

      feData.deploybaselineiamroles = {
        value: beData[`${PREFIX}/deploybaselineiamroles`],
      };

      if (feData.deploybaselineiamroles.value === "yes") {
        if(beData[`${PREFIX}/baselineiamroleslist`]){
        feData.array = beData[`${PREFIX}/baselineiamroleslist`].split(",");
      }
      else{
        feData.array = [];
      }
      }
    }

    return feData;
  };

  const exists = (value) => {
    return formData.array.indexOf(value) !== -1;
  };

  const modifyArray = (value) => {
    setIsDirty(true);

    if (exists(value)) {
      setFormData((state) => {
        return {
          ...state,
          array: update(state.array, {
            $splice: [[state.array.indexOf(value), 1]],
          }),
        };
      });
    } else {
      setFormData((state) => {
        return {
          ...state,
          array: update(state.array, { $push: [value] }),
        };
      });
    }
  };

  const saveDataIfDirty = async (routeToGoTo) => {
    if (isDirty) {
      await submit();
    }
    history.push(routeToGoTo);
  };

  useHideLeftMenu();
  useUnload();

  return (
    <>
      {errorMsg && (
        <div className="alert-area">
          <div className="redalert">
            <div className="warnImg">
              <img alt="" src="../images/warning.svg" />
            </div>
            <span className="closebtn" onClick={() => setErrorMsg(null)}>
              &times;
            </span>
            <div className="messageText">
              <strong>Error!</strong> {errorMsg}
            </div>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="alert-area">
          <div className="bluealert">
            <div className="messageText">Please wait...</div>
          </div>
        </div>
      )}
      <div className="userTitleTop">Identity&Access</div>
      <LeftHandMenu
        saveDataIfDirty={saveDataIfDirty}
        domain={`${PREFIX.split("/")[1]}`}
      ></LeftHandMenu>
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="fitToScreenRight h-100 d-flex flex-column">
          <TopMenu saveDataIfDirty={saveDataIfDirty}></TopMenu>
            <form
              onSubmit={onSubmit}
              className="tableArea accountsTableArea aplicationTableArea haveFilter container-fluid p-0 d-flex flex-column"
            >
              <DomainMenu
                saveDataIfDirty={saveDataIfDirty}
                title="Identity & Access"
                isDirty={isDirty}
                dashboardRoute={ROUTES.IDENTITY_PROVIDERS}
                designRoute={ROUTES.DESIGN_IAM}
              ></DomainMenu>
              <fieldset disabled={disabled} className="mainArea fitDeviceHeight">
              <div className="mainArea fitDeviceHeight flex-column pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 ">
                <div className="row mb-2 d-flex align-items-center">
                  <div className="col-xl-6 col-lg-5 col-md-4 pl-lg-0 px-2">
                    <h3>Azure ADFS Integration</h3>
                  </div>
                  <div className="col-xl-6 col-lg-7 col-md-8 d-flex pr-0 align-items-center justify-content-end rightNavArea">
                    <div className="d-inline-flex yes-no-area align-items-center">
                      <div className="content-label mr-xl-5 mr-lg-3 mr-1 font-12">
                        Deploy Integration With Azure ADFS
                      </div>
                      <div className="btn-group btn-group-toggle btn-group-radio mw-100 mr-0">
                        <label
                          className={`createElementBtn btn btn-link btn-linkYes ${
                            formData.deployadfsintegration.value === "yes"
                              ? "active"
                              : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name="options"
                            checked={
                              formData.deployadfsintegration.value === "yes"
                            }
                            value="yes"
                            onChange={() => {
                              setIsDirty(true);
                              setFormData((state) => {
                                return {
                                  ...state,
                                  deployadfsintegration: {
                                    value: "yes",
                                  },
                                };
                              });
                            }}
                          />{" "}
                          YES
                        </label>
                        <label
                          className={`dontCreateElementBtn btn btn-link btn-linkNo ${
                            formData.deployadfsintegration.value === "no"
                              ? "active"
                              : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name="options"
                            checked={
                              formData.deployadfsintegration.value === "no"
                            }
                            value="no"
                            onChange={() => {
                              setIsDirty(true);
                              setFormData((state) => {
                                return {
                                  ...state,
                                  deployadfsintegration: {
                                    value: "no",
                                  },
                                };
                              });
                            }}
                          />{" "}
                          NO
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {empty && (
                    <NoInitialData />
                )}
                {formData.deployadfsintegration.value === "yes" && (
                  <div className="show-hide-content">
                    <div className=" d-inline-flex row formRow w-100 topOfFormRow  px-md-2 my-1 align-items-center justify-content-between">
                      <div className="uploadImage row d-inline-flex align-items-center justify-content-between">
                        <div className="col-lg-6 col-12 pl-lg-3 pl-0 ">
                          <h2 className="mb-0 font-weight-normal font-12">
                            Upload Azure ADFS Metadata XML
                          </h2>
                        </div>

                        <div className="col-lg-6 col-12 one form-line d-inline-flex align-items-center pr-0">
                          <div
                            className="d-flex align-items-center uploadIcon mr-3"
                            onClick={() => fileInputRef.current.click()}
                          >
                            <img src="../images/upload.svg" />
                          </div>
                          <button
                            disabled={disabled || !xmlFile}
                            className="closableInput"
                            onClick={() => {
                              setIsDirty(true);
                              setXmlFile(null);
                              setExistingXmlFile(null);
                            }}
                          ></button>
                          <input
                            type="file"
                            id="upload-photo"
                            name="photo"
                            className="form-control"
                            ref={fileInputRef}
                            onChange={(e) => {
                              const file = e.target.files[0];

                              if (file.size > FILE_SIZE_LIMIT_IN_BYTE) {
                                setErrorMsg(
                                  `${file.name} has exceeded ${FILE_SIZE_LIMIT_IN_BYTE} Bytes`
                                );
                                return;
                              }

                              if (file.type !== "text/xml") {
                                setErrorMsg("Only XML file is allowed");
                                return;
                              }
                              const newFileName = "iam-adfsintegration-adfsmetadata.xml";
                              var blob = file.slice(0, file.size, file.type); 
                              var newFile = new File([blob], newFileName, {type: ''});
                              setXmlFile(newFile);
                             // setXmlFile(file);

                              setIsDirty(true);
                            }}
                          />
                          <label
                            className="flex-1 py-2 pl-3 mw-200 bg-white color-spc border-10 fileInpt d-flex align-items-center m-0"
                            htmlFor="upload-photo"
                          >
                            {xmlFile
                              ? xmlFile.name
                              : "Upload Azure ADFS Metadata XML"}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-column mainContentArea  pl-3 pr-3 my-1 py-1">
                      <div className="d-inline-flex w-100 my-1">
                        <div className="pl-lg-0 px-2 d-flex align-items-center mr-xl-5 mr-3 font-12">
                          <div>Deploy Baseline Roles in Member Accounts</div>
                        </div>
                        <div className="btn-group btn-group-toggle btn-group-radio mw-100 mr-0">
                          <label
                            className={`createSubElementBtn btn btn-link btn-linkYes ${
                              formData.deploybaselineiamroles.value === "yes"
                                ? "active"
                                : ""
                            }`}
                          >
                            <input
                              type="radio"
                              name="options"
                              value="yes"
                              checked={
                                formData.deploybaselineiamroles.value === "yes"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                setFormData((state) => {
                                  return {
                                    ...state,
                                    deploybaselineiamroles: {
                                      value: "yes",
                                    },
                                  };
                                });
                              }}
                            />{" "}
                            YES
                          </label>
                          <label
                            className={`dontCreateSubElementBtn btn btn-link btn-linkNo ${
                              formData.deploybaselineiamroles.value === "no"
                                ? "active"
                                : ""
                            }`}
                          >
                            <input
                              type="radio"
                              name="options"
                              checked={
                                formData.deploybaselineiamroles.value === "no"
                              }
                              onChange={() => {
                                setIsDirty(true);
                                setFormData((state) => {
                                  return {
                                    ...state,
                                    deploybaselineiamroles: {
                                      value: "no",
                                    },
                                  };
                                });
                              }}
                            />{" "}
                            NO
                          </label>
                        </div>
                      </div>

                      {formData.deploybaselineiamroles.value === "yes" && (
                        <div className="show-hide-sub-content">
                          <div className=" row  px-md-2 px-0  align-items-center justify-content-between">
                            <div className="col-lg-3 my-1 col-md-6 col-6 form-line d-inline-flex align-items-center ">
                              <div className="d-inline-flex align-items-center bg-special border-10 w-100 pr-2 py-2">
                                <label className="switch mb-0 ml-0">
                                  <input
                                    type="checkbox"
                                    checked={exists("CloudAdmin")}
                                    onChange={() => modifyArray("CloudAdmin")}
                                  />
                                  <span className="slider round"></span>
                                </label>
                                <span className="switchLabel ">
                                  Cloud Admin
                                </span>
                              </div>
                            </div>
                            <div className="col-lg-3 my-1 col-md-6 col-6  form-line d-inline-flex align-items-center">
                              <div className="d-inline-flex align-items-center bg-special border-10 w-100 pr-2 py-2">
                                <label className="switch mb-0 ml-lg-3 ml-0">
                                  <input
                                    type="checkbox"
                                    checked={exists("IAMAdmin")}
                                    onChange={() => modifyArray("IAMAdmin")}
                                  />
                                  <span className="slider round"></span>
                                </label>
                                <span className="switchLabel">IAM Admin</span>
                              </div>
                            </div>
                            <div className="col-lg-3 my-1 col-md-6 col-6 form-line d-inline-flex align-items-center">
                              <div className="d-inline-flex align-items-center bg-special border-10 w-100 pr-2 py-2">
                                <label className="switch mb-0 ml-lg-3 ml-0">
                                  <input
                                    type="checkbox"
                                    checked={exists("SecurityAuditor")}
                                    onChange={() =>
                                      modifyArray("SecurityAuditor")
                                    }
                                  />
                                  <span className="slider round"></span>
                                </label>
                                <span className="switchLabel ">
                                  Security Auditor
                                </span>
                              </div>
                            </div>
                            <div className="col-lg-3 my-1 col-md-6 col-6 form-line d-inline-flex align-items-center">
                              <div className="d-inline-flex align-items-center bg-special border-10 w-100  pr-2 py-2">
                                <label className="switch mb-0 ml-lg-3 ml-0">
                                  <input
                                    type="checkbox"
                                    checked={exists("SecurityViewOnly")}
                                    onChange={() =>
                                      modifyArray("SecurityViewOnly")
                                    }
                                  />
                                  <span className="slider round"></span>
                                </label>
                                <span className="switchLabel">
                                  Security View Only
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className=" row  px-md-2 px-0  align-items-center">
                            <div className="col-lg-3 my-1 col-md-6 col-6 form-line d-inline-flex align-items-center ">
                              <div className="d-inline-flex align-items-center bg-special border-10 w-100 pr-2 py-2">
                                <label className="switch mb-0 ml-0">
                                  <input
                                    type="checkbox"
                                    checked={exists("NetworkAdmin")}
                                    onChange={() => modifyArray("NetworkAdmin")}
                                  />
                                  <span className="slider round"></span>
                                </label>
                                <span className="switchLabel ">
                                  Network Admin
                                </span>
                              </div>
                            </div>
                            <div className="col-lg-3 my-1 col-md-6 col-6  form-line d-inline-flex align-items-center">
                              <div className="d-inline-flex align-items-center bg-special border-10 w-100 pr-2 py-2">
                                <label className="switch mb-0 ml-lg-3 ml-0">
                                  <input
                                    type="checkbox"
                                    checked={exists("OperationsSupport")}
                                    onChange={() =>
                                      modifyArray("OperationsSupport")
                                    }
                                  />
                                  <span className="slider round"></span>
                                </label>
                                <span className="switchLabel">
                                  Operations Support
                                </span>
                              </div>
                            </div>
                            <div className="col-lg-3 my-1 col-md-6 col-6 form-line d-inline-flex align-items-center">
                              <div className="d-inline-flex align-items-center bg-special border-10 w-100 pr-2 py-2">
                                <label className="switch mb-0 ml-lg-3 ml-0">
                                  <input
                                    type="checkbox"
                                    checked={exists("OperationsViewOnly")}
                                    onChange={() =>
                                      modifyArray("OperationsViewOnly")
                                    }
                                  />
                                  <span className="slider round"></span>
                                </label>
                                <span className="switchLabel">
                                  Operations View Only
                                </span>
                              </div>
                            </div>
                            <div className="col-lg-3 my-1 col-md-6 col-6 form-line d-inline-flex align-items-center">
                              <div className="d-inline-flex align-items-center bg-special border-10 w-100  pr-2 py-2">
                                <label className="switch mb-0 ml-lg-3 ml-0">
                                  <input
                                    type="checkbox"
                                    checked={exists("AnalyticsAdmin")}
                                    onChange={() =>
                                      modifyArray("AnalyticsAdmin")
                                    }
                                  />
                                  <span className="slider round"></span>
                                </label>
                                <span className="switchLabel">
                                  Analytics Admin
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className=" row  px-md-2 px-0  align-items-center">
                            <div className="col-lg-3 my-1 col-md-6 col-6 form-line d-inline-flex align-items-center ">
                              <div className="d-inline-flex align-items-center bg-special border-10 w-100 pr-2 py-2">
                                <label className="switch mb-0 ml-0">
                                  <input
                                    type="checkbox"
                                    checked={exists("Finance")}
                                    onChange={() => modifyArray("Finance")}
                                  />
                                  <span className="slider round"></span>
                                </label>
                                <span className="switchLabel">
                                  Finance Billing
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              </fieldset>
              <div>
                <div className="d-flex justify-content-end footerOfMainArea pl-xl-5 pr-xl-5 py-xl-3 p-lg-4 p-0">
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() => saveDataIfDirty(ROUTES.OKTA_INTEGRATION+"/"+projectId)}
                  >
                    <img src="../images/ribbon-design.svg" />
                    <p className="m-0 p-0 ml-2 font-weight-bold">
                      Okta Integration
                    </p>
                  </button>
                  <button
                    type="button"
                    className="btn-post btn-animation d-inline-flex"
                    onClick={() => saveDataIfDirty(ROUTES.AWS_SSO_INTEGRATION+"/"+projectId)}
                  >
                    <p className="m-0 p-0 mr-2 font-weight-bold">
                      AWS SSO Integration
                    </p>
                    <img src="../images/ribbon-designRight.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdfsIntegration;
