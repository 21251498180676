import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import Dashboard from "../../../Dashboard";
import * as ROUTES from "../../../../../constants/routes";
import DetailsTags from "../../../dashboard-common/DetailsTags";
import { getArrayValue } from "../../../dashboard-common/DashboardHelper";
import DetailsGrid from "../../../dashboard-common/DetailsGrid";

const dashboardName = "KMS CMKs";

const SubMenu = () => {
  const { projectId } = useParams();

  return (
    <>
      <h3>Key Management</h3>
      <div className="otherTableTitles ">
        <h3 className="font-15 activetable">  
          Keys      
        </h3>
        <h3 className="font-15">   
        <Link className="text-color-gray" to={ROUTES.DATA_PROTECTION_KEY_STORES + "/" + projectId}>  
           KeyStores
        </Link>          
        </h3>
      </div>
    </>
  );
};


  const Details = ({ close, selectedRow }) => {
    const [type, setType] = useState("Details");
  
    return (
      <div className="justify-content-center align-items-end account-detail">
        <div className="w-100 s-flex flex-column account-detail-area">
          <button className="exitAccount" onClick={() => close()}>
            <img src="../images/close.svg" />
          </button>
          <div className="">
            <ul className="account-detail-link m-0 p-0 d-inline-flex">
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Details" && "my_active"
                }`}
                onClick={() => setType("Details")}
              >
                Details
              </li>
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Key Policy" && "my_active"
                }`}
                onClick={() => setType("Key Policy")}
              >
               Key Policy
              </li>           
              <li
                className={`bolgeler-btn my-active px-3 py-2 ${
                  type === "Tags" && "my_active"
                }`}
                onClick={() => setType("Tags")}
              >
                Tags
              </li> 
            </ul>
          </div>
          <div className="d-flex w-100 account-detail-container-area">
            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Details" && "inaktif"
              }`}
            >
              <div className="col-xl-4 col-lg-5 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Account ID</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.AWSAccountId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Region</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Region}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Key Alias</p>
                  <p className="m-0 p-0 account-title-subcontent">
                   {selectedRow.KeyAlias}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Description</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Description}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Key Id
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.KeyId}
                  </p>
                </div>
  
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Origin</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Origin}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Key Manager</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.KeyManager}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Key State</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.KeyState}
                  </p>
                </div>
              </div>
              <div className="col-xl-8 col-lg-7 col-md-12 col-12 d-flex flex-column">
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Key Usage
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.KeyUsage}
                  </p>
                </div>
  
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Creation Date
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.CreationDate}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">Key ARN</p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.AWSAccountId}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Enabled
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    {selectedRow.Enabled.toString()}
                  </p>
                </div>
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Customer Master Key Spec
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    { selectedRow.CustomerMasterKeySpec}
                  </p>
                </div>
             
                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Encryption Algorithms
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    { selectedRow.EncryptionAlgorithms}
                  </p>
                </div>

                <div className="d-inline-flex w-100 my-1">
                  <p className="m-0 p-0 account-detail-subtitle">
                  Key Rotation Enabled
                  </p>
                  <p className="m-0 p-0 account-title-subcontent">
                    { selectedRow.KeyRotation && selectedRow.KeyRotation.KeyRotationEnabled.toString()}
                  </p>
                </div>
              </div>
            </div>

            <div
              className={`bolgeler-container w-100 row p-5 ${
                type !== "Key Policy" && "inaktif"
              }`}
            >
             <div className="col-12">
              <p className="color-spc3">
                <pre className="font-12">
                 JSON TEXT
                  {/*TODO:  Data not available
                   {JSON.stringify(selectedRow.KeyPolicy, null,2)} */}
                </pre>
              </p>
            </div>
         </div>

         <DetailsTags type={type} tags={selectedRow.Tags}></DetailsTags>

          </div>
        </div>
      </div>
    );
  };

  const Navigations = () => {
    const { projectId } = useParams();
    return (
      <>
      <Link
        className="btn-post btn-animation d-inline-flex"
        to={ROUTES.DATA_PROTECTION_ACM + "/" + projectId}
      >
        <img src="../images/ribbon-design.svg" />
        <p className="m-0 p-0 ml-2 font-weight-bold">ACM Certificates</p>       
      </Link>
      </>
    );
  };
  
  
const Keys = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Account ID",
        accessor: "AccountId",
        id: "Account ID"
      },
      {
        Header: "Region",
        accessor: "Region",
      },
      {
        Header: "Key Alias",
        accessor: "KeyAlias",
        id: "Key Alias"
      },
      {
        Header: "Key Description",
        accessor: "Description",
        id: "Key Description"
      },
      {
        Header: "Key ID",
        accessor: "KeyId",
        id: "Key ID"
      },
      {
        Header: "Origin",
        accessor: "Origin",
        id: "Origin"
      },
      {
        Header: "Key Manager",
        accessor: "KeyManager",
        id: "Key Manager"
      },
      {
        Header: "Key State",
        accessor: "KeyState",
        id: "Key State"
      },
      {
        Header: "Key Usage",
        accessor: "KeyUsage",
        id: "Key Usage"
      },
      {
        Header: "Creation Date",
        accessor: "CreationDate",
        id: "Creation Date"
      },
      {
        Header: "Key ARN",
        accessor: "Arn",
        id: "Key ARN"
      },
      {
        Header: "Enabled",
        accessor: (value) =>
        value.hasOwnProperty("Enabled") && value.Enabled.toString(),
        id: "Enabled"
      },
    ],
    []
  );

  return Dashboard({
    domain: "dataprotection",
    title: "Data Protection",
    dashboardName,
    columns,
    Details,
    Navigations,
    configureRoute: ROUTES.DATA_PROTECTION_KEY_MANAGEMENT,
    designRoute: ROUTES.DESIGN_DATA_PROTECTION,
    SubMenu: SubMenu,
  });
};

export default Keys;