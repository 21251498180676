import { useEffect, useState } from "react";
import useAwsAmplify from "./useAwsAmplify";
import $ from "jquery";

const useProjectDisabledStatus = (projectId, disabledStatuses) => {
  const awsAmplify = useAwsAmplify();
  const [disabled, setDisabled] = useState(false);

  const disabledProjectStatuses = ["Launching", "Deploying"];

  useEffect(() => {
    const fetch = async () => {
      const results = await awsAmplify.getProjectStatus(projectId);

      if (results.data && results.data.getProjectStatus) {
        const { status } = results.data.getProjectStatus;

        setDisabled(disabledProjectStatuses.includes(status));
      }
    };

    fetch();
  }, [projectId]);

  return disabled;
};

export default useProjectDisabledStatus;
